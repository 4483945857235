import React from "react";
import {Route, Switch, useHistory} from "react-router-dom";
import '@progress/kendo-theme-default/dist/all.css';
import Header from "./Header/Header";
import {AuthProvider, Container} from 'epri-react-core-components';
import ErrorBoundary from './ErrorBoundary'
import './index.scss'
import Footer from "./Footer/Footer";
import routes from "./routes";
import Nav from "./Header/Nav/Nav";
import LoggedInRoute from "./LoggedInRoute";
import {AUDIENCE, AUTH_REDIRECT_PARAM, CLIENT_ID, CONNECTION, REST_BASEURL, DOMAIN} from "./constants";
import TagManager from 'react-gtm-module'

const App = () => {
    const history = useHistory()

    const authOptions = {
        domain:DOMAIN,
        clientId: CLIENT_ID,
        audience: AUDIENCE,
        baseUrl: REST_BASEURL,
        authRedirectParam: AUTH_REDIRECT_PARAM,
        connection: CONNECTION,
        redirectCallback: (path: string) => {
            history?.push(path)
        }
    }

    const tagManagerArgs = {
        gtmId: 'GTM-MQTBRV2'
    }

    TagManager.initialize(tagManagerArgs)

    return <AuthProvider options={authOptions}>
        <div className='flex flex-col min-h-screen'>
            <Header/>
            <Switch>
                <div className='flex-grow'>
                    <Nav/>
                    <Container>
                        <React.Suspense fallback={<div>Loading...</div>}>
                            <ErrorBoundary>
                                {routes.publicRoutes?.map(it => <Route key={it.route}
                                                                       path={it.route} exact={it.exact}
                                                                       component={it.component}
                                />)}
                                {routes.authorizedRoutes?.map(it =>
                                    <Route key={it.route} path={it.route} exact={it.exact}>
                                        <LoggedInRoute component={it.component}/>
                                    </Route>
                                )}
                            </ErrorBoundary>
                        </React.Suspense>
                    </Container>
                </div>
            </Switch>
            <Footer/>
        </div>
    </AuthProvider>
};

export default App;