import * as Apollo from '@apollo/client';
import {gql} from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /**
     * A date-time string at UTC, such as 2007-12-03T10:15:30Z,
     *     compliant with the 'date-time' format outlined in section 5.6 of
     *     the RFC 3339 profile of the ISO 8601 standard for representation
     *     of dates and times using the Gregorian calendar.
     */
    DateTime: any;
    /** The 'Dimension' type represents dimensions as whole numeric values between `1` and `4000`. */
    Dimension: any;
    /** The 'HexColor' type represents color in `rgb:ffffff` string format. */
    HexColor: any;
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSON: any;
    /** The 'Quality' type represents quality as whole numeric values between `1` and `100`. */
    Quality: any;
    /**
     * A date-time string at UTC, such as 2007-12-03T10:15:30Z,
     *     compliant with the 'date-time' format outlined in section 5.6 of
     *     the RFC 3339 profile of the ISO 8601 standard for representation
     *     of dates and times using the Gregorian calendar.
     */
    eprijournal_DateTime: any;
    /** The 'Dimension' type represents dimensions as whole numeric values between `1` and `4000`. */
    eprijournal_Dimension: any;
    /** The 'HexColor' type represents color in `rgb:ffffff` string format. */
    eprijournal_HexColor: any;
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    eprijournal_JSON: any;
    /** The 'Quality' type represents quality as whole numeric values between `1` and `100`. */
    eprijournal_Quality: any;
};

export type AcgMember = {
    acgName?: Maybe<Scalars['String']>;
    acgType?: Maybe<Scalars['String']>;
    address1?: Maybe<Scalars['String']>;
    address2?: Maybe<Scalars['String']>;
    addressCity?: Maybe<Scalars['String']>;
    addressCountryCode?: Maybe<Scalars['String']>;
    addressCountryDesc?: Maybe<Scalars['String']>;
    addressPostalCode?: Maybe<Scalars['String']>;
    addressState?: Maybe<Scalars['String']>;
    companyId?: Maybe<Scalars['String']>;
    companyName?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    defaultFunderCompanyId?: Maybe<Scalars['String']>;
    defaultFunderCompanyName?: Maybe<Scalars['String']>;
    domainNames?: Maybe<Scalars['String']>;
    funder?: Maybe<Scalars['String']>;
    hasPendingEdit?: Maybe<Scalars['Boolean']>;
    inCurrentYear?: Maybe<Scalars['String']>;
    inNextYear?: Maybe<Scalars['String']>;
    isConfirmed?: Maybe<Scalars['Boolean']>;
    lastConfirmedBy?: Maybe<Scalars['String']>;
    lastconfirmedDate?: Maybe<Scalars['String']>;
    legalEntityName?: Maybe<Scalars['String']>;
    nonBinding?: Maybe<Scalars['Boolean']>;
    nonUtility?: Maybe<Scalars['Boolean']>;
    stringAddress?: Maybe<Scalars['String']>;
    year?: Maybe<Scalars['String']>;
};

/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aboutEpri) */
export type AboutEpri = Entry & {
  contentfulMetadata: ContentfulMetadata;
  jumbotron?: Maybe<Jumbotron>;
  linkedFrom?: Maybe<AboutEpriLinkingCollections>;
  permalink?: Maybe<Scalars['String']>;
  sectionOneBody?: Maybe<Scalars['String']>;
  sectionOneImage?: Maybe<Asset>;
  sectionOneTitle?: Maybe<Scalars['String']>;
  sectionThreeBody?: Maybe<Scalars['String']>;
  sectionThreeImage?: Maybe<Asset>;
  sectionThreeTitle?: Maybe<Scalars['String']>;
  sectionTwoBody?: Maybe<Scalars['String']>;
  sectionTwoImage?: Maybe<Asset>;
  sectionTwoTitle?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aboutEpri) */
export type AboutEpriJumbotronArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aboutEpri) */
export type AboutEpriLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aboutEpri) */
export type AboutEpriPermalinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aboutEpri) */
export type AboutEpriSectionOneBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aboutEpri) */
export type AboutEpriSectionOneImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aboutEpri) */
export type AboutEpriSectionOneTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aboutEpri) */
export type AboutEpriSectionThreeBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aboutEpri) */
export type AboutEpriSectionThreeImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aboutEpri) */
export type AboutEpriSectionThreeTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aboutEpri) */
export type AboutEpriSectionTwoBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aboutEpri) */
export type AboutEpriSectionTwoImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aboutEpri) */
export type AboutEpriSectionTwoTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aboutEpri) */
export type AboutEpriTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type AboutEpriCollection = {
  items: Array<Maybe<AboutEpri>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AboutEpriFilter = {
  AND?: InputMaybe<Array<InputMaybe<AboutEpriFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AboutEpriFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  jumbotron?: InputMaybe<CfJumbotronNestedFilter>;
  jumbotron_exists?: InputMaybe<Scalars['Boolean']>;
  permalink?: InputMaybe<Scalars['String']>;
  permalink_contains?: InputMaybe<Scalars['String']>;
  permalink_exists?: InputMaybe<Scalars['Boolean']>;
  permalink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  permalink_not?: InputMaybe<Scalars['String']>;
  permalink_not_contains?: InputMaybe<Scalars['String']>;
  permalink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionOneBody?: InputMaybe<Scalars['String']>;
  sectionOneBody_contains?: InputMaybe<Scalars['String']>;
  sectionOneBody_exists?: InputMaybe<Scalars['Boolean']>;
  sectionOneBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionOneBody_not?: InputMaybe<Scalars['String']>;
  sectionOneBody_not_contains?: InputMaybe<Scalars['String']>;
  sectionOneBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionOneImage_exists?: InputMaybe<Scalars['Boolean']>;
  sectionOneTitle?: InputMaybe<Scalars['String']>;
  sectionOneTitle_contains?: InputMaybe<Scalars['String']>;
  sectionOneTitle_exists?: InputMaybe<Scalars['Boolean']>;
  sectionOneTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionOneTitle_not?: InputMaybe<Scalars['String']>;
  sectionOneTitle_not_contains?: InputMaybe<Scalars['String']>;
  sectionOneTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionThreeBody?: InputMaybe<Scalars['String']>;
  sectionThreeBody_contains?: InputMaybe<Scalars['String']>;
  sectionThreeBody_exists?: InputMaybe<Scalars['Boolean']>;
  sectionThreeBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionThreeBody_not?: InputMaybe<Scalars['String']>;
  sectionThreeBody_not_contains?: InputMaybe<Scalars['String']>;
  sectionThreeBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionThreeImage_exists?: InputMaybe<Scalars['Boolean']>;
  sectionThreeTitle?: InputMaybe<Scalars['String']>;
  sectionThreeTitle_contains?: InputMaybe<Scalars['String']>;
  sectionThreeTitle_exists?: InputMaybe<Scalars['Boolean']>;
  sectionThreeTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionThreeTitle_not?: InputMaybe<Scalars['String']>;
  sectionThreeTitle_not_contains?: InputMaybe<Scalars['String']>;
  sectionThreeTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionTwoBody?: InputMaybe<Scalars['String']>;
  sectionTwoBody_contains?: InputMaybe<Scalars['String']>;
  sectionTwoBody_exists?: InputMaybe<Scalars['Boolean']>;
  sectionTwoBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionTwoBody_not?: InputMaybe<Scalars['String']>;
  sectionTwoBody_not_contains?: InputMaybe<Scalars['String']>;
  sectionTwoBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionTwoImage_exists?: InputMaybe<Scalars['Boolean']>;
  sectionTwoTitle?: InputMaybe<Scalars['String']>;
  sectionTwoTitle_contains?: InputMaybe<Scalars['String']>;
  sectionTwoTitle_exists?: InputMaybe<Scalars['Boolean']>;
  sectionTwoTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionTwoTitle_not?: InputMaybe<Scalars['String']>;
  sectionTwoTitle_not_contains?: InputMaybe<Scalars['String']>;
  sectionTwoTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AboutEpriLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type AboutEpriLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AboutEpriOrder {
  PermalinkAsc = 'permalink_ASC',
  PermalinkDesc = 'permalink_DESC',
  SectionOneTitleAsc = 'sectionOneTitle_ASC',
  SectionOneTitleDesc = 'sectionOneTitle_DESC',
  SectionThreeTitleAsc = 'sectionThreeTitle_ASC',
  SectionThreeTitleDesc = 'sectionThreeTitle_DESC',
  SectionTwoTitleAsc = 'sectionTwoTitle_ASC',
  SectionTwoTitleDesc = 'sectionTwoTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type AccountExecutive = {
  contacts?: Maybe<Array<Maybe<Contact>>>;
  region?: Maybe<Scalars['String']>;
};

export enum Action {
  Add = 'Add',
  Update = 'Update'
}

export type AdvisorActivity = {
  advisor?: Maybe<CommitteeAdvisor>;
  committees?: Maybe<Array<Maybe<AdvisorCommittee>>>;
  downloads?: Maybe<Array<Maybe<AdvisorDownload>>>;
  events?: Maybe<Array<Maybe<AdvisorEvent>>>;
  favorites?: Maybe<Array<Maybe<AdvisorTipFavorite>>>;
};

export type AdvisorCommittee = {
  advisorRole?: Maybe<Scalars['String']>;
  committeeName?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
  siteNumber?: Maybe<Scalars['String']>;
};

export type AdvisorDownload = {
  documentType?: Maybe<Scalars['String']>;
  downloadDate?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
  shortProductId?: Maybe<Scalars['String']>;
  siteNumber?: Maybe<Scalars['String']>;
};

export type AdvisorEvent = {
  eventStartDate?: Maybe<Scalars['String']>;
  eventTitle?: Maybe<Scalars['String']>;
  hasAdvisorAttended?: Maybe<Scalars['Boolean']>;
  inviteStatus?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
  siteNumber?: Maybe<Scalars['String']>;
};

export type AdvisorRequestInput = {
  advisorContactId: Scalars['String'];
  advisorEmail?: InputMaybe<Scalars['String']>;
  advisorFirstName?: InputMaybe<Scalars['String']>;
  advisorFullName?: InputMaybe<Scalars['String']>;
  advisorLastName?: InputMaybe<Scalars['String']>;
  advisorRole?: InputMaybe<Scalars['String']>;
  committeeId?: InputMaybe<Scalars['String']>;
  committeeName?: InputMaybe<Scalars['String']>;
  companyGroup?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
  hasOptedOutNotification?: InputMaybe<Scalars['Boolean']>;
  requestType: RequestType;
};

export type AdvisorTipFavorite = {
  favoriteType?: Maybe<Scalars['String']>;
  hasSubscribed?: Maybe<Scalars['Boolean']>;
  isFavorite?: Maybe<Scalars['Boolean']>;
  sectorLTN?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
  siteLTN?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  siteNumber?: Maybe<Scalars['String']>;
};

/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/advisoryBoard) */
export type AdvisoryBoard = Entry & {
  advisoryCouncilCollection?: Maybe<AdvisoryBoardAdvisoryCouncilCollection>;
  advisoryCouncilNarucCollection?: Maybe<AdvisoryBoardAdvisoryCouncilNarucCollection>;
  contentfulMetadata: ContentfulMetadata;
  dividerText?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<AdvisoryBoardLinkingCollections>;
  printVersion?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  webVersion?: Maybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/advisoryBoard) */
export type AdvisoryBoardAdvisoryCouncilCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/advisoryBoard) */
export type AdvisoryBoardAdvisoryCouncilNarucCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/advisoryBoard) */
export type AdvisoryBoardDividerTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/advisoryBoard) */
export type AdvisoryBoardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/advisoryBoard) */
export type AdvisoryBoardPrintVersionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/advisoryBoard) */
export type AdvisoryBoardTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/advisoryBoard) */
export type AdvisoryBoardWebVersionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type AdvisoryBoardAdvisoryCouncilCollection = {
  items: Array<Maybe<AdvisoryBoardAdvisoryCouncilItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AdvisoryBoardAdvisoryCouncilItem = AdvisoryBoardCard | BoardOfDirectorCard;

export type AdvisoryBoardAdvisoryCouncilNarucCollection = {
  items: Array<Maybe<AdvisoryBoardAdvisoryCouncilNarucItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AdvisoryBoardAdvisoryCouncilNarucItem = AdvisoryBoardCard | BoardOfDirectorCard;

/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/advisoryBoardCard) */
export type AdvisoryBoardCard = Entry & {
  appointedByNaruc?: Maybe<Scalars['Boolean']>;
  bio?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  firstName?: Maybe<Scalars['String']>;
  image?: Maybe<Asset>;
  lastName?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<AdvisoryBoardCardLinkingCollections>;
  middleName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  title2?: Maybe<Scalars['String']>;
  title3?: Maybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/advisoryBoardCard) */
export type AdvisoryBoardCardAppointedByNarucArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/advisoryBoardCard) */
export type AdvisoryBoardCardBioArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/advisoryBoardCard) */
export type AdvisoryBoardCardFirstNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/advisoryBoardCard) */
export type AdvisoryBoardCardImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/advisoryBoardCard) */
export type AdvisoryBoardCardLastNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/advisoryBoardCard) */
export type AdvisoryBoardCardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/advisoryBoardCard) */
export type AdvisoryBoardCardMiddleNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/advisoryBoardCard) */
export type AdvisoryBoardCardNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/advisoryBoardCard) */
export type AdvisoryBoardCardTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/advisoryBoardCard) */
export type AdvisoryBoardCardTitle2Args = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/advisoryBoardCard) */
export type AdvisoryBoardCardTitle3Args = {
  locale?: InputMaybe<Scalars['String']>;
};

export type AdvisoryBoardCardCollection = {
  items: Array<Maybe<AdvisoryBoardCard>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AdvisoryBoardCardFilter = {
  AND?: InputMaybe<Array<InputMaybe<AdvisoryBoardCardFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AdvisoryBoardCardFilter>>>;
  appointedByNaruc?: InputMaybe<Scalars['Boolean']>;
  appointedByNaruc_exists?: InputMaybe<Scalars['Boolean']>;
  appointedByNaruc_not?: InputMaybe<Scalars['Boolean']>;
  bio?: InputMaybe<Scalars['String']>;
  bio_contains?: InputMaybe<Scalars['String']>;
  bio_exists?: InputMaybe<Scalars['Boolean']>;
  bio_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bio_not?: InputMaybe<Scalars['String']>;
  bio_not_contains?: InputMaybe<Scalars['String']>;
  bio_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  firstName?: InputMaybe<Scalars['String']>;
  firstName_contains?: InputMaybe<Scalars['String']>;
  firstName_exists?: InputMaybe<Scalars['Boolean']>;
  firstName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  firstName_not?: InputMaybe<Scalars['String']>;
  firstName_not_contains?: InputMaybe<Scalars['String']>;
  firstName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastName_contains?: InputMaybe<Scalars['String']>;
  lastName_exists?: InputMaybe<Scalars['Boolean']>;
  lastName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lastName_not?: InputMaybe<Scalars['String']>;
  lastName_not_contains?: InputMaybe<Scalars['String']>;
  lastName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  middleName?: InputMaybe<Scalars['String']>;
  middleName_contains?: InputMaybe<Scalars['String']>;
  middleName_exists?: InputMaybe<Scalars['Boolean']>;
  middleName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  middleName_not?: InputMaybe<Scalars['String']>;
  middleName_not_contains?: InputMaybe<Scalars['String']>;
  middleName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title2?: InputMaybe<Scalars['String']>;
  title2_contains?: InputMaybe<Scalars['String']>;
  title2_exists?: InputMaybe<Scalars['Boolean']>;
  title2_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title2_not?: InputMaybe<Scalars['String']>;
  title2_not_contains?: InputMaybe<Scalars['String']>;
  title2_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title3?: InputMaybe<Scalars['String']>;
  title3_contains?: InputMaybe<Scalars['String']>;
  title3_exists?: InputMaybe<Scalars['Boolean']>;
  title3_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title3_not?: InputMaybe<Scalars['String']>;
  title3_not_contains?: InputMaybe<Scalars['String']>;
  title3_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AdvisoryBoardCardLinkingCollections = {
  advisoryBoardCollection?: Maybe<AdvisoryBoardCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type AdvisoryBoardCardLinkingCollectionsAdvisoryBoardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AdvisoryBoardCardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AdvisoryBoardCardOrder {
  AppointedByNarucAsc = 'appointedByNaruc_ASC',
  AppointedByNarucDesc = 'appointedByNaruc_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  MiddleNameAsc = 'middleName_ASC',
  MiddleNameDesc = 'middleName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  Title2Asc = 'title2_ASC',
  Title2Desc = 'title2_DESC',
  Title3Asc = 'title3_ASC',
  Title3Desc = 'title3_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type AdvisoryBoardCollection = {
  items: Array<Maybe<AdvisoryBoard>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AdvisoryBoardFilter = {
  AND?: InputMaybe<Array<InputMaybe<AdvisoryBoardFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AdvisoryBoardFilter>>>;
  advisoryCouncilCollection_exists?: InputMaybe<Scalars['Boolean']>;
  advisoryCouncilNarucCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  dividerText?: InputMaybe<Scalars['String']>;
  dividerText_contains?: InputMaybe<Scalars['String']>;
  dividerText_exists?: InputMaybe<Scalars['Boolean']>;
  dividerText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dividerText_not?: InputMaybe<Scalars['String']>;
  dividerText_not_contains?: InputMaybe<Scalars['String']>;
  dividerText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  printVersion?: InputMaybe<Scalars['String']>;
  printVersion_contains?: InputMaybe<Scalars['String']>;
  printVersion_exists?: InputMaybe<Scalars['Boolean']>;
  printVersion_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  printVersion_not?: InputMaybe<Scalars['String']>;
  printVersion_not_contains?: InputMaybe<Scalars['String']>;
  printVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  webVersion?: InputMaybe<Scalars['String']>;
  webVersion_contains?: InputMaybe<Scalars['String']>;
  webVersion_exists?: InputMaybe<Scalars['Boolean']>;
  webVersion_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  webVersion_not?: InputMaybe<Scalars['String']>;
  webVersion_not_contains?: InputMaybe<Scalars['String']>;
  webVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AdvisoryBoardLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type AdvisoryBoardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AdvisoryBoardOrder {
  DividerTextAsc = 'dividerText_ASC',
  DividerTextDesc = 'dividerText_DESC',
  PrintVersionAsc = 'printVersion_ASC',
  PrintVersionDesc = 'printVersion_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  WebVersionAsc = 'webVersion_ASC',
  WebVersionDesc = 'webVersion_DESC'
}

/**
 * This is a generic component for repeated entries on the AI Page.  It can contain
 * industry news articles, cards, testimonials, or social media details. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiComponent)
 */
export type AiComponent = Entry & {
  contentfulMetadata: ContentfulMetadata;
  entryTitle?: Maybe<Scalars['String']>;
  entryType?: Maybe<Scalars['String']>;
  industryNewsDatePublished?: Maybe<Scalars['DateTime']>;
  industryNewsSourceSite?: Maybe<Scalars['String']>;
  industryNewsTitle?: Maybe<Scalars['String']>;
  industryNewsUrl?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<AiComponentLinkingCollections>;
  simpleCardBody?: Maybe<Scalars['String']>;
  simpleCardButtonText?: Maybe<Scalars['String']>;
  simpleCardButtonUrl?: Maybe<Scalars['String']>;
  simpleCardImage?: Maybe<Asset>;
  simpleCardTitle?: Maybe<Scalars['String']>;
  socialMediaContent?: Maybe<Scalars['String']>;
  socialMediaDateTime?: Maybe<Scalars['DateTime']>;
  socialMediaIcon?: Maybe<Asset>;
  socialMediaSource?: Maybe<Scalars['String']>;
  socialMediaUrl?: Maybe<Scalars['String']>;
  sys: Sys;
  testimonialBody?: Maybe<Scalars['String']>;
  testimonialName?: Maybe<Scalars['String']>;
  testimonialTitle?: Maybe<Scalars['String']>;
};


/**
 * This is a generic component for repeated entries on the AI Page.  It can contain
 * industry news articles, cards, testimonials, or social media details. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiComponent)
 */
export type AiComponentEntryTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * This is a generic component for repeated entries on the AI Page.  It can contain
 * industry news articles, cards, testimonials, or social media details. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiComponent)
 */
export type AiComponentEntryTypeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * This is a generic component for repeated entries on the AI Page.  It can contain
 * industry news articles, cards, testimonials, or social media details. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiComponent)
 */
export type AiComponentIndustryNewsDatePublishedArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * This is a generic component for repeated entries on the AI Page.  It can contain
 * industry news articles, cards, testimonials, or social media details. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiComponent)
 */
export type AiComponentIndustryNewsSourceSiteArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * This is a generic component for repeated entries on the AI Page.  It can contain
 * industry news articles, cards, testimonials, or social media details. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiComponent)
 */
export type AiComponentIndustryNewsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * This is a generic component for repeated entries on the AI Page.  It can contain
 * industry news articles, cards, testimonials, or social media details. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiComponent)
 */
export type AiComponentIndustryNewsUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * This is a generic component for repeated entries on the AI Page.  It can contain
 * industry news articles, cards, testimonials, or social media details. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiComponent)
 */
export type AiComponentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/**
 * This is a generic component for repeated entries on the AI Page.  It can contain
 * industry news articles, cards, testimonials, or social media details. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiComponent)
 */
export type AiComponentSimpleCardBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * This is a generic component for repeated entries on the AI Page.  It can contain
 * industry news articles, cards, testimonials, or social media details. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiComponent)
 */
export type AiComponentSimpleCardButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * This is a generic component for repeated entries on the AI Page.  It can contain
 * industry news articles, cards, testimonials, or social media details. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiComponent)
 */
export type AiComponentSimpleCardButtonUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * This is a generic component for repeated entries on the AI Page.  It can contain
 * industry news articles, cards, testimonials, or social media details. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiComponent)
 */
export type AiComponentSimpleCardImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/**
 * This is a generic component for repeated entries on the AI Page.  It can contain
 * industry news articles, cards, testimonials, or social media details. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiComponent)
 */
export type AiComponentSimpleCardTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * This is a generic component for repeated entries on the AI Page.  It can contain
 * industry news articles, cards, testimonials, or social media details. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiComponent)
 */
export type AiComponentSocialMediaContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * This is a generic component for repeated entries on the AI Page.  It can contain
 * industry news articles, cards, testimonials, or social media details. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiComponent)
 */
export type AiComponentSocialMediaDateTimeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * This is a generic component for repeated entries on the AI Page.  It can contain
 * industry news articles, cards, testimonials, or social media details. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiComponent)
 */
export type AiComponentSocialMediaIconArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/**
 * This is a generic component for repeated entries on the AI Page.  It can contain
 * industry news articles, cards, testimonials, or social media details. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiComponent)
 */
export type AiComponentSocialMediaSourceArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * This is a generic component for repeated entries on the AI Page.  It can contain
 * industry news articles, cards, testimonials, or social media details. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiComponent)
 */
export type AiComponentSocialMediaUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * This is a generic component for repeated entries on the AI Page.  It can contain
 * industry news articles, cards, testimonials, or social media details. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiComponent)
 */
export type AiComponentTestimonialBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * This is a generic component for repeated entries on the AI Page.  It can contain
 * industry news articles, cards, testimonials, or social media details. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiComponent)
 */
export type AiComponentTestimonialNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * This is a generic component for repeated entries on the AI Page.  It can contain
 * industry news articles, cards, testimonials, or social media details. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiComponent)
 */
export type AiComponentTestimonialTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type AiComponentCollection = {
  items: Array<Maybe<AiComponent>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AiComponentFilter = {
  AND?: InputMaybe<Array<InputMaybe<AiComponentFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AiComponentFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  entryTitle?: InputMaybe<Scalars['String']>;
  entryTitle_contains?: InputMaybe<Scalars['String']>;
  entryTitle_exists?: InputMaybe<Scalars['Boolean']>;
  entryTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  entryTitle_not?: InputMaybe<Scalars['String']>;
  entryTitle_not_contains?: InputMaybe<Scalars['String']>;
  entryTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  entryType?: InputMaybe<Scalars['String']>;
  entryType_contains?: InputMaybe<Scalars['String']>;
  entryType_exists?: InputMaybe<Scalars['Boolean']>;
  entryType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  entryType_not?: InputMaybe<Scalars['String']>;
  entryType_not_contains?: InputMaybe<Scalars['String']>;
  entryType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  industryNewsDatePublished?: InputMaybe<Scalars['DateTime']>;
  industryNewsDatePublished_exists?: InputMaybe<Scalars['Boolean']>;
  industryNewsDatePublished_gt?: InputMaybe<Scalars['DateTime']>;
  industryNewsDatePublished_gte?: InputMaybe<Scalars['DateTime']>;
  industryNewsDatePublished_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  industryNewsDatePublished_lt?: InputMaybe<Scalars['DateTime']>;
  industryNewsDatePublished_lte?: InputMaybe<Scalars['DateTime']>;
  industryNewsDatePublished_not?: InputMaybe<Scalars['DateTime']>;
  industryNewsDatePublished_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  industryNewsSourceSite?: InputMaybe<Scalars['String']>;
  industryNewsSourceSite_contains?: InputMaybe<Scalars['String']>;
  industryNewsSourceSite_exists?: InputMaybe<Scalars['Boolean']>;
  industryNewsSourceSite_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  industryNewsSourceSite_not?: InputMaybe<Scalars['String']>;
  industryNewsSourceSite_not_contains?: InputMaybe<Scalars['String']>;
  industryNewsSourceSite_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  industryNewsTitle?: InputMaybe<Scalars['String']>;
  industryNewsTitle_contains?: InputMaybe<Scalars['String']>;
  industryNewsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  industryNewsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  industryNewsTitle_not?: InputMaybe<Scalars['String']>;
  industryNewsTitle_not_contains?: InputMaybe<Scalars['String']>;
  industryNewsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  industryNewsUrl?: InputMaybe<Scalars['String']>;
  industryNewsUrl_contains?: InputMaybe<Scalars['String']>;
  industryNewsUrl_exists?: InputMaybe<Scalars['Boolean']>;
  industryNewsUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  industryNewsUrl_not?: InputMaybe<Scalars['String']>;
  industryNewsUrl_not_contains?: InputMaybe<Scalars['String']>;
  industryNewsUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  simpleCardBody?: InputMaybe<Scalars['String']>;
  simpleCardBody_contains?: InputMaybe<Scalars['String']>;
  simpleCardBody_exists?: InputMaybe<Scalars['Boolean']>;
  simpleCardBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  simpleCardBody_not?: InputMaybe<Scalars['String']>;
  simpleCardBody_not_contains?: InputMaybe<Scalars['String']>;
  simpleCardBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  simpleCardButtonText?: InputMaybe<Scalars['String']>;
  simpleCardButtonText_contains?: InputMaybe<Scalars['String']>;
  simpleCardButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  simpleCardButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  simpleCardButtonText_not?: InputMaybe<Scalars['String']>;
  simpleCardButtonText_not_contains?: InputMaybe<Scalars['String']>;
  simpleCardButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  simpleCardButtonUrl?: InputMaybe<Scalars['String']>;
  simpleCardButtonUrl_contains?: InputMaybe<Scalars['String']>;
  simpleCardButtonUrl_exists?: InputMaybe<Scalars['Boolean']>;
  simpleCardButtonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  simpleCardButtonUrl_not?: InputMaybe<Scalars['String']>;
  simpleCardButtonUrl_not_contains?: InputMaybe<Scalars['String']>;
  simpleCardButtonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  simpleCardImage_exists?: InputMaybe<Scalars['Boolean']>;
  simpleCardTitle?: InputMaybe<Scalars['String']>;
  simpleCardTitle_contains?: InputMaybe<Scalars['String']>;
  simpleCardTitle_exists?: InputMaybe<Scalars['Boolean']>;
  simpleCardTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  simpleCardTitle_not?: InputMaybe<Scalars['String']>;
  simpleCardTitle_not_contains?: InputMaybe<Scalars['String']>;
  simpleCardTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaContent?: InputMaybe<Scalars['String']>;
  socialMediaContent_contains?: InputMaybe<Scalars['String']>;
  socialMediaContent_exists?: InputMaybe<Scalars['Boolean']>;
  socialMediaContent_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaContent_not?: InputMaybe<Scalars['String']>;
  socialMediaContent_not_contains?: InputMaybe<Scalars['String']>;
  socialMediaContent_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaDateTime?: InputMaybe<Scalars['DateTime']>;
  socialMediaDateTime_exists?: InputMaybe<Scalars['Boolean']>;
  socialMediaDateTime_gt?: InputMaybe<Scalars['DateTime']>;
  socialMediaDateTime_gte?: InputMaybe<Scalars['DateTime']>;
  socialMediaDateTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  socialMediaDateTime_lt?: InputMaybe<Scalars['DateTime']>;
  socialMediaDateTime_lte?: InputMaybe<Scalars['DateTime']>;
  socialMediaDateTime_not?: InputMaybe<Scalars['DateTime']>;
  socialMediaDateTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  socialMediaIcon_exists?: InputMaybe<Scalars['Boolean']>;
  socialMediaSource?: InputMaybe<Scalars['String']>;
  socialMediaSource_contains?: InputMaybe<Scalars['String']>;
  socialMediaSource_exists?: InputMaybe<Scalars['Boolean']>;
  socialMediaSource_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaSource_not?: InputMaybe<Scalars['String']>;
  socialMediaSource_not_contains?: InputMaybe<Scalars['String']>;
  socialMediaSource_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaUrl?: InputMaybe<Scalars['String']>;
  socialMediaUrl_contains?: InputMaybe<Scalars['String']>;
  socialMediaUrl_exists?: InputMaybe<Scalars['Boolean']>;
  socialMediaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaUrl_not?: InputMaybe<Scalars['String']>;
  socialMediaUrl_not_contains?: InputMaybe<Scalars['String']>;
  socialMediaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  testimonialBody?: InputMaybe<Scalars['String']>;
  testimonialBody_contains?: InputMaybe<Scalars['String']>;
  testimonialBody_exists?: InputMaybe<Scalars['Boolean']>;
  testimonialBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  testimonialBody_not?: InputMaybe<Scalars['String']>;
  testimonialBody_not_contains?: InputMaybe<Scalars['String']>;
  testimonialBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  testimonialName?: InputMaybe<Scalars['String']>;
  testimonialName_contains?: InputMaybe<Scalars['String']>;
  testimonialName_exists?: InputMaybe<Scalars['Boolean']>;
  testimonialName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  testimonialName_not?: InputMaybe<Scalars['String']>;
  testimonialName_not_contains?: InputMaybe<Scalars['String']>;
  testimonialName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  testimonialTitle?: InputMaybe<Scalars['String']>;
  testimonialTitle_contains?: InputMaybe<Scalars['String']>;
  testimonialTitle_exists?: InputMaybe<Scalars['Boolean']>;
  testimonialTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  testimonialTitle_not?: InputMaybe<Scalars['String']>;
  testimonialTitle_not_contains?: InputMaybe<Scalars['String']>;
  testimonialTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AiComponentLinkingCollections = {
  aiPageCollection?: Maybe<AiPageCollection>;
  aiTemplatePageCollection?: Maybe<AiTemplatePageCollection>;
  entryCollection?: Maybe<EntryCollection>;
  thoughtLeadershipCollection?: Maybe<ThoughtLeadershipCollection>;
};


export type AiComponentLinkingCollectionsAiPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AiComponentLinkingCollectionsAiTemplatePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AiComponentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AiComponentLinkingCollectionsThoughtLeadershipCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AiComponentOrder {
  EntryTitleAsc = 'entryTitle_ASC',
  EntryTitleDesc = 'entryTitle_DESC',
  EntryTypeAsc = 'entryType_ASC',
  EntryTypeDesc = 'entryType_DESC',
  IndustryNewsDatePublishedAsc = 'industryNewsDatePublished_ASC',
  IndustryNewsDatePublishedDesc = 'industryNewsDatePublished_DESC',
  IndustryNewsSourceSiteAsc = 'industryNewsSourceSite_ASC',
  IndustryNewsSourceSiteDesc = 'industryNewsSourceSite_DESC',
  IndustryNewsTitleAsc = 'industryNewsTitle_ASC',
  IndustryNewsTitleDesc = 'industryNewsTitle_DESC',
  IndustryNewsUrlAsc = 'industryNewsUrl_ASC',
  IndustryNewsUrlDesc = 'industryNewsUrl_DESC',
  SimpleCardButtonTextAsc = 'simpleCardButtonText_ASC',
  SimpleCardButtonTextDesc = 'simpleCardButtonText_DESC',
  SimpleCardButtonUrlAsc = 'simpleCardButtonUrl_ASC',
  SimpleCardButtonUrlDesc = 'simpleCardButtonUrl_DESC',
  SimpleCardTitleAsc = 'simpleCardTitle_ASC',
  SimpleCardTitleDesc = 'simpleCardTitle_DESC',
  SocialMediaDateTimeAsc = 'socialMediaDateTime_ASC',
  SocialMediaDateTimeDesc = 'socialMediaDateTime_DESC',
  SocialMediaSourceAsc = 'socialMediaSource_ASC',
  SocialMediaSourceDesc = 'socialMediaSource_DESC',
  SocialMediaUrlAsc = 'socialMediaUrl_ASC',
  SocialMediaUrlDesc = 'socialMediaUrl_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TestimonialNameAsc = 'testimonialName_ASC',
  TestimonialNameDesc = 'testimonialName_DESC',
  TestimonialTitleAsc = 'testimonialTitle_ASC',
  TestimonialTitleDesc = 'testimonialTitle_DESC'
}

/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPage = Entry & {
  announcementsArchive?: Maybe<PressReleaseLanding>;
  announcementsCollection?: Maybe<AiPageAnnouncementsCollection>;
  announcementsTitle?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  entryTitle?: Maybe<Scalars['String']>;
  epri10Archive?: Maybe<PressReleaseLanding>;
  epri10Collection?: Maybe<AiPageEpri10Collection>;
  epri10Title?: Maybe<Scalars['String']>;
  eventsArchive?: Maybe<PressReleaseLanding>;
  facebook?: Maybe<AiComponent>;
  heroImage?: Maybe<Asset>;
  industryNewsArticlesCollection?: Maybe<AiPageIndustryNewsArticlesCollection>;
  industryNewsTitle?: Maybe<Scalars['String']>;
  leftCard?: Maybe<AiComponent>;
  leftColumnBody?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<AiPageLinkingCollections>;
  linkedIn?: Maybe<AiComponent>;
  moreAnnouncementsButtonText?: Maybe<Scalars['String']>;
  moreEventsButtonText?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  permalink?: Maybe<Scalars['String']>;
  readMoreButtonText?: Maybe<Scalars['String']>;
  redirect?: Maybe<Scalars['String']>;
  rightCard?: Maybe<AiComponent>;
  rightColumnBody?: Maybe<Scalars['String']>;
  showTestimonials?: Maybe<Scalars['Boolean']>;
  sys: Sys;
  testCollection?: Maybe<AiPageTestCollection>;
  testimonialsCollection?: Maybe<AiPageTestimonialsCollection>;
  testimonialsTitle?: Maybe<Scalars['String']>;
  twitter?: Maybe<AiComponent>;
  upcomingEventsCollection?: Maybe<AiPageUpcomingEventsCollection>;
  upcomingEventsTitle?: Maybe<Scalars['String']>;
  useCasesArchive?: Maybe<PressReleaseLanding>;
  useCasesCollection?: Maybe<AiPageUseCasesCollection>;
  useCasesTitle?: Maybe<Scalars['String']>;
  videoButtonText?: Maybe<Scalars['String']>;
  videoButtonUrl?: Maybe<Scalars['String']>;
  videoDescription?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
  videoTitle?: Maybe<Scalars['String']>;
  viewMoreButtonText?: Maybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageAnnouncementsArchiveArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageAnnouncementsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageAnnouncementsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageEntryTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageEpri10ArchiveArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageEpri10CollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageEpri10TitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageEventsArchiveArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageFacebookArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageHeroImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageIndustryNewsArticlesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageIndustryNewsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageLeftCardArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageLeftColumnBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageLinkedInArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageMoreAnnouncementsButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageMoreEventsButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPagePageTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPagePermalinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageReadMoreButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageRedirectArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageRightCardArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageRightColumnBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageShowTestimonialsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageTestCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageTestimonialsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageTestimonialsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageTwitterArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageUpcomingEventsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageUpcomingEventsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageUseCasesArchiveArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageUseCasesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageUseCasesTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageVideoButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageVideoButtonUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageVideoDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageVideoIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageVideoTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiPage) */
export type AiPageViewMoreButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type AiPageAnnouncementsCollection = {
  items: Array<Maybe<PressRelease>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AiPageCollection = {
  items: Array<Maybe<AiPage>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AiPageEpri10Collection = {
  items: Array<Maybe<PressRelease>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AiPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<AiPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AiPageFilter>>>;
  announcementsArchive?: InputMaybe<CfPressReleaseLandingNestedFilter>;
  announcementsArchive_exists?: InputMaybe<Scalars['Boolean']>;
  announcementsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  announcementsTitle?: InputMaybe<Scalars['String']>;
  announcementsTitle_contains?: InputMaybe<Scalars['String']>;
  announcementsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  announcementsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  announcementsTitle_not?: InputMaybe<Scalars['String']>;
  announcementsTitle_not_contains?: InputMaybe<Scalars['String']>;
  announcementsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  entryTitle?: InputMaybe<Scalars['String']>;
  entryTitle_contains?: InputMaybe<Scalars['String']>;
  entryTitle_exists?: InputMaybe<Scalars['Boolean']>;
  entryTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  entryTitle_not?: InputMaybe<Scalars['String']>;
  entryTitle_not_contains?: InputMaybe<Scalars['String']>;
  entryTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  epri10Archive?: InputMaybe<CfPressReleaseLandingNestedFilter>;
  epri10Archive_exists?: InputMaybe<Scalars['Boolean']>;
  epri10Collection_exists?: InputMaybe<Scalars['Boolean']>;
  epri10Title?: InputMaybe<Scalars['String']>;
  epri10Title_contains?: InputMaybe<Scalars['String']>;
  epri10Title_exists?: InputMaybe<Scalars['Boolean']>;
  epri10Title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  epri10Title_not?: InputMaybe<Scalars['String']>;
  epri10Title_not_contains?: InputMaybe<Scalars['String']>;
  epri10Title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  eventsArchive?: InputMaybe<CfPressReleaseLandingNestedFilter>;
  eventsArchive_exists?: InputMaybe<Scalars['Boolean']>;
  facebook?: InputMaybe<CfAiComponentNestedFilter>;
  facebook_exists?: InputMaybe<Scalars['Boolean']>;
  heroImage_exists?: InputMaybe<Scalars['Boolean']>;
  industryNewsArticlesCollection_exists?: InputMaybe<Scalars['Boolean']>;
  industryNewsTitle?: InputMaybe<Scalars['String']>;
  industryNewsTitle_contains?: InputMaybe<Scalars['String']>;
  industryNewsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  industryNewsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  industryNewsTitle_not?: InputMaybe<Scalars['String']>;
  industryNewsTitle_not_contains?: InputMaybe<Scalars['String']>;
  industryNewsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  leftCard?: InputMaybe<CfAiComponentNestedFilter>;
  leftCard_exists?: InputMaybe<Scalars['Boolean']>;
  leftColumnBody?: InputMaybe<Scalars['String']>;
  leftColumnBody_contains?: InputMaybe<Scalars['String']>;
  leftColumnBody_exists?: InputMaybe<Scalars['Boolean']>;
  leftColumnBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  leftColumnBody_not?: InputMaybe<Scalars['String']>;
  leftColumnBody_not_contains?: InputMaybe<Scalars['String']>;
  leftColumnBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  linkedIn?: InputMaybe<CfAiComponentNestedFilter>;
  linkedIn_exists?: InputMaybe<Scalars['Boolean']>;
  moreAnnouncementsButtonText?: InputMaybe<Scalars['String']>;
  moreAnnouncementsButtonText_contains?: InputMaybe<Scalars['String']>;
  moreAnnouncementsButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  moreAnnouncementsButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  moreAnnouncementsButtonText_not?: InputMaybe<Scalars['String']>;
  moreAnnouncementsButtonText_not_contains?: InputMaybe<Scalars['String']>;
  moreAnnouncementsButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  moreEventsButtonText?: InputMaybe<Scalars['String']>;
  moreEventsButtonText_contains?: InputMaybe<Scalars['String']>;
  moreEventsButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  moreEventsButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  moreEventsButtonText_not?: InputMaybe<Scalars['String']>;
  moreEventsButtonText_not_contains?: InputMaybe<Scalars['String']>;
  moreEventsButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pageTitle?: InputMaybe<Scalars['String']>;
  pageTitle_contains?: InputMaybe<Scalars['String']>;
  pageTitle_exists?: InputMaybe<Scalars['Boolean']>;
  pageTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pageTitle_not?: InputMaybe<Scalars['String']>;
  pageTitle_not_contains?: InputMaybe<Scalars['String']>;
  pageTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  permalink?: InputMaybe<Scalars['String']>;
  permalink_contains?: InputMaybe<Scalars['String']>;
  permalink_exists?: InputMaybe<Scalars['Boolean']>;
  permalink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  permalink_not?: InputMaybe<Scalars['String']>;
  permalink_not_contains?: InputMaybe<Scalars['String']>;
  permalink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readMoreButtonText?: InputMaybe<Scalars['String']>;
  readMoreButtonText_contains?: InputMaybe<Scalars['String']>;
  readMoreButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  readMoreButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readMoreButtonText_not?: InputMaybe<Scalars['String']>;
  readMoreButtonText_not_contains?: InputMaybe<Scalars['String']>;
  readMoreButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redirect?: InputMaybe<Scalars['String']>;
  redirect_contains?: InputMaybe<Scalars['String']>;
  redirect_exists?: InputMaybe<Scalars['Boolean']>;
  redirect_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redirect_not?: InputMaybe<Scalars['String']>;
  redirect_not_contains?: InputMaybe<Scalars['String']>;
  redirect_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rightCard?: InputMaybe<CfAiComponentNestedFilter>;
  rightCard_exists?: InputMaybe<Scalars['Boolean']>;
  rightColumnBody?: InputMaybe<Scalars['String']>;
  rightColumnBody_contains?: InputMaybe<Scalars['String']>;
  rightColumnBody_exists?: InputMaybe<Scalars['Boolean']>;
  rightColumnBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rightColumnBody_not?: InputMaybe<Scalars['String']>;
  rightColumnBody_not_contains?: InputMaybe<Scalars['String']>;
  rightColumnBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  showTestimonials?: InputMaybe<Scalars['Boolean']>;
  showTestimonials_exists?: InputMaybe<Scalars['Boolean']>;
  showTestimonials_not?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  testCollection_exists?: InputMaybe<Scalars['Boolean']>;
  testimonialsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  testimonialsTitle?: InputMaybe<Scalars['String']>;
  testimonialsTitle_contains?: InputMaybe<Scalars['String']>;
  testimonialsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  testimonialsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  testimonialsTitle_not?: InputMaybe<Scalars['String']>;
  testimonialsTitle_not_contains?: InputMaybe<Scalars['String']>;
  testimonialsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  twitter?: InputMaybe<CfAiComponentNestedFilter>;
  twitter_exists?: InputMaybe<Scalars['Boolean']>;
  upcomingEventsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  upcomingEventsTitle?: InputMaybe<Scalars['String']>;
  upcomingEventsTitle_contains?: InputMaybe<Scalars['String']>;
  upcomingEventsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  upcomingEventsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  upcomingEventsTitle_not?: InputMaybe<Scalars['String']>;
  upcomingEventsTitle_not_contains?: InputMaybe<Scalars['String']>;
  upcomingEventsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  useCasesArchive?: InputMaybe<CfPressReleaseLandingNestedFilter>;
  useCasesArchive_exists?: InputMaybe<Scalars['Boolean']>;
  useCasesCollection_exists?: InputMaybe<Scalars['Boolean']>;
  useCasesTitle?: InputMaybe<Scalars['String']>;
  useCasesTitle_contains?: InputMaybe<Scalars['String']>;
  useCasesTitle_exists?: InputMaybe<Scalars['Boolean']>;
  useCasesTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  useCasesTitle_not?: InputMaybe<Scalars['String']>;
  useCasesTitle_not_contains?: InputMaybe<Scalars['String']>;
  useCasesTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoButtonText?: InputMaybe<Scalars['String']>;
  videoButtonText_contains?: InputMaybe<Scalars['String']>;
  videoButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  videoButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoButtonText_not?: InputMaybe<Scalars['String']>;
  videoButtonText_not_contains?: InputMaybe<Scalars['String']>;
  videoButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoButtonUrl?: InputMaybe<Scalars['String']>;
  videoButtonUrl_contains?: InputMaybe<Scalars['String']>;
  videoButtonUrl_exists?: InputMaybe<Scalars['Boolean']>;
  videoButtonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoButtonUrl_not?: InputMaybe<Scalars['String']>;
  videoButtonUrl_not_contains?: InputMaybe<Scalars['String']>;
  videoButtonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoDescription?: InputMaybe<Scalars['String']>;
  videoDescription_contains?: InputMaybe<Scalars['String']>;
  videoDescription_exists?: InputMaybe<Scalars['Boolean']>;
  videoDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoDescription_not?: InputMaybe<Scalars['String']>;
  videoDescription_not_contains?: InputMaybe<Scalars['String']>;
  videoDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoId?: InputMaybe<Scalars['String']>;
  videoId_contains?: InputMaybe<Scalars['String']>;
  videoId_exists?: InputMaybe<Scalars['Boolean']>;
  videoId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoId_not?: InputMaybe<Scalars['String']>;
  videoId_not_contains?: InputMaybe<Scalars['String']>;
  videoId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoTitle?: InputMaybe<Scalars['String']>;
  videoTitle_contains?: InputMaybe<Scalars['String']>;
  videoTitle_exists?: InputMaybe<Scalars['Boolean']>;
  videoTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoTitle_not?: InputMaybe<Scalars['String']>;
  videoTitle_not_contains?: InputMaybe<Scalars['String']>;
  videoTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  viewMoreButtonText?: InputMaybe<Scalars['String']>;
  viewMoreButtonText_contains?: InputMaybe<Scalars['String']>;
  viewMoreButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  viewMoreButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  viewMoreButtonText_not?: InputMaybe<Scalars['String']>;
  viewMoreButtonText_not_contains?: InputMaybe<Scalars['String']>;
  viewMoreButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AiPageIndustryNewsArticlesCollection = {
  items: Array<Maybe<AiComponent>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AiPageLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type AiPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AiPageOrder {
  AnnouncementsTitleAsc = 'announcementsTitle_ASC',
  AnnouncementsTitleDesc = 'announcementsTitle_DESC',
  EntryTitleAsc = 'entryTitle_ASC',
  EntryTitleDesc = 'entryTitle_DESC',
  Epri10TitleAsc = 'epri10Title_ASC',
  Epri10TitleDesc = 'epri10Title_DESC',
  IndustryNewsTitleAsc = 'industryNewsTitle_ASC',
  IndustryNewsTitleDesc = 'industryNewsTitle_DESC',
  MoreAnnouncementsButtonTextAsc = 'moreAnnouncementsButtonText_ASC',
  MoreAnnouncementsButtonTextDesc = 'moreAnnouncementsButtonText_DESC',
  MoreEventsButtonTextAsc = 'moreEventsButtonText_ASC',
  MoreEventsButtonTextDesc = 'moreEventsButtonText_DESC',
  PageTitleAsc = 'pageTitle_ASC',
  PageTitleDesc = 'pageTitle_DESC',
  PermalinkAsc = 'permalink_ASC',
  PermalinkDesc = 'permalink_DESC',
  ReadMoreButtonTextAsc = 'readMoreButtonText_ASC',
  ReadMoreButtonTextDesc = 'readMoreButtonText_DESC',
  ShowTestimonialsAsc = 'showTestimonials_ASC',
  ShowTestimonialsDesc = 'showTestimonials_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TestimonialsTitleAsc = 'testimonialsTitle_ASC',
  TestimonialsTitleDesc = 'testimonialsTitle_DESC',
  UpcomingEventsTitleAsc = 'upcomingEventsTitle_ASC',
  UpcomingEventsTitleDesc = 'upcomingEventsTitle_DESC',
  UseCasesTitleAsc = 'useCasesTitle_ASC',
  UseCasesTitleDesc = 'useCasesTitle_DESC',
  VideoButtonTextAsc = 'videoButtonText_ASC',
  VideoButtonTextDesc = 'videoButtonText_DESC',
  VideoButtonUrlAsc = 'videoButtonUrl_ASC',
  VideoButtonUrlDesc = 'videoButtonUrl_DESC',
  VideoIdAsc = 'videoId_ASC',
  VideoIdDesc = 'videoId_DESC',
  VideoTitleAsc = 'videoTitle_ASC',
  VideoTitleDesc = 'videoTitle_DESC',
  ViewMoreButtonTextAsc = 'viewMoreButtonText_ASC',
  ViewMoreButtonTextDesc = 'viewMoreButtonText_DESC'
}

export type AiPageTestCollection = {
  items: Array<Maybe<PressReleaseLanding>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AiPageTestimonialsCollection = {
  items: Array<Maybe<AiComponent>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AiPageUpcomingEventsCollection = {
  items: Array<Maybe<PressRelease>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AiPageUseCasesCollection = {
  items: Array<Maybe<PressRelease>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiTemplatePage) */
export type AiTemplatePage = Entry & {
  announcementsArchive?: Maybe<PressReleaseLanding>;
  cardsAfterCardsCollection?: Maybe<AiTemplatePageCardsAfterCardsCollection>;
  cardsAfterCardsTitle?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  entryTitle?: Maybe<Scalars['String']>;
  firstArchivesSectionCollection?: Maybe<AiTemplatePageFirstArchivesSectionCollection>;
  header?: Maybe<Jumbotron>;
  infoCardsCollection?: Maybe<AiTemplatePageInfoCardsCollection>;
  leftColumnBody?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<AiTemplatePageLinkingCollections>;
  rightColumnBody?: Maybe<Scalars['String']>;
  secondArchivesSectionCollection?: Maybe<AiTemplatePageSecondArchivesSectionCollection>;
  socialCardsCollection?: Maybe<AiTemplatePageSocialCardsCollection>;
  sys: Sys;
  tabsCollection?: Maybe<AiTemplatePageTabsCollection>;
  textBlocksCollection?: Maybe<AiTemplatePageTextBlocksCollection>;
  videoButtonText?: Maybe<Scalars['String']>;
  videoButtonUrl?: Maybe<Scalars['String']>;
  videoDescription?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
  videoTitle?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiTemplatePage) */
export type AiTemplatePageAnnouncementsArchiveArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiTemplatePage) */
export type AiTemplatePageCardsAfterCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiTemplatePage) */
export type AiTemplatePageCardsAfterCardsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiTemplatePage) */
export type AiTemplatePageEntryTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiTemplatePage) */
export type AiTemplatePageFirstArchivesSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiTemplatePage) */
export type AiTemplatePageHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiTemplatePage) */
export type AiTemplatePageInfoCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiTemplatePage) */
export type AiTemplatePageLeftColumnBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiTemplatePage) */
export type AiTemplatePageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiTemplatePage) */
export type AiTemplatePageRightColumnBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiTemplatePage) */
export type AiTemplatePageSecondArchivesSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiTemplatePage) */
export type AiTemplatePageSocialCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiTemplatePage) */
export type AiTemplatePageTabsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiTemplatePage) */
export type AiTemplatePageTextBlocksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiTemplatePage) */
export type AiTemplatePageVideoButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiTemplatePage) */
export type AiTemplatePageVideoButtonUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiTemplatePage) */
export type AiTemplatePageVideoDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiTemplatePage) */
export type AiTemplatePageVideoIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/aiTemplatePage) */
export type AiTemplatePageVideoTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type AiTemplatePageCardsAfterCardsCollection = {
  items: Array<Maybe<AiComponent>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AiTemplatePageCollection = {
  items: Array<Maybe<AiTemplatePage>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AiTemplatePageFilter = {
  AND?: InputMaybe<Array<InputMaybe<AiTemplatePageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AiTemplatePageFilter>>>;
  announcementsArchive?: InputMaybe<CfPressReleaseLandingNestedFilter>;
  announcementsArchive_exists?: InputMaybe<Scalars['Boolean']>;
  cardsAfterCardsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  cardsAfterCardsTitle?: InputMaybe<Scalars['String']>;
  cardsAfterCardsTitle_contains?: InputMaybe<Scalars['String']>;
  cardsAfterCardsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  cardsAfterCardsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  cardsAfterCardsTitle_not?: InputMaybe<Scalars['String']>;
  cardsAfterCardsTitle_not_contains?: InputMaybe<Scalars['String']>;
  cardsAfterCardsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  entryTitle?: InputMaybe<Scalars['String']>;
  entryTitle_contains?: InputMaybe<Scalars['String']>;
  entryTitle_exists?: InputMaybe<Scalars['Boolean']>;
  entryTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  entryTitle_not?: InputMaybe<Scalars['String']>;
  entryTitle_not_contains?: InputMaybe<Scalars['String']>;
  entryTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  firstArchivesSectionCollection_exists?: InputMaybe<Scalars['Boolean']>;
  header?: InputMaybe<CfJumbotronNestedFilter>;
  header_exists?: InputMaybe<Scalars['Boolean']>;
  infoCardsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  leftColumnBody?: InputMaybe<Scalars['String']>;
  leftColumnBody_contains?: InputMaybe<Scalars['String']>;
  leftColumnBody_exists?: InputMaybe<Scalars['Boolean']>;
  leftColumnBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  leftColumnBody_not?: InputMaybe<Scalars['String']>;
  leftColumnBody_not_contains?: InputMaybe<Scalars['String']>;
  leftColumnBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rightColumnBody?: InputMaybe<Scalars['String']>;
  rightColumnBody_contains?: InputMaybe<Scalars['String']>;
  rightColumnBody_exists?: InputMaybe<Scalars['Boolean']>;
  rightColumnBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rightColumnBody_not?: InputMaybe<Scalars['String']>;
  rightColumnBody_not_contains?: InputMaybe<Scalars['String']>;
  rightColumnBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  secondArchivesSectionCollection_exists?: InputMaybe<Scalars['Boolean']>;
  socialCardsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  tabsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  textBlocksCollection_exists?: InputMaybe<Scalars['Boolean']>;
  videoButtonText?: InputMaybe<Scalars['String']>;
  videoButtonText_contains?: InputMaybe<Scalars['String']>;
  videoButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  videoButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoButtonText_not?: InputMaybe<Scalars['String']>;
  videoButtonText_not_contains?: InputMaybe<Scalars['String']>;
  videoButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoButtonUrl?: InputMaybe<Scalars['String']>;
  videoButtonUrl_contains?: InputMaybe<Scalars['String']>;
  videoButtonUrl_exists?: InputMaybe<Scalars['Boolean']>;
  videoButtonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoButtonUrl_not?: InputMaybe<Scalars['String']>;
  videoButtonUrl_not_contains?: InputMaybe<Scalars['String']>;
  videoButtonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoDescription?: InputMaybe<Scalars['String']>;
  videoDescription_contains?: InputMaybe<Scalars['String']>;
  videoDescription_exists?: InputMaybe<Scalars['Boolean']>;
  videoDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoDescription_not?: InputMaybe<Scalars['String']>;
  videoDescription_not_contains?: InputMaybe<Scalars['String']>;
  videoDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoId?: InputMaybe<Scalars['String']>;
  videoId_contains?: InputMaybe<Scalars['String']>;
  videoId_exists?: InputMaybe<Scalars['Boolean']>;
  videoId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoId_not?: InputMaybe<Scalars['String']>;
  videoId_not_contains?: InputMaybe<Scalars['String']>;
  videoId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoTitle?: InputMaybe<Scalars['String']>;
  videoTitle_contains?: InputMaybe<Scalars['String']>;
  videoTitle_exists?: InputMaybe<Scalars['Boolean']>;
  videoTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoTitle_not?: InputMaybe<Scalars['String']>;
  videoTitle_not_contains?: InputMaybe<Scalars['String']>;
  videoTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AiTemplatePageFirstArchivesSectionCollection = {
  items: Array<Maybe<PressReleaseLanding>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AiTemplatePageInfoCardsCollection = {
  items: Array<Maybe<AiComponent>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AiTemplatePageLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  pagePreConfiguredTemplatesCollection?: Maybe<PagePreConfiguredTemplatesCollection>;
};


export type AiTemplatePageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AiTemplatePageLinkingCollectionsPagePreConfiguredTemplatesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AiTemplatePageOrder {
  CardsAfterCardsTitleAsc = 'cardsAfterCardsTitle_ASC',
  CardsAfterCardsTitleDesc = 'cardsAfterCardsTitle_DESC',
  EntryTitleAsc = 'entryTitle_ASC',
  EntryTitleDesc = 'entryTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VideoButtonTextAsc = 'videoButtonText_ASC',
  VideoButtonTextDesc = 'videoButtonText_DESC',
  VideoButtonUrlAsc = 'videoButtonUrl_ASC',
  VideoButtonUrlDesc = 'videoButtonUrl_DESC',
  VideoIdAsc = 'videoId_ASC',
  VideoIdDesc = 'videoId_DESC',
  VideoTitleAsc = 'videoTitle_ASC',
  VideoTitleDesc = 'videoTitle_DESC'
}

export type AiTemplatePageSecondArchivesSectionCollection = {
  items: Array<Maybe<PressReleaseLanding>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AiTemplatePageSocialCardsCollection = {
  items: Array<Maybe<AiComponent>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AiTemplatePageTabsCollection = {
  items: Array<Maybe<Tabs>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AiTemplatePageTextBlocksCollection = {
  items: Array<Maybe<Markdown>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AlertInput = {
  changeType: Scalars['String'];
  itemTitle: Scalars['String'];
  itemType: Scalars['String'];
  itemURL: Scalars['String'];
  ltn: Scalars['String'];
};

/** Secured & Public Announcements for Sectors and Programs [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/announcement) */
export type Announcement = Entry & {
  announcementId?: Maybe<Scalars['String']>;
  attachmentsCollection?: Maybe<AnnouncementAttachmentsCollection>;
  authorEmail?: Maybe<Scalars['String']>;
  authorFirstName?: Maybe<Scalars['String']>;
  authorLastName?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  expireDate?: Maybe<Scalars['DateTime']>;
  image?: Maybe<Asset>;
  linkedFrom?: Maybe<AnnouncementLinkingCollections>;
  ltn?: Maybe<Scalars['String']>;
  publicOrMemberOnly?: Maybe<Scalars['String']>;
  publishedDate?: Maybe<Scalars['DateTime']>;
  subtitle?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Secured & Public Announcements for Sectors and Programs [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/announcement) */
export type AnnouncementAnnouncementIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Secured & Public Announcements for Sectors and Programs [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/announcement) */
export type AnnouncementAttachmentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Secured & Public Announcements for Sectors and Programs [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/announcement) */
export type AnnouncementAuthorEmailArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Secured & Public Announcements for Sectors and Programs [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/announcement) */
export type AnnouncementAuthorFirstNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Secured & Public Announcements for Sectors and Programs [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/announcement) */
export type AnnouncementAuthorLastNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Secured & Public Announcements for Sectors and Programs [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/announcement) */
export type AnnouncementContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Secured & Public Announcements for Sectors and Programs [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/announcement) */
export type AnnouncementExpireDateArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Secured & Public Announcements for Sectors and Programs [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/announcement) */
export type AnnouncementImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Secured & Public Announcements for Sectors and Programs [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/announcement) */
export type AnnouncementLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Secured & Public Announcements for Sectors and Programs [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/announcement) */
export type AnnouncementLtnArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Secured & Public Announcements for Sectors and Programs [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/announcement) */
export type AnnouncementPublicOrMemberOnlyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Secured & Public Announcements for Sectors and Programs [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/announcement) */
export type AnnouncementPublishedDateArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Secured & Public Announcements for Sectors and Programs [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/announcement) */
export type AnnouncementSubtitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Secured & Public Announcements for Sectors and Programs [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/announcement) */
export type AnnouncementTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type AnnouncementAttachmentsCollection = {
  items: Array<Maybe<AnnouncementAttachmentsItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AnnouncementAttachmentsItem = PublicAttachment | SecuredAttachment;

export type AnnouncementCollection = {
  items: Array<Maybe<Announcement>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AnnouncementFilter = {
  AND?: InputMaybe<Array<InputMaybe<AnnouncementFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AnnouncementFilter>>>;
  announcementId?: InputMaybe<Scalars['String']>;
  announcementId_contains?: InputMaybe<Scalars['String']>;
  announcementId_exists?: InputMaybe<Scalars['Boolean']>;
  announcementId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  announcementId_not?: InputMaybe<Scalars['String']>;
  announcementId_not_contains?: InputMaybe<Scalars['String']>;
  announcementId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  attachmentsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  authorEmail?: InputMaybe<Scalars['String']>;
  authorEmail_contains?: InputMaybe<Scalars['String']>;
  authorEmail_exists?: InputMaybe<Scalars['Boolean']>;
  authorEmail_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  authorEmail_not?: InputMaybe<Scalars['String']>;
  authorEmail_not_contains?: InputMaybe<Scalars['String']>;
  authorEmail_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  authorFirstName?: InputMaybe<Scalars['String']>;
  authorFirstName_contains?: InputMaybe<Scalars['String']>;
  authorFirstName_exists?: InputMaybe<Scalars['Boolean']>;
  authorFirstName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  authorFirstName_not?: InputMaybe<Scalars['String']>;
  authorFirstName_not_contains?: InputMaybe<Scalars['String']>;
  authorFirstName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  authorLastName?: InputMaybe<Scalars['String']>;
  authorLastName_contains?: InputMaybe<Scalars['String']>;
  authorLastName_exists?: InputMaybe<Scalars['Boolean']>;
  authorLastName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  authorLastName_not?: InputMaybe<Scalars['String']>;
  authorLastName_not_contains?: InputMaybe<Scalars['String']>;
  authorLastName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  content?: InputMaybe<Scalars['String']>;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  content_not?: InputMaybe<Scalars['String']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  content_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  expireDate?: InputMaybe<Scalars['DateTime']>;
  expireDate_exists?: InputMaybe<Scalars['Boolean']>;
  expireDate_gt?: InputMaybe<Scalars['DateTime']>;
  expireDate_gte?: InputMaybe<Scalars['DateTime']>;
  expireDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  expireDate_lt?: InputMaybe<Scalars['DateTime']>;
  expireDate_lte?: InputMaybe<Scalars['DateTime']>;
  expireDate_not?: InputMaybe<Scalars['DateTime']>;
  expireDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  ltn?: InputMaybe<Scalars['String']>;
  ltn_contains?: InputMaybe<Scalars['String']>;
  ltn_exists?: InputMaybe<Scalars['Boolean']>;
  ltn_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ltn_not?: InputMaybe<Scalars['String']>;
  ltn_not_contains?: InputMaybe<Scalars['String']>;
  ltn_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicOrMemberOnly?: InputMaybe<Scalars['String']>;
  publicOrMemberOnly_contains?: InputMaybe<Scalars['String']>;
  publicOrMemberOnly_exists?: InputMaybe<Scalars['Boolean']>;
  publicOrMemberOnly_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicOrMemberOnly_not?: InputMaybe<Scalars['String']>;
  publicOrMemberOnly_not_contains?: InputMaybe<Scalars['String']>;
  publicOrMemberOnly_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publishedDate?: InputMaybe<Scalars['DateTime']>;
  publishedDate_exists?: InputMaybe<Scalars['Boolean']>;
  publishedDate_gt?: InputMaybe<Scalars['DateTime']>;
  publishedDate_gte?: InputMaybe<Scalars['DateTime']>;
  publishedDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedDate_lt?: InputMaybe<Scalars['DateTime']>;
  publishedDate_lte?: InputMaybe<Scalars['DateTime']>;
  publishedDate_not?: InputMaybe<Scalars['DateTime']>;
  publishedDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  subtitle?: InputMaybe<Scalars['String']>;
  subtitle_contains?: InputMaybe<Scalars['String']>;
  subtitle_exists?: InputMaybe<Scalars['Boolean']>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subtitle_not?: InputMaybe<Scalars['String']>;
  subtitle_not_contains?: InputMaybe<Scalars['String']>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AnnouncementLinkingCollections = {
  announcementWrapperCollection?: Maybe<AnnouncementWrapperCollection>;
  entryCollection?: Maybe<EntryCollection>;
  xLprLandingPageCollection?: Maybe<XLprLandingPageCollection>;
};


export type AnnouncementLinkingCollectionsAnnouncementWrapperCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AnnouncementLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AnnouncementLinkingCollectionsXLprLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AnnouncementOrder {
  AnnouncementIdAsc = 'announcementId_ASC',
  AnnouncementIdDesc = 'announcementId_DESC',
  AuthorEmailAsc = 'authorEmail_ASC',
  AuthorEmailDesc = 'authorEmail_DESC',
  AuthorFirstNameAsc = 'authorFirstName_ASC',
  AuthorFirstNameDesc = 'authorFirstName_DESC',
  AuthorLastNameAsc = 'authorLastName_ASC',
  AuthorLastNameDesc = 'authorLastName_DESC',
  ExpireDateAsc = 'expireDate_ASC',
  ExpireDateDesc = 'expireDate_DESC',
  LtnAsc = 'ltn_ASC',
  LtnDesc = 'ltn_DESC',
  PublicOrMemberOnlyAsc = 'publicOrMemberOnly_ASC',
  PublicOrMemberOnlyDesc = 'publicOrMemberOnly_DESC',
  PublishedDateAsc = 'publishedDate_ASC',
  PublishedDateDesc = 'publishedDate_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type AnnouncementUpdate = {
  modifiedDate?: Maybe<Scalars['String']>;
  programLTN?: Maybe<Scalars['String']>;
  programName?: Maybe<Scalars['String']>;
  programNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/announcementWrapper) */
export type AnnouncementWrapper = Entry & {
  announcementCollection?: Maybe<AnnouncementWrapperAnnouncementCollection>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<AnnouncementWrapperLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  sys: Sys;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/announcementWrapper) */
export type AnnouncementWrapperAnnouncementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/announcementWrapper) */
export type AnnouncementWrapperLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/announcementWrapper) */
export type AnnouncementWrapperNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type AnnouncementWrapperAnnouncementCollection = {
  items: Array<Maybe<Announcement>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AnnouncementWrapperCollection = {
  items: Array<Maybe<AnnouncementWrapper>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AnnouncementWrapperFilter = {
  AND?: InputMaybe<Array<InputMaybe<AnnouncementWrapperFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AnnouncementWrapperFilter>>>;
  announcementCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type AnnouncementWrapperLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  ltnLandingPageCollection?: Maybe<LtnLandingPageCollection>;
};


export type AnnouncementWrapperLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AnnouncementWrapperLinkingCollectionsLtnLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AnnouncementWrapperOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Represents a binary file in a space. An asset can be any file type. */
export type Asset = {
  contentType?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  linkedFrom?: Maybe<AssetLinkingCollections>;
  size?: Maybe<Scalars['Int']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetUrlArgs = {
  transform?: InputMaybe<ImageTransformOptions>;
};

export type AssetCollection = {
  items: Array<Maybe<Asset>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssetFilter = {
  AND?: InputMaybe<Array<InputMaybe<AssetFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AssetFilter>>>;
  contentType?: InputMaybe<Scalars['String']>;
  contentType_contains?: InputMaybe<Scalars['String']>;
  contentType_exists?: InputMaybe<Scalars['Boolean']>;
  contentType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentType_not?: InputMaybe<Scalars['String']>;
  contentType_not_contains?: InputMaybe<Scalars['String']>;
  contentType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fileName?: InputMaybe<Scalars['String']>;
  fileName_contains?: InputMaybe<Scalars['String']>;
  fileName_exists?: InputMaybe<Scalars['Boolean']>;
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fileName_not?: InputMaybe<Scalars['String']>;
  fileName_not_contains?: InputMaybe<Scalars['String']>;
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  height?: InputMaybe<Scalars['Int']>;
  height_exists?: InputMaybe<Scalars['Boolean']>;
  height_gt?: InputMaybe<Scalars['Int']>;
  height_gte?: InputMaybe<Scalars['Int']>;
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  height_lt?: InputMaybe<Scalars['Int']>;
  height_lte?: InputMaybe<Scalars['Int']>;
  height_not?: InputMaybe<Scalars['Int']>;
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  size?: InputMaybe<Scalars['Int']>;
  size_exists?: InputMaybe<Scalars['Boolean']>;
  size_gt?: InputMaybe<Scalars['Int']>;
  size_gte?: InputMaybe<Scalars['Int']>;
  size_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  size_lt?: InputMaybe<Scalars['Int']>;
  size_lte?: InputMaybe<Scalars['Int']>;
  size_not?: InputMaybe<Scalars['Int']>;
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  width?: InputMaybe<Scalars['Int']>;
  width_exists?: InputMaybe<Scalars['Boolean']>;
  width_gt?: InputMaybe<Scalars['Int']>;
  width_gte?: InputMaybe<Scalars['Int']>;
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  width_lt?: InputMaybe<Scalars['Int']>;
  width_lte?: InputMaybe<Scalars['Int']>;
  width_not?: InputMaybe<Scalars['Int']>;
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type AssetLinkingCollections = {
  aboutEpriCollection?: Maybe<AboutEpriCollection>;
  advisoryBoardCardCollection?: Maybe<AdvisoryBoardCardCollection>;
  aiComponentCollection?: Maybe<AiComponentCollection>;
  aiPageCollection?: Maybe<AiPageCollection>;
  announcementCollection?: Maybe<AnnouncementCollection>;
  boardOfDirectorCardCollection?: Maybe<BoardOfDirectorCardCollection>;
  careersCollection?: Maybe<CareersCollection>;
  copyrightCollection?: Maybe<CopyrightCollection>;
  entryCollection?: Maybe<EntryCollection>;
  eventsCollection?: Maybe<EventsCollection>;
  featuredReportCollection?: Maybe<FeaturedReportCollection>;
  featuredResearcherSectionCollection?: Maybe<FeaturedResearcherSectionCollection>;
  governanceCollection?: Maybe<GovernanceCollection>;
  homeCollection?: Maybe<HomeCollection>;
  jumbotronCollection?: Maybe<JumbotronCollection>;
  leaderCardCollection?: Maybe<LeaderCardCollection>;
  locationTabCollection?: Maybe<LocationTabCollection>;
  multimediaTemplateCollection?: Maybe<MultimediaTemplateCollection>;
  ndeToolLandingPageCollection?: Maybe<NdeToolLandingPageCollection>;
  newFeaturesPageCollection?: Maybe<NewFeaturesPageCollection>;
  nuclearPlantModernizationLandingPageCollection?: Maybe<NuclearPlantModernizationLandingPageCollection>;
  personCollection?: Maybe<PersonCollection>;
  portfolioSectionCollection?: Maybe<PortfolioSectionCollection>;
  portfolioSectorsCollection?: Maybe<PortfolioSectorsCollection>;
  pressReleaseCollection?: Maybe<PressReleaseCollection>;
  profileCardCollection?: Maybe<ProfileCardCollection>;
  programContactsCollection?: Maybe<ProgramContactsCollection>;
  publicAttachmentCollection?: Maybe<PublicAttachmentCollection>;
  researchAdvisoryCommitteeHomeCollection?: Maybe<ResearchAdvisoryCommitteeHomeCollection>;
  researchLandingPageCollection?: Maybe<ResearchLandingPageCollection>;
  researchLandingTabsCollection?: Maybe<ResearchLandingTabsCollection>;
  storyCardsCollection?: Maybe<StoryCardsCollection>;
  thoughtLeadershipCollection?: Maybe<ThoughtLeadershipCollection>;
  twoColumnTemplateCollection?: Maybe<TwoColumnTemplateCollection>;
  videoTemplateCollection?: Maybe<VideoTemplateCollection>;
  xLprLandingPageCollection?: Maybe<XLprLandingPageCollection>;
};


export type AssetLinkingCollectionsAboutEpriCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsAdvisoryBoardCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsAiComponentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsAiPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsAnnouncementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsBoardOfDirectorCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsCareersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsCopyrightCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsEventsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsFeaturedReportCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsFeaturedResearcherSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsGovernanceCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsJumbotronCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsLeaderCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsLocationTabCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsMultimediaTemplateCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsNdeToolLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsNewFeaturesPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsNuclearPlantModernizationLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsPersonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsPortfolioSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsPortfolioSectorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsPressReleaseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsProfileCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsProgramContactsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsPublicAttachmentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsResearchAdvisoryCommitteeHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsResearchLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsResearchLandingTabsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsStoryCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsThoughtLeadershipCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsTwoColumnTemplateCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsVideoTemplateCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type AssetLinkingCollectionsXLprLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum AssetOrder {
  ContentTypeAsc = 'contentType_ASC',
  ContentTypeDesc = 'contentType_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC'
}

export type Association = {
  action?: Maybe<Scalars['String']>;
  associationId?: Maybe<Scalars['String']>;
  attachmentCategoryId?: Maybe<Scalars['String']>;
  attachmentId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileExtension?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  hasAccess?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  itemUniqueId?: Maybe<Scalars['String']>;
  itemUrl?: Maybe<Scalars['String']>;
  modifiedBy?: Maybe<Scalars['String']>;
  modifiedDate?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sharePointGroup?: Maybe<Scalars['String']>;
  siteLTNs?: Maybe<Scalars['String']>;
  swod?: Maybe<Scalars['String']>;
  webId?: Maybe<Scalars['String']>;
  webUrl?: Maybe<Scalars['String']>;
};

export type AttachmentCategory = {
  attachments?: Maybe<Array<Maybe<Association>>>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  media?: Maybe<Array<Maybe<EventMedia>>>;
  subCategories?: Maybe<Array<Maybe<AttachmentCategory>>>;
  title?: Maybe<Scalars['String']>;
};

export type BCam = {
    costOfCapital?: Maybe<Scalars['Float']>;
    createdBy?: Maybe<Scalars['String']>;
    createdDate?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    fteCost?: Maybe<Scalars['Float']>;
    fteHours?: Maybe<Scalars['Float']>;
    id?: Maybe<Scalars['Int']>;
    materialSoftware?: Maybe<Scalars['Float']>;
    modelRun?: Maybe<Scalars['String']>;
    modifiedDate?: Maybe<Scalars['String']>;
    oneTimeEngineeringContract?: Maybe<Scalars['Float']>;
    oneTimeHardware?: Maybe<Scalars['Float']>;
    oneTimeInstallationContract?: Maybe<Scalars['Float']>;
    oneTimeLabor?: Maybe<Scalars['Float']>;
    oneTimeSoftware?: Maybe<Scalars['Float']>;
    ongoingHours?: Maybe<Scalars['Float']>;
    onlineContractServiceSaving?: Maybe<Scalars['Float']>;
    onlineLaborSaving?: Maybe<Scalars['Float']>;
    onlineMaterialInventorySaving?: Maybe<Scalars['Float']>;
    outageContractServiceSaving?: Maybe<Scalars['Float']>;
    outageLaborSaving?: Maybe<Scalars['Float']>;
    outageMaterialInventorySaving?: Maybe<Scalars['Float']>;
    outages?: Maybe<Array<Maybe<Scalars['Float']>>>;
    projectName?: Maybe<Scalars['String']>;
    qualitativeBenefits?: Maybe<Array<Maybe<Scalars['String']>>>;
    reactors?: Maybe<Scalars['Int']>;
    refuelCycle?: Maybe<Scalars['Int']>;
};

export type BwvripReport = {
  active?: Maybe<Scalars['String']>;
  bwrmodel1?: Maybe<Scalars['String']>;
  bwrmodel2?: Maybe<Scalars['String']>;
  bwrmodel3?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  include?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  pdfURL?: Maybe<Scalars['String']>;
  recordId?: Maybe<Scalars['Int']>;
  reportNumber?: Maybe<Scalars['String']>;
  revisionForm?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
  weld1?: Maybe<Scalars['String']>;
  weld2?: Maybe<Scalars['String']>;
  weld3?: Maybe<Scalars['String']>;
  weld4?: Maybe<Scalars['String']>;
  weld5?: Maybe<Scalars['String']>;
  weld6?: Maybe<Scalars['String']>;
  weld7?: Maybe<Scalars['String']>;
};

/** Includes: Title, left column body (80% width), right column sidebar (20% width) [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/basicTemplate) */
export type BasicTemplate = Entry & {
  body?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<BasicTemplateLinkingCollections>;
  permalink?: Maybe<Scalars['String']>;
  redirect?: Maybe<Scalars['String']>;
  sidebar?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Includes: Title, left column body (80% width), right column sidebar (20% width) [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/basicTemplate) */
export type BasicTemplateBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Includes: Title, left column body (80% width), right column sidebar (20% width) [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/basicTemplate) */
export type BasicTemplateLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Includes: Title, left column body (80% width), right column sidebar (20% width) [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/basicTemplate) */
export type BasicTemplatePermalinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Includes: Title, left column body (80% width), right column sidebar (20% width) [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/basicTemplate) */
export type BasicTemplateRedirectArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Includes: Title, left column body (80% width), right column sidebar (20% width) [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/basicTemplate) */
export type BasicTemplateSidebarArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Includes: Title, left column body (80% width), right column sidebar (20% width) [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/basicTemplate) */
export type BasicTemplateTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type BasicTemplateCollection = {
  items: Array<Maybe<BasicTemplate>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type BasicTemplateFilter = {
  AND?: InputMaybe<Array<InputMaybe<BasicTemplateFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BasicTemplateFilter>>>;
  body?: InputMaybe<Scalars['String']>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  permalink?: InputMaybe<Scalars['String']>;
  permalink_contains?: InputMaybe<Scalars['String']>;
  permalink_exists?: InputMaybe<Scalars['Boolean']>;
  permalink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  permalink_not?: InputMaybe<Scalars['String']>;
  permalink_not_contains?: InputMaybe<Scalars['String']>;
  permalink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redirect?: InputMaybe<Scalars['String']>;
  redirect_contains?: InputMaybe<Scalars['String']>;
  redirect_exists?: InputMaybe<Scalars['Boolean']>;
  redirect_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redirect_not?: InputMaybe<Scalars['String']>;
  redirect_not_contains?: InputMaybe<Scalars['String']>;
  redirect_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sidebar?: InputMaybe<Scalars['String']>;
  sidebar_contains?: InputMaybe<Scalars['String']>;
  sidebar_exists?: InputMaybe<Scalars['Boolean']>;
  sidebar_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sidebar_not?: InputMaybe<Scalars['String']>;
  sidebar_not_contains?: InputMaybe<Scalars['String']>;
  sidebar_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BasicTemplateLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  pagePreConfiguredTemplatesCollection?: Maybe<PagePreConfiguredTemplatesCollection>;
};


export type BasicTemplateLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type BasicTemplateLinkingCollectionsPagePreConfiguredTemplatesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum BasicTemplateOrder {
  PermalinkAsc = 'permalink_ASC',
  PermalinkDesc = 'permalink_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum BcamActionType {
  Add = 'Add',
  Delete = 'Delete',
  Update = 'Update'
}

export type BcamInput = {
    action: BcamActionType;
    costOfCapital?: InputMaybe<Scalars['Float']>;
    createdBy?: InputMaybe<Scalars['String']>;
    createdDate?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    fteCost?: InputMaybe<Scalars['Float']>;
    fteHours?: InputMaybe<Scalars['Float']>;
    id?: InputMaybe<Scalars['Int']>;
    materialSoftware?: InputMaybe<Scalars['Float']>;
    modelRun?: InputMaybe<Scalars['String']>;
    modifiedDate?: InputMaybe<Scalars['String']>;
    oneTimeEngineeringContract?: InputMaybe<Scalars['Float']>;
    oneTimeHardware?: InputMaybe<Scalars['Float']>;
    oneTimeInstallationContract?: InputMaybe<Scalars['Float']>;
    oneTimeLabor?: InputMaybe<Scalars['Float']>;
    oneTimeSoftware?: InputMaybe<Scalars['Float']>;
    ongoingHours?: InputMaybe<Scalars['Float']>;
    onlineContractServiceSaving?: InputMaybe<Scalars['Float']>;
    onlineLaborSaving?: InputMaybe<Scalars['Float']>;
    onlineMaterialInventorySaving?: InputMaybe<Scalars['Float']>;
    outageContractServiceSaving?: InputMaybe<Scalars['Float']>;
    outageLaborSaving?: InputMaybe<Scalars['Float']>;
    outageMaterialInventorySaving?: InputMaybe<Scalars['Float']>;
    outages?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
    projectName?: InputMaybe<Scalars['String']>;
    qualitativeBenefits?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    reactors?: InputMaybe<Scalars['Int']>;
    refuelCycle?: InputMaybe<Scalars['Int']>;
};

/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/boardOfDirectorCard) */
export type BoardOfDirectorCard = Entry & {
  bio?: Maybe<Scalars['String']>;
  boardChair?: Maybe<Scalars['Boolean']>;
  boardMemberSinceDate?: Maybe<Scalars['String']>;
  chair?: Maybe<Scalars['Boolean']>;
  contentfulMetadata: ContentfulMetadata;
  firstName?: Maybe<Scalars['String']>;
  image?: Maybe<Asset>;
  lastName?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<BoardOfDirectorCardLinkingCollections>;
  middleName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  title2?: Maybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/boardOfDirectorCard) */
export type BoardOfDirectorCardBioArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/boardOfDirectorCard) */
export type BoardOfDirectorCardBoardChairArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/boardOfDirectorCard) */
export type BoardOfDirectorCardBoardMemberSinceDateArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/boardOfDirectorCard) */
export type BoardOfDirectorCardChairArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/boardOfDirectorCard) */
export type BoardOfDirectorCardFirstNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/boardOfDirectorCard) */
export type BoardOfDirectorCardImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/boardOfDirectorCard) */
export type BoardOfDirectorCardLastNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/boardOfDirectorCard) */
export type BoardOfDirectorCardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/boardOfDirectorCard) */
export type BoardOfDirectorCardMiddleNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/boardOfDirectorCard) */
export type BoardOfDirectorCardNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/boardOfDirectorCard) */
export type BoardOfDirectorCardTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/boardOfDirectorCard) */
export type BoardOfDirectorCardTitle2Args = {
  locale?: InputMaybe<Scalars['String']>;
};

export type BoardOfDirectorCardCollection = {
  items: Array<Maybe<BoardOfDirectorCard>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type BoardOfDirectorCardFilter = {
  AND?: InputMaybe<Array<InputMaybe<BoardOfDirectorCardFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BoardOfDirectorCardFilter>>>;
  bio?: InputMaybe<Scalars['String']>;
  bio_contains?: InputMaybe<Scalars['String']>;
  bio_exists?: InputMaybe<Scalars['Boolean']>;
  bio_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bio_not?: InputMaybe<Scalars['String']>;
  bio_not_contains?: InputMaybe<Scalars['String']>;
  bio_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  boardChair?: InputMaybe<Scalars['Boolean']>;
  boardChair_exists?: InputMaybe<Scalars['Boolean']>;
  boardChair_not?: InputMaybe<Scalars['Boolean']>;
  boardMemberSinceDate?: InputMaybe<Scalars['String']>;
  boardMemberSinceDate_contains?: InputMaybe<Scalars['String']>;
  boardMemberSinceDate_exists?: InputMaybe<Scalars['Boolean']>;
  boardMemberSinceDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  boardMemberSinceDate_not?: InputMaybe<Scalars['String']>;
  boardMemberSinceDate_not_contains?: InputMaybe<Scalars['String']>;
  boardMemberSinceDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  chair?: InputMaybe<Scalars['Boolean']>;
  chair_exists?: InputMaybe<Scalars['Boolean']>;
  chair_not?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  firstName?: InputMaybe<Scalars['String']>;
  firstName_contains?: InputMaybe<Scalars['String']>;
  firstName_exists?: InputMaybe<Scalars['Boolean']>;
  firstName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  firstName_not?: InputMaybe<Scalars['String']>;
  firstName_not_contains?: InputMaybe<Scalars['String']>;
  firstName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastName_contains?: InputMaybe<Scalars['String']>;
  lastName_exists?: InputMaybe<Scalars['Boolean']>;
  lastName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lastName_not?: InputMaybe<Scalars['String']>;
  lastName_not_contains?: InputMaybe<Scalars['String']>;
  lastName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  middleName?: InputMaybe<Scalars['String']>;
  middleName_contains?: InputMaybe<Scalars['String']>;
  middleName_exists?: InputMaybe<Scalars['Boolean']>;
  middleName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  middleName_not?: InputMaybe<Scalars['String']>;
  middleName_not_contains?: InputMaybe<Scalars['String']>;
  middleName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title2?: InputMaybe<Scalars['String']>;
  title2_contains?: InputMaybe<Scalars['String']>;
  title2_exists?: InputMaybe<Scalars['Boolean']>;
  title2_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title2_not?: InputMaybe<Scalars['String']>;
  title2_not_contains?: InputMaybe<Scalars['String']>;
  title2_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BoardOfDirectorCardLinkingCollections = {
  advisoryBoardCollection?: Maybe<AdvisoryBoardCollection>;
  boardOfDirectorsCollection?: Maybe<BoardOfDirectorsCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type BoardOfDirectorCardLinkingCollectionsAdvisoryBoardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type BoardOfDirectorCardLinkingCollectionsBoardOfDirectorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type BoardOfDirectorCardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum BoardOfDirectorCardOrder {
  BoardChairAsc = 'boardChair_ASC',
  BoardChairDesc = 'boardChair_DESC',
  BoardMemberSinceDateAsc = 'boardMemberSinceDate_ASC',
  BoardMemberSinceDateDesc = 'boardMemberSinceDate_DESC',
  ChairAsc = 'chair_ASC',
  ChairDesc = 'chair_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  MiddleNameAsc = 'middleName_ASC',
  MiddleNameDesc = 'middleName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  Title2Asc = 'title2_ASC',
  Title2Desc = 'title2_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/boardOfDirectors) */
export type BoardOfDirectors = Entry & {
  boardMemberSinceText?: Maybe<Scalars['String']>;
  boardOfDirectorsCollection?: Maybe<BoardOfDirectorsBoardOfDirectorsCollection>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<BoardOfDirectorsLinkingCollections>;
  printVersion?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  webVersion?: Maybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/boardOfDirectors) */
export type BoardOfDirectorsBoardMemberSinceTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/boardOfDirectors) */
export type BoardOfDirectorsBoardOfDirectorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/boardOfDirectors) */
export type BoardOfDirectorsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/boardOfDirectors) */
export type BoardOfDirectorsPrintVersionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/boardOfDirectors) */
export type BoardOfDirectorsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/boardOfDirectors) */
export type BoardOfDirectorsWebVersionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type BoardOfDirectorsBoardOfDirectorsCollection = {
  items: Array<Maybe<BoardOfDirectorCard>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type BoardOfDirectorsCollection = {
  items: Array<Maybe<BoardOfDirectors>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type BoardOfDirectorsFilter = {
  AND?: InputMaybe<Array<InputMaybe<BoardOfDirectorsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BoardOfDirectorsFilter>>>;
  boardMemberSinceText?: InputMaybe<Scalars['String']>;
  boardMemberSinceText_contains?: InputMaybe<Scalars['String']>;
  boardMemberSinceText_exists?: InputMaybe<Scalars['Boolean']>;
  boardMemberSinceText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  boardMemberSinceText_not?: InputMaybe<Scalars['String']>;
  boardMemberSinceText_not_contains?: InputMaybe<Scalars['String']>;
  boardMemberSinceText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  boardOfDirectorsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  printVersion?: InputMaybe<Scalars['String']>;
  printVersion_contains?: InputMaybe<Scalars['String']>;
  printVersion_exists?: InputMaybe<Scalars['Boolean']>;
  printVersion_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  printVersion_not?: InputMaybe<Scalars['String']>;
  printVersion_not_contains?: InputMaybe<Scalars['String']>;
  printVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  webVersion?: InputMaybe<Scalars['String']>;
  webVersion_contains?: InputMaybe<Scalars['String']>;
  webVersion_exists?: InputMaybe<Scalars['Boolean']>;
  webVersion_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  webVersion_not?: InputMaybe<Scalars['String']>;
  webVersion_not_contains?: InputMaybe<Scalars['String']>;
  webVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BoardOfDirectorsLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type BoardOfDirectorsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum BoardOfDirectorsOrder {
  BoardMemberSinceTextAsc = 'boardMemberSinceText_ASC',
  BoardMemberSinceTextDesc = 'boardMemberSinceText_DESC',
  PrintVersionAsc = 'printVersion_ASC',
  PrintVersionDesc = 'printVersion_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  WebVersionAsc = 'webVersion_ASC',
  WebVersionDesc = 'webVersion_DESC'
}

export type Bookmark = {
  bookmarkDate: Scalars['String'];
  id: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
  url: Scalars['String'];
};

export type BookmarkInput = {
  action: FavoriteAction;
  id: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  type: BookmarkType;
  url?: InputMaybe<Scalars['String']>;
};

export enum BookmarkType {
  All = 'All',
  Announcement = 'Announcement',
  Event = 'Event',
  Podcast = 'Podcast',
  Research = 'Research',
  Site = 'Site',
  Training = 'Training',
  Video = 'Video'
}

export type BookmarksResponse = {
  bookmarks?: Maybe<Array<Maybe<Bookmark>>>;
  noOfPages?: Maybe<Scalars['Int']>;
  totalRecords: Scalars['Int'];
};

/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/button) */
export type Button = Entry & {
  buttonLink?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<ButtonLinkingCollections>;
  style?: Maybe<Scalars['String']>;
  sys: Sys;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/button) */
export type ButtonButtonLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/button) */
export type ButtonButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/button) */
export type ButtonLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/button) */
export type ButtonStyleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ButtonCollection = {
  items: Array<Maybe<Button>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ButtonFilter = {
  AND?: InputMaybe<Array<InputMaybe<ButtonFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ButtonFilter>>>;
  buttonLink?: InputMaybe<Scalars['String']>;
  buttonLink_contains?: InputMaybe<Scalars['String']>;
  buttonLink_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink_not?: InputMaybe<Scalars['String']>;
  buttonLink_not_contains?: InputMaybe<Scalars['String']>;
  buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonText_contains?: InputMaybe<Scalars['String']>;
  buttonText_exists?: InputMaybe<Scalars['Boolean']>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText_not?: InputMaybe<Scalars['String']>;
  buttonText_not_contains?: InputMaybe<Scalars['String']>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  style?: InputMaybe<Scalars['String']>;
  style_contains?: InputMaybe<Scalars['String']>;
  style_exists?: InputMaybe<Scalars['Boolean']>;
  style_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  style_not?: InputMaybe<Scalars['String']>;
  style_not_contains?: InputMaybe<Scalars['String']>;
  style_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type ButtonLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  jumbotronCollection?: Maybe<JumbotronCollection>;
};


export type ButtonLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ButtonLinkingCollectionsJumbotronCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ButtonOrder {
  ButtonLinkAsc = 'buttonLink_ASC',
  ButtonLinkDesc = 'buttonLink_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  StyleAsc = 'style_ASC',
  StyleDesc = 'style_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** BWRVIP-03 Sections Data [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/bwrvip03) */
export type Bwrvip03 = Entry & {
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<Bwrvip03LinkingCollections>;
  sectionsCollection?: Maybe<Bwrvip03SectionsCollection>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** BWRVIP-03 Sections Data [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/bwrvip03) */
export type Bwrvip03LinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** BWRVIP-03 Sections Data [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/bwrvip03) */
export type Bwrvip03SectionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** BWRVIP-03 Sections Data [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/bwrvip03) */
export type Bwrvip03TitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type Bwrvip03Collection = {
  items: Array<Maybe<Bwrvip03>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Bwrvip03Filter = {
  AND?: InputMaybe<Array<InputMaybe<Bwrvip03Filter>>>;
  OR?: InputMaybe<Array<InputMaybe<Bwrvip03Filter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sectionsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Bwrvip03LinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type Bwrvip03LinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum Bwrvip03Order {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** BWRVIP03 Secondary NAV Links  [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/bwrvip03Section) */
export type Bwrvip03Section = Entry & {
  contactEmail?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<Bwrvip03SectionLinkingCollections>;
  readMoreLink?: Maybe<Scalars['String']>;
  readMoreText?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** BWRVIP03 Secondary NAV Links  [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/bwrvip03Section) */
export type Bwrvip03SectionContactEmailArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** BWRVIP03 Secondary NAV Links  [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/bwrvip03Section) */
export type Bwrvip03SectionContactNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** BWRVIP03 Secondary NAV Links  [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/bwrvip03Section) */
export type Bwrvip03SectionContactPhoneArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** BWRVIP03 Secondary NAV Links  [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/bwrvip03Section) */
export type Bwrvip03SectionContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** BWRVIP03 Secondary NAV Links  [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/bwrvip03Section) */
export type Bwrvip03SectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** BWRVIP03 Secondary NAV Links  [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/bwrvip03Section) */
export type Bwrvip03SectionReadMoreLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** BWRVIP03 Secondary NAV Links  [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/bwrvip03Section) */
export type Bwrvip03SectionReadMoreTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** BWRVIP03 Secondary NAV Links  [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/bwrvip03Section) */
export type Bwrvip03SectionTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type Bwrvip03SectionCollection = {
  items: Array<Maybe<Bwrvip03Section>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Bwrvip03SectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<Bwrvip03SectionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Bwrvip03SectionFilter>>>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactEmail_contains?: InputMaybe<Scalars['String']>;
  contactEmail_exists?: InputMaybe<Scalars['Boolean']>;
  contactEmail_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contactEmail_not?: InputMaybe<Scalars['String']>;
  contactEmail_not_contains?: InputMaybe<Scalars['String']>;
  contactEmail_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contactName?: InputMaybe<Scalars['String']>;
  contactName_contains?: InputMaybe<Scalars['String']>;
  contactName_exists?: InputMaybe<Scalars['Boolean']>;
  contactName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contactName_not?: InputMaybe<Scalars['String']>;
  contactName_not_contains?: InputMaybe<Scalars['String']>;
  contactName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contactPhone?: InputMaybe<Scalars['String']>;
  contactPhone_contains?: InputMaybe<Scalars['String']>;
  contactPhone_exists?: InputMaybe<Scalars['Boolean']>;
  contactPhone_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contactPhone_not?: InputMaybe<Scalars['String']>;
  contactPhone_not_contains?: InputMaybe<Scalars['String']>;
  contactPhone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  content?: InputMaybe<Scalars['String']>;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  content_not?: InputMaybe<Scalars['String']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  content_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  readMoreLink?: InputMaybe<Scalars['String']>;
  readMoreLink_contains?: InputMaybe<Scalars['String']>;
  readMoreLink_exists?: InputMaybe<Scalars['Boolean']>;
  readMoreLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readMoreLink_not?: InputMaybe<Scalars['String']>;
  readMoreLink_not_contains?: InputMaybe<Scalars['String']>;
  readMoreLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readMoreText?: InputMaybe<Scalars['String']>;
  readMoreText_contains?: InputMaybe<Scalars['String']>;
  readMoreText_exists?: InputMaybe<Scalars['Boolean']>;
  readMoreText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readMoreText_not?: InputMaybe<Scalars['String']>;
  readMoreText_not_contains?: InputMaybe<Scalars['String']>;
  readMoreText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Bwrvip03SectionLinkingCollections = {
  bwrvip03Collection?: Maybe<Bwrvip03Collection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type Bwrvip03SectionLinkingCollectionsBwrvip03CollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type Bwrvip03SectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum Bwrvip03SectionOrder {
  ContactEmailAsc = 'contactEmail_ASC',
  ContactEmailDesc = 'contactEmail_DESC',
  ContactNameAsc = 'contactName_ASC',
  ContactNameDesc = 'contactName_DESC',
  ContactPhoneAsc = 'contactPhone_ASC',
  ContactPhoneDesc = 'contactPhone_DESC',
  ReadMoreLinkAsc = 'readMoreLink_ASC',
  ReadMoreLinkDesc = 'readMoreLink_DESC',
  ReadMoreTextAsc = 'readMoreText_ASC',
  ReadMoreTextDesc = 'readMoreText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type Bwrvip03SectionsCollection = {
  items: Array<Maybe<Bwrvip03Section>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ByTopicProduct = {
  abstractURL?: Maybe<Scalars['String']>;
  dateExpected?: Maybe<Scalars['String']>;
  datePublished?: Maybe<Scalars['String']>;
  dateSort?: Maybe<Scalars['Int']>;
  documentType?: Maybe<Scalars['String']>;
  hasAccess?: Maybe<Scalars['Boolean']>;
  hasCategory?: Maybe<Scalars['Boolean']>;
  implementationCategory?: Maybe<Scalars['String']>;
  languageCode?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  shortProductId?: Maybe<Scalars['String']>;
  siteLTN?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusComment?: Maybe<Scalars['String']>;
  subTopicId?: Maybe<Scalars['String']>;
  topicId?: Maybe<Scalars['String']>;
  typeCategory?: Maybe<Scalars['String']>;
};

/** Includes: Jumbotron (image or video), content, 3 or 6 cards, tabber [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/cardTemplate) */
export type CardTemplate = Entry & {
  cardsCollection?: Maybe<CardTemplateCardsCollection>;
  contentfulMetadata: ContentfulMetadata;
  infoContentLeft?: Maybe<Scalars['String']>;
  infoContentRight?: Maybe<Scalars['String']>;
  jumbotron?: Maybe<Jumbotron>;
  linkedFrom?: Maybe<CardTemplateLinkingCollections>;
  permalink?: Maybe<Scalars['String']>;
  redirect?: Maybe<Scalars['String']>;
  showSubscriptionForm?: Maybe<Scalars['Boolean']>;
  sys: Sys;
  tabsCollection?: Maybe<CardTemplateTabsCollection>;
  title?: Maybe<Scalars['String']>;
};


/** Includes: Jumbotron (image or video), content, 3 or 6 cards, tabber [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/cardTemplate) */
export type CardTemplateCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Includes: Jumbotron (image or video), content, 3 or 6 cards, tabber [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/cardTemplate) */
export type CardTemplateInfoContentLeftArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Includes: Jumbotron (image or video), content, 3 or 6 cards, tabber [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/cardTemplate) */
export type CardTemplateInfoContentRightArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Includes: Jumbotron (image or video), content, 3 or 6 cards, tabber [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/cardTemplate) */
export type CardTemplateJumbotronArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Includes: Jumbotron (image or video), content, 3 or 6 cards, tabber [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/cardTemplate) */
export type CardTemplateLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Includes: Jumbotron (image or video), content, 3 or 6 cards, tabber [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/cardTemplate) */
export type CardTemplatePermalinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Includes: Jumbotron (image or video), content, 3 or 6 cards, tabber [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/cardTemplate) */
export type CardTemplateRedirectArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Includes: Jumbotron (image or video), content, 3 or 6 cards, tabber [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/cardTemplate) */
export type CardTemplateShowSubscriptionFormArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Includes: Jumbotron (image or video), content, 3 or 6 cards, tabber [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/cardTemplate) */
export type CardTemplateTabsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Includes: Jumbotron (image or video), content, 3 or 6 cards, tabber [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/cardTemplate) */
export type CardTemplateTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type CardTemplateCardsCollection = {
  items: Array<Maybe<StoryCards>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CardTemplateCollection = {
  items: Array<Maybe<CardTemplate>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CardTemplateFilter = {
  AND?: InputMaybe<Array<InputMaybe<CardTemplateFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CardTemplateFilter>>>;
  cardsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  infoContentLeft?: InputMaybe<Scalars['String']>;
  infoContentLeft_contains?: InputMaybe<Scalars['String']>;
  infoContentLeft_exists?: InputMaybe<Scalars['Boolean']>;
  infoContentLeft_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  infoContentLeft_not?: InputMaybe<Scalars['String']>;
  infoContentLeft_not_contains?: InputMaybe<Scalars['String']>;
  infoContentLeft_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  infoContentRight?: InputMaybe<Scalars['String']>;
  infoContentRight_contains?: InputMaybe<Scalars['String']>;
  infoContentRight_exists?: InputMaybe<Scalars['Boolean']>;
  infoContentRight_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  infoContentRight_not?: InputMaybe<Scalars['String']>;
  infoContentRight_not_contains?: InputMaybe<Scalars['String']>;
  infoContentRight_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jumbotron?: InputMaybe<CfJumbotronNestedFilter>;
  jumbotron_exists?: InputMaybe<Scalars['Boolean']>;
  permalink?: InputMaybe<Scalars['String']>;
  permalink_contains?: InputMaybe<Scalars['String']>;
  permalink_exists?: InputMaybe<Scalars['Boolean']>;
  permalink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  permalink_not?: InputMaybe<Scalars['String']>;
  permalink_not_contains?: InputMaybe<Scalars['String']>;
  permalink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redirect?: InputMaybe<Scalars['String']>;
  redirect_contains?: InputMaybe<Scalars['String']>;
  redirect_exists?: InputMaybe<Scalars['Boolean']>;
  redirect_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redirect_not?: InputMaybe<Scalars['String']>;
  redirect_not_contains?: InputMaybe<Scalars['String']>;
  redirect_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  showSubscriptionForm?: InputMaybe<Scalars['Boolean']>;
  showSubscriptionForm_exists?: InputMaybe<Scalars['Boolean']>;
  showSubscriptionForm_not?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  tabsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CardTemplateLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  pagePreConfiguredTemplatesCollection?: Maybe<PagePreConfiguredTemplatesCollection>;
};


export type CardTemplateLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type CardTemplateLinkingCollectionsPagePreConfiguredTemplatesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum CardTemplateOrder {
  PermalinkAsc = 'permalink_ASC',
  PermalinkDesc = 'permalink_DESC',
  ShowSubscriptionFormAsc = 'showSubscriptionForm_ASC',
  ShowSubscriptionFormDesc = 'showSubscriptionForm_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type CardTemplateTabsCollection = {
  items: Array<Maybe<Tabs>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/careers) */
export type Careers = Entry & {
  contentfulMetadata: ContentfulMetadata;
  findCareerSection?: Maybe<FindCareerSection>;
  jumbotron?: Maybe<Jumbotron>;
  linkedFrom?: Maybe<CareersLinkingCollections>;
  sectionFourBody?: Maybe<Scalars['String']>;
  sectionFourTitle?: Maybe<Scalars['String']>;
  sectionOneBody?: Maybe<Scalars['String']>;
  sectionOneImage?: Maybe<Asset>;
  sectionOneTabsCollection?: Maybe<CareersSectionOneTabsCollection>;
  sectionOneTitle?: Maybe<Scalars['String']>;
  sectionThreeBody?: Maybe<Scalars['String']>;
  sectionThreeButtonLink?: Maybe<Scalars['String']>;
  sectionThreeButtonText?: Maybe<Scalars['String']>;
  sectionThreeImage?: Maybe<Asset>;
  sectionThreeTabsCollection?: Maybe<CareersSectionThreeTabsCollection>;
  sectionThreeTitle?: Maybe<Scalars['String']>;
  sectionTwoBody?: Maybe<Scalars['String']>;
  sectionTwoTitle?: Maybe<Scalars['String']>;
  sectionTwoVideo?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/careers) */
export type CareersFindCareerSectionArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/careers) */
export type CareersJumbotronArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/careers) */
export type CareersLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/careers) */
export type CareersSectionFourBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/careers) */
export type CareersSectionFourTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/careers) */
export type CareersSectionOneBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/careers) */
export type CareersSectionOneImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/careers) */
export type CareersSectionOneTabsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/careers) */
export type CareersSectionOneTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/careers) */
export type CareersSectionThreeBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/careers) */
export type CareersSectionThreeButtonLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/careers) */
export type CareersSectionThreeButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/careers) */
export type CareersSectionThreeImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/careers) */
export type CareersSectionThreeTabsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/careers) */
export type CareersSectionThreeTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/careers) */
export type CareersSectionTwoBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/careers) */
export type CareersSectionTwoTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/careers) */
export type CareersSectionTwoVideoArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/careers) */
export type CareersTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type CareersCollection = {
  items: Array<Maybe<Careers>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CareersFilter = {
  AND?: InputMaybe<Array<InputMaybe<CareersFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CareersFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  findCareerSection?: InputMaybe<CfFindCareerSectionNestedFilter>;
  findCareerSection_exists?: InputMaybe<Scalars['Boolean']>;
  jumbotron?: InputMaybe<CfJumbotronNestedFilter>;
  jumbotron_exists?: InputMaybe<Scalars['Boolean']>;
  sectionFourBody?: InputMaybe<Scalars['String']>;
  sectionFourBody_contains?: InputMaybe<Scalars['String']>;
  sectionFourBody_exists?: InputMaybe<Scalars['Boolean']>;
  sectionFourBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionFourBody_not?: InputMaybe<Scalars['String']>;
  sectionFourBody_not_contains?: InputMaybe<Scalars['String']>;
  sectionFourBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionFourTitle?: InputMaybe<Scalars['String']>;
  sectionFourTitle_contains?: InputMaybe<Scalars['String']>;
  sectionFourTitle_exists?: InputMaybe<Scalars['Boolean']>;
  sectionFourTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionFourTitle_not?: InputMaybe<Scalars['String']>;
  sectionFourTitle_not_contains?: InputMaybe<Scalars['String']>;
  sectionFourTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionOneBody?: InputMaybe<Scalars['String']>;
  sectionOneBody_contains?: InputMaybe<Scalars['String']>;
  sectionOneBody_exists?: InputMaybe<Scalars['Boolean']>;
  sectionOneBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionOneBody_not?: InputMaybe<Scalars['String']>;
  sectionOneBody_not_contains?: InputMaybe<Scalars['String']>;
  sectionOneBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionOneImage_exists?: InputMaybe<Scalars['Boolean']>;
  sectionOneTabsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sectionOneTitle?: InputMaybe<Scalars['String']>;
  sectionOneTitle_contains?: InputMaybe<Scalars['String']>;
  sectionOneTitle_exists?: InputMaybe<Scalars['Boolean']>;
  sectionOneTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionOneTitle_not?: InputMaybe<Scalars['String']>;
  sectionOneTitle_not_contains?: InputMaybe<Scalars['String']>;
  sectionOneTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionThreeBody?: InputMaybe<Scalars['String']>;
  sectionThreeBody_contains?: InputMaybe<Scalars['String']>;
  sectionThreeBody_exists?: InputMaybe<Scalars['Boolean']>;
  sectionThreeBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionThreeBody_not?: InputMaybe<Scalars['String']>;
  sectionThreeBody_not_contains?: InputMaybe<Scalars['String']>;
  sectionThreeBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionThreeButtonLink?: InputMaybe<Scalars['String']>;
  sectionThreeButtonLink_contains?: InputMaybe<Scalars['String']>;
  sectionThreeButtonLink_exists?: InputMaybe<Scalars['Boolean']>;
  sectionThreeButtonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionThreeButtonLink_not?: InputMaybe<Scalars['String']>;
  sectionThreeButtonLink_not_contains?: InputMaybe<Scalars['String']>;
  sectionThreeButtonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionThreeButtonText?: InputMaybe<Scalars['String']>;
  sectionThreeButtonText_contains?: InputMaybe<Scalars['String']>;
  sectionThreeButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  sectionThreeButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionThreeButtonText_not?: InputMaybe<Scalars['String']>;
  sectionThreeButtonText_not_contains?: InputMaybe<Scalars['String']>;
  sectionThreeButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionThreeImage_exists?: InputMaybe<Scalars['Boolean']>;
  sectionThreeTabsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sectionThreeTitle?: InputMaybe<Scalars['String']>;
  sectionThreeTitle_contains?: InputMaybe<Scalars['String']>;
  sectionThreeTitle_exists?: InputMaybe<Scalars['Boolean']>;
  sectionThreeTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionThreeTitle_not?: InputMaybe<Scalars['String']>;
  sectionThreeTitle_not_contains?: InputMaybe<Scalars['String']>;
  sectionThreeTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionTwoBody?: InputMaybe<Scalars['String']>;
  sectionTwoBody_contains?: InputMaybe<Scalars['String']>;
  sectionTwoBody_exists?: InputMaybe<Scalars['Boolean']>;
  sectionTwoBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionTwoBody_not?: InputMaybe<Scalars['String']>;
  sectionTwoBody_not_contains?: InputMaybe<Scalars['String']>;
  sectionTwoBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionTwoTitle?: InputMaybe<Scalars['String']>;
  sectionTwoTitle_contains?: InputMaybe<Scalars['String']>;
  sectionTwoTitle_exists?: InputMaybe<Scalars['Boolean']>;
  sectionTwoTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionTwoTitle_not?: InputMaybe<Scalars['String']>;
  sectionTwoTitle_not_contains?: InputMaybe<Scalars['String']>;
  sectionTwoTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionTwoVideo?: InputMaybe<Scalars['String']>;
  sectionTwoVideo_contains?: InputMaybe<Scalars['String']>;
  sectionTwoVideo_exists?: InputMaybe<Scalars['Boolean']>;
  sectionTwoVideo_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionTwoVideo_not?: InputMaybe<Scalars['String']>;
  sectionTwoVideo_not_contains?: InputMaybe<Scalars['String']>;
  sectionTwoVideo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CareersLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type CareersLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum CareersOrder {
  SectionFourTitleAsc = 'sectionFourTitle_ASC',
  SectionFourTitleDesc = 'sectionFourTitle_DESC',
  SectionOneTitleAsc = 'sectionOneTitle_ASC',
  SectionOneTitleDesc = 'sectionOneTitle_DESC',
  SectionThreeButtonLinkAsc = 'sectionThreeButtonLink_ASC',
  SectionThreeButtonLinkDesc = 'sectionThreeButtonLink_DESC',
  SectionThreeButtonTextAsc = 'sectionThreeButtonText_ASC',
  SectionThreeButtonTextDesc = 'sectionThreeButtonText_DESC',
  SectionThreeTitleAsc = 'sectionThreeTitle_ASC',
  SectionThreeTitleDesc = 'sectionThreeTitle_DESC',
  SectionTwoBodyAsc = 'sectionTwoBody_ASC',
  SectionTwoBodyDesc = 'sectionTwoBody_DESC',
  SectionTwoTitleAsc = 'sectionTwoTitle_ASC',
  SectionTwoTitleDesc = 'sectionTwoTitle_DESC',
  SectionTwoVideoAsc = 'sectionTwoVideo_ASC',
  SectionTwoVideoDesc = 'sectionTwoVideo_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type CareersSectionOneTabsCollection = {
  items: Array<Maybe<Tabs>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CareersSectionThreeTabsCollection = {
  items: Array<Maybe<Tabs>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Chapter = {
  applicationsMockup?: Maybe<Scalars['String']>;
  bwr2ImageUrl?: Maybe<Scalars['String']>;
  bwr6ImageUrl?: Maybe<Scalars['String']>;
  bwr35ImageUrl?: Maybe<Scalars['String']>;
  extra1?: Maybe<Scalars['String']>;
  extra2?: Maybe<Scalars['String']>;
  extra3?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  inspectionConsiderations?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ClickThru = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  addressCountryCode?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clickThruDate?: Maybe<Scalars['String']>;
  companyHasMLicense?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['String']>;
  companyMettName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  gtsUpdatedDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  licenseAccepted?: Maybe<Scalars['Boolean']>;
  licenseAgreementId?: Maybe<Scalars['String']>;
  licenseType?: Maybe<Scalars['String']>;
  linkToAgreement?: Maybe<Scalars['String']>;
  masterAgreement?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  productHasNotes?: Maybe<Scalars['Boolean']>;
  productId?: Maybe<Scalars['String']>;
  productLicenseType?: Maybe<Scalars['String']>;
  productLongDescription?: Maybe<Scalars['String']>;
  productNotes?: Maybe<Scalars['String']>;
  productPrice?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['String']>;
  system?: Maybe<Scalars['String']>;
  systemSource?: Maybe<Scalars['String']>;
  usageNotes?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type ClickThruInput = {
  action?: InputMaybe<Scalars['String']>;
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  address3?: InputMaybe<Scalars['String']>;
  addressCountryCode?: InputMaybe<Scalars['String']>;
  addressId?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  clickThruDate?: InputMaybe<Scalars['String']>;
  companyHasMLicense?: InputMaybe<Scalars['Boolean']>;
  companyId?: InputMaybe<Scalars['String']>;
  companyName: Scalars['String'];
  contactId?: InputMaybe<Scalars['String']>;
  contactName: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  entityName?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  gtsUpdatedDate?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  licenseAccepted?: InputMaybe<Scalars['Boolean']>;
  licenseAgreementId?: InputMaybe<Scalars['String']>;
  licenseType?: InputMaybe<Scalars['String']>;
  linkToAgreement?: InputMaybe<Scalars['String']>;
  masterAgreement?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  postalCode: Scalars['String'];
  productHasNotes?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['String']>;
  productLicenseType?: InputMaybe<Scalars['String']>;
  productLongDescription?: InputMaybe<Scalars['String']>;
  productNotes?: InputMaybe<Scalars['String']>;
  productPrice?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  statusCode?: InputMaybe<Scalars['String']>;
  system?: InputMaybe<Scalars['String']>;
  systemSource?: InputMaybe<Scalars['String']>;
  usageNotes: Scalars['String'];
};

export type ClientDeliverable = {
  abstractLink?: Maybe<Scalars['String']>;
  actualCompletionDate?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  companyGroup?: Maybe<Scalars['String']>;
  eligible?: Maybe<Scalars['String']>;
  fundingType?: Maybe<Scalars['String']>;
  implementationCategory?: Maybe<Scalars['String']>;
  implementationNotes?: Maybe<Scalars['String']>;
  nqaAttribute?: Maybe<Scalars['String']>;
  plannedCompletionDate?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productIsPublic?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  programManager?: Maybe<Scalars['String']>;
  programName?: Maybe<Scalars['String']>;
  programNumber?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  projectSetId?: Maybe<Scalars['String']>;
  projectSetName?: Maybe<Scalars['String']>;
  received?: Maybe<Scalars['String']>;
  researchArea?: Maybe<Scalars['String']>;
  revisedPlannedCompletionDate?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
  sectorShortName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tiFlag?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type ClientDeliverableResponse = {
  data?: Maybe<Array<Maybe<ClientDeliverable>>>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export type ClientDeliverableSummary = {
  sectorName?: Maybe<Scalars['String']>;
  sectorShortName?: Maybe<Scalars['String']>;
  totalAvailable?: Maybe<Scalars['Int']>;
  totalDeliverables?: Maybe<Scalars['Int']>;
  totalNotYetReceived?: Maybe<Scalars['Int']>;
  totalReceived?: Maybe<Scalars['Int']>;
};

export type CollaborativeProject = {
  budget?: Maybe<Scalars['String']>;
  fundingType?: Maybe<Scalars['String']>;
  hasPOF?: Maybe<Scalars['Boolean']>;
  hasPSU?: Maybe<Scalars['Boolean']>;
  hostingProgramMarketingValue?: Maybe<Scalars['String']>;
  hostingProgramTitle?: Maybe<Scalars['String']>;
  lastPublishYear?: Maybe<Scalars['String']>;
  ltn?: Maybe<Scalars['String']>;
  overviewPublishedDate?: Maybe<Scalars['Float']>;
  overviewPublishedYear?: Maybe<Scalars['String']>;
  publishedDate?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  statusPublishedDate?: Maybe<Scalars['String']>;
  statusPublishedMonth?: Maybe<Scalars['String']>;
  statusPublishedYear?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum ColumnClicked {
  EnrollmentCount = 'EnrollmentCount',
  HasWebId = 'HasWebId',
  TotalContacts = 'TotalContacts',
  WebIdInactive = 'WebIDInactive'
}

export type Committee = {
  contacts?: Maybe<ContactsResponse>;
  /** @deprecated Use `shortDescription` or `longDescription`. */
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  siteLtn?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  siteNumber?: Maybe<Scalars['String']>;
};

export type CommitteeAdvisor = {
  NoOfAttendedEvents?: Maybe<Scalars['Int']>;
  NoOfCommittees?: Maybe<Scalars['Int']>;
  NoOfDownloads?: Maybe<Scalars['Int']>;
  NoOfEventInvites?: Maybe<Scalars['Int']>;
  NoOfFavorites?: Maybe<Scalars['Int']>;
  NoOfSubscriptions?: Maybe<Scalars['Int']>;
  committeeId?: Maybe<Scalars['Int']>;
  committeeName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  isMarkedForRemoval?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  primarySector?: Maybe<Scalars['String']>;
  primarySectorLTN?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  shouldbeBolded?: Maybe<Scalars['Boolean']>;
  userName?: Maybe<Scalars['String']>;
  webIDStatus?: Maybe<Scalars['String']>;
};

export type CommitteeContact = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  committeeRole?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  companyName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  declineToDisplay?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isEPRIContact?: Maybe<Scalars['Int']>;
  isFavorite?: Maybe<Scalars['Int']>;
  lastFirstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type CommitteeContacts = {
  epriContacts?: Maybe<ContactsResponse>;
  memberContacts?: Maybe<ContactsResponse>;
  name?: Maybe<Scalars['String']>;
};

export type CommitteeExportDetail = {
  committeeId?: Maybe<Scalars['String']>;
  committeeName?: Maybe<Scalars['String']>;
  companyGroup?: Maybe<Scalars['String']>;
  companyGroupDescr?: Maybe<Scalars['String']>;
  contactCompanyId?: Maybe<Scalars['String']>;
  contactCompanyName?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  contactLastName?: Maybe<Scalars['String']>;
  eligibility?: Maybe<Scalars['String']>;
  epriContact?: Maybe<Scalars['String']>;
  fundingRequirement?: Maybe<Scalars['String']>;
  invitationOnly?: Maybe<Scalars['Boolean']>;
  productId?: Maybe<Scalars['String']>;
  representative?: Maybe<Scalars['String']>;
  representativeMail?: Maybe<Scalars['String']>;
  represented?: Maybe<Scalars['String']>;
  researchArea?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  scenario?: Maybe<Scalars['String']>;
  sector?: Maybe<Scalars['String']>;
  sectorLTN?: Maybe<Scalars['String']>;
  shortSectorName?: Maybe<Scalars['String']>;
  shouldbeBolded?: Maybe<Scalars['Boolean']>;
  showAddAdvisor?: Maybe<Scalars['Boolean']>;
  siteName?: Maybe<Scalars['String']>;
  siteNumber?: Maybe<Scalars['String']>;
};

export type CommitteeSite = {
  committees?: Maybe<Array<Maybe<SiteCommittee>>>;
  siteName?: Maybe<Scalars['String']>;
  siteNumber?: Maybe<Scalars['String']>;
};

export type Company = {
    address1?: Maybe<Scalars['String']>;
    address2?: Maybe<Scalars['String']>;
    address3?: Maybe<Scalars['String']>;
    addresses?: Maybe<Array<Maybe<CompanyAddress>>>;
    city?: Maybe<Scalars['String']>;
    countryCode?: Maybe<Scalars['String']>;
    countryDesc?: Maybe<Scalars['String']>;
    defaultACG?: Maybe<Scalars['String']>;
    /** Company Domain Names */
    domainNames?: Maybe<Scalars['String']>;
    /** Company Group */
    group?: Maybe<Scalars['String']>;
    /** Company Group Description */
    groupDesc?: Maybe<Scalars['String']>;
    /** Flag to indicate if the company has any Self Directed funding */
    hasSDF?: Maybe<Scalars['Boolean']>;
    /** Flag to indicate if the company has any Tailored collaboration funding */
    hasTC?: Maybe<Scalars['Boolean']>;
    /** Company Id */
    id?: Maybe<Scalars['String']>;
    isPublic?: Maybe<Scalars['Boolean']>;
    /** Name of Company */
    name?: Maybe<Scalars['String']>;
    nonUtility?: Maybe<Scalars['String']>;
    postalCode?: Maybe<Scalars['String']>;
    /** Region Code */
    regionCode?: Maybe<Scalars['String']>;
    specialRule?: Maybe<Scalars['String']>;
    state?: Maybe<Scalars['String']>;
};

export type CompanyAddress = {
  acgDefault?: Maybe<Scalars['String']>;
  addressCity?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['Int']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressState?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  defaultAddressId?: Maybe<Scalars['Int']>;
  portalCode?: Maybe<Scalars['String']>;
  stringAddress?: Maybe<Scalars['String']>;
};

export enum CompanyOption {
  Adv = 'ADV',
  All = 'ALL',
  Cdr = 'CDR',
  Extended = 'EXTENDED',
  Lms = 'LMS',
  LmsOnly = 'LMS_ONLY',
  Mem = 'MEM',
  Met = 'MET',
  Mymett = 'MYMETT',
  PublicAndLms = 'PUBLIC_AND_LMS'
}

export type Contact = {
  accountExecRegionCode?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  canManageContacts?: Maybe<Scalars['Int']>;
  canSeeOnlinePricing?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  committees?: Maybe<Array<Maybe<ContactCommittee>>>;
  companyGroup?: Maybe<Scalars['String']>;
  companyHasSCON?: Maybe<Scalars['Int']>;
  /** Contact's Company */
  companyId?: Maybe<Scalars['Int']>;
  companyIsPublic?: Maybe<Scalars['Int']>;
  companyName?: Maybe<Scalars['String']>;
  companyhasOpenAR?: Maybe<Scalars['Int']>;
  contactLoginType?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  declineToDisplay?: Maybe<Scalars['Int']>;
  /** Email Address */
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  /** Contact First Name */
  firstName?: Maybe<Scalars['String']>;
  /** Full Name */
  fullName?: Maybe<Scalars['String']>;
  hasMLicense?: Maybe<Scalars['Int']>;
  hasTISubscription?: Maybe<Scalars['Int']>;
  /** Contact Id */
  id?: Maybe<Scalars['String']>;
  isEPRIContact?: Maybe<Scalars['Int']>;
  isFavorite?: Maybe<Scalars['Int']>;
  isMett?: Maybe<Scalars['Int']>;
  lastFirstName?: Maybe<Scalars['String']>;
  /** Contact Last Name */
  lastName?: Maybe<Scalars['String']>;
  mettName?: Maybe<Scalars['String']>;
  /** Contact Middle Name */
  middleName?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  otherRole?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  showPart810Agreement?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  /** Title */
  title?: Maybe<Scalars['String']>;
  /** Contact's User Id */
  userId?: Maybe<Scalars['String']>;
};

export type ContactCollection = {
  items: Array<Maybe<Contact>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ContactCommittee = {
  committeeId?: Maybe<Scalars['String']>;
  committeeName?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  /** @deprecated Use `shortDescription` or `longDescription`. */
  description?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
};

export type ContactFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContactFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ContactFilter>>>;
  city?: InputMaybe<Scalars['String']>;
  city_contains?: InputMaybe<Scalars['String']>;
  city_exists?: InputMaybe<Scalars['Boolean']>;
  city_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  city_not?: InputMaybe<Scalars['String']>;
  city_not_contains?: InputMaybe<Scalars['String']>;
  city_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  company?: InputMaybe<Scalars['String']>;
  companyHint?: InputMaybe<Scalars['String']>;
  companyHint_contains?: InputMaybe<Scalars['String']>;
  companyHint_exists?: InputMaybe<Scalars['Boolean']>;
  companyHint_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  companyHint_not?: InputMaybe<Scalars['String']>;
  companyHint_not_contains?: InputMaybe<Scalars['String']>;
  companyHint_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  company_contains?: InputMaybe<Scalars['String']>;
  company_exists?: InputMaybe<Scalars['Boolean']>;
  company_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  company_not?: InputMaybe<Scalars['String']>;
  company_not_contains?: InputMaybe<Scalars['String']>;
  company_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  email?: InputMaybe<Scalars['String']>;
  email_contains?: InputMaybe<Scalars['String']>;
  email_exists?: InputMaybe<Scalars['Boolean']>;
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  email_not?: InputMaybe<Scalars['String']>;
  email_not_contains?: InputMaybe<Scalars['String']>;
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  generalInquiries?: InputMaybe<Scalars['String']>;
  generalInquiries_contains?: InputMaybe<Scalars['String']>;
  generalInquiries_exists?: InputMaybe<Scalars['Boolean']>;
  generalInquiries_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  generalInquiries_not?: InputMaybe<Scalars['String']>;
  generalInquiries_not_contains?: InputMaybe<Scalars['String']>;
  generalInquiries_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jobTitle?: InputMaybe<Scalars['String']>;
  jobTitle_contains?: InputMaybe<Scalars['String']>;
  jobTitle_exists?: InputMaybe<Scalars['Boolean']>;
  jobTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jobTitle_not?: InputMaybe<Scalars['String']>;
  jobTitle_not_contains?: InputMaybe<Scalars['String']>;
  jobTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  message?: InputMaybe<Scalars['String']>;
  messageSubjects_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  messageSubjects_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  messageSubjects_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  messageSubjects_exists?: InputMaybe<Scalars['Boolean']>;
  message_contains?: InputMaybe<Scalars['String']>;
  message_exists?: InputMaybe<Scalars['Boolean']>;
  message_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  message_not?: InputMaybe<Scalars['String']>;
  message_not_contains?: InputMaybe<Scalars['String']>;
  message_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumber_contains?: InputMaybe<Scalars['String']>;
  phoneNumber_exists?: InputMaybe<Scalars['Boolean']>;
  phoneNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  phoneNumber_not?: InputMaybe<Scalars['String']>;
  phoneNumber_not_contains?: InputMaybe<Scalars['String']>;
  phoneNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  required?: InputMaybe<Scalars['String']>;
  required_contains?: InputMaybe<Scalars['String']>;
  required_exists?: InputMaybe<Scalars['Boolean']>;
  required_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  required_not?: InputMaybe<Scalars['String']>;
  required_not_contains?: InputMaybe<Scalars['String']>;
  required_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  selectASubject?: InputMaybe<Scalars['String']>;
  selectASubject_contains?: InputMaybe<Scalars['String']>;
  selectASubject_exists?: InputMaybe<Scalars['Boolean']>;
  selectASubject_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  selectASubject_not?: InputMaybe<Scalars['String']>;
  selectASubject_not_contains?: InputMaybe<Scalars['String']>;
  selectASubject_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  state?: InputMaybe<Scalars['String']>;
  state_contains?: InputMaybe<Scalars['String']>;
  state_exists?: InputMaybe<Scalars['Boolean']>;
  state_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  state_not?: InputMaybe<Scalars['String']>;
  state_not_contains?: InputMaybe<Scalars['String']>;
  state_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  streetAddress?: InputMaybe<Scalars['String']>;
  streetAddress_contains?: InputMaybe<Scalars['String']>;
  streetAddress_exists?: InputMaybe<Scalars['Boolean']>;
  streetAddress_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  streetAddress_not?: InputMaybe<Scalars['String']>;
  streetAddress_not_contains?: InputMaybe<Scalars['String']>;
  streetAddress_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  submit?: InputMaybe<Scalars['String']>;
  submit_contains?: InputMaybe<Scalars['String']>;
  submit_exists?: InputMaybe<Scalars['Boolean']>;
  submit_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  submit_not?: InputMaybe<Scalars['String']>;
  submit_not_contains?: InputMaybe<Scalars['String']>;
  submit_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subscribeText?: InputMaybe<Scalars['String']>;
  subscribeText_contains?: InputMaybe<Scalars['String']>;
  subscribeText_exists?: InputMaybe<Scalars['Boolean']>;
  subscribeText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subscribeText_not?: InputMaybe<Scalars['String']>;
  subscribeText_not_contains?: InputMaybe<Scalars['String']>;
  subscribeText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subscriptionFormTitle?: InputMaybe<Scalars['String']>;
  subscriptionFormTitle_contains?: InputMaybe<Scalars['String']>;
  subscriptionFormTitle_exists?: InputMaybe<Scalars['Boolean']>;
  subscriptionFormTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subscriptionFormTitle_not?: InputMaybe<Scalars['String']>;
  subscriptionFormTitle_not_contains?: InputMaybe<Scalars['String']>;
  subscriptionFormTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subscriptionSubmissionFailureMessage?: InputMaybe<Scalars['String']>;
  subscriptionSubmissionFailureMessage_contains?: InputMaybe<Scalars['String']>;
  subscriptionSubmissionFailureMessage_exists?: InputMaybe<Scalars['Boolean']>;
  subscriptionSubmissionFailureMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subscriptionSubmissionFailureMessage_not?: InputMaybe<Scalars['String']>;
  subscriptionSubmissionFailureMessage_not_contains?: InputMaybe<Scalars['String']>;
  subscriptionSubmissionFailureMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subscriptionSubmissionSuccessMessage?: InputMaybe<Scalars['String']>;
  subscriptionSubmissionSuccessMessage_contains?: InputMaybe<Scalars['String']>;
  subscriptionSubmissionSuccessMessage_exists?: InputMaybe<Scalars['Boolean']>;
  subscriptionSubmissionSuccessMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subscriptionSubmissionSuccessMessage_not?: InputMaybe<Scalars['String']>;
  subscriptionSubmissionSuccessMessage_not_contains?: InputMaybe<Scalars['String']>;
  subscriptionSubmissionSuccessMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subtitle?: InputMaybe<Scalars['String']>;
  subtitle_contains?: InputMaybe<Scalars['String']>;
  subtitle_exists?: InputMaybe<Scalars['Boolean']>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subtitle_not?: InputMaybe<Scalars['String']>;
  subtitle_not_contains?: InputMaybe<Scalars['String']>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  titleFieldLabel?: InputMaybe<Scalars['String']>;
  titleFieldLabel_contains?: InputMaybe<Scalars['String']>;
  titleFieldLabel_exists?: InputMaybe<Scalars['Boolean']>;
  titleFieldLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  titleFieldLabel_not?: InputMaybe<Scalars['String']>;
  titleFieldLabel_not_contains?: InputMaybe<Scalars['String']>;
  titleFieldLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  unsubscribeMessage?: InputMaybe<Scalars['String']>;
  unsubscribeMessage_contains?: InputMaybe<Scalars['String']>;
  unsubscribeMessage_exists?: InputMaybe<Scalars['Boolean']>;
  unsubscribeMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  unsubscribeMessage_not?: InputMaybe<Scalars['String']>;
  unsubscribeMessage_not_contains?: InputMaybe<Scalars['String']>;
  unsubscribeMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  validEmail?: InputMaybe<Scalars['String']>;
  validEmail_contains?: InputMaybe<Scalars['String']>;
  validEmail_exists?: InputMaybe<Scalars['Boolean']>;
  validEmail_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  validEmail_not?: InputMaybe<Scalars['String']>;
  validEmail_not_contains?: InputMaybe<Scalars['String']>;
  validEmail_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  webAssistance?: InputMaybe<Scalars['String']>;
  webAssistance_contains?: InputMaybe<Scalars['String']>;
  webAssistance_exists?: InputMaybe<Scalars['Boolean']>;
  webAssistance_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  webAssistance_not?: InputMaybe<Scalars['String']>;
  webAssistance_not_contains?: InputMaybe<Scalars['String']>;
  webAssistance_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContactLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type ContactLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ContactOrder {
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  CompanyHintAsc = 'companyHint_ASC',
  CompanyHintDesc = 'companyHint_DESC',
  CompanyAsc = 'company_ASC',
  CompanyDesc = 'company_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  GeneralInquiriesAsc = 'generalInquiries_ASC',
  GeneralInquiriesDesc = 'generalInquiries_DESC',
  JobTitleAsc = 'jobTitle_ASC',
  JobTitleDesc = 'jobTitle_DESC',
  MessageAsc = 'message_ASC',
  MessageDesc = 'message_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PhoneNumberAsc = 'phoneNumber_ASC',
  PhoneNumberDesc = 'phoneNumber_DESC',
  RequiredAsc = 'required_ASC',
  RequiredDesc = 'required_DESC',
  SelectASubjectAsc = 'selectASubject_ASC',
  SelectASubjectDesc = 'selectASubject_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  StreetAddressAsc = 'streetAddress_ASC',
  StreetAddressDesc = 'streetAddress_DESC',
  SubmitAsc = 'submit_ASC',
  SubmitDesc = 'submit_DESC',
  SubscribeTextAsc = 'subscribeText_ASC',
  SubscribeTextDesc = 'subscribeText_DESC',
  SubscriptionFormTitleAsc = 'subscriptionFormTitle_ASC',
  SubscriptionFormTitleDesc = 'subscriptionFormTitle_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleFieldLabelAsc = 'titleFieldLabel_ASC',
  TitleFieldLabelDesc = 'titleFieldLabel_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ValidEmailAsc = 'validEmail_ASC',
  ValidEmailDesc = 'validEmail_DESC'
}

export type ContactRequestInput = {
  companyId: Scalars['String'];
  contactId: Scalars['String'];
  requestType: ContactRequestType;
};

export enum ContactRequestType {
  Inactivate = 'Inactivate',
  Remove = 'Remove'
}

export type ContactsResponse = {
  data?: Maybe<Array<Maybe<CommitteeContact>>>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export type ContentfulMetadata = {
  tags: Array<Maybe<ContentfulTag>>;
};

export type ContentfulMetadataFilter = {
  tags?: InputMaybe<ContentfulMetadataTagsFilter>;
  tags_exists?: InputMaybe<Scalars['Boolean']>;
};

export type ContentfulMetadataTagsFilter = {
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/**
 * Represents a tag entity for finding and organizing content easily.
 *     Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulTag = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/**
 * Page
 *  [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/copyright)
 */
export type Copyright = Entry & {
  contentfulMetadata: ContentfulMetadata;
  image?: Maybe<Asset>;
  linkedFrom?: Maybe<CopyrightLinkingCollections>;
  sectionOneContent?: Maybe<Scalars['String']>;
  sectionOneHeader?: Maybe<Scalars['String']>;
  sectionThreeContent?: Maybe<Scalars['String']>;
  sectionThreeHeader?: Maybe<Scalars['String']>;
  sectionTwoContent?: Maybe<Scalars['String']>;
  sectionTwoHeader?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/**
 * Page
 *  [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/copyright)
 */
export type CopyrightImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/**
 * Page
 *  [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/copyright)
 */
export type CopyrightLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/**
 * Page
 *  [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/copyright)
 */
export type CopyrightSectionOneContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Page
 *  [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/copyright)
 */
export type CopyrightSectionOneHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Page
 *  [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/copyright)
 */
export type CopyrightSectionThreeContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Page
 *  [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/copyright)
 */
export type CopyrightSectionThreeHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Page
 *  [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/copyright)
 */
export type CopyrightSectionTwoContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Page
 *  [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/copyright)
 */
export type CopyrightSectionTwoHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Page
 *  [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/copyright)
 */
export type CopyrightTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type CopyrightCollection = {
  items: Array<Maybe<Copyright>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CopyrightFilter = {
  AND?: InputMaybe<Array<InputMaybe<CopyrightFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CopyrightFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  sectionOneContent?: InputMaybe<Scalars['String']>;
  sectionOneContent_contains?: InputMaybe<Scalars['String']>;
  sectionOneContent_exists?: InputMaybe<Scalars['Boolean']>;
  sectionOneContent_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionOneContent_not?: InputMaybe<Scalars['String']>;
  sectionOneContent_not_contains?: InputMaybe<Scalars['String']>;
  sectionOneContent_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionOneHeader?: InputMaybe<Scalars['String']>;
  sectionOneHeader_contains?: InputMaybe<Scalars['String']>;
  sectionOneHeader_exists?: InputMaybe<Scalars['Boolean']>;
  sectionOneHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionOneHeader_not?: InputMaybe<Scalars['String']>;
  sectionOneHeader_not_contains?: InputMaybe<Scalars['String']>;
  sectionOneHeader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionThreeContent?: InputMaybe<Scalars['String']>;
  sectionThreeContent_contains?: InputMaybe<Scalars['String']>;
  sectionThreeContent_exists?: InputMaybe<Scalars['Boolean']>;
  sectionThreeContent_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionThreeContent_not?: InputMaybe<Scalars['String']>;
  sectionThreeContent_not_contains?: InputMaybe<Scalars['String']>;
  sectionThreeContent_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionThreeHeader?: InputMaybe<Scalars['String']>;
  sectionThreeHeader_contains?: InputMaybe<Scalars['String']>;
  sectionThreeHeader_exists?: InputMaybe<Scalars['Boolean']>;
  sectionThreeHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionThreeHeader_not?: InputMaybe<Scalars['String']>;
  sectionThreeHeader_not_contains?: InputMaybe<Scalars['String']>;
  sectionThreeHeader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionTwoContent?: InputMaybe<Scalars['String']>;
  sectionTwoContent_contains?: InputMaybe<Scalars['String']>;
  sectionTwoContent_exists?: InputMaybe<Scalars['Boolean']>;
  sectionTwoContent_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionTwoContent_not?: InputMaybe<Scalars['String']>;
  sectionTwoContent_not_contains?: InputMaybe<Scalars['String']>;
  sectionTwoContent_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionTwoHeader?: InputMaybe<Scalars['String']>;
  sectionTwoHeader_contains?: InputMaybe<Scalars['String']>;
  sectionTwoHeader_exists?: InputMaybe<Scalars['Boolean']>;
  sectionTwoHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionTwoHeader_not?: InputMaybe<Scalars['String']>;
  sectionTwoHeader_not_contains?: InputMaybe<Scalars['String']>;
  sectionTwoHeader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CopyrightLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type CopyrightLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum CopyrightOrder {
  SectionOneHeaderAsc = 'sectionOneHeader_ASC',
  SectionOneHeaderDesc = 'sectionOneHeader_DESC',
  SectionThreeHeaderAsc = 'sectionThreeHeader_ASC',
  SectionThreeHeaderDesc = 'sectionThreeHeader_DESC',
  SectionTwoHeaderAsc = 'sectionTwoHeader_ASC',
  SectionTwoHeaderDesc = 'sectionTwoHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Core spray Piping and Sparger Landing Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/coresprayPipingLandingPage) */
export type CoresprayPipingLandingPage = Entry & {
  contentfulMetadata: ContentfulMetadata;
  coreSprayPipingTabsCollection?: Maybe<CoresprayPipingLandingPageCoreSprayPipingTabsCollection>;
  linkedFrom?: Maybe<CoresprayPipingLandingPageLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Core spray Piping and Sparger Landing Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/coresprayPipingLandingPage) */
export type CoresprayPipingLandingPageCoreSprayPipingTabsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Core spray Piping and Sparger Landing Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/coresprayPipingLandingPage) */
export type CoresprayPipingLandingPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Core spray Piping and Sparger Landing Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/coresprayPipingLandingPage) */
export type CoresprayPipingLandingPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type CoresprayPipingLandingPageCollection = {
  items: Array<Maybe<CoresprayPipingLandingPage>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CoresprayPipingLandingPageCoreSprayPipingTabsCollection = {
  items: Array<Maybe<Tabs>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CoresprayPipingLandingPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<CoresprayPipingLandingPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CoresprayPipingLandingPageFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  coreSprayPipingTabsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CoresprayPipingLandingPageLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type CoresprayPipingLandingPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum CoresprayPipingLandingPageOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type Country = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  states?: Maybe<Array<Maybe<State>>>;
};

/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/customerService) */
export type CustomerService = Entry & {
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<CustomerServiceLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  sys: Sys;
  tabsCollection?: Maybe<CustomerServiceTabsCollection>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/customerService) */
export type CustomerServiceLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/customerService) */
export type CustomerServiceNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/customerService) */
export type CustomerServiceTabsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type CustomerServiceCollection = {
  items: Array<Maybe<CustomerService>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CustomerServiceFilter = {
  AND?: InputMaybe<Array<InputMaybe<CustomerServiceFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CustomerServiceFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  tabsCollection_exists?: InputMaybe<Scalars['Boolean']>;
};

export type CustomerServiceLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type CustomerServiceLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum CustomerServiceOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type CustomerServiceTabsCollection = {
  items: Array<Maybe<Faq>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum DisplayType {
  Basic = 'Basic',
  /** This display type is passed to get EPRI Contacts Support Team */
  SalesReps = 'SalesReps'
}

export type DownloadUserInput = {
  company: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  fullName?: InputMaybe<Scalars['String']>;
  hasSubscribed?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
};

export type DownloadUsersResponse = {
  details?: Maybe<Array<Maybe<UserDownloadDetail>>>;
  noOfPages?: Maybe<Scalars['Int']>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export type EligibleNotRepresentedBySector = {
  committees?: Maybe<Array<Maybe<EligibleNotRepresentedCommittee>>>;
  sectorLTN?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
  sectorShortName?: Maybe<Scalars['String']>;
};

export type EligibleNotRepresentedCommittee = {
  committeeId?: Maybe<Scalars['Int']>;
  committeeName?: Maybe<Scalars['String']>;
  invitationOnly?: Maybe<Scalars['Boolean']>;
  programName?: Maybe<Scalars['String']>;
  programNumber?: Maybe<Scalars['String']>;
  showAddAdvisor?: Maybe<Scalars['Boolean']>;
};

export type EnrollUser = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  addressCity?: Maybe<Scalars['String']>;
  addressCountry?: Maybe<Scalars['String']>;
  addressCountryCode?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['String']>;
  addressPostalCode?: Maybe<Scalars['String']>;
  addressState?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contactAlternateBusinessMail?: Maybe<Scalars['String']>;
  contactBusinessMail?: Maybe<Scalars['String']>;
  contactBusinessMailCopy?: Maybe<Scalars['String']>;
  contactFax?: Maybe<PhoneFax>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  contactLastName?: Maybe<Scalars['String']>;
  contactMiddleName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<PhoneFax>;
  contactPreferredName?: Maybe<Scalars['String']>;
  contactRoleType?: Maybe<Scalars['String']>;
  contactUserName?: Maybe<Scalars['String']>;
  declineToDisplay?: Maybe<Scalars['Boolean']>;
  isCompanyRolledOut?: Maybe<Scalars['Boolean']>;
  isEPRIEmployee?: Maybe<Scalars['Boolean']>;
  mailStop?: Maybe<Scalars['String']>;
  plant?: Maybe<Plant>;
  salutation?: Maybe<Scalars['String']>;
  specialRuleTypes?: Maybe<Scalars['String']>;
  stringAddress?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Entry = {
  contentfulMetadata: ContentfulMetadata;
  sys: Sys;
};

export type EntryCollection = {
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type EntryFilter = {
  AND?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export enum EntryOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/epriU) */
export type EpriU = Entry & {
  contentfulMetadata: ContentfulMetadata;
  header?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<EpriULinkingCollections>;
  redesignAuthenticatedJumbotron?: Maybe<Jumbotron>;
  redesignBody?: Maybe<Scalars['String']>;
  redesignJumbotron?: Maybe<Jumbotron>;
  redesignLinks?: Maybe<LinkGroup>;
  redesignLowerJumbotron?: Maybe<Jumbotron>;
  redesignStoryCardsCollection?: Maybe<EpriURedesign_StoryCardsCollection>;
  redesignStoryCardsTitle?: Maybe<Scalars['String']>;
  redesignTabsCollection?: Maybe<EpriURedesign_TabsCollection>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/epriU) */
export type EpriUHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/epriU) */
export type EpriULinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/epriU) */
export type EpriURedesignAuthenticatedJumbotronArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/epriU) */
export type EpriURedesignBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/epriU) */
export type EpriURedesignJumbotronArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/epriU) */
export type EpriURedesignLinksArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/epriU) */
export type EpriURedesignLowerJumbotronArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/epriU) */
export type EpriURedesignStoryCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/epriU) */
export type EpriURedesignStoryCardsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/epriU) */
export type EpriURedesignTabsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/epriU) */
export type EpriUTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type EpriUCollection = {
  items: Array<Maybe<EpriU>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type EpriUFilter = {
  AND?: InputMaybe<Array<InputMaybe<EpriUFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<EpriUFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  header?: InputMaybe<Scalars['String']>;
  header_contains?: InputMaybe<Scalars['String']>;
  header_exists?: InputMaybe<Scalars['Boolean']>;
  header_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  header_not?: InputMaybe<Scalars['String']>;
  header_not_contains?: InputMaybe<Scalars['String']>;
  header_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redesign_AuthenticatedJumbotron?: InputMaybe<CfJumbotronNestedFilter>;
  redesign_AuthenticatedJumbotron_exists?: InputMaybe<Scalars['Boolean']>;
  redesign_body?: InputMaybe<Scalars['String']>;
  redesign_body_contains?: InputMaybe<Scalars['String']>;
  redesign_body_exists?: InputMaybe<Scalars['Boolean']>;
  redesign_body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redesign_body_not?: InputMaybe<Scalars['String']>;
  redesign_body_not_contains?: InputMaybe<Scalars['String']>;
  redesign_body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redesign_jumbotron?: InputMaybe<CfJumbotronNestedFilter>;
  redesign_jumbotron_exists?: InputMaybe<Scalars['Boolean']>;
  redesign_links?: InputMaybe<CfLinkGroupNestedFilter>;
  redesign_links_exists?: InputMaybe<Scalars['Boolean']>;
  redesign_lowerJumbotron?: InputMaybe<CfJumbotronNestedFilter>;
  redesign_lowerJumbotron_exists?: InputMaybe<Scalars['Boolean']>;
  redesign_storyCardsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  redesign_storyCardsTitle?: InputMaybe<Scalars['String']>;
  redesign_storyCardsTitle_contains?: InputMaybe<Scalars['String']>;
  redesign_storyCardsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  redesign_storyCardsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redesign_storyCardsTitle_not?: InputMaybe<Scalars['String']>;
  redesign_storyCardsTitle_not_contains?: InputMaybe<Scalars['String']>;
  redesign_storyCardsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redesign_tabsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EpriULinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type EpriULinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum EpriUOrder {
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  RedesignStoryCardsTitleAsc = 'redesign_storyCardsTitle_ASC',
  RedesignStoryCardsTitleDesc = 'redesign_storyCardsTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type EpriURedesign_StoryCardsCollection = {
  items: Array<Maybe<StoryCards>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type EpriURedesign_TabsCollection = {
  items: Array<Maybe<ResearchLandingTabs>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type EventDashboard = {
  attended?: Maybe<Array<Maybe<UserEvent>>>;
  featured?: Maybe<Array<Maybe<UserEvent>>>;
  invited?: Maybe<Array<Maybe<UserEvent>>>;
  registered?: Maybe<Array<Maybe<UserEvent>>>;
  upcoming?: Maybe<Array<Maybe<UserEvent>>>;
};

export type EventDetail = {
  companyGroup?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  meetingName?: Maybe<Scalars['String']>;
  participated?: Maybe<Scalars['String']>;
  productID?: Maybe<Scalars['String']>;
  programName?: Maybe<Scalars['String']>;
  programNumber?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  projectNumber?: Maybe<Scalars['String']>;
  projectSetName?: Maybe<Scalars['String']>;
  projectSetNumber?: Maybe<Scalars['String']>;
  registered?: Maybe<Scalars['String']>;
  researchArea?: Maybe<Scalars['String']>;
  sector?: Maybe<Scalars['String']>;
  shortSector?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};

export type EventDetailResponse = {
  details?: Maybe<Array<Maybe<EventDetail>>>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export type EventInviteeSummary = {
  nonRegisteredEvents?: Maybe<Scalars['Int']>;
  registeredEvents?: Maybe<Scalars['Int']>;
  sectorLTN?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
  shortSectorName?: Maybe<Scalars['String']>;
  totalEvents?: Maybe<Scalars['Int']>;
  totalInvitees?: Maybe<Scalars['Int']>;
  totalNonRegistrants?: Maybe<Scalars['Int']>;
  totalRegistrants?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type EventMedia = {
  categoryId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  hasAccess?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  mediaDownloadUrl?: Maybe<Scalars['String']>;
  mediaHeight?: Maybe<Scalars['String']>;
  mediaPlayUrl?: Maybe<Scalars['String']>;
  mediaTitle?: Maybe<Scalars['String']>;
  mediaUrl?: Maybe<Scalars['String']>;
  mediaWidth?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/eventcourseFeaturedList) */
export type EventcourseFeaturedList = Entry & {
  contentfulMetadata: ContentfulMetadata;
  endDate?: Maybe<Scalars['DateTime']>;
  linkedFrom?: Maybe<EventcourseFeaturedListLinkingCollections>;
  location?: Maybe<Scalars['String']>;
  registerLink?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  uniqueId?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/eventcourseFeaturedList) */
export type EventcourseFeaturedListEndDateArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/eventcourseFeaturedList) */
export type EventcourseFeaturedListLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/eventcourseFeaturedList) */
export type EventcourseFeaturedListLocationArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/eventcourseFeaturedList) */
export type EventcourseFeaturedListRegisterLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/eventcourseFeaturedList) */
export type EventcourseFeaturedListStartDateArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/eventcourseFeaturedList) */
export type EventcourseFeaturedListTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/eventcourseFeaturedList) */
export type EventcourseFeaturedListUniqueIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type EventcourseFeaturedListCollection = {
  items: Array<Maybe<EventcourseFeaturedList>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type EventcourseFeaturedListFilter = {
  AND?: InputMaybe<Array<InputMaybe<EventcourseFeaturedListFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<EventcourseFeaturedListFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDate_exists?: InputMaybe<Scalars['Boolean']>;
  endDate_gt?: InputMaybe<Scalars['DateTime']>;
  endDate_gte?: InputMaybe<Scalars['DateTime']>;
  endDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  endDate_lt?: InputMaybe<Scalars['DateTime']>;
  endDate_lte?: InputMaybe<Scalars['DateTime']>;
  endDate_not?: InputMaybe<Scalars['DateTime']>;
  endDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  location?: InputMaybe<Scalars['String']>;
  location_contains?: InputMaybe<Scalars['String']>;
  location_exists?: InputMaybe<Scalars['Boolean']>;
  location_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  location_not?: InputMaybe<Scalars['String']>;
  location_not_contains?: InputMaybe<Scalars['String']>;
  location_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  registerLink?: InputMaybe<Scalars['String']>;
  registerLink_contains?: InputMaybe<Scalars['String']>;
  registerLink_exists?: InputMaybe<Scalars['Boolean']>;
  registerLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  registerLink_not?: InputMaybe<Scalars['String']>;
  registerLink_not_contains?: InputMaybe<Scalars['String']>;
  registerLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  startDate_exists?: InputMaybe<Scalars['Boolean']>;
  startDate_gt?: InputMaybe<Scalars['DateTime']>;
  startDate_gte?: InputMaybe<Scalars['DateTime']>;
  startDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  startDate_lt?: InputMaybe<Scalars['DateTime']>;
  startDate_lte?: InputMaybe<Scalars['DateTime']>;
  startDate_not?: InputMaybe<Scalars['DateTime']>;
  startDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  uniqueId?: InputMaybe<Scalars['String']>;
  uniqueId_contains?: InputMaybe<Scalars['String']>;
  uniqueId_exists?: InputMaybe<Scalars['Boolean']>;
  uniqueId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  uniqueId_not?: InputMaybe<Scalars['String']>;
  uniqueId_not_contains?: InputMaybe<Scalars['String']>;
  uniqueId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EventcourseFeaturedListLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  eventcourseSectionCollection?: Maybe<EventcourseSectionCollection>;
};


export type EventcourseFeaturedListLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type EventcourseFeaturedListLinkingCollectionsEventcourseSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum EventcourseFeaturedListOrder {
  EndDateAsc = 'endDate_ASC',
  EndDateDesc = 'endDate_DESC',
  LocationAsc = 'location_ASC',
  LocationDesc = 'location_DESC',
  RegisterLinkAsc = 'registerLink_ASC',
  RegisterLinkDesc = 'registerLink_DESC',
  StartDateAsc = 'startDate_ASC',
  StartDateDesc = 'startDate_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UniqueIdAsc = 'uniqueId_ASC',
  UniqueIdDesc = 'uniqueId_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/eventcourseSection) */
export type EventcourseSection = Entry & {
  contentfulMetadata: ContentfulMetadata;
  eventsCollection?: Maybe<EventcourseSectionEventsCollection>;
  linkedFrom?: Maybe<EventcourseSectionLinkingCollections>;
  moreButtonLink?: Maybe<Scalars['String']>;
  moreButtonText?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/eventcourseSection) */
export type EventcourseSectionEventsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/eventcourseSection) */
export type EventcourseSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/eventcourseSection) */
export type EventcourseSectionMoreButtonLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/eventcourseSection) */
export type EventcourseSectionMoreButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/eventcourseSection) */
export type EventcourseSectionTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type EventcourseSectionCollection = {
  items: Array<Maybe<EventcourseSection>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type EventcourseSectionEventsCollection = {
  items: Array<Maybe<EventcourseFeaturedList>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type EventcourseSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<EventcourseSectionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<EventcourseSectionFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  eventsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  moreButtonLink?: InputMaybe<Scalars['String']>;
  moreButtonLink_contains?: InputMaybe<Scalars['String']>;
  moreButtonLink_exists?: InputMaybe<Scalars['Boolean']>;
  moreButtonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  moreButtonLink_not?: InputMaybe<Scalars['String']>;
  moreButtonLink_not_contains?: InputMaybe<Scalars['String']>;
  moreButtonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  moreButtonText?: InputMaybe<Scalars['String']>;
  moreButtonText_contains?: InputMaybe<Scalars['String']>;
  moreButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  moreButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  moreButtonText_not?: InputMaybe<Scalars['String']>;
  moreButtonText_not_contains?: InputMaybe<Scalars['String']>;
  moreButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EventcourseSectionLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type EventcourseSectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum EventcourseSectionOrder {
  MoreButtonLinkAsc = 'moreButtonLink_ASC',
  MoreButtonLinkDesc = 'moreButtonLink_DESC',
  MoreButtonTextAsc = 'moreButtonText_ASC',
  MoreButtonTextDesc = 'moreButtonText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type Events = Entry & {
  body?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  dashboardSubtitleOne?: Maybe<Scalars['String']>;
  dashboardSubtitleTwo?: Maybe<Scalars['String']>;
  dashboardTitle?: Maybe<Scalars['String']>;
  detailTabsCollection?: Maybe<EventsDetailTabsCollection>;
  energySectorDefaultImage?: Maybe<Asset>;
  eventDetailAirportLabel?: Maybe<Scalars['String']>;
  eventDetailAttendeesLabel?: Maybe<Scalars['String']>;
  eventDetailDescriptionLabel?: Maybe<Scalars['String']>;
  eventDetailGoalsAndObjectivesLabel?: Maybe<Scalars['String']>;
  eventDetailHotelLabel?: Maybe<Scalars['String']>;
  eventDetailTabLabelsCollection?: Maybe<EventsEventDetailTabLabelsCollection>;
  eventsCalendarButtonText?: Maybe<Scalars['String']>;
  generationSectorDefaultImage?: Maybe<Asset>;
  image?: Maybe<Asset>;
  infoCardsCollection?: Maybe<EventsInfoCardsCollection>;
  jumbotron?: Maybe<Jumbotron>;
  linkedFrom?: Maybe<EventsLinkingCollections>;
  noEventWithId?: Maybe<Scalars['String']>;
  noUpcomingEventsMessage?: Maybe<Scalars['String']>;
  nuclearSectorDefaultImage?: Maybe<Asset>;
  otherSectorDefaultImage?: Maybe<Asset>;
  pduSectorDefaultImage?: Maybe<Asset>;
  subtitle?: Maybe<Scalars['String']>;
  suggestedEventsTitle?: Maybe<Scalars['String']>;
  sys: Sys;
  technologySectorDefaultImage?: Maybe<Asset>;
  title?: Maybe<Scalars['String']>;
  upcomingEventsTabsCollection?: Maybe<EventsUpcomingEventsTabsCollection>;
  upcomingEventsTitle?: Maybe<Scalars['String']>;
  viewMyListButtonText?: Maybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsDashboardSubtitleOneArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsDashboardSubtitleTwoArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsDashboardTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsDetailTabsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsEnergySectorDefaultImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsEventDetailAirportLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsEventDetailAttendeesLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsEventDetailDescriptionLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsEventDetailGoalsAndObjectivesLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsEventDetailHotelLabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsEventDetailTabLabelsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsEventsCalendarButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsGenerationSectorDefaultImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsInfoCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsJumbotronArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsNoEventWithIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsNoUpcomingEventsMessageArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsNuclearSectorDefaultImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsOtherSectorDefaultImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsPduSectorDefaultImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsSubtitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsSuggestedEventsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsTechnologySectorDefaultImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsUpcomingEventsTabsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsUpcomingEventsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/events) */
export type EventsViewMyListButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type EventsCollection = {
  items: Array<Maybe<Events>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type EventsDetailTabsCollection = {
  items: Array<Maybe<ListComponent>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type EventsEventDetailTabLabelsCollection = {
  items: Array<Maybe<ListComponent>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type EventsFilter = {
  AND?: InputMaybe<Array<InputMaybe<EventsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<EventsFilter>>>;
  body?: InputMaybe<Scalars['String']>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  dashboardSubtitleOne?: InputMaybe<Scalars['String']>;
  dashboardSubtitleOne_contains?: InputMaybe<Scalars['String']>;
  dashboardSubtitleOne_exists?: InputMaybe<Scalars['Boolean']>;
  dashboardSubtitleOne_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dashboardSubtitleOne_not?: InputMaybe<Scalars['String']>;
  dashboardSubtitleOne_not_contains?: InputMaybe<Scalars['String']>;
  dashboardSubtitleOne_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dashboardSubtitleTwo?: InputMaybe<Scalars['String']>;
  dashboardSubtitleTwo_contains?: InputMaybe<Scalars['String']>;
  dashboardSubtitleTwo_exists?: InputMaybe<Scalars['Boolean']>;
  dashboardSubtitleTwo_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dashboardSubtitleTwo_not?: InputMaybe<Scalars['String']>;
  dashboardSubtitleTwo_not_contains?: InputMaybe<Scalars['String']>;
  dashboardSubtitleTwo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dashboardTitle?: InputMaybe<Scalars['String']>;
  dashboardTitle_contains?: InputMaybe<Scalars['String']>;
  dashboardTitle_exists?: InputMaybe<Scalars['Boolean']>;
  dashboardTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dashboardTitle_not?: InputMaybe<Scalars['String']>;
  dashboardTitle_not_contains?: InputMaybe<Scalars['String']>;
  dashboardTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  detailTabsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  energySectorDefaultImage_exists?: InputMaybe<Scalars['Boolean']>;
  eventDetailAirportLabel?: InputMaybe<Scalars['String']>;
  eventDetailAirportLabel_contains?: InputMaybe<Scalars['String']>;
  eventDetailAirportLabel_exists?: InputMaybe<Scalars['Boolean']>;
  eventDetailAirportLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  eventDetailAirportLabel_not?: InputMaybe<Scalars['String']>;
  eventDetailAirportLabel_not_contains?: InputMaybe<Scalars['String']>;
  eventDetailAirportLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  eventDetailAttendeesLabel?: InputMaybe<Scalars['String']>;
  eventDetailAttendeesLabel_contains?: InputMaybe<Scalars['String']>;
  eventDetailAttendeesLabel_exists?: InputMaybe<Scalars['Boolean']>;
  eventDetailAttendeesLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  eventDetailAttendeesLabel_not?: InputMaybe<Scalars['String']>;
  eventDetailAttendeesLabel_not_contains?: InputMaybe<Scalars['String']>;
  eventDetailAttendeesLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  eventDetailDescriptionLabel?: InputMaybe<Scalars['String']>;
  eventDetailDescriptionLabel_contains?: InputMaybe<Scalars['String']>;
  eventDetailDescriptionLabel_exists?: InputMaybe<Scalars['Boolean']>;
  eventDetailDescriptionLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  eventDetailDescriptionLabel_not?: InputMaybe<Scalars['String']>;
  eventDetailDescriptionLabel_not_contains?: InputMaybe<Scalars['String']>;
  eventDetailDescriptionLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  eventDetailGoalsAndObjectivesLabel?: InputMaybe<Scalars['String']>;
  eventDetailGoalsAndObjectivesLabel_contains?: InputMaybe<Scalars['String']>;
  eventDetailGoalsAndObjectivesLabel_exists?: InputMaybe<Scalars['Boolean']>;
  eventDetailGoalsAndObjectivesLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  eventDetailGoalsAndObjectivesLabel_not?: InputMaybe<Scalars['String']>;
  eventDetailGoalsAndObjectivesLabel_not_contains?: InputMaybe<Scalars['String']>;
  eventDetailGoalsAndObjectivesLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  eventDetailHotelLabel?: InputMaybe<Scalars['String']>;
  eventDetailHotelLabel_contains?: InputMaybe<Scalars['String']>;
  eventDetailHotelLabel_exists?: InputMaybe<Scalars['Boolean']>;
  eventDetailHotelLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  eventDetailHotelLabel_not?: InputMaybe<Scalars['String']>;
  eventDetailHotelLabel_not_contains?: InputMaybe<Scalars['String']>;
  eventDetailHotelLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  eventDetailTabLabelsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  eventsCalendarButtonText?: InputMaybe<Scalars['String']>;
  eventsCalendarButtonText_contains?: InputMaybe<Scalars['String']>;
  eventsCalendarButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  eventsCalendarButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  eventsCalendarButtonText_not?: InputMaybe<Scalars['String']>;
  eventsCalendarButtonText_not_contains?: InputMaybe<Scalars['String']>;
  eventsCalendarButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  generationSectorDefaultImage_exists?: InputMaybe<Scalars['Boolean']>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  infoCardsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  jumbotron?: InputMaybe<CfJumbotronNestedFilter>;
  jumbotron_exists?: InputMaybe<Scalars['Boolean']>;
  noEventWithId?: InputMaybe<Scalars['String']>;
  noEventWithId_contains?: InputMaybe<Scalars['String']>;
  noEventWithId_exists?: InputMaybe<Scalars['Boolean']>;
  noEventWithId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  noEventWithId_not?: InputMaybe<Scalars['String']>;
  noEventWithId_not_contains?: InputMaybe<Scalars['String']>;
  noEventWithId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  noUpcomingEventsMessage?: InputMaybe<Scalars['String']>;
  noUpcomingEventsMessage_contains?: InputMaybe<Scalars['String']>;
  noUpcomingEventsMessage_exists?: InputMaybe<Scalars['Boolean']>;
  noUpcomingEventsMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  noUpcomingEventsMessage_not?: InputMaybe<Scalars['String']>;
  noUpcomingEventsMessage_not_contains?: InputMaybe<Scalars['String']>;
  noUpcomingEventsMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nuclearSectorDefaultImage_exists?: InputMaybe<Scalars['Boolean']>;
  otherSectorDefaultImage_exists?: InputMaybe<Scalars['Boolean']>;
  pduSectorDefaultImage_exists?: InputMaybe<Scalars['Boolean']>;
  subtitle?: InputMaybe<Scalars['String']>;
  subtitle_contains?: InputMaybe<Scalars['String']>;
  subtitle_exists?: InputMaybe<Scalars['Boolean']>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subtitle_not?: InputMaybe<Scalars['String']>;
  subtitle_not_contains?: InputMaybe<Scalars['String']>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  suggestedEventsTitle?: InputMaybe<Scalars['String']>;
  suggestedEventsTitle_contains?: InputMaybe<Scalars['String']>;
  suggestedEventsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  suggestedEventsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  suggestedEventsTitle_not?: InputMaybe<Scalars['String']>;
  suggestedEventsTitle_not_contains?: InputMaybe<Scalars['String']>;
  suggestedEventsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  technologySectorDefaultImage_exists?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  upcomingEventsTabsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  upcomingEventsTitle?: InputMaybe<Scalars['String']>;
  upcomingEventsTitle_contains?: InputMaybe<Scalars['String']>;
  upcomingEventsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  upcomingEventsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  upcomingEventsTitle_not?: InputMaybe<Scalars['String']>;
  upcomingEventsTitle_not_contains?: InputMaybe<Scalars['String']>;
  upcomingEventsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  viewMyListButtonText?: InputMaybe<Scalars['String']>;
  viewMyListButtonText_contains?: InputMaybe<Scalars['String']>;
  viewMyListButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  viewMyListButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  viewMyListButtonText_not?: InputMaybe<Scalars['String']>;
  viewMyListButtonText_not_contains?: InputMaybe<Scalars['String']>;
  viewMyListButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EventsInfoCardsCollection = {
  items: Array<Maybe<StoryCards>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type EventsLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type EventsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum EventsOrder {
  DashboardSubtitleOneAsc = 'dashboardSubtitleOne_ASC',
  DashboardSubtitleOneDesc = 'dashboardSubtitleOne_DESC',
  DashboardSubtitleTwoAsc = 'dashboardSubtitleTwo_ASC',
  DashboardSubtitleTwoDesc = 'dashboardSubtitleTwo_DESC',
  DashboardTitleAsc = 'dashboardTitle_ASC',
  DashboardTitleDesc = 'dashboardTitle_DESC',
  EventDetailAirportLabelAsc = 'eventDetailAirportLabel_ASC',
  EventDetailAirportLabelDesc = 'eventDetailAirportLabel_DESC',
  EventDetailAttendeesLabelAsc = 'eventDetailAttendeesLabel_ASC',
  EventDetailAttendeesLabelDesc = 'eventDetailAttendeesLabel_DESC',
  EventDetailDescriptionLabelAsc = 'eventDetailDescriptionLabel_ASC',
  EventDetailDescriptionLabelDesc = 'eventDetailDescriptionLabel_DESC',
  EventDetailGoalsAndObjectivesLabelAsc = 'eventDetailGoalsAndObjectivesLabel_ASC',
  EventDetailGoalsAndObjectivesLabelDesc = 'eventDetailGoalsAndObjectivesLabel_DESC',
  EventDetailHotelLabelAsc = 'eventDetailHotelLabel_ASC',
  EventDetailHotelLabelDesc = 'eventDetailHotelLabel_DESC',
  EventsCalendarButtonTextAsc = 'eventsCalendarButtonText_ASC',
  EventsCalendarButtonTextDesc = 'eventsCalendarButtonText_DESC',
  NoEventWithIdAsc = 'noEventWithId_ASC',
  NoEventWithIdDesc = 'noEventWithId_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SuggestedEventsTitleAsc = 'suggestedEventsTitle_ASC',
  SuggestedEventsTitleDesc = 'suggestedEventsTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpcomingEventsTitleAsc = 'upcomingEventsTitle_ASC',
  UpcomingEventsTitleDesc = 'upcomingEventsTitle_DESC',
  ViewMyListButtonTextAsc = 'viewMyListButtonText_ASC',
  ViewMyListButtonTextDesc = 'viewMyListButtonText_DESC'
}

export type EventsUpcomingEventsTabsCollection = {
  items: Array<Maybe<ListComponent>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ExaminationBasis = {
  analysisType?: Maybe<Array<Maybe<Scalars['String']>>>;
  asmeInitiativeDocuments?: Maybe<Array<Maybe<ExaminationDocument>>>;
  asmeInitiatives?: Maybe<Scalars['String']>;
  asmeInitiativesList?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<ExaminationCategory>>>;
  componentGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  documentId?: Maybe<Scalars['Int']>;
  examinationDocuments?: Maybe<Array<Maybe<ExaminationDocument>>>;
  examinationItems?: Maybe<Array<Maybe<ExaminationItem>>>;
  id?: Maybe<Scalars['Int']>;
  itemId?: Maybe<Scalars['Int']>;
  levelOfRegulatoryAcceptance?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ExaminationBasisResponse = {
  basisList?: Maybe<Array<Maybe<ExaminationBasis>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ExaminationCategoriesResponse = {
  categories?: Maybe<Array<Maybe<ExaminationCategory>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ExaminationCategory = {
  category?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['Int']>;
  examItems?: Maybe<Array<Maybe<ExaminationItem>>>;
  id?: Maybe<Scalars['Int']>;
  summary?: Maybe<Scalars['String']>;
};

export type ExaminationDocument = {
  basisId?: Maybe<Scalars['Int']>;
  documentNumber?: Maybe<Scalars['String']>;
  examinationBases?: Maybe<Array<Maybe<ExaminationBasis>>>;
  id?: Maybe<Scalars['Int']>;
  itemId?: Maybe<Scalars['Int']>;
  publishedDate?: Maybe<Scalars['String']>;
  publisher?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ExaminationDocumentResponse = {
  documents?: Maybe<Array<Maybe<ExaminationDocument>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ExaminationItem = {
  basisId?: Maybe<Scalars['Int']>;
  categoryDescription?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Int']>;
  categoryName?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['Int']>;
  componentGroup?: Maybe<Scalars['String']>;
  examinationBases?: Maybe<Array<Maybe<ExaminationBasis>>>;
  figureNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  itemNumber?: Maybe<Scalars['String']>;
  methods?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
};

export type ExistingProductCreditDetail = {
  amount?: Maybe<Scalars['Float']>;
  customerName?: Maybe<Scalars['String']>;
  dateOrdered?: Maybe<Scalars['String']>;
  orderCompletedDate?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productPrice?: Maybe<Scalars['String']>;
  programNumber?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  requestorId?: Maybe<Scalars['String']>;
  requestorName?: Maybe<Scalars['String']>;
  salesOrder?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
  shipTo?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ExistingProductCreditResponse = {
  details?: Maybe<Array<Maybe<ExistingProductCreditDetail>>>;
  summary?: Maybe<ExistingProductCreditSummary>;
};

export type ExistingProductCreditSummary = {
  amountUsed?: Maybe<Scalars['Float']>;
  balanceRemaining?: Maybe<Scalars['Float']>;
  beginningBalance?: Maybe<Scalars['Float']>;
  expirationDate?: Maybe<Scalars['String']>;
};

export enum ExportType {
  All = 'All',
  Primary = 'Primary'
}

/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/faq) */
export type Faq = Entry & {
    contentfulMetadata: ContentfulMetadata;
    description?: Maybe<Scalars['String']>;
    linkedFrom?: Maybe<FaqLinkingCollections>;
    questionsCollection?: Maybe<FaqQuestionsCollection>;
    requireAuthentication?: Maybe<Scalars['Boolean']>;
    sys: Sys;
    title?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/faq) */
export type FaqDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/faq) */
export type FaqLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/faq) */
export type FaqQuestionsCollectionArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    locale?: InputMaybe<Scalars['String']>;
    preview?: InputMaybe<Scalars['Boolean']>;
    skip?: InputMaybe<Scalars['Int']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/faq) */
export type FaqRequireAuthenticationArgs = {
    locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/faq) */
export type FaqTitleArgs = {
    locale?: InputMaybe<Scalars['String']>;
};

export type FaqCollection = {
    items: Array<Maybe<Faq>>;
    limit: Scalars['Int'];
    skip: Scalars['Int'];
    total: Scalars['Int'];
};

export type FaqFilter = {
    AND?: InputMaybe<Array<InputMaybe<FaqFilter>>>;
    OR?: InputMaybe<Array<InputMaybe<FaqFilter>>>;
    contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
    description?: InputMaybe<Scalars['String']>;
    description_contains?: InputMaybe<Scalars['String']>;
    description_exists?: InputMaybe<Scalars['Boolean']>;
    description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    description_not?: InputMaybe<Scalars['String']>;
    description_not_contains?: InputMaybe<Scalars['String']>;
    description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    questionsCollection_exists?: InputMaybe<Scalars['Boolean']>;
    requireAuthentication?: InputMaybe<Scalars['Boolean']>;
    requireAuthentication_exists?: InputMaybe<Scalars['Boolean']>;
    requireAuthentication_not?: InputMaybe<Scalars['Boolean']>;
    sys?: InputMaybe<SysFilter>;
    title?: InputMaybe<Scalars['String']>;
    title_contains?: InputMaybe<Scalars['String']>;
    title_exists?: InputMaybe<Scalars['Boolean']>;
    title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title_not?: InputMaybe<Scalars['String']>;
    title_not_contains?: InputMaybe<Scalars['String']>;
    title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/faqItem) */
export type FaqItem = Entry & {
  body?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<FaqItemLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/faqItem) */
export type FaqItemBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/faqItem) */
export type FaqItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/faqItem) */
export type FaqItemTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FaqItemCollection = {
  items: Array<Maybe<FaqItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FaqItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqItemFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqItemFilter>>>;
  body?: InputMaybe<Scalars['String']>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FaqItemLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  faqCollection?: Maybe<FaqCollection>;
};


export type FaqItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type FaqItemLinkingCollectionsFaqCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FaqItemOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type FaqLinkingCollections = {
  customerServiceCollection?: Maybe<CustomerServiceCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type FaqLinkingCollectionsCustomerServiceCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type FaqLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FaqOrder {
    RequireAuthenticationAsc = 'requireAuthentication_ASC',
    RequireAuthenticationDesc = 'requireAuthentication_DESC',
    SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
    SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
    SysIdAsc = 'sys_id_ASC',
    SysIdDesc = 'sys_id_DESC',
    SysPublishedAtAsc = 'sys_publishedAt_ASC',
    SysPublishedAtDesc = 'sys_publishedAt_DESC',
    SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
    SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
    TitleAsc = 'title_ASC',
    TitleDesc = 'title_DESC'
}

export type FaqQuestionsCollection = {
  items: Array<Maybe<FaqItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FavoriteAction {
  Add = 'Add',
  Delete = 'Delete'
}

export type FavoriteContact = {
  accountExecRegionCode?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  canManageContacts?: Maybe<Scalars['Int']>;
  canSeeOnlinePricing?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  companyGroup?: Maybe<Scalars['String']>;
  companyHasSCON?: Maybe<Scalars['Int']>;
  /** Contact's Company */
  companyId?: Maybe<Scalars['Int']>;
  companyIsPublic?: Maybe<Scalars['Int']>;
  companyName?: Maybe<Scalars['String']>;
  companyhasOpenAR?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  declineToDisplay?: Maybe<Scalars['Int']>;
  /** Email Address */
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  /** Contact First Name */
  firstName?: Maybe<Scalars['String']>;
  /** Full Name */
  fullName?: Maybe<Scalars['String']>;
  hasMLicense?: Maybe<Scalars['Int']>;
  hasTISubscription?: Maybe<Scalars['Int']>;
  /** Contact Id */
  id?: Maybe<Scalars['String']>;
  isEPRIContact?: Maybe<Scalars['Int']>;
  isFavorite?: Maybe<Scalars['Int']>;
  isMett?: Maybe<Scalars['Int']>;
  lastFirstName?: Maybe<Scalars['String']>;
  /** Contact Last Name */
  lastName?: Maybe<Scalars['String']>;
  mettName?: Maybe<Scalars['String']>;
  /** Contact Middle Name */
  middleName?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  otherRole?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  showPart810Agreement?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  /** Title */
  title?: Maybe<Scalars['String']>;
  /** Contact's User Id */
  userId?: Maybe<Scalars['String']>;
};

export type FavoriteEvent = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  coordinatorPhone?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  cventId?: Maybe<Scalars['String']>;
  cventInviteeStatus?: Maybe<Scalars['String']>;
  cventRegistrationStatus?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  endDateTime?: Maybe<Scalars['String']>;
  eventStatus?: Maybe<Scalars['String']>;
  eventType?: Maybe<Scalars['String']>;
  eventUniqueId: Scalars['String'];
  favorite?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  plannerEmail?: Maybe<Scalars['String']>;
  plannerName?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productSector?: Maybe<Scalars['String']>;
  programLTN?: Maybe<Scalars['String']>;
  programName?: Maybe<Scalars['String']>;
  sector?: Maybe<Scalars['String']>;
  sectorLTN?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  unixEndDateTime?: Maybe<Scalars['String']>;
  unixstartDateTime?: Maybe<Scalars['String']>;
};

export type FavoriteProduct = {
  displayLink?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  originalProductId?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  shortProductId?: Maybe<Scalars['String']>;
};

export type FavoriteSite = {
  isFavorite?: Maybe<Scalars['Boolean']>;
  levelNumber?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  ltn?: Maybe<Scalars['String']>;
  marketValue?: Maybe<Scalars['String']>;
  researchAreaLTN?: Maybe<Scalars['String']>;
  researchAreaName?: Maybe<Scalars['String']>;
  sectorLTN?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  siteNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum FavoriteType {
  Contact = 'Contact',
  Event = 'Event',
  Product = 'Product',
  Site = 'Site'
}

export type FavoritesCollection = {
  contacts?: Maybe<Array<Maybe<FavoriteContact>>>;
  events?: Maybe<Array<Maybe<FavoriteEvent>>>;
  products?: Maybe<Array<Maybe<FavoriteProduct>>>;
  siteType?: Maybe<Scalars['String']>;
  sites?: Maybe<Array<Maybe<FavoriteSite>>>;
  userId: Scalars['String'];
};

/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/featuredReport) */
export type FeaturedReport = Entry & {
  abstractId?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  buttonLink?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  image?: Maybe<Asset>;
  linkedFrom?: Maybe<FeaturedReportLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/featuredReport) */
export type FeaturedReportAbstractIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/featuredReport) */
export type FeaturedReportBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/featuredReport) */
export type FeaturedReportButtonLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/featuredReport) */
export type FeaturedReportButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/featuredReport) */
export type FeaturedReportImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/featuredReport) */
export type FeaturedReportLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/featuredReport) */
export type FeaturedReportTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FeaturedReportCollection = {
  items: Array<Maybe<FeaturedReport>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FeaturedReportFilter = {
  AND?: InputMaybe<Array<InputMaybe<FeaturedReportFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FeaturedReportFilter>>>;
  abstractId?: InputMaybe<Scalars['String']>;
  abstractId_contains?: InputMaybe<Scalars['String']>;
  abstractId_exists?: InputMaybe<Scalars['Boolean']>;
  abstractId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  abstractId_not?: InputMaybe<Scalars['String']>;
  abstractId_not_contains?: InputMaybe<Scalars['String']>;
  abstractId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body?: InputMaybe<Scalars['String']>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink?: InputMaybe<Scalars['String']>;
  buttonLink_contains?: InputMaybe<Scalars['String']>;
  buttonLink_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink_not?: InputMaybe<Scalars['String']>;
  buttonLink_not_contains?: InputMaybe<Scalars['String']>;
  buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonText_contains?: InputMaybe<Scalars['String']>;
  buttonText_exists?: InputMaybe<Scalars['Boolean']>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText_not?: InputMaybe<Scalars['String']>;
  buttonText_not_contains?: InputMaybe<Scalars['String']>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FeaturedReportLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  mediaResourcesCollection?: Maybe<MediaResourcesCollection>;
  researchCollection?: Maybe<ResearchCollection>;
};


export type FeaturedReportLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type FeaturedReportLinkingCollectionsMediaResourcesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type FeaturedReportLinkingCollectionsResearchCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FeaturedReportOrder {
  AbstractIdAsc = 'abstractId_ASC',
  AbstractIdDesc = 'abstractId_DESC',
  ButtonLinkAsc = 'buttonLink_ASC',
  ButtonLinkDesc = 'buttonLink_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/featuredResearcherSection) */
export type FeaturedResearcherSection = Entry & {
  bio?: Maybe<Scalars['String']>;
  buttonLink?: Maybe<Scalars['String']>;
  buttonLinkBeta?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  image?: Maybe<Asset>;
  jobTitle?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<FeaturedResearcherSectionLinkingCollections>;
  researcherName?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/featuredResearcherSection) */
export type FeaturedResearcherSectionBioArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/featuredResearcherSection) */
export type FeaturedResearcherSectionButtonLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/featuredResearcherSection) */
export type FeaturedResearcherSectionButtonLinkBetaArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/featuredResearcherSection) */
export type FeaturedResearcherSectionButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/featuredResearcherSection) */
export type FeaturedResearcherSectionImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/featuredResearcherSection) */
export type FeaturedResearcherSectionJobTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/featuredResearcherSection) */
export type FeaturedResearcherSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/featuredResearcherSection) */
export type FeaturedResearcherSectionResearcherNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/featuredResearcherSection) */
export type FeaturedResearcherSectionTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FeaturedResearcherSectionCollection = {
  items: Array<Maybe<FeaturedResearcherSection>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FeaturedResearcherSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<FeaturedResearcherSectionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FeaturedResearcherSectionFilter>>>;
  bio?: InputMaybe<Scalars['String']>;
  bio_contains?: InputMaybe<Scalars['String']>;
  bio_exists?: InputMaybe<Scalars['Boolean']>;
  bio_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bio_not?: InputMaybe<Scalars['String']>;
  bio_not_contains?: InputMaybe<Scalars['String']>;
  bio_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink?: InputMaybe<Scalars['String']>;
  buttonLinkBeta?: InputMaybe<Scalars['String']>;
  buttonLinkBeta_contains?: InputMaybe<Scalars['String']>;
  buttonLinkBeta_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLinkBeta_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLinkBeta_not?: InputMaybe<Scalars['String']>;
  buttonLinkBeta_not_contains?: InputMaybe<Scalars['String']>;
  buttonLinkBeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink_contains?: InputMaybe<Scalars['String']>;
  buttonLink_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink_not?: InputMaybe<Scalars['String']>;
  buttonLink_not_contains?: InputMaybe<Scalars['String']>;
  buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonText_contains?: InputMaybe<Scalars['String']>;
  buttonText_exists?: InputMaybe<Scalars['Boolean']>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText_not?: InputMaybe<Scalars['String']>;
  buttonText_not_contains?: InputMaybe<Scalars['String']>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  jobTitle_contains?: InputMaybe<Scalars['String']>;
  jobTitle_exists?: InputMaybe<Scalars['Boolean']>;
  jobTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jobTitle_not?: InputMaybe<Scalars['String']>;
  jobTitle_not_contains?: InputMaybe<Scalars['String']>;
  jobTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  researcherName?: InputMaybe<Scalars['String']>;
  researcherName_contains?: InputMaybe<Scalars['String']>;
  researcherName_exists?: InputMaybe<Scalars['Boolean']>;
  researcherName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  researcherName_not?: InputMaybe<Scalars['String']>;
  researcherName_not_contains?: InputMaybe<Scalars['String']>;
  researcherName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FeaturedResearcherSectionLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  researchCollection?: Maybe<ResearchCollection>;
};


export type FeaturedResearcherSectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type FeaturedResearcherSectionLinkingCollectionsResearchCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FeaturedResearcherSectionOrder {
  ButtonLinkBetaAsc = 'buttonLinkBeta_ASC',
  ButtonLinkBetaDesc = 'buttonLinkBeta_DESC',
  ButtonLinkAsc = 'buttonLink_ASC',
  ButtonLinkDesc = 'buttonLink_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  JobTitleAsc = 'jobTitle_ASC',
  JobTitleDesc = 'jobTitle_DESC',
  ResearcherNameAsc = 'researcherName_ASC',
  ResearcherNameDesc = 'researcherName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type Fee = {
  createdDate?: Maybe<Scalars['String']>;
  cventId?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  feeAmount?: Maybe<Scalars['String']>;
  feeID?: Maybe<Scalars['String']>;
  feeName?: Maybe<Scalars['String']>;
  gLCode?: Maybe<Scalars['String']>;
  gLDescription?: Maybe<Scalars['String']>;
  gLID?: Maybe<Scalars['String']>;
  gLName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  productID?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
};

export type FeedbackInput = {
  company?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
  contactId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  message: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  programNumber?: InputMaybe<Scalars['String']>;
  sendMail?: InputMaybe<Scalars['Boolean']>;
  subject: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/findCareerSection) */
export type FindCareerSection = Entry & {
  buttonLink?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<FindCareerSectionLinkingCollections>;
  slug?: Maybe<Scalars['String']>;
  sys: Sys;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/findCareerSection) */
export type FindCareerSectionButtonLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/findCareerSection) */
export type FindCareerSectionButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/findCareerSection) */
export type FindCareerSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/findCareerSection) */
export type FindCareerSectionSlugArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FindCareerSectionCollection = {
  items: Array<Maybe<FindCareerSection>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FindCareerSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<FindCareerSectionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FindCareerSectionFilter>>>;
  buttonLink?: InputMaybe<Scalars['String']>;
  buttonLink_contains?: InputMaybe<Scalars['String']>;
  buttonLink_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink_not?: InputMaybe<Scalars['String']>;
  buttonLink_not_contains?: InputMaybe<Scalars['String']>;
  buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonText_contains?: InputMaybe<Scalars['String']>;
  buttonText_exists?: InputMaybe<Scalars['Boolean']>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText_not?: InputMaybe<Scalars['String']>;
  buttonText_not_contains?: InputMaybe<Scalars['String']>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  slug?: InputMaybe<Scalars['String']>;
  slug_contains?: InputMaybe<Scalars['String']>;
  slug_exists?: InputMaybe<Scalars['Boolean']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not?: InputMaybe<Scalars['String']>;
  slug_not_contains?: InputMaybe<Scalars['String']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type FindCareerSectionLinkingCollections = {
  careersCollection?: Maybe<CareersCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type FindCareerSectionLinkingCollectionsCareersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type FindCareerSectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FindCareerSectionOrder {
  ButtonLinkAsc = 'buttonLink_ASC',
  ButtonLinkDesc = 'buttonLink_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/footer) */
export type Footer = Entry & {
  address?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  established?: Maybe<Scalars['String']>;
  footerLinks?: Maybe<LinkGroup>;
  googleMapsAddressLink?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<FooterLinkingCollections>;
  socialMediaLinks?: Maybe<LinkGroup>;
  sys: Sys;
  telephone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/footer) */
export type FooterAddressArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/footer) */
export type FooterEstablishedArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/footer) */
export type FooterFooterLinksArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/footer) */
export type FooterGoogleMapsAddressLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/footer) */
export type FooterLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/footer) */
export type FooterSocialMediaLinksArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/footer) */
export type FooterTelephoneArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/footer) */
export type FooterTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type FooterCollection = {
  items: Array<Maybe<Footer>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FooterFilter = {
  AND?: InputMaybe<Array<InputMaybe<FooterFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FooterFilter>>>;
  address?: InputMaybe<Scalars['String']>;
  address_contains?: InputMaybe<Scalars['String']>;
  address_exists?: InputMaybe<Scalars['Boolean']>;
  address_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  address_not?: InputMaybe<Scalars['String']>;
  address_not_contains?: InputMaybe<Scalars['String']>;
  address_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  established?: InputMaybe<Scalars['String']>;
  established_contains?: InputMaybe<Scalars['String']>;
  established_exists?: InputMaybe<Scalars['Boolean']>;
  established_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  established_not?: InputMaybe<Scalars['String']>;
  established_not_contains?: InputMaybe<Scalars['String']>;
  established_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  footerLinks?: InputMaybe<CfLinkGroupNestedFilter>;
  footerLinks_exists?: InputMaybe<Scalars['Boolean']>;
  googleMapsAddressLink?: InputMaybe<Scalars['String']>;
  googleMapsAddressLink_contains?: InputMaybe<Scalars['String']>;
  googleMapsAddressLink_exists?: InputMaybe<Scalars['Boolean']>;
  googleMapsAddressLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  googleMapsAddressLink_not?: InputMaybe<Scalars['String']>;
  googleMapsAddressLink_not_contains?: InputMaybe<Scalars['String']>;
  googleMapsAddressLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaLinks?: InputMaybe<CfLinkGroupNestedFilter>;
  socialMediaLinks_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  telephone?: InputMaybe<Scalars['String']>;
  telephone_contains?: InputMaybe<Scalars['String']>;
  telephone_exists?: InputMaybe<Scalars['Boolean']>;
  telephone_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  telephone_not?: InputMaybe<Scalars['String']>;
  telephone_not_contains?: InputMaybe<Scalars['String']>;
  telephone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FooterLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type FooterLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum FooterOrder {
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  EstablishedAsc = 'established_ASC',
  EstablishedDesc = 'established_DESC',
  GoogleMapsAddressLinkAsc = 'googleMapsAddressLink_ASC',
  GoogleMapsAddressLinkDesc = 'googleMapsAddressLink_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TelephoneAsc = 'telephone_ASC',
  TelephoneDesc = 'telephone_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type FundingDetail = {
  amount?: Maybe<Scalars['Float']>;
  companyName?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  epriContract?: Maybe<Scalars['String']>;
  fundingType?: Maybe<Scalars['String']>;
  fundingYear?: Maybe<Scalars['Int']>;
  productSector?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type FundingDetailResponse = {
  details?: Maybe<Array<Maybe<FundingDetail>>>;
  title?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type FundingSummary = {
  amount?: Maybe<Scalars['Float']>;
  companyName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fundingName?: Maybe<Scalars['String']>;
  fundingType?: Maybe<Scalars['String']>;
  fundingYear?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export type FundingSummaryResponse = {
  summary?: Maybe<Array<Maybe<FundingSummary>>>;
  title?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/galleryTemplate) */
export type GalleryTemplate = Entry & {
  body?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<GalleryTemplateLinkingCollections>;
  permalink?: Maybe<Scalars['String']>;
  redirect?: Maybe<Scalars['String']>;
  section1GalleryCollection?: Maybe<GalleryTemplateSection1GalleryCollection>;
  section1Title?: Maybe<Scalars['String']>;
  section2GalleryCollection?: Maybe<GalleryTemplateSection2GalleryCollection>;
  section2Title?: Maybe<Scalars['String']>;
  section3GalleryCollection?: Maybe<GalleryTemplateSection3GalleryCollection>;
  section3Title?: Maybe<Scalars['String']>;
  section4GalleryCollection?: Maybe<GalleryTemplateSection4GalleryCollection>;
  section4Title?: Maybe<Scalars['String']>;
  section5GalleryCollection?: Maybe<GalleryTemplateSection5GalleryCollection>;
  section5Title?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  tooltip?: Maybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/galleryTemplate) */
export type GalleryTemplateBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/galleryTemplate) */
export type GalleryTemplateLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/galleryTemplate) */
export type GalleryTemplatePermalinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/galleryTemplate) */
export type GalleryTemplateRedirectArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/galleryTemplate) */
export type GalleryTemplateSection1GalleryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/galleryTemplate) */
export type GalleryTemplateSection1TitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/galleryTemplate) */
export type GalleryTemplateSection2GalleryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/galleryTemplate) */
export type GalleryTemplateSection2TitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/galleryTemplate) */
export type GalleryTemplateSection3GalleryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/galleryTemplate) */
export type GalleryTemplateSection3TitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/galleryTemplate) */
export type GalleryTemplateSection4GalleryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/galleryTemplate) */
export type GalleryTemplateSection4TitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/galleryTemplate) */
export type GalleryTemplateSection5GalleryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/galleryTemplate) */
export type GalleryTemplateSection5TitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/galleryTemplate) */
export type GalleryTemplateTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/galleryTemplate) */
export type GalleryTemplateTooltipArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type GalleryTemplateCollection = {
  items: Array<Maybe<GalleryTemplate>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type GalleryTemplateFilter = {
  AND?: InputMaybe<Array<InputMaybe<GalleryTemplateFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<GalleryTemplateFilter>>>;
  body?: InputMaybe<Scalars['String']>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  permalink?: InputMaybe<Scalars['String']>;
  permalink_contains?: InputMaybe<Scalars['String']>;
  permalink_exists?: InputMaybe<Scalars['Boolean']>;
  permalink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  permalink_not?: InputMaybe<Scalars['String']>;
  permalink_not_contains?: InputMaybe<Scalars['String']>;
  permalink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redirect?: InputMaybe<Scalars['String']>;
  redirect_contains?: InputMaybe<Scalars['String']>;
  redirect_exists?: InputMaybe<Scalars['Boolean']>;
  redirect_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redirect_not?: InputMaybe<Scalars['String']>;
  redirect_not_contains?: InputMaybe<Scalars['String']>;
  redirect_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  section1GalleryCollection_exists?: InputMaybe<Scalars['Boolean']>;
  section1Title?: InputMaybe<Scalars['String']>;
  section1Title_contains?: InputMaybe<Scalars['String']>;
  section1Title_exists?: InputMaybe<Scalars['Boolean']>;
  section1Title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  section1Title_not?: InputMaybe<Scalars['String']>;
  section1Title_not_contains?: InputMaybe<Scalars['String']>;
  section1Title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  section2GalleryCollection_exists?: InputMaybe<Scalars['Boolean']>;
  section2Title?: InputMaybe<Scalars['String']>;
  section2Title_contains?: InputMaybe<Scalars['String']>;
  section2Title_exists?: InputMaybe<Scalars['Boolean']>;
  section2Title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  section2Title_not?: InputMaybe<Scalars['String']>;
  section2Title_not_contains?: InputMaybe<Scalars['String']>;
  section2Title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  section3GalleryCollection_exists?: InputMaybe<Scalars['Boolean']>;
  section3Title?: InputMaybe<Scalars['String']>;
  section3Title_contains?: InputMaybe<Scalars['String']>;
  section3Title_exists?: InputMaybe<Scalars['Boolean']>;
  section3Title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  section3Title_not?: InputMaybe<Scalars['String']>;
  section3Title_not_contains?: InputMaybe<Scalars['String']>;
  section3Title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  section4GalleryCollection_exists?: InputMaybe<Scalars['Boolean']>;
  section4Title?: InputMaybe<Scalars['String']>;
  section4Title_contains?: InputMaybe<Scalars['String']>;
  section4Title_exists?: InputMaybe<Scalars['Boolean']>;
  section4Title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  section4Title_not?: InputMaybe<Scalars['String']>;
  section4Title_not_contains?: InputMaybe<Scalars['String']>;
  section4Title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  section5GalleryCollection_exists?: InputMaybe<Scalars['Boolean']>;
  section5Title?: InputMaybe<Scalars['String']>;
  section5Title_contains?: InputMaybe<Scalars['String']>;
  section5Title_exists?: InputMaybe<Scalars['Boolean']>;
  section5Title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  section5Title_not?: InputMaybe<Scalars['String']>;
  section5Title_not_contains?: InputMaybe<Scalars['String']>;
  section5Title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tooltip?: InputMaybe<Scalars['String']>;
  tooltip_contains?: InputMaybe<Scalars['String']>;
  tooltip_exists?: InputMaybe<Scalars['Boolean']>;
  tooltip_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tooltip_not?: InputMaybe<Scalars['String']>;
  tooltip_not_contains?: InputMaybe<Scalars['String']>;
  tooltip_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GalleryTemplateLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type GalleryTemplateLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum GalleryTemplateOrder {
  PermalinkAsc = 'permalink_ASC',
  PermalinkDesc = 'permalink_DESC',
  RedirectAsc = 'redirect_ASC',
  RedirectDesc = 'redirect_DESC',
  Section1TitleAsc = 'section1Title_ASC',
  Section1TitleDesc = 'section1Title_DESC',
  Section2TitleAsc = 'section2Title_ASC',
  Section2TitleDesc = 'section2Title_DESC',
  Section3TitleAsc = 'section3Title_ASC',
  Section3TitleDesc = 'section3Title_DESC',
  Section4TitleAsc = 'section4Title_ASC',
  Section4TitleDesc = 'section4Title_DESC',
  Section5TitleAsc = 'section5Title_ASC',
  Section5TitleDesc = 'section5Title_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TooltipAsc = 'tooltip_ASC',
  TooltipDesc = 'tooltip_DESC'
}

export type GalleryTemplateSection1GalleryCollection = {
  items: Array<Maybe<StoryCards>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type GalleryTemplateSection2GalleryCollection = {
  items: Array<Maybe<StoryCards>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type GalleryTemplateSection3GalleryCollection = {
  items: Array<Maybe<StoryCards>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type GalleryTemplateSection4GalleryCollection = {
  items: Array<Maybe<StoryCards>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type GalleryTemplateSection5GalleryCollection = {
  items: Array<Maybe<StoryCards>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/genericTitleAndBodyContent) */
export type GenericTitleAndBodyContent = Entry & {
  body?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<GenericTitleAndBodyContentLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/genericTitleAndBodyContent) */
export type GenericTitleAndBodyContentBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/genericTitleAndBodyContent) */
export type GenericTitleAndBodyContentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/genericTitleAndBodyContent) */
export type GenericTitleAndBodyContentTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type GenericTitleAndBodyContentCollection = {
  items: Array<Maybe<GenericTitleAndBodyContent>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type GenericTitleAndBodyContentFilter = {
  AND?: InputMaybe<Array<InputMaybe<GenericTitleAndBodyContentFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<GenericTitleAndBodyContentFilter>>>;
  body?: InputMaybe<Scalars['String']>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GenericTitleAndBodyContentLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type GenericTitleAndBodyContentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum GenericTitleAndBodyContentOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/governance) */
export type Governance = Entry & {
  bodyIntro?: Maybe<Scalars['String']>;
  columnOneCollection?: Maybe<GovernanceColumnOneCollection>;
  columnTwoCollection?: Maybe<GovernanceColumnTwoCollection>;
  contentfulMetadata: ContentfulMetadata;
  image?: Maybe<Asset>;
  jumbotron?: Maybe<Jumbotron>;
  linkedFrom?: Maybe<GovernanceLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/governance) */
export type GovernanceBodyIntroArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/governance) */
export type GovernanceColumnOneCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/governance) */
export type GovernanceColumnTwoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/governance) */
export type GovernanceImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/governance) */
export type GovernanceJumbotronArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/governance) */
export type GovernanceLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/governance) */
export type GovernanceTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type GovernanceCollection = {
  items: Array<Maybe<Governance>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type GovernanceColumnOneCollection = {
  items: Array<Maybe<GovernanceContent>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type GovernanceColumnTwoCollection = {
  items: Array<Maybe<GovernanceContent>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/governanceContent) */
export type GovernanceContent = Entry & {
  body?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<GovernanceContentLinkingCollections>;
  list?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/governanceContent) */
export type GovernanceContentBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/governanceContent) */
export type GovernanceContentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/governanceContent) */
export type GovernanceContentListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/governanceContent) */
export type GovernanceContentTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type GovernanceContentCollection = {
  items: Array<Maybe<GovernanceContent>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type GovernanceContentFilter = {
  AND?: InputMaybe<Array<InputMaybe<GovernanceContentFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<GovernanceContentFilter>>>;
  body?: InputMaybe<Scalars['String']>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  list?: InputMaybe<Scalars['String']>;
  list_contains?: InputMaybe<Scalars['String']>;
  list_exists?: InputMaybe<Scalars['Boolean']>;
  list_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  list_not?: InputMaybe<Scalars['String']>;
  list_not_contains?: InputMaybe<Scalars['String']>;
  list_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GovernanceContentLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  governanceCollection?: Maybe<GovernanceCollection>;
};


export type GovernanceContentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type GovernanceContentLinkingCollectionsGovernanceCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum GovernanceContentOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type GovernanceFilter = {
  AND?: InputMaybe<Array<InputMaybe<GovernanceFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<GovernanceFilter>>>;
  bodyIntro?: InputMaybe<Scalars['String']>;
  bodyIntro_contains?: InputMaybe<Scalars['String']>;
  bodyIntro_exists?: InputMaybe<Scalars['Boolean']>;
  bodyIntro_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bodyIntro_not?: InputMaybe<Scalars['String']>;
  bodyIntro_not_contains?: InputMaybe<Scalars['String']>;
  bodyIntro_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  columnOneCollection_exists?: InputMaybe<Scalars['Boolean']>;
  columnTwoCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  jumbotron?: InputMaybe<CfJumbotronNestedFilter>;
  jumbotron_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GovernanceLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type GovernanceLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum GovernanceOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/hbuDemoData) */
export type HbuDemoData = Entry & {
  contentfulMetadata: ContentfulMetadata;
  hbuContent?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<HbuDemoDataLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/hbuDemoData) */
export type HbuDemoDataHbuContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/hbuDemoData) */
export type HbuDemoDataLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/hbuDemoData) */
export type HbuDemoDataTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type HbuDemoDataCollection = {
  items: Array<Maybe<HbuDemoData>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type HbuDemoDataFilter = {
  AND?: InputMaybe<Array<InputMaybe<HbuDemoDataFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<HbuDemoDataFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  hbuContent?: InputMaybe<Scalars['String']>;
  hbuContent_contains?: InputMaybe<Scalars['String']>;
  hbuContent_exists?: InputMaybe<Scalars['Boolean']>;
  hbuContent_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hbuContent_not?: InputMaybe<Scalars['String']>;
  hbuContent_not_contains?: InputMaybe<Scalars['String']>;
  hbuContent_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type HbuDemoDataLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type HbuDemoDataLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum HbuDemoDataOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/header) */
export type Header = Entry & {
  contact?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  createAnAccount?: Maybe<Scalars['String']>;
  events?: Maybe<Scalars['String']>;
  help?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<HeaderLinkingCollections>;
  loginText?: Maybe<Scalars['String']>;
  logoutText?: Maybe<Scalars['String']>;
  memberLogin?: Maybe<Scalars['String']>;
  menu?: Maybe<Scalars['String']>;
  more?: Maybe<Scalars['String']>;
  noTermsMatching?: Maybe<Scalars['String']>;
  research?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  searchEpriEvents?: Maybe<Scalars['String']>;
  searchEpriResearch?: Maybe<Scalars['String']>;
  searchEpriWebsites?: Maybe<Scalars['String']>;
  searchingIn?: Maybe<Scalars['String']>;
  selectLanguage?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  websites?: Maybe<Scalars['String']>;
  wereFound?: Maybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/header) */
export type HeaderContactArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/header) */
export type HeaderCreateAnAccountArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/header) */
export type HeaderEventsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/header) */
export type HeaderHelpArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/header) */
export type HeaderLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/header) */
export type HeaderLoginTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/header) */
export type HeaderLogoutTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/header) */
export type HeaderMemberLoginArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/header) */
export type HeaderMenuArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/header) */
export type HeaderMoreArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/header) */
export type HeaderNoTermsMatchingArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/header) */
export type HeaderResearchArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/header) */
export type HeaderSearchArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/header) */
export type HeaderSearchEpriEventsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/header) */
export type HeaderSearchEpriResearchArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/header) */
export type HeaderSearchEpriWebsitesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/header) */
export type HeaderSearchingInArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/header) */
export type HeaderSelectLanguageArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/header) */
export type HeaderTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/header) */
export type HeaderUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/header) */
export type HeaderWebsitesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/header) */
export type HeaderWereFoundArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type HeaderCollection = {
  items: Array<Maybe<Header>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type HeaderFilter = {
  AND?: InputMaybe<Array<InputMaybe<HeaderFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<HeaderFilter>>>;
  contact?: InputMaybe<Scalars['String']>;
  contact_contains?: InputMaybe<Scalars['String']>;
  contact_exists?: InputMaybe<Scalars['Boolean']>;
  contact_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contact_not?: InputMaybe<Scalars['String']>;
  contact_not_contains?: InputMaybe<Scalars['String']>;
  contact_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  createAnAccount?: InputMaybe<Scalars['String']>;
  createAnAccount_contains?: InputMaybe<Scalars['String']>;
  createAnAccount_exists?: InputMaybe<Scalars['Boolean']>;
  createAnAccount_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  createAnAccount_not?: InputMaybe<Scalars['String']>;
  createAnAccount_not_contains?: InputMaybe<Scalars['String']>;
  createAnAccount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  events?: InputMaybe<Scalars['String']>;
  events_contains?: InputMaybe<Scalars['String']>;
  events_exists?: InputMaybe<Scalars['Boolean']>;
  events_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  events_not?: InputMaybe<Scalars['String']>;
  events_not_contains?: InputMaybe<Scalars['String']>;
  events_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  help?: InputMaybe<Scalars['String']>;
  help_contains?: InputMaybe<Scalars['String']>;
  help_exists?: InputMaybe<Scalars['Boolean']>;
  help_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  help_not?: InputMaybe<Scalars['String']>;
  help_not_contains?: InputMaybe<Scalars['String']>;
  help_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  loginText?: InputMaybe<Scalars['String']>;
  loginText_contains?: InputMaybe<Scalars['String']>;
  loginText_exists?: InputMaybe<Scalars['Boolean']>;
  loginText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  loginText_not?: InputMaybe<Scalars['String']>;
  loginText_not_contains?: InputMaybe<Scalars['String']>;
  loginText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  logoutText?: InputMaybe<Scalars['String']>;
  logoutText_contains?: InputMaybe<Scalars['String']>;
  logoutText_exists?: InputMaybe<Scalars['Boolean']>;
  logoutText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  logoutText_not?: InputMaybe<Scalars['String']>;
  logoutText_not_contains?: InputMaybe<Scalars['String']>;
  logoutText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  memberLogin?: InputMaybe<Scalars['String']>;
  memberLogin_contains?: InputMaybe<Scalars['String']>;
  memberLogin_exists?: InputMaybe<Scalars['Boolean']>;
  memberLogin_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  memberLogin_not?: InputMaybe<Scalars['String']>;
  memberLogin_not_contains?: InputMaybe<Scalars['String']>;
  memberLogin_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  menu?: InputMaybe<Scalars['String']>;
  menu_contains?: InputMaybe<Scalars['String']>;
  menu_exists?: InputMaybe<Scalars['Boolean']>;
  menu_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  menu_not?: InputMaybe<Scalars['String']>;
  menu_not_contains?: InputMaybe<Scalars['String']>;
  menu_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  more?: InputMaybe<Scalars['String']>;
  more_contains?: InputMaybe<Scalars['String']>;
  more_exists?: InputMaybe<Scalars['Boolean']>;
  more_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  more_not?: InputMaybe<Scalars['String']>;
  more_not_contains?: InputMaybe<Scalars['String']>;
  more_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  noTermsMatching?: InputMaybe<Scalars['String']>;
  noTermsMatching_contains?: InputMaybe<Scalars['String']>;
  noTermsMatching_exists?: InputMaybe<Scalars['Boolean']>;
  noTermsMatching_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  noTermsMatching_not?: InputMaybe<Scalars['String']>;
  noTermsMatching_not_contains?: InputMaybe<Scalars['String']>;
  noTermsMatching_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  research?: InputMaybe<Scalars['String']>;
  research_contains?: InputMaybe<Scalars['String']>;
  research_exists?: InputMaybe<Scalars['Boolean']>;
  research_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  research_not?: InputMaybe<Scalars['String']>;
  research_not_contains?: InputMaybe<Scalars['String']>;
  research_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  search?: InputMaybe<Scalars['String']>;
  searchEpriEvents?: InputMaybe<Scalars['String']>;
  searchEpriEvents_contains?: InputMaybe<Scalars['String']>;
  searchEpriEvents_exists?: InputMaybe<Scalars['Boolean']>;
  searchEpriEvents_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchEpriEvents_not?: InputMaybe<Scalars['String']>;
  searchEpriEvents_not_contains?: InputMaybe<Scalars['String']>;
  searchEpriEvents_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchEpriResearch?: InputMaybe<Scalars['String']>;
  searchEpriResearch_contains?: InputMaybe<Scalars['String']>;
  searchEpriResearch_exists?: InputMaybe<Scalars['Boolean']>;
  searchEpriResearch_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchEpriResearch_not?: InputMaybe<Scalars['String']>;
  searchEpriResearch_not_contains?: InputMaybe<Scalars['String']>;
  searchEpriResearch_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchEpriWebsites?: InputMaybe<Scalars['String']>;
  searchEpriWebsites_contains?: InputMaybe<Scalars['String']>;
  searchEpriWebsites_exists?: InputMaybe<Scalars['Boolean']>;
  searchEpriWebsites_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchEpriWebsites_not?: InputMaybe<Scalars['String']>;
  searchEpriWebsites_not_contains?: InputMaybe<Scalars['String']>;
  searchEpriWebsites_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  search_contains?: InputMaybe<Scalars['String']>;
  search_exists?: InputMaybe<Scalars['Boolean']>;
  search_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  search_not?: InputMaybe<Scalars['String']>;
  search_not_contains?: InputMaybe<Scalars['String']>;
  search_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchingIn?: InputMaybe<Scalars['String']>;
  searchingIn_contains?: InputMaybe<Scalars['String']>;
  searchingIn_exists?: InputMaybe<Scalars['Boolean']>;
  searchingIn_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchingIn_not?: InputMaybe<Scalars['String']>;
  searchingIn_not_contains?: InputMaybe<Scalars['String']>;
  searchingIn_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  selectLanguage?: InputMaybe<Scalars['String']>;
  selectLanguage_contains?: InputMaybe<Scalars['String']>;
  selectLanguage_exists?: InputMaybe<Scalars['Boolean']>;
  selectLanguage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  selectLanguage_not?: InputMaybe<Scalars['String']>;
  selectLanguage_not_contains?: InputMaybe<Scalars['String']>;
  selectLanguage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  websites?: InputMaybe<Scalars['String']>;
  websites_contains?: InputMaybe<Scalars['String']>;
  websites_exists?: InputMaybe<Scalars['Boolean']>;
  websites_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  websites_not?: InputMaybe<Scalars['String']>;
  websites_not_contains?: InputMaybe<Scalars['String']>;
  websites_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  wereFound?: InputMaybe<Scalars['String']>;
  wereFound_contains?: InputMaybe<Scalars['String']>;
  wereFound_exists?: InputMaybe<Scalars['Boolean']>;
  wereFound_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  wereFound_not?: InputMaybe<Scalars['String']>;
  wereFound_not_contains?: InputMaybe<Scalars['String']>;
  wereFound_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type HeaderLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type HeaderLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum HeaderOrder {
  ContactAsc = 'contact_ASC',
  ContactDesc = 'contact_DESC',
  CreateAnAccountAsc = 'createAnAccount_ASC',
  CreateAnAccountDesc = 'createAnAccount_DESC',
  EventsAsc = 'events_ASC',
  EventsDesc = 'events_DESC',
  HelpAsc = 'help_ASC',
  HelpDesc = 'help_DESC',
  LoginTextAsc = 'loginText_ASC',
  LoginTextDesc = 'loginText_DESC',
  LogoutTextAsc = 'logoutText_ASC',
  LogoutTextDesc = 'logoutText_DESC',
  MemberLoginAsc = 'memberLogin_ASC',
  MemberLoginDesc = 'memberLogin_DESC',
  MenuAsc = 'menu_ASC',
  MenuDesc = 'menu_DESC',
  MoreAsc = 'more_ASC',
  MoreDesc = 'more_DESC',
  NoTermsMatchingAsc = 'noTermsMatching_ASC',
  NoTermsMatchingDesc = 'noTermsMatching_DESC',
  ResearchAsc = 'research_ASC',
  ResearchDesc = 'research_DESC',
  SearchEpriEventsAsc = 'searchEpriEvents_ASC',
  SearchEpriEventsDesc = 'searchEpriEvents_DESC',
  SearchEpriResearchAsc = 'searchEpriResearch_ASC',
  SearchEpriResearchDesc = 'searchEpriResearch_DESC',
  SearchEpriWebsitesAsc = 'searchEpriWebsites_ASC',
  SearchEpriWebsitesDesc = 'searchEpriWebsites_DESC',
  SearchAsc = 'search_ASC',
  SearchDesc = 'search_DESC',
  SearchingInAsc = 'searchingIn_ASC',
  SearchingInDesc = 'searchingIn_DESC',
  SelectLanguageAsc = 'selectLanguage_ASC',
  SelectLanguageDesc = 'selectLanguage_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  WebsitesAsc = 'websites_ASC',
  WebsitesDesc = 'websites_DESC',
  WereFoundAsc = 'wereFound_ASC',
  WereFoundDesc = 'wereFound_DESC'
}

/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/help) */
export type Help = Entry & {
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<HelpLinkingCollections>;
  sidebarContent?: Maybe<Scalars['String']>;
  sys: Sys;
  tabsCollection?: Maybe<HelpTabsCollection>;
  title?: Maybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/help) */
export type HelpLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/help) */
export type HelpSidebarContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/help) */
export type HelpTabsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/help) */
export type HelpTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type HelpCollection = {
  items: Array<Maybe<Help>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type HelpFilter = {
  AND?: InputMaybe<Array<InputMaybe<HelpFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<HelpFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sidebarContent?: InputMaybe<Scalars['String']>;
  sidebarContent_contains?: InputMaybe<Scalars['String']>;
  sidebarContent_exists?: InputMaybe<Scalars['Boolean']>;
  sidebarContent_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sidebarContent_not?: InputMaybe<Scalars['String']>;
  sidebarContent_not_contains?: InputMaybe<Scalars['String']>;
  sidebarContent_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  tabsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type HelpLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type HelpLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum HelpOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/helpTab) */
export type HelpTab = Entry & {
  content?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<HelpTabLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/helpTab) */
export type HelpTabContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/helpTab) */
export type HelpTabLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/helpTab) */
export type HelpTabTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type HelpTabCollection = {
  items: Array<Maybe<HelpTab>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type HelpTabFilter = {
  AND?: InputMaybe<Array<InputMaybe<HelpTabFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<HelpTabFilter>>>;
  content?: InputMaybe<Scalars['String']>;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  content_not?: InputMaybe<Scalars['String']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  content_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type HelpTabLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  helpCollection?: Maybe<HelpCollection>;
};


export type HelpTabLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type HelpTabLinkingCollectionsHelpCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum HelpTabOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type HelpTabsCollection = {
  items: Array<Maybe<HelpTab>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum HierarchySortOrder {
  Ltn = 'ltn',
  Number = 'number',
  Title = 'title'
}

/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type Home = Entry & {
  contentfulMetadata: ContentfulMetadata;
  jobsBody?: Maybe<Scalars['String']>;
  jobsButtonLinkBetaUseInternal?: Maybe<Scalars['Boolean']>;
  jobsButtonOneLink?: Maybe<Scalars['String']>;
  jobsButtonOneText?: Maybe<Scalars['String']>;
  jobsButtonTwoLink?: Maybe<Scalars['String']>;
  jobsButtonTwoLinkBeta?: Maybe<Scalars['String']>;
  jobsButtonTwoLinkBetaExternal?: Maybe<Scalars['String']>;
  jobsButtonTwoText?: Maybe<Scalars['String']>;
  jobsImage?: Maybe<Asset>;
  jobsTitle?: Maybe<Scalars['String']>;
  jumbotron?: Maybe<Jumbotron>;
  linkedFrom?: Maybe<HomeLinkingCollections>;
  portfolio?: Maybe<PortfolioSection>;
  promotedSectionBody?: Maybe<Scalars['String']>;
  promotedSectionButtonBetaUseInternal?: Maybe<Scalars['Boolean']>;
  promotedSectionButtonLink?: Maybe<Scalars['String']>;
  promotedSectionButtonLinkBetaExternal?: Maybe<Scalars['String']>;
  promotedSectionButtonLinkBetaInternal?: Maybe<Scalars['String']>;
  promotedSectionButtonText?: Maybe<Scalars['String']>;
  promotedSectionImage?: Maybe<Asset>;
  promotedSectionTitle?: Maybe<Scalars['String']>;
  socialTitle?: Maybe<Scalars['String']>;
  storyCardsCollection?: Maybe<HomeStoryCardsCollection>;
  sys: Sys;
  tabsCollection?: Maybe<HomeTabsCollection>;
  title?: Maybe<Scalars['String']>;
  twitterImage?: Maybe<Asset>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomeJobsBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomeJobsButtonLinkBetaUseInternalArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomeJobsButtonOneLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomeJobsButtonOneTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomeJobsButtonTwoLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomeJobsButtonTwoLinkBetaArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomeJobsButtonTwoLinkBetaExternalArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomeJobsButtonTwoTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomeJobsImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomeJobsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomeJumbotronArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomeLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomePortfolioArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomePromotedSectionBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomePromotedSectionButtonBetaUseInternalArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomePromotedSectionButtonLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomePromotedSectionButtonLinkBetaExternalArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomePromotedSectionButtonLinkBetaInternalArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomePromotedSectionButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomePromotedSectionImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomePromotedSectionTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomeSocialTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomeStoryCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomeTabsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomeTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/home) */
export type HomeTwitterImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type HomeCollection = {
  items: Array<Maybe<Home>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type HomeFilter = {
  AND?: InputMaybe<Array<InputMaybe<HomeFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<HomeFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  jobsBody?: InputMaybe<Scalars['String']>;
  jobsBody_contains?: InputMaybe<Scalars['String']>;
  jobsBody_exists?: InputMaybe<Scalars['Boolean']>;
  jobsBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jobsBody_not?: InputMaybe<Scalars['String']>;
  jobsBody_not_contains?: InputMaybe<Scalars['String']>;
  jobsBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jobsButtonLinkBetaUseInternal?: InputMaybe<Scalars['Boolean']>;
  jobsButtonLinkBetaUseInternal_exists?: InputMaybe<Scalars['Boolean']>;
  jobsButtonLinkBetaUseInternal_not?: InputMaybe<Scalars['Boolean']>;
  jobsButtonOneLink?: InputMaybe<Scalars['String']>;
  jobsButtonOneLink_contains?: InputMaybe<Scalars['String']>;
  jobsButtonOneLink_exists?: InputMaybe<Scalars['Boolean']>;
  jobsButtonOneLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jobsButtonOneLink_not?: InputMaybe<Scalars['String']>;
  jobsButtonOneLink_not_contains?: InputMaybe<Scalars['String']>;
  jobsButtonOneLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jobsButtonOneText?: InputMaybe<Scalars['String']>;
  jobsButtonOneText_contains?: InputMaybe<Scalars['String']>;
  jobsButtonOneText_exists?: InputMaybe<Scalars['Boolean']>;
  jobsButtonOneText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jobsButtonOneText_not?: InputMaybe<Scalars['String']>;
  jobsButtonOneText_not_contains?: InputMaybe<Scalars['String']>;
  jobsButtonOneText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jobsButtonTwoLink?: InputMaybe<Scalars['String']>;
  jobsButtonTwoLinkBeta?: InputMaybe<Scalars['String']>;
  jobsButtonTwoLinkBetaExternal?: InputMaybe<Scalars['String']>;
  jobsButtonTwoLinkBetaExternal_contains?: InputMaybe<Scalars['String']>;
  jobsButtonTwoLinkBetaExternal_exists?: InputMaybe<Scalars['Boolean']>;
  jobsButtonTwoLinkBetaExternal_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jobsButtonTwoLinkBetaExternal_not?: InputMaybe<Scalars['String']>;
  jobsButtonTwoLinkBetaExternal_not_contains?: InputMaybe<Scalars['String']>;
  jobsButtonTwoLinkBetaExternal_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jobsButtonTwoLinkBeta_contains?: InputMaybe<Scalars['String']>;
  jobsButtonTwoLinkBeta_exists?: InputMaybe<Scalars['Boolean']>;
  jobsButtonTwoLinkBeta_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jobsButtonTwoLinkBeta_not?: InputMaybe<Scalars['String']>;
  jobsButtonTwoLinkBeta_not_contains?: InputMaybe<Scalars['String']>;
  jobsButtonTwoLinkBeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jobsButtonTwoLink_contains?: InputMaybe<Scalars['String']>;
  jobsButtonTwoLink_exists?: InputMaybe<Scalars['Boolean']>;
  jobsButtonTwoLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jobsButtonTwoLink_not?: InputMaybe<Scalars['String']>;
  jobsButtonTwoLink_not_contains?: InputMaybe<Scalars['String']>;
  jobsButtonTwoLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jobsButtonTwoText?: InputMaybe<Scalars['String']>;
  jobsButtonTwoText_contains?: InputMaybe<Scalars['String']>;
  jobsButtonTwoText_exists?: InputMaybe<Scalars['Boolean']>;
  jobsButtonTwoText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jobsButtonTwoText_not?: InputMaybe<Scalars['String']>;
  jobsButtonTwoText_not_contains?: InputMaybe<Scalars['String']>;
  jobsButtonTwoText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jobsImage_exists?: InputMaybe<Scalars['Boolean']>;
  jobsTitle?: InputMaybe<Scalars['String']>;
  jobsTitle_contains?: InputMaybe<Scalars['String']>;
  jobsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  jobsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jobsTitle_not?: InputMaybe<Scalars['String']>;
  jobsTitle_not_contains?: InputMaybe<Scalars['String']>;
  jobsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jumbotron?: InputMaybe<CfJumbotronNestedFilter>;
  jumbotron_exists?: InputMaybe<Scalars['Boolean']>;
  portfolio?: InputMaybe<CfPortfolioSectionNestedFilter>;
  portfolio_exists?: InputMaybe<Scalars['Boolean']>;
  promotedSectionBody?: InputMaybe<Scalars['String']>;
  promotedSectionBody_contains?: InputMaybe<Scalars['String']>;
  promotedSectionBody_exists?: InputMaybe<Scalars['Boolean']>;
  promotedSectionBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  promotedSectionBody_not?: InputMaybe<Scalars['String']>;
  promotedSectionBody_not_contains?: InputMaybe<Scalars['String']>;
  promotedSectionBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  promotedSectionButtonBetaUseInternal?: InputMaybe<Scalars['Boolean']>;
  promotedSectionButtonBetaUseInternal_exists?: InputMaybe<Scalars['Boolean']>;
  promotedSectionButtonBetaUseInternal_not?: InputMaybe<Scalars['Boolean']>;
  promotedSectionButtonLink?: InputMaybe<Scalars['String']>;
  promotedSectionButtonLinkBetaExternal?: InputMaybe<Scalars['String']>;
  promotedSectionButtonLinkBetaExternal_contains?: InputMaybe<Scalars['String']>;
  promotedSectionButtonLinkBetaExternal_exists?: InputMaybe<Scalars['Boolean']>;
  promotedSectionButtonLinkBetaExternal_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  promotedSectionButtonLinkBetaExternal_not?: InputMaybe<Scalars['String']>;
  promotedSectionButtonLinkBetaExternal_not_contains?: InputMaybe<Scalars['String']>;
  promotedSectionButtonLinkBetaExternal_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  promotedSectionButtonLinkBetaInternal?: InputMaybe<Scalars['String']>;
  promotedSectionButtonLinkBetaInternal_contains?: InputMaybe<Scalars['String']>;
  promotedSectionButtonLinkBetaInternal_exists?: InputMaybe<Scalars['Boolean']>;
  promotedSectionButtonLinkBetaInternal_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  promotedSectionButtonLinkBetaInternal_not?: InputMaybe<Scalars['String']>;
  promotedSectionButtonLinkBetaInternal_not_contains?: InputMaybe<Scalars['String']>;
  promotedSectionButtonLinkBetaInternal_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  promotedSectionButtonLink_contains?: InputMaybe<Scalars['String']>;
  promotedSectionButtonLink_exists?: InputMaybe<Scalars['Boolean']>;
  promotedSectionButtonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  promotedSectionButtonLink_not?: InputMaybe<Scalars['String']>;
  promotedSectionButtonLink_not_contains?: InputMaybe<Scalars['String']>;
  promotedSectionButtonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  promotedSectionButtonText?: InputMaybe<Scalars['String']>;
  promotedSectionButtonText_contains?: InputMaybe<Scalars['String']>;
  promotedSectionButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  promotedSectionButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  promotedSectionButtonText_not?: InputMaybe<Scalars['String']>;
  promotedSectionButtonText_not_contains?: InputMaybe<Scalars['String']>;
  promotedSectionButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  promotedSectionImage_exists?: InputMaybe<Scalars['Boolean']>;
  promotedSectionTitle?: InputMaybe<Scalars['String']>;
  promotedSectionTitle_contains?: InputMaybe<Scalars['String']>;
  promotedSectionTitle_exists?: InputMaybe<Scalars['Boolean']>;
  promotedSectionTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  promotedSectionTitle_not?: InputMaybe<Scalars['String']>;
  promotedSectionTitle_not_contains?: InputMaybe<Scalars['String']>;
  promotedSectionTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialTitle?: InputMaybe<Scalars['String']>;
  socialTitle_contains?: InputMaybe<Scalars['String']>;
  socialTitle_exists?: InputMaybe<Scalars['Boolean']>;
  socialTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialTitle_not?: InputMaybe<Scalars['String']>;
  socialTitle_not_contains?: InputMaybe<Scalars['String']>;
  socialTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  storyCardsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  tabsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  twitterImage_exists?: InputMaybe<Scalars['Boolean']>;
};

export type HomeLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type HomeLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum HomeOrder {
  JobsButtonLinkBetaUseInternalAsc = 'jobsButtonLinkBetaUseInternal_ASC',
  JobsButtonLinkBetaUseInternalDesc = 'jobsButtonLinkBetaUseInternal_DESC',
  JobsButtonOneLinkAsc = 'jobsButtonOneLink_ASC',
  JobsButtonOneLinkDesc = 'jobsButtonOneLink_DESC',
  JobsButtonOneTextAsc = 'jobsButtonOneText_ASC',
  JobsButtonOneTextDesc = 'jobsButtonOneText_DESC',
  JobsButtonTwoLinkBetaExternalAsc = 'jobsButtonTwoLinkBetaExternal_ASC',
  JobsButtonTwoLinkBetaExternalDesc = 'jobsButtonTwoLinkBetaExternal_DESC',
  JobsButtonTwoLinkBetaAsc = 'jobsButtonTwoLinkBeta_ASC',
  JobsButtonTwoLinkBetaDesc = 'jobsButtonTwoLinkBeta_DESC',
  JobsButtonTwoLinkAsc = 'jobsButtonTwoLink_ASC',
  JobsButtonTwoLinkDesc = 'jobsButtonTwoLink_DESC',
  JobsButtonTwoTextAsc = 'jobsButtonTwoText_ASC',
  JobsButtonTwoTextDesc = 'jobsButtonTwoText_DESC',
  JobsTitleAsc = 'jobsTitle_ASC',
  JobsTitleDesc = 'jobsTitle_DESC',
  PromotedSectionButtonBetaUseInternalAsc = 'promotedSectionButtonBetaUseInternal_ASC',
  PromotedSectionButtonBetaUseInternalDesc = 'promotedSectionButtonBetaUseInternal_DESC',
  PromotedSectionButtonLinkBetaExternalAsc = 'promotedSectionButtonLinkBetaExternal_ASC',
  PromotedSectionButtonLinkBetaExternalDesc = 'promotedSectionButtonLinkBetaExternal_DESC',
  PromotedSectionButtonLinkBetaInternalAsc = 'promotedSectionButtonLinkBetaInternal_ASC',
  PromotedSectionButtonLinkBetaInternalDesc = 'promotedSectionButtonLinkBetaInternal_DESC',
  PromotedSectionButtonLinkAsc = 'promotedSectionButtonLink_ASC',
  PromotedSectionButtonLinkDesc = 'promotedSectionButtonLink_DESC',
  PromotedSectionButtonTextAsc = 'promotedSectionButtonText_ASC',
  PromotedSectionButtonTextDesc = 'promotedSectionButtonText_DESC',
  PromotedSectionTitleAsc = 'promotedSectionTitle_ASC',
  PromotedSectionTitleDesc = 'promotedSectionTitle_DESC',
  SocialTitleAsc = 'socialTitle_ASC',
  SocialTitleDesc = 'socialTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type HomeStoryCardsCollection = {
  items: Array<Maybe<StoryCards>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type HomeTabsCollection = {
  items: Array<Maybe<Tabs>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ImageFormat {
  Avif = 'AVIF',
  /** JPG image format. */
  Jpg = 'JPG',
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JpgProgressive = 'JPG_PROGRESSIVE',
  /** PNG image format */
  Png = 'PNG',
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  Png8 = 'PNG8',
  /** WebP image format. */
  Webp = 'WEBP'
}

export enum ImageResizeFocus {
  /** Focus the resizing on the bottom. */
  Bottom = 'BOTTOM',
  /** Focus the resizing on the bottom left. */
  BottomLeft = 'BOTTOM_LEFT',
  /** Focus the resizing on the bottom right. */
  BottomRight = 'BOTTOM_RIGHT',
  /** Focus the resizing on the center. */
  Center = 'CENTER',
  /** Focus the resizing on the largest face. */
  Face = 'FACE',
  /** Focus the resizing on the area containing all the faces. */
  Faces = 'FACES',
  /** Focus the resizing on the left. */
  Left = 'LEFT',
  /** Focus the resizing on the right. */
  Right = 'RIGHT',
  /** Focus the resizing on the top. */
  Top = 'TOP',
  /** Focus the resizing on the top left. */
  TopLeft = 'TOP_LEFT',
  /** Focus the resizing on the top right. */
  TopRight = 'TOP_RIGHT'
}

export enum ImageResizeStrategy {
  /** Crops a part of the original image to fit into the specified dimensions. */
  Crop = 'CROP',
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  Fill = 'FILL',
  /** Resizes the image to fit into the specified dimensions. */
  Fit = 'FIT',
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  Pad = 'PAD',
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  Scale = 'SCALE',
  /** Creates a thumbnail from the image. */
  Thumb = 'THUMB'
}

export type ImageTransformOptions = {
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor?: InputMaybe<Scalars['HexColor']>;
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius?: InputMaybe<Scalars['Int']>;
  /** Desired image format. Defaults to the original image format. */
  format?: InputMaybe<ImageFormat>;
  /** Desired height in pixels. Defaults to the original image height. */
  height?: InputMaybe<Scalars['Dimension']>;
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality?: InputMaybe<Scalars['Quality']>;
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus?: InputMaybe<ImageResizeFocus>;
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy?: InputMaybe<ImageResizeStrategy>;
  /** Desired width in pixels. Defaults to the original image width. */
  width?: InputMaybe<Scalars['Dimension']>;
};

export type IndividualAccessContact = {
  contactCompanyGroup?: Maybe<Scalars['String']>;
  contactCompanyId?: Maybe<Scalars['String']>;
  contactCompanyName?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPending?: Maybe<Scalars['Boolean']>;
  productId?: Maybe<Scalars['String']>;
  sapSendDate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type IndividualAccessDetail = {
  contactCompanyId?: Maybe<Scalars['String']>;
  contactCompanyName?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  isPending?: Maybe<Scalars['Boolean']>;
  productId?: Maybe<Scalars['String']>;
  productLongDescription?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  sapSendDate?: Maybe<Scalars['String']>;
  sectorDisplayName?: Maybe<Scalars['String']>;
  sectorLTN?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type IndividualAccessDetailsResponse = {
  productContacts?: Maybe<Array<Maybe<IndividualAccessProduct>>>;
  products?: Maybe<Array<Maybe<IndividualAccessDetail>>>;
  projectId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  sectorDisplayName?: Maybe<Scalars['String']>;
  sectorLTN?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
};

export type IndividualAccessProduct = {
  contacts?: Maybe<Array<Maybe<IndividualAccessContact>>>;
  productId?: Maybe<Scalars['String']>;
  productTitle?: Maybe<Scalars['String']>;
};

export type IndividualAccessProject = {
  products?: Maybe<Array<Maybe<IndividualAccessProduct>>>;
  projectId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
};

export type IndividualAccessRequestInput = {
  applyToAllProductsInProject?: InputMaybe<Scalars['Boolean']>;
  companyId: Scalars['String'];
  contactEmail?: InputMaybe<Scalars['String']>;
  contactFirstName?: InputMaybe<Scalars['String']>;
  contactId: Scalars['String'];
  contactLastName?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
  projectId: Scalars['String'];
  type?: InputMaybe<IndividualAccessRequestType>;
};

export enum IndividualAccessRequestType {
  Add = 'Add',
  Remove = 'Remove'
}

export type IndividualAccessSummary = {
  productsCount?: Maybe<Scalars['Int']>;
  productsWithContactsCount?: Maybe<Scalars['Int']>;
  productswihoutContactsCount?: Maybe<Scalars['Int']>;
  sectorDisplayName?: Maybe<Scalars['String']>;
  sectorLTN?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
};

export type IndividualAccessSummaryProduct = {
  projects?: Maybe<Array<Maybe<IndividualAccessProject>>>;
  sectorDisplayName?: Maybe<Scalars['String']>;
  sectorLTN?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
};

export type IndividualAccessSummaryResponse = {
  productsWithoutContacts?: Maybe<Array<Maybe<IndividualAccessSummaryProduct>>>;
  summaries?: Maybe<Array<Maybe<IndividualAccessSummary>>>;
};

export type InputPhoneFax = {
  countryCode?: InputMaybe<Scalars['String']>;
  extension?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
};

export type InviteeDetail = {
  advisorRole?: Maybe<Scalars['String']>;
  companyGroup?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contactID?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  invitedStatus?: Maybe<Scalars['String']>;
  isAdvisor?: Maybe<Scalars['Boolean']>;
  meetingName?: Maybe<Scalars['String']>;
  participatedStatus?: Maybe<Scalars['String']>;
  productID?: Maybe<Scalars['String']>;
  programName?: Maybe<Scalars['String']>;
  programNumber?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  projectNumber?: Maybe<Scalars['String']>;
  projectSetName?: Maybe<Scalars['String']>;
  projectSetNumber?: Maybe<Scalars['String']>;
  researchArea?: Maybe<Scalars['String']>;
  sector?: Maybe<Scalars['String']>;
  shortSector?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type InviteeDetailResponse = {
  details?: Maybe<Array<Maybe<InviteeDetail>>>;
  totalRecords?: Maybe<Scalars['Int']>;
};

/** xLPR Issue Requests [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/issueRequests) */
export type IssueRequests = Entry & {
  attachments?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<IssueRequestsLinkingCollections>;
  sys: Sys;
  taContent?: Maybe<IssueRequestsTaContent>;
};


/** xLPR Issue Requests [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/issueRequests) */
export type IssueRequestsAttachmentsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** xLPR Issue Requests [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/issueRequests) */
export type IssueRequestsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** xLPR Issue Requests [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/issueRequests) */
export type IssueRequestsTaContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type IssueRequestsCollection = {
  items: Array<Maybe<IssueRequests>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type IssueRequestsFilter = {
  AND?: InputMaybe<Array<InputMaybe<IssueRequestsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<IssueRequestsFilter>>>;
  attachments?: InputMaybe<Scalars['String']>;
  attachments_contains?: InputMaybe<Scalars['String']>;
  attachments_exists?: InputMaybe<Scalars['Boolean']>;
  attachments_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  attachments_not?: InputMaybe<Scalars['String']>;
  attachments_not_contains?: InputMaybe<Scalars['String']>;
  attachments_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  taContent_contains?: InputMaybe<Scalars['String']>;
  taContent_exists?: InputMaybe<Scalars['Boolean']>;
  taContent_not_contains?: InputMaybe<Scalars['String']>;
};

export type IssueRequestsLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type IssueRequestsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum IssueRequestsOrder {
  AttachmentsAsc = 'attachments_ASC',
  AttachmentsDesc = 'attachments_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type IssueRequestsTaContent = {
  json: Scalars['JSON'];
  links: IssueRequestsTaContentLinks;
};

export type IssueRequestsTaContentAssets = {
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type IssueRequestsTaContentEntries = {
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type IssueRequestsTaContentLinks = {
  assets: IssueRequestsTaContentAssets;
  entries: IssueRequestsTaContentEntries;
};

/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/jumbotron) */
export type Jumbotron = Entry & {
  actionButtonsCollection?: Maybe<JumbotronActionButtonsCollection>;
  body?: Maybe<Scalars['String']>;
  bodyMarkdown?: Maybe<Scalars['String']>;
  buttonLink?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  entryTitle?: Maybe<Scalars['String']>;
  fifthButtonText?: Maybe<Scalars['String']>;
  fifthButtonUrl?: Maybe<Scalars['String']>;
  fourthButtonText?: Maybe<Scalars['String']>;
  fourthButtonUrl?: Maybe<Scalars['String']>;
  image?: Maybe<Asset>;
  linkedFrom?: Maybe<JumbotronLinkingCollections>;
  preferredUi?: Maybe<Scalars['String']>;
  secondaryButtonLink?: Maybe<Scalars['String']>;
  secondaryButtonText?: Maybe<Scalars['String']>;
  sixthButtonText?: Maybe<Scalars['String']>;
  sixthButtonUrl?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  sys: Sys;
  thirdButtonText?: Maybe<Scalars['String']>;
  thirdButtonUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  videoThumbnail?: Maybe<Asset>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/jumbotron) */
export type JumbotronActionButtonsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/jumbotron) */
export type JumbotronBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/jumbotron) */
export type JumbotronBodyMarkdownArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/jumbotron) */
export type JumbotronButtonLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/jumbotron) */
export type JumbotronButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/jumbotron) */
export type JumbotronEntryTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/jumbotron) */
export type JumbotronFifthButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/jumbotron) */
export type JumbotronFifthButtonUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/jumbotron) */
export type JumbotronFourthButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/jumbotron) */
export type JumbotronFourthButtonUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/jumbotron) */
export type JumbotronImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/jumbotron) */
export type JumbotronLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/jumbotron) */
export type JumbotronPreferredUiArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/jumbotron) */
export type JumbotronSecondaryButtonLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/jumbotron) */
export type JumbotronSecondaryButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/jumbotron) */
export type JumbotronSixthButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/jumbotron) */
export type JumbotronSixthButtonUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/jumbotron) */
export type JumbotronSubtitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/jumbotron) */
export type JumbotronThirdButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/jumbotron) */
export type JumbotronThirdButtonUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/jumbotron) */
export type JumbotronTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/jumbotron) */
export type JumbotronVideoThumbnailArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type JumbotronActionButtonsCollection = {
  items: Array<Maybe<Button>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type JumbotronCollection = {
  items: Array<Maybe<Jumbotron>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type JumbotronFilter = {
  AND?: InputMaybe<Array<InputMaybe<JumbotronFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<JumbotronFilter>>>;
  actionButtonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  body?: InputMaybe<Scalars['String']>;
  bodyMarkdown?: InputMaybe<Scalars['String']>;
  bodyMarkdown_contains?: InputMaybe<Scalars['String']>;
  bodyMarkdown_exists?: InputMaybe<Scalars['Boolean']>;
  bodyMarkdown_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bodyMarkdown_not?: InputMaybe<Scalars['String']>;
  bodyMarkdown_not_contains?: InputMaybe<Scalars['String']>;
  bodyMarkdown_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink?: InputMaybe<Scalars['String']>;
  buttonLink_contains?: InputMaybe<Scalars['String']>;
  buttonLink_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink_not?: InputMaybe<Scalars['String']>;
  buttonLink_not_contains?: InputMaybe<Scalars['String']>;
  buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonText_contains?: InputMaybe<Scalars['String']>;
  buttonText_exists?: InputMaybe<Scalars['Boolean']>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText_not?: InputMaybe<Scalars['String']>;
  buttonText_not_contains?: InputMaybe<Scalars['String']>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  entryTitle?: InputMaybe<Scalars['String']>;
  entryTitle_contains?: InputMaybe<Scalars['String']>;
  entryTitle_exists?: InputMaybe<Scalars['Boolean']>;
  entryTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  entryTitle_not?: InputMaybe<Scalars['String']>;
  entryTitle_not_contains?: InputMaybe<Scalars['String']>;
  entryTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fifthButtonText?: InputMaybe<Scalars['String']>;
  fifthButtonText_contains?: InputMaybe<Scalars['String']>;
  fifthButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  fifthButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fifthButtonText_not?: InputMaybe<Scalars['String']>;
  fifthButtonText_not_contains?: InputMaybe<Scalars['String']>;
  fifthButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fifthButtonUrl?: InputMaybe<Scalars['String']>;
  fifthButtonUrl_contains?: InputMaybe<Scalars['String']>;
  fifthButtonUrl_exists?: InputMaybe<Scalars['Boolean']>;
  fifthButtonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fifthButtonUrl_not?: InputMaybe<Scalars['String']>;
  fifthButtonUrl_not_contains?: InputMaybe<Scalars['String']>;
  fifthButtonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fourthButtonText?: InputMaybe<Scalars['String']>;
  fourthButtonText_contains?: InputMaybe<Scalars['String']>;
  fourthButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  fourthButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fourthButtonText_not?: InputMaybe<Scalars['String']>;
  fourthButtonText_not_contains?: InputMaybe<Scalars['String']>;
  fourthButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fourthButtonUrl?: InputMaybe<Scalars['String']>;
  fourthButtonUrl_contains?: InputMaybe<Scalars['String']>;
  fourthButtonUrl_exists?: InputMaybe<Scalars['Boolean']>;
  fourthButtonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fourthButtonUrl_not?: InputMaybe<Scalars['String']>;
  fourthButtonUrl_not_contains?: InputMaybe<Scalars['String']>;
  fourthButtonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  preferredUi?: InputMaybe<Scalars['String']>;
  preferredUi_contains?: InputMaybe<Scalars['String']>;
  preferredUi_exists?: InputMaybe<Scalars['Boolean']>;
  preferredUi_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  preferredUi_not?: InputMaybe<Scalars['String']>;
  preferredUi_not_contains?: InputMaybe<Scalars['String']>;
  preferredUi_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  secondaryButtonLink?: InputMaybe<Scalars['String']>;
  secondaryButtonLink_contains?: InputMaybe<Scalars['String']>;
  secondaryButtonLink_exists?: InputMaybe<Scalars['Boolean']>;
  secondaryButtonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  secondaryButtonLink_not?: InputMaybe<Scalars['String']>;
  secondaryButtonLink_not_contains?: InputMaybe<Scalars['String']>;
  secondaryButtonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  secondaryButtonText?: InputMaybe<Scalars['String']>;
  secondaryButtonText_contains?: InputMaybe<Scalars['String']>;
  secondaryButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  secondaryButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  secondaryButtonText_not?: InputMaybe<Scalars['String']>;
  secondaryButtonText_not_contains?: InputMaybe<Scalars['String']>;
  secondaryButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sixthButtonText?: InputMaybe<Scalars['String']>;
  sixthButtonText_contains?: InputMaybe<Scalars['String']>;
  sixthButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  sixthButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sixthButtonText_not?: InputMaybe<Scalars['String']>;
  sixthButtonText_not_contains?: InputMaybe<Scalars['String']>;
  sixthButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sixthButtonUrl?: InputMaybe<Scalars['String']>;
  sixthButtonUrl_contains?: InputMaybe<Scalars['String']>;
  sixthButtonUrl_exists?: InputMaybe<Scalars['Boolean']>;
  sixthButtonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sixthButtonUrl_not?: InputMaybe<Scalars['String']>;
  sixthButtonUrl_not_contains?: InputMaybe<Scalars['String']>;
  sixthButtonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subtitle?: InputMaybe<Scalars['String']>;
  subtitle_contains?: InputMaybe<Scalars['String']>;
  subtitle_exists?: InputMaybe<Scalars['Boolean']>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subtitle_not?: InputMaybe<Scalars['String']>;
  subtitle_not_contains?: InputMaybe<Scalars['String']>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  thirdButtonText?: InputMaybe<Scalars['String']>;
  thirdButtonText_contains?: InputMaybe<Scalars['String']>;
  thirdButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  thirdButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  thirdButtonText_not?: InputMaybe<Scalars['String']>;
  thirdButtonText_not_contains?: InputMaybe<Scalars['String']>;
  thirdButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  thirdButtonUrl?: InputMaybe<Scalars['String']>;
  thirdButtonUrl_contains?: InputMaybe<Scalars['String']>;
  thirdButtonUrl_exists?: InputMaybe<Scalars['Boolean']>;
  thirdButtonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  thirdButtonUrl_not?: InputMaybe<Scalars['String']>;
  thirdButtonUrl_not_contains?: InputMaybe<Scalars['String']>;
  thirdButtonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoThumbnail_exists?: InputMaybe<Scalars['Boolean']>;
};

export type JumbotronLinkingCollections = {
  aboutEpriCollection?: Maybe<AboutEpriCollection>;
  aiTemplatePageCollection?: Maybe<AiTemplatePageCollection>;
  cardTemplateCollection?: Maybe<CardTemplateCollection>;
  careersCollection?: Maybe<CareersCollection>;
  entryCollection?: Maybe<EntryCollection>;
  epriUCollection?: Maybe<EpriUCollection>;
  eventsCollection?: Maybe<EventsCollection>;
  governanceCollection?: Maybe<GovernanceCollection>;
  homeCollection?: Maybe<HomeCollection>;
  ltnLandingPageCollection?: Maybe<LtnLandingPageCollection>;
  mediaResourcesCollection?: Maybe<MediaResourcesCollection>;
  multimediaTemplateCollection?: Maybe<MultimediaTemplateCollection>;
  portfolioSectorsCollection?: Maybe<PortfolioSectorsCollection>;
  researchCollection?: Maybe<ResearchCollection>;
  singleVideoTemplateCollection?: Maybe<SingleVideoTemplateCollection>;
  thoughtLeadershipCollection?: Maybe<ThoughtLeadershipCollection>;
};


export type JumbotronLinkingCollectionsAboutEpriCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type JumbotronLinkingCollectionsAiTemplatePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type JumbotronLinkingCollectionsCardTemplateCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type JumbotronLinkingCollectionsCareersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type JumbotronLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type JumbotronLinkingCollectionsEpriUCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type JumbotronLinkingCollectionsEventsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type JumbotronLinkingCollectionsGovernanceCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type JumbotronLinkingCollectionsHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type JumbotronLinkingCollectionsLtnLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type JumbotronLinkingCollectionsMediaResourcesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type JumbotronLinkingCollectionsMultimediaTemplateCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type JumbotronLinkingCollectionsPortfolioSectorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type JumbotronLinkingCollectionsResearchCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type JumbotronLinkingCollectionsSingleVideoTemplateCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type JumbotronLinkingCollectionsThoughtLeadershipCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum JumbotronOrder {
  ButtonLinkAsc = 'buttonLink_ASC',
  ButtonLinkDesc = 'buttonLink_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  EntryTitleAsc = 'entryTitle_ASC',
  EntryTitleDesc = 'entryTitle_DESC',
  FifthButtonTextAsc = 'fifthButtonText_ASC',
  FifthButtonTextDesc = 'fifthButtonText_DESC',
  FifthButtonUrlAsc = 'fifthButtonUrl_ASC',
  FifthButtonUrlDesc = 'fifthButtonUrl_DESC',
  FourthButtonTextAsc = 'fourthButtonText_ASC',
  FourthButtonTextDesc = 'fourthButtonText_DESC',
  FourthButtonUrlAsc = 'fourthButtonUrl_ASC',
  FourthButtonUrlDesc = 'fourthButtonUrl_DESC',
  PreferredUiAsc = 'preferredUi_ASC',
  PreferredUiDesc = 'preferredUi_DESC',
  SecondaryButtonLinkAsc = 'secondaryButtonLink_ASC',
  SecondaryButtonLinkDesc = 'secondaryButtonLink_DESC',
  SecondaryButtonTextAsc = 'secondaryButtonText_ASC',
  SecondaryButtonTextDesc = 'secondaryButtonText_DESC',
  SixthButtonTextAsc = 'sixthButtonText_ASC',
  SixthButtonTextDesc = 'sixthButtonText_DESC',
  SixthButtonUrlAsc = 'sixthButtonUrl_ASC',
  SixthButtonUrlDesc = 'sixthButtonUrl_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThirdButtonTextAsc = 'thirdButtonText_ASC',
  ThirdButtonTextDesc = 'thirdButtonText_DESC',
  ThirdButtonUrlAsc = 'thirdButtonUrl_ASC',
  ThirdButtonUrlDesc = 'thirdButtonUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type KeywordType = {
  iD?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
};

export type LmsTranscript = {
  companyDivision?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  curriculumTrainingTitle?: Maybe<Scalars['String']>;
  lastTranscriptStatusChangeDate?: Maybe<Scalars['String']>;
  lmsEventId?: Maybe<Scalars['String']>;
  lmsTrainingType?: Maybe<Scalars['String']>;
  pdh?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['String']>;
  trainingCompletedDate?: Maybe<Scalars['String']>;
  trainingStartDate?: Maybe<Scalars['String']>;
  trainingTitle?: Maybe<Scalars['String']>;
  trainingType?: Maybe<Scalars['String']>;
  transcriptStatus?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  userFirstName?: Maybe<Scalars['String']>;
  userLastName?: Maybe<Scalars['String']>;
};

export type Language = {
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionInEnglish?: Maybe<Scalars['String']>;
  titles?: Maybe<Scalars['String']>;
  topics?: Maybe<Array<Maybe<Topic>>>;
};

/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/leaderCard) */
export type LeaderCard = Entry & {
  bio?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  firstName?: Maybe<Scalars['String']>;
  image?: Maybe<Asset>;
  lastName?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<LeaderCardLinkingCollections>;
  middleName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pdf?: Maybe<Asset>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/leaderCard) */
export type LeaderCardBioArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/leaderCard) */
export type LeaderCardFirstNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/leaderCard) */
export type LeaderCardImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/leaderCard) */
export type LeaderCardLastNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/leaderCard) */
export type LeaderCardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/leaderCard) */
export type LeaderCardMiddleNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/leaderCard) */
export type LeaderCardNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/leaderCard) */
export type LeaderCardPdfArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/leaderCard) */
export type LeaderCardTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type LeaderCardCollection = {
  items: Array<Maybe<LeaderCard>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type LeaderCardFilter = {
  AND?: InputMaybe<Array<InputMaybe<LeaderCardFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LeaderCardFilter>>>;
  bio?: InputMaybe<Scalars['String']>;
  bio_contains?: InputMaybe<Scalars['String']>;
  bio_exists?: InputMaybe<Scalars['Boolean']>;
  bio_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bio_not?: InputMaybe<Scalars['String']>;
  bio_not_contains?: InputMaybe<Scalars['String']>;
  bio_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  firstName?: InputMaybe<Scalars['String']>;
  firstName_contains?: InputMaybe<Scalars['String']>;
  firstName_exists?: InputMaybe<Scalars['Boolean']>;
  firstName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  firstName_not?: InputMaybe<Scalars['String']>;
  firstName_not_contains?: InputMaybe<Scalars['String']>;
  firstName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastName_contains?: InputMaybe<Scalars['String']>;
  lastName_exists?: InputMaybe<Scalars['Boolean']>;
  lastName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lastName_not?: InputMaybe<Scalars['String']>;
  lastName_not_contains?: InputMaybe<Scalars['String']>;
  lastName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  middleName?: InputMaybe<Scalars['String']>;
  middleName_contains?: InputMaybe<Scalars['String']>;
  middleName_exists?: InputMaybe<Scalars['Boolean']>;
  middleName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  middleName_not?: InputMaybe<Scalars['String']>;
  middleName_not_contains?: InputMaybe<Scalars['String']>;
  middleName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pdf_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LeaderCardLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  leadersCollection?: Maybe<LeadersCollection>;
};


export type LeaderCardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type LeaderCardLinkingCollectionsLeadersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum LeaderCardOrder {
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  MiddleNameAsc = 'middleName_ASC',
  MiddleNameDesc = 'middleName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/leaders) */
export type Leaders = Entry & {
  contentfulMetadata: ContentfulMetadata;
  leadersCollection?: Maybe<LeadersLeadersCollection>;
  linkedFrom?: Maybe<LeadersLinkingCollections>;
  printVersion?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  webVersion?: Maybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/leaders) */
export type LeadersLeadersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/leaders) */
export type LeadersLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/leaders) */
export type LeadersPrintVersionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/leaders) */
export type LeadersTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/leaders) */
export type LeadersWebVersionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type LeadersCollection = {
  items: Array<Maybe<Leaders>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type LeadersFilter = {
  AND?: InputMaybe<Array<InputMaybe<LeadersFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LeadersFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  leadersCollection_exists?: InputMaybe<Scalars['Boolean']>;
  printVersion?: InputMaybe<Scalars['String']>;
  printVersion_contains?: InputMaybe<Scalars['String']>;
  printVersion_exists?: InputMaybe<Scalars['Boolean']>;
  printVersion_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  printVersion_not?: InputMaybe<Scalars['String']>;
  printVersion_not_contains?: InputMaybe<Scalars['String']>;
  printVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  webVersion?: InputMaybe<Scalars['String']>;
  webVersion_contains?: InputMaybe<Scalars['String']>;
  webVersion_exists?: InputMaybe<Scalars['Boolean']>;
  webVersion_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  webVersion_not?: InputMaybe<Scalars['String']>;
  webVersion_not_contains?: InputMaybe<Scalars['String']>;
  webVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LeadersLeadersCollection = {
  items: Array<Maybe<LeaderCard>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type LeadersLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type LeadersLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum LeadersOrder {
  PrintVersionAsc = 'printVersion_ASC',
  PrintVersionDesc = 'printVersion_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  WebVersionAsc = 'webVersion_ASC',
  WebVersionDesc = 'webVersion_DESC'
}

/** Link Object for Sectors Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/link) */
export type Link = Entry & {
  contentfulMetadata: ContentfulMetadata;
  icon?: Maybe<Scalars['String']>;
  linkCategory?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<LinkLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  publicOrMemberOnly?: Maybe<Scalars['String']>;
  sys: Sys;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};


/** Link Object for Sectors Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/link) */
export type LinkIconArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Link Object for Sectors Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/link) */
export type LinkLinkCategoryArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Link Object for Sectors Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/link) */
export type LinkLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Link Object for Sectors Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/link) */
export type LinkNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Link Object for Sectors Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/link) */
export type LinkPublicOrMemberOnlyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Link Object for Sectors Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/link) */
export type LinkTypeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Link Object for Sectors Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/link) */
export type LinkUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type LinkCollection = {
  items: Array<Maybe<Link>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type LinkFilter = {
  AND?: InputMaybe<Array<InputMaybe<LinkFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LinkFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  icon?: InputMaybe<Scalars['String']>;
  icon_contains?: InputMaybe<Scalars['String']>;
  icon_exists?: InputMaybe<Scalars['Boolean']>;
  icon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  icon_not?: InputMaybe<Scalars['String']>;
  icon_not_contains?: InputMaybe<Scalars['String']>;
  icon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  linkCategory?: InputMaybe<Scalars['String']>;
  linkCategory_contains?: InputMaybe<Scalars['String']>;
  linkCategory_exists?: InputMaybe<Scalars['Boolean']>;
  linkCategory_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  linkCategory_not?: InputMaybe<Scalars['String']>;
  linkCategory_not_contains?: InputMaybe<Scalars['String']>;
  linkCategory_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicOrMemberOnly?: InputMaybe<Scalars['String']>;
  publicOrMemberOnly_contains?: InputMaybe<Scalars['String']>;
  publicOrMemberOnly_exists?: InputMaybe<Scalars['Boolean']>;
  publicOrMemberOnly_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicOrMemberOnly_not?: InputMaybe<Scalars['String']>;
  publicOrMemberOnly_not_contains?: InputMaybe<Scalars['String']>;
  publicOrMemberOnly_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  type?: InputMaybe<Scalars['String']>;
  type_contains?: InputMaybe<Scalars['String']>;
  type_exists?: InputMaybe<Scalars['Boolean']>;
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type_not?: InputMaybe<Scalars['String']>;
  type_not_contains?: InputMaybe<Scalars['String']>;
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Group of Link Objects for Sectors Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/linkGroup) */
export type LinkGroup = Entry & {
  contentfulMetadata: ContentfulMetadata;
  groupName?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<LinkGroupLinkingCollections>;
  linksCollection?: Maybe<LinkGroupLinksCollection>;
  sys: Sys;
};


/** Group of Link Objects for Sectors Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/linkGroup) */
export type LinkGroupGroupNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Group of Link Objects for Sectors Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/linkGroup) */
export type LinkGroupLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Group of Link Objects for Sectors Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/linkGroup) */
export type LinkGroupLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkGroupCollection = {
  items: Array<Maybe<LinkGroup>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type LinkGroupFilter = {
  AND?: InputMaybe<Array<InputMaybe<LinkGroupFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LinkGroupFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  groupName?: InputMaybe<Scalars['String']>;
  groupName_contains?: InputMaybe<Scalars['String']>;
  groupName_exists?: InputMaybe<Scalars['Boolean']>;
  groupName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  groupName_not?: InputMaybe<Scalars['String']>;
  groupName_not_contains?: InputMaybe<Scalars['String']>;
  groupName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  linksCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type LinkGroupLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  epriUCollection?: Maybe<EpriUCollection>;
  footerCollection?: Maybe<FooterCollection>;
  ltnLandingPageCollection?: Maybe<LtnLandingPageCollection>;
  portfolioSectorsCollection?: Maybe<PortfolioSectorsCollection>;
};


export type LinkGroupLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type LinkGroupLinkingCollectionsEpriUCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type LinkGroupLinkingCollectionsFooterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type LinkGroupLinkingCollectionsLtnLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type LinkGroupLinkingCollectionsPortfolioSectorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LinkGroupLinksCollection = {
  items: Array<Maybe<Link>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum LinkGroupOrder {
  GroupNameAsc = 'groupName_ASC',
  GroupNameDesc = 'groupName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type LinkLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  linkGroupCollection?: Maybe<LinkGroupCollection>;
  xLprLandingPageCollection?: Maybe<XLprLandingPageCollection>;
};


export type LinkLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type LinkLinkingCollectionsLinkGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type LinkLinkingCollectionsXLprLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum LinkOrder {
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  LinkCategoryAsc = 'linkCategory_ASC',
  LinkCategoryDesc = 'linkCategory_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublicOrMemberOnlyAsc = 'publicOrMemberOnly_ASC',
  PublicOrMemberOnlyDesc = 'publicOrMemberOnly_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/listComponent) */
export type ListComponent = Entry & {
  body?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  key?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<ListComponentLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/listComponent) */
export type ListComponentBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/listComponent) */
export type ListComponentKeyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/listComponent) */
export type ListComponentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/listComponent) */
export type ListComponentTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ListComponentCollection = {
  items: Array<Maybe<ListComponent>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ListComponentFilter = {
  AND?: InputMaybe<Array<InputMaybe<ListComponentFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ListComponentFilter>>>;
  body?: InputMaybe<Scalars['String']>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  key?: InputMaybe<Scalars['String']>;
  key_contains?: InputMaybe<Scalars['String']>;
  key_exists?: InputMaybe<Scalars['Boolean']>;
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  key_not?: InputMaybe<Scalars['String']>;
  key_not_contains?: InputMaybe<Scalars['String']>;
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ListComponentLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  eventsCollection?: Maybe<EventsCollection>;
};


export type ListComponentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ListComponentLinkingCollectionsEventsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ListComponentOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/locationTab) */
export type LocationTab = Entry & {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  info?: Maybe<Asset>;
  lat?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<LocationTabLinkingCollections>;
  long?: Maybe<Scalars['String']>;
  map?: Maybe<Asset>;
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/locationTab) */
export type LocationTabAddressArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/locationTab) */
export type LocationTabCityArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/locationTab) */
export type LocationTabInfoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/locationTab) */
export type LocationTabLatArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/locationTab) */
export type LocationTabLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/locationTab) */
export type LocationTabLongArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/locationTab) */
export type LocationTabMapArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/locationTab) */
export type LocationTabPhoneArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/locationTab) */
export type LocationTabStateArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/locationTab) */
export type LocationTabStreetArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/locationTab) */
export type LocationTabTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/locationTab) */
export type LocationTabZipArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type LocationTabCollection = {
  items: Array<Maybe<LocationTab>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type LocationTabFilter = {
  AND?: InputMaybe<Array<InputMaybe<LocationTabFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LocationTabFilter>>>;
  address?: InputMaybe<Scalars['String']>;
  address_contains?: InputMaybe<Scalars['String']>;
  address_exists?: InputMaybe<Scalars['Boolean']>;
  address_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  address_not?: InputMaybe<Scalars['String']>;
  address_not_contains?: InputMaybe<Scalars['String']>;
  address_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  city?: InputMaybe<Scalars['String']>;
  city_contains?: InputMaybe<Scalars['String']>;
  city_exists?: InputMaybe<Scalars['Boolean']>;
  city_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  city_not?: InputMaybe<Scalars['String']>;
  city_not_contains?: InputMaybe<Scalars['String']>;
  city_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  info_exists?: InputMaybe<Scalars['Boolean']>;
  lat?: InputMaybe<Scalars['String']>;
  lat_contains?: InputMaybe<Scalars['String']>;
  lat_exists?: InputMaybe<Scalars['Boolean']>;
  lat_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lat_not?: InputMaybe<Scalars['String']>;
  lat_not_contains?: InputMaybe<Scalars['String']>;
  lat_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  long?: InputMaybe<Scalars['String']>;
  long_contains?: InputMaybe<Scalars['String']>;
  long_exists?: InputMaybe<Scalars['Boolean']>;
  long_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  long_not?: InputMaybe<Scalars['String']>;
  long_not_contains?: InputMaybe<Scalars['String']>;
  long_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  map_exists?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  phone_contains?: InputMaybe<Scalars['String']>;
  phone_exists?: InputMaybe<Scalars['Boolean']>;
  phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  phone_not?: InputMaybe<Scalars['String']>;
  phone_not_contains?: InputMaybe<Scalars['String']>;
  phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  state?: InputMaybe<Scalars['String']>;
  state_contains?: InputMaybe<Scalars['String']>;
  state_exists?: InputMaybe<Scalars['Boolean']>;
  state_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  state_not?: InputMaybe<Scalars['String']>;
  state_not_contains?: InputMaybe<Scalars['String']>;
  state_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  street?: InputMaybe<Scalars['String']>;
  street_contains?: InputMaybe<Scalars['String']>;
  street_exists?: InputMaybe<Scalars['Boolean']>;
  street_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  street_not?: InputMaybe<Scalars['String']>;
  street_not_contains?: InputMaybe<Scalars['String']>;
  street_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  zip?: InputMaybe<Scalars['String']>;
  zip_contains?: InputMaybe<Scalars['String']>;
  zip_exists?: InputMaybe<Scalars['Boolean']>;
  zip_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  zip_not?: InputMaybe<Scalars['String']>;
  zip_not_contains?: InputMaybe<Scalars['String']>;
  zip_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LocationTabLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  locationsCollection?: Maybe<LocationsCollection>;
};


export type LocationTabLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type LocationTabLinkingCollectionsLocationsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum LocationTabOrder {
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  LatAsc = 'lat_ASC',
  LatDesc = 'lat_DESC',
  LongAsc = 'long_ASC',
  LongDesc = 'long_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  StreetAsc = 'street_ASC',
  StreetDesc = 'street_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ZipAsc = 'zip_ASC',
  ZipDesc = 'zip_DESC'
}

/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/locations) */
export type Locations = Entry & {
  body?: Maybe<Scalars['String']>;
  buttonPrimary?: Maybe<Scalars['String']>;
  buttonSecondary?: Maybe<Scalars['String']>;
  buttonTertiary?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<LocationsLinkingCollections>;
  locationsCollection?: Maybe<LocationsLocationsCollection>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/locations) */
export type LocationsBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/locations) */
export type LocationsButtonPrimaryArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/locations) */
export type LocationsButtonSecondaryArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/locations) */
export type LocationsButtonTertiaryArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/locations) */
export type LocationsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/locations) */
export type LocationsLocationsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/locations) */
export type LocationsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type LocationsCollection = {
  items: Array<Maybe<Locations>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type LocationsFilter = {
  AND?: InputMaybe<Array<InputMaybe<LocationsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LocationsFilter>>>;
  body?: InputMaybe<Scalars['String']>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonPrimary?: InputMaybe<Scalars['String']>;
  buttonPrimary_contains?: InputMaybe<Scalars['String']>;
  buttonPrimary_exists?: InputMaybe<Scalars['Boolean']>;
  buttonPrimary_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonPrimary_not?: InputMaybe<Scalars['String']>;
  buttonPrimary_not_contains?: InputMaybe<Scalars['String']>;
  buttonPrimary_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonSecondary?: InputMaybe<Scalars['String']>;
  buttonSecondary_contains?: InputMaybe<Scalars['String']>;
  buttonSecondary_exists?: InputMaybe<Scalars['Boolean']>;
  buttonSecondary_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonSecondary_not?: InputMaybe<Scalars['String']>;
  buttonSecondary_not_contains?: InputMaybe<Scalars['String']>;
  buttonSecondary_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonTertiary?: InputMaybe<Scalars['String']>;
  buttonTertiary_contains?: InputMaybe<Scalars['String']>;
  buttonTertiary_exists?: InputMaybe<Scalars['Boolean']>;
  buttonTertiary_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonTertiary_not?: InputMaybe<Scalars['String']>;
  buttonTertiary_not_contains?: InputMaybe<Scalars['String']>;
  buttonTertiary_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  locationsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LocationsLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type LocationsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LocationsLocationsCollection = {
  items: Array<Maybe<LocationTab>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum LocationsOrder {
  ButtonPrimaryAsc = 'buttonPrimary_ASC',
  ButtonPrimaryDesc = 'buttonPrimary_DESC',
  ButtonSecondaryAsc = 'buttonSecondary_ASC',
  ButtonSecondaryDesc = 'buttonSecondary_DESC',
  ButtonTertiaryAsc = 'buttonTertiary_ASC',
  ButtonTertiaryDesc = 'buttonTertiary_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type LogInput = {
  /** One line Error Message */
  error: Scalars['String'];
  /** Full inner exception details */
  formattedError?: InputMaybe<Scalars['String']>;
  severity: Severity;
  title: Scalars['String'];
};

export type LookUpData = {
  name?: Maybe<Scalars['String']>;
  nominationId?: Maybe<Scalars['Int']>;
};

/** LTN Sectors and Programs [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ltn) */
export type Ltn = Entry & {
  associateWithProgram?: Maybe<Scalars['Boolean']>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<LtnLinkingCollections>;
  program?: Maybe<Scalars['String']>;
  sector?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** LTN Sectors and Programs [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ltn) */
export type LtnAssociateWithProgramArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** LTN Sectors and Programs [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ltn) */
export type LtnLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** LTN Sectors and Programs [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ltn) */
export type LtnProgramArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** LTN Sectors and Programs [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ltn) */
export type LtnSectorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** LTN Sectors and Programs [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ltn) */
export type LtnTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type LtnCollection = {
  items: Array<Maybe<Ltn>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type LtnFilter = {
  AND?: InputMaybe<Array<InputMaybe<LtnFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LtnFilter>>>;
  associateWithProgram?: InputMaybe<Scalars['Boolean']>;
  associateWithProgram_exists?: InputMaybe<Scalars['Boolean']>;
  associateWithProgram_not?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  program?: InputMaybe<Scalars['String']>;
  program_contains?: InputMaybe<Scalars['String']>;
  program_exists?: InputMaybe<Scalars['Boolean']>;
  program_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  program_not?: InputMaybe<Scalars['String']>;
  program_not_contains?: InputMaybe<Scalars['String']>;
  program_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sector?: InputMaybe<Scalars['String']>;
  sector_contains?: InputMaybe<Scalars['String']>;
  sector_exists?: InputMaybe<Scalars['Boolean']>;
  sector_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sector_not?: InputMaybe<Scalars['String']>;
  sector_not_contains?: InputMaybe<Scalars['String']>;
  sector_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Content for Sector/Program/ProjectSet/Project Landing Pages [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ltnLandingPage) */
export type LtnLandingPage = Entry & {
  announcements?: Maybe<AnnouncementWrapper>;
  checklist?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  featuredArticle?: Maybe<Jumbotron>;
  linkGroupsCollection?: Maybe<LtnLandingPageLinkGroupsCollection>;
  linkedFrom?: Maybe<LtnLandingPageLinkingCollections>;
  linksCollection?: Maybe<LtnLandingPageLinksCollection>;
  ltn?: Maybe<Scalars['String']>;
  marketingCardsCollection?: Maybe<LtnLandingPageMarketingCardsCollection>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Content for Sector/Program/ProjectSet/Project Landing Pages [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ltnLandingPage) */
export type LtnLandingPageAnnouncementsArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Content for Sector/Program/ProjectSet/Project Landing Pages [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ltnLandingPage) */
export type LtnLandingPageChecklistArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Content for Sector/Program/ProjectSet/Project Landing Pages [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ltnLandingPage) */
export type LtnLandingPageFeaturedArticleArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Content for Sector/Program/ProjectSet/Project Landing Pages [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ltnLandingPage) */
export type LtnLandingPageLinkGroupsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Content for Sector/Program/ProjectSet/Project Landing Pages [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ltnLandingPage) */
export type LtnLandingPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Content for Sector/Program/ProjectSet/Project Landing Pages [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ltnLandingPage) */
export type LtnLandingPageLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Content for Sector/Program/ProjectSet/Project Landing Pages [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ltnLandingPage) */
export type LtnLandingPageLtnArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Content for Sector/Program/ProjectSet/Project Landing Pages [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ltnLandingPage) */
export type LtnLandingPageMarketingCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Content for Sector/Program/ProjectSet/Project Landing Pages [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ltnLandingPage) */
export type LtnLandingPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type LtnLandingPageCollection = {
  items: Array<Maybe<LtnLandingPage>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type LtnLandingPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<LtnLandingPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LtnLandingPageFilter>>>;
  announcements?: InputMaybe<CfAnnouncementWrapperNestedFilter>;
  announcements_exists?: InputMaybe<Scalars['Boolean']>;
  checklist?: InputMaybe<Scalars['String']>;
  checklist_contains?: InputMaybe<Scalars['String']>;
  checklist_exists?: InputMaybe<Scalars['Boolean']>;
  checklist_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  checklist_not?: InputMaybe<Scalars['String']>;
  checklist_not_contains?: InputMaybe<Scalars['String']>;
  checklist_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  featuredArticle?: InputMaybe<CfJumbotronNestedFilter>;
  featuredArticle_exists?: InputMaybe<Scalars['Boolean']>;
  linkGroupsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  linksCollection_exists?: InputMaybe<Scalars['Boolean']>;
  ltn?: InputMaybe<Scalars['String']>;
  ltn_contains?: InputMaybe<Scalars['String']>;
  ltn_exists?: InputMaybe<Scalars['Boolean']>;
  ltn_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ltn_not?: InputMaybe<Scalars['String']>;
  ltn_not_contains?: InputMaybe<Scalars['String']>;
  ltn_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  marketingCardsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LtnLandingPageLinkGroupsCollection = {
  items: Array<Maybe<LinkGroup>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type LtnLandingPageLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type LtnLandingPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type LtnLandingPageLinksCollection = {
  items: Array<Maybe<LinkGroup>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type LtnLandingPageMarketingCardsCollection = {
  items: Array<Maybe<StoryCards>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum LtnLandingPageOrder {
  LtnAsc = 'ltn_ASC',
  LtnDesc = 'ltn_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type LtnLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type LtnLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum LtnOrder {
  AssociateWithProgramAsc = 'associateWithProgram_ASC',
  AssociateWithProgramDesc = 'associateWithProgram_DESC',
  ProgramAsc = 'program_ASC',
  ProgramDesc = 'program_DESC',
  SectorAsc = 'sector_ASC',
  SectorDesc = 'sector_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** markdown long text [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/markdown) */
export type Markdown = Entry & {
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<MarkdownLinkingCollections>;
  markdown?: Maybe<Scalars['String']>;
  sys: Sys;
};


/** markdown long text [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/markdown) */
export type MarkdownLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** markdown long text [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/markdown) */
export type MarkdownMarkdownArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type MarkdownCollection = {
  items: Array<Maybe<Markdown>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type MarkdownFilter = {
  AND?: InputMaybe<Array<InputMaybe<MarkdownFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MarkdownFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  markdown?: InputMaybe<Scalars['String']>;
  markdown_contains?: InputMaybe<Scalars['String']>;
  markdown_exists?: InputMaybe<Scalars['Boolean']>;
  markdown_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  markdown_not?: InputMaybe<Scalars['String']>;
  markdown_not_contains?: InputMaybe<Scalars['String']>;
  markdown_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type MarkdownLinkingCollections = {
  aiTemplatePageCollection?: Maybe<AiTemplatePageCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type MarkdownLinkingCollectionsAiTemplatePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type MarkdownLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum MarkdownOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/mediaHits) */
export type MediaHits = Entry & {
  contentfulMetadata: ContentfulMetadata;
  date?: Maybe<Scalars['DateTime']>;
  link?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<MediaHitsLinkingCollections>;
  publication?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/mediaHits) */
export type MediaHitsDateArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/mediaHits) */
export type MediaHitsLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/mediaHits) */
export type MediaHitsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/mediaHits) */
export type MediaHitsPublicationArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/mediaHits) */
export type MediaHitsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type MediaHitsCollection = {
  items: Array<Maybe<MediaHits>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type MediaHitsFilter = {
  AND?: InputMaybe<Array<InputMaybe<MediaHitsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MediaHitsFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  date?: InputMaybe<Scalars['DateTime']>;
  date_exists?: InputMaybe<Scalars['Boolean']>;
  date_gt?: InputMaybe<Scalars['DateTime']>;
  date_gte?: InputMaybe<Scalars['DateTime']>;
  date_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  date_lt?: InputMaybe<Scalars['DateTime']>;
  date_lte?: InputMaybe<Scalars['DateTime']>;
  date_not?: InputMaybe<Scalars['DateTime']>;
  date_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  link?: InputMaybe<Scalars['String']>;
  link_contains?: InputMaybe<Scalars['String']>;
  link_exists?: InputMaybe<Scalars['Boolean']>;
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  link_not?: InputMaybe<Scalars['String']>;
  link_not_contains?: InputMaybe<Scalars['String']>;
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publication?: InputMaybe<Scalars['String']>;
  publication_contains?: InputMaybe<Scalars['String']>;
  publication_exists?: InputMaybe<Scalars['Boolean']>;
  publication_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publication_not?: InputMaybe<Scalars['String']>;
  publication_not_contains?: InputMaybe<Scalars['String']>;
  publication_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MediaHitsLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  mediaResourcesCollection?: Maybe<MediaResourcesCollection>;
};


export type MediaHitsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type MediaHitsLinkingCollectionsMediaResourcesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum MediaHitsOrder {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  PublicationAsc = 'publication_ASC',
  PublicationDesc = 'publication_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/mediaResources) */
export type MediaResources = Entry & {
  areasOfExpertise?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  jumbotron?: Maybe<Jumbotron>;
  linkedFrom?: Maybe<MediaResourcesLinkingCollections>;
  mediaHitsCollection?: Maybe<MediaResourcesMediaHitsCollection>;
  mediaHitsTitle?: Maybe<Scalars['String']>;
  mediaRelationsTitle?: Maybe<Scalars['String']>;
  mediaResourceLeadersCollection?: Maybe<MediaResourcesMediaResourceLeadersCollection>;
  mediaResourceNonLeadersCollection?: Maybe<MediaResourcesMediaResourceNonLeadersCollection>;
  pressReleasesButtonLink?: Maybe<Scalars['String']>;
  pressReleasesButtonText?: Maybe<Scalars['String']>;
  pressReleasesCollection?: Maybe<MediaResourcesPressReleasesCollection>;
  pressReleasesTitle?: Maybe<Scalars['String']>;
  researchAreaCommunicationsTitle?: Maybe<Scalars['String']>;
  resourceDocumentsCollection?: Maybe<MediaResourcesResourceDocumentsCollection>;
  resourceDocumentsTitle?: Maybe<Scalars['String']>;
  spotlightDocument?: Maybe<FeaturedReport>;
  spotlightDocumentTitle?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/mediaResources) */
export type MediaResourcesAreasOfExpertiseArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/mediaResources) */
export type MediaResourcesJumbotronArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/mediaResources) */
export type MediaResourcesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/mediaResources) */
export type MediaResourcesMediaHitsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/mediaResources) */
export type MediaResourcesMediaHitsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/mediaResources) */
export type MediaResourcesMediaRelationsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/mediaResources) */
export type MediaResourcesMediaResourceLeadersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/mediaResources) */
export type MediaResourcesMediaResourceNonLeadersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/mediaResources) */
export type MediaResourcesPressReleasesButtonLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/mediaResources) */
export type MediaResourcesPressReleasesButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/mediaResources) */
export type MediaResourcesPressReleasesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/mediaResources) */
export type MediaResourcesPressReleasesTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/mediaResources) */
export type MediaResourcesResearchAreaCommunicationsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/mediaResources) */
export type MediaResourcesResourceDocumentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/mediaResources) */
export type MediaResourcesResourceDocumentsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/mediaResources) */
export type MediaResourcesSpotlightDocumentArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/mediaResources) */
export type MediaResourcesSpotlightDocumentTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/mediaResources) */
export type MediaResourcesTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type MediaResourcesCollection = {
  items: Array<Maybe<MediaResources>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type MediaResourcesFilter = {
  AND?: InputMaybe<Array<InputMaybe<MediaResourcesFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MediaResourcesFilter>>>;
  areasOfExpertise?: InputMaybe<Scalars['String']>;
  areasOfExpertise_contains?: InputMaybe<Scalars['String']>;
  areasOfExpertise_exists?: InputMaybe<Scalars['Boolean']>;
  areasOfExpertise_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  areasOfExpertise_not?: InputMaybe<Scalars['String']>;
  areasOfExpertise_not_contains?: InputMaybe<Scalars['String']>;
  areasOfExpertise_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  jumbotron?: InputMaybe<CfJumbotronNestedFilter>;
  jumbotron_exists?: InputMaybe<Scalars['Boolean']>;
  mediaHitsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  mediaHitsTitle?: InputMaybe<Scalars['String']>;
  mediaHitsTitle_contains?: InputMaybe<Scalars['String']>;
  mediaHitsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  mediaHitsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mediaHitsTitle_not?: InputMaybe<Scalars['String']>;
  mediaHitsTitle_not_contains?: InputMaybe<Scalars['String']>;
  mediaHitsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mediaRelationsTitle?: InputMaybe<Scalars['String']>;
  mediaRelationsTitle_contains?: InputMaybe<Scalars['String']>;
  mediaRelationsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  mediaRelationsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mediaRelationsTitle_not?: InputMaybe<Scalars['String']>;
  mediaRelationsTitle_not_contains?: InputMaybe<Scalars['String']>;
  mediaRelationsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mediaResourceLeadersCollection_exists?: InputMaybe<Scalars['Boolean']>;
  mediaResourceNonLeadersCollection_exists?: InputMaybe<Scalars['Boolean']>;
  pressReleasesButtonLink?: InputMaybe<Scalars['String']>;
  pressReleasesButtonLink_contains?: InputMaybe<Scalars['String']>;
  pressReleasesButtonLink_exists?: InputMaybe<Scalars['Boolean']>;
  pressReleasesButtonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pressReleasesButtonLink_not?: InputMaybe<Scalars['String']>;
  pressReleasesButtonLink_not_contains?: InputMaybe<Scalars['String']>;
  pressReleasesButtonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pressReleasesButtonText?: InputMaybe<Scalars['String']>;
  pressReleasesButtonText_contains?: InputMaybe<Scalars['String']>;
  pressReleasesButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  pressReleasesButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pressReleasesButtonText_not?: InputMaybe<Scalars['String']>;
  pressReleasesButtonText_not_contains?: InputMaybe<Scalars['String']>;
  pressReleasesButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pressReleasesCollection_exists?: InputMaybe<Scalars['Boolean']>;
  pressReleasesTitle?: InputMaybe<Scalars['String']>;
  pressReleasesTitle_contains?: InputMaybe<Scalars['String']>;
  pressReleasesTitle_exists?: InputMaybe<Scalars['Boolean']>;
  pressReleasesTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pressReleasesTitle_not?: InputMaybe<Scalars['String']>;
  pressReleasesTitle_not_contains?: InputMaybe<Scalars['String']>;
  pressReleasesTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  researchAreaCommunicationsTitle?: InputMaybe<Scalars['String']>;
  researchAreaCommunicationsTitle_contains?: InputMaybe<Scalars['String']>;
  researchAreaCommunicationsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  researchAreaCommunicationsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  researchAreaCommunicationsTitle_not?: InputMaybe<Scalars['String']>;
  researchAreaCommunicationsTitle_not_contains?: InputMaybe<Scalars['String']>;
  researchAreaCommunicationsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  resourceDocumentsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  resourceDocumentsTitle?: InputMaybe<Scalars['String']>;
  resourceDocumentsTitle_contains?: InputMaybe<Scalars['String']>;
  resourceDocumentsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  resourceDocumentsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  resourceDocumentsTitle_not?: InputMaybe<Scalars['String']>;
  resourceDocumentsTitle_not_contains?: InputMaybe<Scalars['String']>;
  resourceDocumentsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  spotlightDocument?: InputMaybe<CfFeaturedReportNestedFilter>;
  spotlightDocumentTitle?: InputMaybe<Scalars['String']>;
  spotlightDocumentTitle_contains?: InputMaybe<Scalars['String']>;
  spotlightDocumentTitle_exists?: InputMaybe<Scalars['Boolean']>;
  spotlightDocumentTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  spotlightDocumentTitle_not?: InputMaybe<Scalars['String']>;
  spotlightDocumentTitle_not_contains?: InputMaybe<Scalars['String']>;
  spotlightDocumentTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  spotlightDocument_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MediaResourcesLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type MediaResourcesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type MediaResourcesMediaHitsCollection = {
  items: Array<Maybe<MediaHits>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type MediaResourcesMediaResourceLeadersCollection = {
  items: Array<Maybe<ProfileCard>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type MediaResourcesMediaResourceNonLeadersCollection = {
  items: Array<Maybe<ProfileCard>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum MediaResourcesOrder {
  AreasOfExpertiseAsc = 'areasOfExpertise_ASC',
  AreasOfExpertiseDesc = 'areasOfExpertise_DESC',
  MediaHitsTitleAsc = 'mediaHitsTitle_ASC',
  MediaHitsTitleDesc = 'mediaHitsTitle_DESC',
  MediaRelationsTitleAsc = 'mediaRelationsTitle_ASC',
  MediaRelationsTitleDesc = 'mediaRelationsTitle_DESC',
  PressReleasesButtonLinkAsc = 'pressReleasesButtonLink_ASC',
  PressReleasesButtonLinkDesc = 'pressReleasesButtonLink_DESC',
  PressReleasesButtonTextAsc = 'pressReleasesButtonText_ASC',
  PressReleasesButtonTextDesc = 'pressReleasesButtonText_DESC',
  PressReleasesTitleAsc = 'pressReleasesTitle_ASC',
  PressReleasesTitleDesc = 'pressReleasesTitle_DESC',
  ResearchAreaCommunicationsTitleAsc = 'researchAreaCommunicationsTitle_ASC',
  ResearchAreaCommunicationsTitleDesc = 'researchAreaCommunicationsTitle_DESC',
  ResourceDocumentsTitleAsc = 'resourceDocumentsTitle_ASC',
  ResourceDocumentsTitleDesc = 'resourceDocumentsTitle_DESC',
  SpotlightDocumentTitleAsc = 'spotlightDocumentTitle_ASC',
  SpotlightDocumentTitleDesc = 'spotlightDocumentTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type MediaResourcesPressReleasesCollection = {
  items: Array<Maybe<PressRelease>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type MediaResourcesResourceDocumentsCollection = {
  items: Array<Maybe<ResourceDocument>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type MettSupplemental = {
  EPRIcontract?: Maybe<Scalars['String']>;
  allAmount?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<Scalars['String']>;
  funded?: Maybe<Scalars['String']>;
  fundingType?: Maybe<Scalars['String']>;
  fundingYear?: Maybe<Scalars['Int']>;
  leverage?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productSector?: Maybe<Scalars['String']>;
  reportingGroup?: Maybe<Scalars['String']>;
};

export type ModernizationGuide = {
    applicability?: Maybe<Scalars['String']>;
    applicableBCAM?: Maybe<Scalars['String']>;
    cost?: Maybe<Scalars['String']>;
    functionalAreas?: Maybe<Array<Maybe<Scalars['String']>>>;
    functionalAreasFinal?: Maybe<Scalars['String']>;
    implementProficiency?: Maybe<Scalars['String']>;
    licensingReadiness?: Maybe<Scalars['String']>;
    mqgNumber?: Maybe<Scalars['String']>;
    payback?: Maybe<Scalars['String']>;
    pdfLink?: Maybe<Scalars['String']>;
    publishedDate?: Maybe<Scalars['String']>;
    savings?: Maybe<Scalars['String']>;
    schedule?: Maybe<Scalars['String']>;
    technologyReadiness?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
};

export type ModernizationGuideResponse = {
  guides?: Maybe<Array<Maybe<ModernizationGuide>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/**
 * Includes: Jumbotron, left column content, right column list, full width content,
 * left column image, right column content, left column content, right column image
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/multimediaTemplate)
 */
export type MultimediaTemplate = Entry & {
  contentfulMetadata: ContentfulMetadata;
  fullWidthBody?: Maybe<Scalars['String']>;
  galleryCollection?: Maybe<MultimediaTemplateGalleryCollection>;
  gallerySectionTitle?: Maybe<Scalars['String']>;
  jumbotron?: Maybe<Jumbotron>;
  leftColumnBody?: Maybe<Scalars['String']>;
  leftColumnBody2?: Maybe<Scalars['String']>;
  leftColumnMedia?: Maybe<Asset>;
  linkedFrom?: Maybe<MultimediaTemplateLinkingCollections>;
  permalink?: Maybe<Scalars['String']>;
  redirect?: Maybe<Scalars['String']>;
  rightColumnBody?: Maybe<Scalars['String']>;
  rightColumnList?: Maybe<Scalars['String']>;
  rightColumnList2?: Maybe<Scalars['String']>;
  rightColumnMedia?: Maybe<Asset>;
  sys: Sys;
  tabsCollection?: Maybe<MultimediaTemplateTabsCollection>;
  title?: Maybe<Scalars['String']>;
};


/**
 * Includes: Jumbotron, left column content, right column list, full width content,
 * left column image, right column content, left column content, right column image
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/multimediaTemplate)
 */
export type MultimediaTemplateFullWidthBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Includes: Jumbotron, left column content, right column list, full width content,
 * left column image, right column content, left column content, right column image
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/multimediaTemplate)
 */
export type MultimediaTemplateGalleryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/**
 * Includes: Jumbotron, left column content, right column list, full width content,
 * left column image, right column content, left column content, right column image
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/multimediaTemplate)
 */
export type MultimediaTemplateGallerySectionTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Includes: Jumbotron, left column content, right column list, full width content,
 * left column image, right column content, left column content, right column image
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/multimediaTemplate)
 */
export type MultimediaTemplateJumbotronArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/**
 * Includes: Jumbotron, left column content, right column list, full width content,
 * left column image, right column content, left column content, right column image
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/multimediaTemplate)
 */
export type MultimediaTemplateLeftColumnBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Includes: Jumbotron, left column content, right column list, full width content,
 * left column image, right column content, left column content, right column image
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/multimediaTemplate)
 */
export type MultimediaTemplateLeftColumnBody2Args = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Includes: Jumbotron, left column content, right column list, full width content,
 * left column image, right column content, left column content, right column image
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/multimediaTemplate)
 */
export type MultimediaTemplateLeftColumnMediaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/**
 * Includes: Jumbotron, left column content, right column list, full width content,
 * left column image, right column content, left column content, right column image
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/multimediaTemplate)
 */
export type MultimediaTemplateLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/**
 * Includes: Jumbotron, left column content, right column list, full width content,
 * left column image, right column content, left column content, right column image
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/multimediaTemplate)
 */
export type MultimediaTemplatePermalinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Includes: Jumbotron, left column content, right column list, full width content,
 * left column image, right column content, left column content, right column image
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/multimediaTemplate)
 */
export type MultimediaTemplateRedirectArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Includes: Jumbotron, left column content, right column list, full width content,
 * left column image, right column content, left column content, right column image
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/multimediaTemplate)
 */
export type MultimediaTemplateRightColumnBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Includes: Jumbotron, left column content, right column list, full width content,
 * left column image, right column content, left column content, right column image
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/multimediaTemplate)
 */
export type MultimediaTemplateRightColumnListArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Includes: Jumbotron, left column content, right column list, full width content,
 * left column image, right column content, left column content, right column image
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/multimediaTemplate)
 */
export type MultimediaTemplateRightColumnList2Args = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Includes: Jumbotron, left column content, right column list, full width content,
 * left column image, right column content, left column content, right column image
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/multimediaTemplate)
 */
export type MultimediaTemplateRightColumnMediaArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/**
 * Includes: Jumbotron, left column content, right column list, full width content,
 * left column image, right column content, left column content, right column image
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/multimediaTemplate)
 */
export type MultimediaTemplateTabsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/**
 * Includes: Jumbotron, left column content, right column list, full width content,
 * left column image, right column content, left column content, right column image
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/multimediaTemplate)
 */
export type MultimediaTemplateTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type MultimediaTemplateCollection = {
  items: Array<Maybe<MultimediaTemplate>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type MultimediaTemplateFilter = {
  AND?: InputMaybe<Array<InputMaybe<MultimediaTemplateFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MultimediaTemplateFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  fullWidthBody?: InputMaybe<Scalars['String']>;
  fullWidthBody_contains?: InputMaybe<Scalars['String']>;
  fullWidthBody_exists?: InputMaybe<Scalars['Boolean']>;
  fullWidthBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fullWidthBody_not?: InputMaybe<Scalars['String']>;
  fullWidthBody_not_contains?: InputMaybe<Scalars['String']>;
  fullWidthBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  galleryCollection_exists?: InputMaybe<Scalars['Boolean']>;
  gallerySectionTitle?: InputMaybe<Scalars['String']>;
  gallerySectionTitle_contains?: InputMaybe<Scalars['String']>;
  gallerySectionTitle_exists?: InputMaybe<Scalars['Boolean']>;
  gallerySectionTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  gallerySectionTitle_not?: InputMaybe<Scalars['String']>;
  gallerySectionTitle_not_contains?: InputMaybe<Scalars['String']>;
  gallerySectionTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jumbotron?: InputMaybe<CfJumbotronNestedFilter>;
  jumbotron_exists?: InputMaybe<Scalars['Boolean']>;
  leftColumnBody?: InputMaybe<Scalars['String']>;
  leftColumnBody2?: InputMaybe<Scalars['String']>;
  leftColumnBody2_contains?: InputMaybe<Scalars['String']>;
  leftColumnBody2_exists?: InputMaybe<Scalars['Boolean']>;
  leftColumnBody2_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  leftColumnBody2_not?: InputMaybe<Scalars['String']>;
  leftColumnBody2_not_contains?: InputMaybe<Scalars['String']>;
  leftColumnBody2_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  leftColumnBody_contains?: InputMaybe<Scalars['String']>;
  leftColumnBody_exists?: InputMaybe<Scalars['Boolean']>;
  leftColumnBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  leftColumnBody_not?: InputMaybe<Scalars['String']>;
  leftColumnBody_not_contains?: InputMaybe<Scalars['String']>;
  leftColumnBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  leftColumnMedia_exists?: InputMaybe<Scalars['Boolean']>;
  permalink?: InputMaybe<Scalars['String']>;
  permalink_contains?: InputMaybe<Scalars['String']>;
  permalink_exists?: InputMaybe<Scalars['Boolean']>;
  permalink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  permalink_not?: InputMaybe<Scalars['String']>;
  permalink_not_contains?: InputMaybe<Scalars['String']>;
  permalink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redirect?: InputMaybe<Scalars['String']>;
  redirect_contains?: InputMaybe<Scalars['String']>;
  redirect_exists?: InputMaybe<Scalars['Boolean']>;
  redirect_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redirect_not?: InputMaybe<Scalars['String']>;
  redirect_not_contains?: InputMaybe<Scalars['String']>;
  redirect_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rightColumnBody?: InputMaybe<Scalars['String']>;
  rightColumnBody_contains?: InputMaybe<Scalars['String']>;
  rightColumnBody_exists?: InputMaybe<Scalars['Boolean']>;
  rightColumnBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rightColumnBody_not?: InputMaybe<Scalars['String']>;
  rightColumnBody_not_contains?: InputMaybe<Scalars['String']>;
  rightColumnBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rightColumnList?: InputMaybe<Scalars['String']>;
  rightColumnList2?: InputMaybe<Scalars['String']>;
  rightColumnList2_contains?: InputMaybe<Scalars['String']>;
  rightColumnList2_exists?: InputMaybe<Scalars['Boolean']>;
  rightColumnList2_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rightColumnList2_not?: InputMaybe<Scalars['String']>;
  rightColumnList2_not_contains?: InputMaybe<Scalars['String']>;
  rightColumnList2_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rightColumnList_contains?: InputMaybe<Scalars['String']>;
  rightColumnList_exists?: InputMaybe<Scalars['Boolean']>;
  rightColumnList_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rightColumnList_not?: InputMaybe<Scalars['String']>;
  rightColumnList_not_contains?: InputMaybe<Scalars['String']>;
  rightColumnList_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rightColumnMedia_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  tabsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MultimediaTemplateGalleryCollection = {
  items: Array<Maybe<StoryCards>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type MultimediaTemplateLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  pagePreConfiguredTemplatesCollection?: Maybe<PagePreConfiguredTemplatesCollection>;
};


export type MultimediaTemplateLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type MultimediaTemplateLinkingCollectionsPagePreConfiguredTemplatesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum MultimediaTemplateOrder {
  GallerySectionTitleAsc = 'gallerySectionTitle_ASC',
  GallerySectionTitleDesc = 'gallerySectionTitle_DESC',
  PermalinkAsc = 'permalink_ASC',
  PermalinkDesc = 'permalink_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type MultimediaTemplateTabsCollection = {
  items: Array<Maybe<Tabs>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Mutation = {
  /** Posting a feedback request to DB */
  epri_addFeedback?: Maybe<Scalars['Int']>;
  epri_addRemoveProductsToCart: Scalars['String'];
  /** Bounce the service to refresh contentful schema */
  epri_bounceService?: Maybe<Scalars['Boolean']>;
  epri_cancelOrder: Scalars['String'];
  epri_captureDownloadUser: Scalars['Int'];
  epri_deleteNomination?: Maybe<Scalars['Int']>;
  epri_insertProductAccessLog: Scalars['Int'];
  /** Mutation Query to add/delete an advisor to a committee */
  epri_manageAdvisor?: Maybe<Scalars['Int']>;
  /** Add or Remove a Bookmark */
  epri_manageBookmark?: Maybe<Scalars['Int']>;
  epri_manageClickThruAcceptance: ClickThru;
  /** Mutation Query to remove/inactivate a contact from a company */
  epri_manageCompanyParticipant?: Maybe<Scalars['Int']>;
  epri_manageConfirmSubsidiaryRequest?: Maybe<Scalars['Int']>;
  epri_manageDownloadAcknowledgement: Scalars['String'];
  /** Add a Favorite ContactId/EventId/ProductId/SiteLTN for a userId. Pass Type as Contact/Event/Product/Site */
  epri_manageFavorite?: Maybe<Scalars['Int']>;
  /** Mutation Query to Add/Remove a contact to a Fourth Condition Product */
  epri_manageIndividualAccessContact?: Maybe<Scalars['Int']>;
  epri_manageNomination: Scalars['Int'];
  epri_managePart810Acceptance: Scalars['String'];
  /** Updates Overview or either Sector or Research Area */
  epri_managePortfolioOverviews?: Maybe<Scalars['Int']>;
  /** Add/Remove a Site Subscription for a user. */
  epri_manageSubscription?: Maybe<Scalars['Int']>;
  epri_manageSubsidiaryRequest?: Maybe<Scalars['Int']>;
  /** Mutation to add a BCam request */
  epri_postBcamRequest?: Maybe<Scalars['Int']>;
  /** Posting a feedback request to DB */
  epri_postNDEFeedback?: Maybe<Scalars['Int']>;
  epri_submitProductOrder: Scalars['String'];
  /** Query to update Profile */
  epri_updateProfile?: Maybe<ProfileResponse>;
  /** Posting a feedback request to DB */
  epri_writeToLog?: Maybe<Scalars['Int']>;
};


export type MutationEpri_AddFeedbackArgs = {
  feedback: FeedbackInput;
};


export type MutationEpri_AddRemoveProductsToCartArgs = {
  input: OrderInput;
};


export type MutationEpri_CaptureDownloadUserArgs = {
  user: DownloadUserInput;
};


export type MutationEpri_DeleteNominationArgs = {
  id: Scalars['Int'];
};


export type MutationEpri_InsertProductAccessLogArgs = {
  ipAddress?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
  source?: InputMaybe<Scalars['String']>;
};


export type MutationEpri_ManageAdvisorArgs = {
  advisor: AdvisorRequestInput;
};


export type MutationEpri_ManageBookmarkArgs = {
  bookmarkInput: BookmarkInput;
};


export type MutationEpri_ManageClickThruAcceptanceArgs = {
  agreement: ClickThruInput;
};


export type MutationEpri_ManageCompanyParticipantArgs = {
  participant: ContactRequestInput;
};


export type MutationEpri_ManageConfirmSubsidiaryRequestArgs = {
  input: SubsidiaryRequestInput;
};


export type MutationEpri_ManageDownloadAcknowledgementArgs = {
  pdfLogId: Scalars['String'];
  productId: Scalars['String'];
};


export type MutationEpri_ManageFavoriteArgs = {
  action: FavoriteAction;
  id: Scalars['String'];
  type: FavoriteType;
};


export type MutationEpri_ManageIndividualAccessContactArgs = {
  input: IndividualAccessRequestInput;
};


export type MutationEpri_ManageNominationArgs = {
  nomination: NominationInput;
};


export type MutationEpri_ManagePart810AcceptanceArgs = {
  source: Scalars['String'];
};


export type MutationEpri_ManagePortfolioOverviewsArgs = {
  input: PortfolioInput;
};


export type MutationEpri_ManageSubscriptionArgs = {
  subscription: SubscriptionInput;
};


export type MutationEpri_ManageSubsidiaryRequestArgs = {
  input: SubsidiaryRequestInput;
};


export type MutationEpri_PostBcamRequestArgs = {
  input: BcamInput;
};


export type MutationEpri_PostNdeFeedbackArgs = {
  feedback: NdeFeedbackInput;
};


export type MutationEpri_SubmitProductOrderArgs = {
  notes?: InputMaybe<Scalars['String']>;
};


export type MutationEpri_UpdateProfileArgs = {
  profile: ProfileInput;
};


export type MutationEpri_WriteToLogArgs = {
  input: LogInput;
};

export enum NdeFeedbackAction {
  Add = 'Add',
  Update = 'Update'
}

export type NdeFeedbackInput = {
  action: NdeFeedbackAction;
  company?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  contactId: Scalars['String'];
  email: Scalars['String'];
  examinationBasis?: InputMaybe<Scalars['String']>;
  examinationCategories?: InputMaybe<Scalars['String']>;
  examinationDocuments?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  subject: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type NavLink = {
  name?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ndeToolLandingPage) */
export type NdeToolLandingPage = Entry & {
  addCommentText?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<NdeToolLandingPageLinkingCollections>;
  overviewHeader?: Maybe<Scalars['String']>;
  overviewIcon?: Maybe<Asset>;
  overviewImage?: Maybe<Asset>;
  overviewText?: Maybe<Scalars['String']>;
  searchHeader?: Maybe<Scalars['String']>;
  searchPlaceholderText?: Maybe<Scalars['String']>;
  searchText?: Maybe<Scalars['String']>;
  sectionsCollection?: Maybe<NdeToolLandingPageSectionsCollection>;
  sys: Sys;
  yourInputHelpsHeader?: Maybe<Scalars['String']>;
  yourInputHelpsIcon?: Maybe<Asset>;
  yourInputHelpsText?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ndeToolLandingPage) */
export type NdeToolLandingPageAddCommentTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ndeToolLandingPage) */
export type NdeToolLandingPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ndeToolLandingPage) */
export type NdeToolLandingPageOverviewHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ndeToolLandingPage) */
export type NdeToolLandingPageOverviewIconArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ndeToolLandingPage) */
export type NdeToolLandingPageOverviewImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ndeToolLandingPage) */
export type NdeToolLandingPageOverviewTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ndeToolLandingPage) */
export type NdeToolLandingPageSearchHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ndeToolLandingPage) */
export type NdeToolLandingPageSearchPlaceholderTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ndeToolLandingPage) */
export type NdeToolLandingPageSearchTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ndeToolLandingPage) */
export type NdeToolLandingPageSectionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ndeToolLandingPage) */
export type NdeToolLandingPageYourInputHelpsHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ndeToolLandingPage) */
export type NdeToolLandingPageYourInputHelpsIconArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/ndeToolLandingPage) */
export type NdeToolLandingPageYourInputHelpsTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type NdeToolLandingPageCollection = {
  items: Array<Maybe<NdeToolLandingPage>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type NdeToolLandingPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<NdeToolLandingPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<NdeToolLandingPageFilter>>>;
  addCommentText?: InputMaybe<Scalars['String']>;
  addCommentText_contains?: InputMaybe<Scalars['String']>;
  addCommentText_exists?: InputMaybe<Scalars['Boolean']>;
  addCommentText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  addCommentText_not?: InputMaybe<Scalars['String']>;
  addCommentText_not_contains?: InputMaybe<Scalars['String']>;
  addCommentText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  overviewHeader?: InputMaybe<Scalars['String']>;
  overviewHeader_contains?: InputMaybe<Scalars['String']>;
  overviewHeader_exists?: InputMaybe<Scalars['Boolean']>;
  overviewHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  overviewHeader_not?: InputMaybe<Scalars['String']>;
  overviewHeader_not_contains?: InputMaybe<Scalars['String']>;
  overviewHeader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  overviewIcon_exists?: InputMaybe<Scalars['Boolean']>;
  overviewImage_exists?: InputMaybe<Scalars['Boolean']>;
  overviewText?: InputMaybe<Scalars['String']>;
  overviewText_contains?: InputMaybe<Scalars['String']>;
  overviewText_exists?: InputMaybe<Scalars['Boolean']>;
  overviewText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  overviewText_not?: InputMaybe<Scalars['String']>;
  overviewText_not_contains?: InputMaybe<Scalars['String']>;
  overviewText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchHeader?: InputMaybe<Scalars['String']>;
  searchHeader_contains?: InputMaybe<Scalars['String']>;
  searchHeader_exists?: InputMaybe<Scalars['Boolean']>;
  searchHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchHeader_not?: InputMaybe<Scalars['String']>;
  searchHeader_not_contains?: InputMaybe<Scalars['String']>;
  searchHeader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchPlaceholderText?: InputMaybe<Scalars['String']>;
  searchPlaceholderText_contains?: InputMaybe<Scalars['String']>;
  searchPlaceholderText_exists?: InputMaybe<Scalars['Boolean']>;
  searchPlaceholderText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchPlaceholderText_not?: InputMaybe<Scalars['String']>;
  searchPlaceholderText_not_contains?: InputMaybe<Scalars['String']>;
  searchPlaceholderText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchText?: InputMaybe<Scalars['String']>;
  searchText_contains?: InputMaybe<Scalars['String']>;
  searchText_exists?: InputMaybe<Scalars['Boolean']>;
  searchText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchText_not?: InputMaybe<Scalars['String']>;
  searchText_not_contains?: InputMaybe<Scalars['String']>;
  searchText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  yourInputHelpsHeader?: InputMaybe<Scalars['String']>;
  yourInputHelpsHeader_contains?: InputMaybe<Scalars['String']>;
  yourInputHelpsHeader_exists?: InputMaybe<Scalars['Boolean']>;
  yourInputHelpsHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  yourInputHelpsHeader_not?: InputMaybe<Scalars['String']>;
  yourInputHelpsHeader_not_contains?: InputMaybe<Scalars['String']>;
  yourInputHelpsHeader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  yourInputHelpsIcon_exists?: InputMaybe<Scalars['Boolean']>;
  yourInputHelpsText?: InputMaybe<Scalars['String']>;
  yourInputHelpsText_contains?: InputMaybe<Scalars['String']>;
  yourInputHelpsText_exists?: InputMaybe<Scalars['Boolean']>;
  yourInputHelpsText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  yourInputHelpsText_not?: InputMaybe<Scalars['String']>;
  yourInputHelpsText_not_contains?: InputMaybe<Scalars['String']>;
  yourInputHelpsText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type NdeToolLandingPageLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type NdeToolLandingPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum NdeToolLandingPageOrder {
  AddCommentTextAsc = 'addCommentText_ASC',
  AddCommentTextDesc = 'addCommentText_DESC',
  OverviewHeaderAsc = 'overviewHeader_ASC',
  OverviewHeaderDesc = 'overviewHeader_DESC',
  SearchHeaderAsc = 'searchHeader_ASC',
  SearchHeaderDesc = 'searchHeader_DESC',
  SearchPlaceholderTextAsc = 'searchPlaceholderText_ASC',
  SearchPlaceholderTextDesc = 'searchPlaceholderText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  YourInputHelpsHeaderAsc = 'yourInputHelpsHeader_ASC',
  YourInputHelpsHeaderDesc = 'yourInputHelpsHeader_DESC'
}

export type NdeToolLandingPageSectionsCollection = {
  items: Array<Maybe<PressRelease>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPage = Entry & {
  contentfulMetadata: ContentfulMetadata;
  howDoIKnowIfIAmLoggedInTitle?: Maybe<Scalars['String']>;
  howDoIKnowIfImLoggedIn?: Maybe<Scalars['String']>;
  howDoIKnowIfImLoggedInPhoto?: Maybe<Asset>;
  howDoISeeAnnouncements?: Maybe<Scalars['String']>;
  howDoISeeAnnouncementsVideo?: Maybe<Asset>;
  howDoISeeMoreAnnouncementsTitle?: Maybe<Scalars['String']>;
  howDoISeeMoreEvents?: Maybe<Scalars['String']>;
  howDoISeeMoreEventsTitle?: Maybe<Scalars['String']>;
  howDoISeeMoreEventsVideo?: Maybe<Asset>;
  howDoesSearchWork?: Maybe<Scalars['String']>;
  howDoesSearchWorkTitle?: Maybe<Scalars['String']>;
  howDoesSearchWorkVideo?: Maybe<Asset>;
  linkedFrom?: Maybe<NewFeaturesPageLinkingCollections>;
  newColorButtonText?: Maybe<Scalars['String']>;
  newColorCodeText?: Maybe<Scalars['String']>;
  newColorCoding?: Maybe<Scalars['String']>;
  newColorCodingPhoto?: Maybe<Asset>;
  newColorLink?: Maybe<Scalars['String']>;
  newEventLink?: Maybe<Scalars['String']>;
  newEvents?: Maybe<Scalars['String']>;
  newEventsButtonText?: Maybe<Scalars['String']>;
  newEventsPhoto?: Maybe<Asset>;
  newEventsText?: Maybe<Scalars['String']>;
  newFeaturesOverview?: Maybe<Scalars['String']>;
  newSearch?: Maybe<Scalars['String']>;
  newSearchButtonText?: Maybe<Scalars['String']>;
  newSearchLink?: Maybe<Scalars['String']>;
  newSearchPhoto?: Maybe<Asset>;
  newSearchText?: Maybe<Scalars['String']>;
  sys: Sys;
  whereAreCollaborationSites?: Maybe<Scalars['String']>;
  whereAreCollaborationVideo?: Maybe<Asset>;
  whereAreMeetingMaterialsPhoto?: Maybe<Asset>;
  whereAreMyCollaborationSitesTitle?: Maybe<Scalars['String']>;
  whereAreMyMeetingMaterials?: Maybe<Scalars['String']>;
  whereAreMyMeetingMaterialsTitle?: Maybe<Scalars['String']>;
  whereAreResearchResults?: Maybe<Scalars['String']>;
  whereAreResearchResultsVideo?: Maybe<Asset>;
  whereAreTheResearchResultsTitle?: Maybe<Scalars['String']>;
  whereIsMemberCenter?: Maybe<Scalars['String']>;
  whereIsMemberCenterLoginButton?: Maybe<Scalars['String']>;
  whereIsMemberCenterTitle?: Maybe<Scalars['String']>;
  whereIsMemberCenterVideo?: Maybe<Asset>;
  whyCantIDownloadResearch?: Maybe<Scalars['String']>;
  whyCantIDownloadResearchVideo?: Maybe<Asset>;
  whyCantIDownloadTheResearchResultsTitle?: Maybe<Scalars['String']>;
  whyDoIHaveToLogInTitle?: Maybe<Scalars['String']>;
  whyDoIHaveToLogin?: Maybe<Scalars['String']>;
  whyDoIHaveToLoginVideo?: Maybe<Asset>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageHowDoIKnowIfIAmLoggedInTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageHowDoIKnowIfImLoggedInArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageHowDoIKnowIfImLoggedInPhotoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageHowDoISeeAnnouncementsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageHowDoISeeAnnouncementsVideoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageHowDoISeeMoreAnnouncementsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageHowDoISeeMoreEventsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageHowDoISeeMoreEventsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageHowDoISeeMoreEventsVideoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageHowDoesSearchWorkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageHowDoesSearchWorkTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageHowDoesSearchWorkVideoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageNewColorButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageNewColorCodeTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageNewColorCodingArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageNewColorCodingPhotoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageNewColorLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageNewEventLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageNewEventsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageNewEventsButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageNewEventsPhotoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageNewEventsTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageNewFeaturesOverviewArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageNewSearchArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageNewSearchButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageNewSearchLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageNewSearchPhotoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageNewSearchTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageWhereAreCollaborationSitesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageWhereAreCollaborationVideoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageWhereAreMeetingMaterialsPhotoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageWhereAreMyCollaborationSitesTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageWhereAreMyMeetingMaterialsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageWhereAreMyMeetingMaterialsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageWhereAreResearchResultsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageWhereAreResearchResultsVideoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageWhereAreTheResearchResultsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageWhereIsMemberCenterArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageWhereIsMemberCenterLoginButtonArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageWhereIsMemberCenterTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageWhereIsMemberCenterVideoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageWhyCantIDownloadResearchArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageWhyCantIDownloadResearchVideoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageWhyCantIDownloadTheResearchResultsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageWhyDoIHaveToLogInTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageWhyDoIHaveToLoginArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/newFeaturesPage) */
export type NewFeaturesPageWhyDoIHaveToLoginVideoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type NewFeaturesPageCollection = {
  items: Array<Maybe<NewFeaturesPage>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type NewFeaturesPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<NewFeaturesPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<NewFeaturesPageFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  howDoIKnowIfIAmLoggedInTitle?: InputMaybe<Scalars['String']>;
  howDoIKnowIfIAmLoggedInTitle_contains?: InputMaybe<Scalars['String']>;
  howDoIKnowIfIAmLoggedInTitle_exists?: InputMaybe<Scalars['Boolean']>;
  howDoIKnowIfIAmLoggedInTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  howDoIKnowIfIAmLoggedInTitle_not?: InputMaybe<Scalars['String']>;
  howDoIKnowIfIAmLoggedInTitle_not_contains?: InputMaybe<Scalars['String']>;
  howDoIKnowIfIAmLoggedInTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  howDoIKnowIfImLoggedIn?: InputMaybe<Scalars['String']>;
  howDoIKnowIfImLoggedInPhoto_exists?: InputMaybe<Scalars['Boolean']>;
  howDoIKnowIfImLoggedIn_contains?: InputMaybe<Scalars['String']>;
  howDoIKnowIfImLoggedIn_exists?: InputMaybe<Scalars['Boolean']>;
  howDoIKnowIfImLoggedIn_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  howDoIKnowIfImLoggedIn_not?: InputMaybe<Scalars['String']>;
  howDoIKnowIfImLoggedIn_not_contains?: InputMaybe<Scalars['String']>;
  howDoIKnowIfImLoggedIn_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  howDoISeeAnnouncements?: InputMaybe<Scalars['String']>;
  howDoISeeAnnouncementsVideo_exists?: InputMaybe<Scalars['Boolean']>;
  howDoISeeAnnouncements_contains?: InputMaybe<Scalars['String']>;
  howDoISeeAnnouncements_exists?: InputMaybe<Scalars['Boolean']>;
  howDoISeeAnnouncements_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  howDoISeeAnnouncements_not?: InputMaybe<Scalars['String']>;
  howDoISeeAnnouncements_not_contains?: InputMaybe<Scalars['String']>;
  howDoISeeAnnouncements_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  howDoISeeMoreAnnouncementsTitle?: InputMaybe<Scalars['String']>;
  howDoISeeMoreAnnouncementsTitle_contains?: InputMaybe<Scalars['String']>;
  howDoISeeMoreAnnouncementsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  howDoISeeMoreAnnouncementsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  howDoISeeMoreAnnouncementsTitle_not?: InputMaybe<Scalars['String']>;
  howDoISeeMoreAnnouncementsTitle_not_contains?: InputMaybe<Scalars['String']>;
  howDoISeeMoreAnnouncementsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  howDoISeeMoreEvents?: InputMaybe<Scalars['String']>;
  howDoISeeMoreEventsTitle?: InputMaybe<Scalars['String']>;
  howDoISeeMoreEventsTitle_contains?: InputMaybe<Scalars['String']>;
  howDoISeeMoreEventsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  howDoISeeMoreEventsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  howDoISeeMoreEventsTitle_not?: InputMaybe<Scalars['String']>;
  howDoISeeMoreEventsTitle_not_contains?: InputMaybe<Scalars['String']>;
  howDoISeeMoreEventsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  howDoISeeMoreEventsVideo_exists?: InputMaybe<Scalars['Boolean']>;
  howDoISeeMoreEvents_contains?: InputMaybe<Scalars['String']>;
  howDoISeeMoreEvents_exists?: InputMaybe<Scalars['Boolean']>;
  howDoISeeMoreEvents_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  howDoISeeMoreEvents_not?: InputMaybe<Scalars['String']>;
  howDoISeeMoreEvents_not_contains?: InputMaybe<Scalars['String']>;
  howDoISeeMoreEvents_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  howDoesSearchWork?: InputMaybe<Scalars['String']>;
  howDoesSearchWorkTitle?: InputMaybe<Scalars['String']>;
  howDoesSearchWorkTitle_contains?: InputMaybe<Scalars['String']>;
  howDoesSearchWorkTitle_exists?: InputMaybe<Scalars['Boolean']>;
  howDoesSearchWorkTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  howDoesSearchWorkTitle_not?: InputMaybe<Scalars['String']>;
  howDoesSearchWorkTitle_not_contains?: InputMaybe<Scalars['String']>;
  howDoesSearchWorkTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  howDoesSearchWorkVideo_exists?: InputMaybe<Scalars['Boolean']>;
  howDoesSearchWork_contains?: InputMaybe<Scalars['String']>;
  howDoesSearchWork_exists?: InputMaybe<Scalars['Boolean']>;
  howDoesSearchWork_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  howDoesSearchWork_not?: InputMaybe<Scalars['String']>;
  howDoesSearchWork_not_contains?: InputMaybe<Scalars['String']>;
  howDoesSearchWork_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newColorButtonText?: InputMaybe<Scalars['String']>;
  newColorButtonText_contains?: InputMaybe<Scalars['String']>;
  newColorButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  newColorButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newColorButtonText_not?: InputMaybe<Scalars['String']>;
  newColorButtonText_not_contains?: InputMaybe<Scalars['String']>;
  newColorButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newColorCodeText?: InputMaybe<Scalars['String']>;
  newColorCodeText_contains?: InputMaybe<Scalars['String']>;
  newColorCodeText_exists?: InputMaybe<Scalars['Boolean']>;
  newColorCodeText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newColorCodeText_not?: InputMaybe<Scalars['String']>;
  newColorCodeText_not_contains?: InputMaybe<Scalars['String']>;
  newColorCodeText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newColorCoding?: InputMaybe<Scalars['String']>;
  newColorCodingPhoto_exists?: InputMaybe<Scalars['Boolean']>;
  newColorCoding_contains?: InputMaybe<Scalars['String']>;
  newColorCoding_exists?: InputMaybe<Scalars['Boolean']>;
  newColorCoding_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newColorCoding_not?: InputMaybe<Scalars['String']>;
  newColorCoding_not_contains?: InputMaybe<Scalars['String']>;
  newColorCoding_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newColorLink?: InputMaybe<Scalars['String']>;
  newColorLink_contains?: InputMaybe<Scalars['String']>;
  newColorLink_exists?: InputMaybe<Scalars['Boolean']>;
  newColorLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newColorLink_not?: InputMaybe<Scalars['String']>;
  newColorLink_not_contains?: InputMaybe<Scalars['String']>;
  newColorLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newEventLink?: InputMaybe<Scalars['String']>;
  newEventLink_contains?: InputMaybe<Scalars['String']>;
  newEventLink_exists?: InputMaybe<Scalars['Boolean']>;
  newEventLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newEventLink_not?: InputMaybe<Scalars['String']>;
  newEventLink_not_contains?: InputMaybe<Scalars['String']>;
  newEventLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newEvents?: InputMaybe<Scalars['String']>;
  newEventsButtonText?: InputMaybe<Scalars['String']>;
  newEventsButtonText_contains?: InputMaybe<Scalars['String']>;
  newEventsButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  newEventsButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newEventsButtonText_not?: InputMaybe<Scalars['String']>;
  newEventsButtonText_not_contains?: InputMaybe<Scalars['String']>;
  newEventsButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newEventsPhoto_exists?: InputMaybe<Scalars['Boolean']>;
  newEventsText?: InputMaybe<Scalars['String']>;
  newEventsText_contains?: InputMaybe<Scalars['String']>;
  newEventsText_exists?: InputMaybe<Scalars['Boolean']>;
  newEventsText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newEventsText_not?: InputMaybe<Scalars['String']>;
  newEventsText_not_contains?: InputMaybe<Scalars['String']>;
  newEventsText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newEvents_contains?: InputMaybe<Scalars['String']>;
  newEvents_exists?: InputMaybe<Scalars['Boolean']>;
  newEvents_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newEvents_not?: InputMaybe<Scalars['String']>;
  newEvents_not_contains?: InputMaybe<Scalars['String']>;
  newEvents_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newFeaturesOverview?: InputMaybe<Scalars['String']>;
  newFeaturesOverview_contains?: InputMaybe<Scalars['String']>;
  newFeaturesOverview_exists?: InputMaybe<Scalars['Boolean']>;
  newFeaturesOverview_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newFeaturesOverview_not?: InputMaybe<Scalars['String']>;
  newFeaturesOverview_not_contains?: InputMaybe<Scalars['String']>;
  newFeaturesOverview_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newSearch?: InputMaybe<Scalars['String']>;
  newSearchButtonText?: InputMaybe<Scalars['String']>;
  newSearchButtonText_contains?: InputMaybe<Scalars['String']>;
  newSearchButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  newSearchButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newSearchButtonText_not?: InputMaybe<Scalars['String']>;
  newSearchButtonText_not_contains?: InputMaybe<Scalars['String']>;
  newSearchButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newSearchLink?: InputMaybe<Scalars['String']>;
  newSearchLink_contains?: InputMaybe<Scalars['String']>;
  newSearchLink_exists?: InputMaybe<Scalars['Boolean']>;
  newSearchLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newSearchLink_not?: InputMaybe<Scalars['String']>;
  newSearchLink_not_contains?: InputMaybe<Scalars['String']>;
  newSearchLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newSearchPhoto_exists?: InputMaybe<Scalars['Boolean']>;
  newSearchText?: InputMaybe<Scalars['String']>;
  newSearchText_contains?: InputMaybe<Scalars['String']>;
  newSearchText_exists?: InputMaybe<Scalars['Boolean']>;
  newSearchText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newSearchText_not?: InputMaybe<Scalars['String']>;
  newSearchText_not_contains?: InputMaybe<Scalars['String']>;
  newSearchText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newSearch_contains?: InputMaybe<Scalars['String']>;
  newSearch_exists?: InputMaybe<Scalars['Boolean']>;
  newSearch_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newSearch_not?: InputMaybe<Scalars['String']>;
  newSearch_not_contains?: InputMaybe<Scalars['String']>;
  newSearch_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  whereAreCollaborationSites?: InputMaybe<Scalars['String']>;
  whereAreCollaborationSites_contains?: InputMaybe<Scalars['String']>;
  whereAreCollaborationSites_exists?: InputMaybe<Scalars['Boolean']>;
  whereAreCollaborationSites_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  whereAreCollaborationSites_not?: InputMaybe<Scalars['String']>;
  whereAreCollaborationSites_not_contains?: InputMaybe<Scalars['String']>;
  whereAreCollaborationSites_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  whereAreCollaborationVideo_exists?: InputMaybe<Scalars['Boolean']>;
  whereAreMeetingMaterialsPhoto_exists?: InputMaybe<Scalars['Boolean']>;
  whereAreMyCollaborationSitesTitle?: InputMaybe<Scalars['String']>;
  whereAreMyCollaborationSitesTitle_contains?: InputMaybe<Scalars['String']>;
  whereAreMyCollaborationSitesTitle_exists?: InputMaybe<Scalars['Boolean']>;
  whereAreMyCollaborationSitesTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  whereAreMyCollaborationSitesTitle_not?: InputMaybe<Scalars['String']>;
  whereAreMyCollaborationSitesTitle_not_contains?: InputMaybe<Scalars['String']>;
  whereAreMyCollaborationSitesTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  whereAreMyMeetingMaterials?: InputMaybe<Scalars['String']>;
  whereAreMyMeetingMaterialsTitle?: InputMaybe<Scalars['String']>;
  whereAreMyMeetingMaterialsTitle_contains?: InputMaybe<Scalars['String']>;
  whereAreMyMeetingMaterialsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  whereAreMyMeetingMaterialsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  whereAreMyMeetingMaterialsTitle_not?: InputMaybe<Scalars['String']>;
  whereAreMyMeetingMaterialsTitle_not_contains?: InputMaybe<Scalars['String']>;
  whereAreMyMeetingMaterialsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  whereAreMyMeetingMaterials_contains?: InputMaybe<Scalars['String']>;
  whereAreMyMeetingMaterials_exists?: InputMaybe<Scalars['Boolean']>;
  whereAreMyMeetingMaterials_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  whereAreMyMeetingMaterials_not?: InputMaybe<Scalars['String']>;
  whereAreMyMeetingMaterials_not_contains?: InputMaybe<Scalars['String']>;
  whereAreMyMeetingMaterials_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  whereAreResearchResults?: InputMaybe<Scalars['String']>;
  whereAreResearchResultsVideo_exists?: InputMaybe<Scalars['Boolean']>;
  whereAreResearchResults_contains?: InputMaybe<Scalars['String']>;
  whereAreResearchResults_exists?: InputMaybe<Scalars['Boolean']>;
  whereAreResearchResults_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  whereAreResearchResults_not?: InputMaybe<Scalars['String']>;
  whereAreResearchResults_not_contains?: InputMaybe<Scalars['String']>;
  whereAreResearchResults_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  whereAreTheResearchResultsTitle?: InputMaybe<Scalars['String']>;
  whereAreTheResearchResultsTitle_contains?: InputMaybe<Scalars['String']>;
  whereAreTheResearchResultsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  whereAreTheResearchResultsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  whereAreTheResearchResultsTitle_not?: InputMaybe<Scalars['String']>;
  whereAreTheResearchResultsTitle_not_contains?: InputMaybe<Scalars['String']>;
  whereAreTheResearchResultsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  whereIsMemberCenter?: InputMaybe<Scalars['String']>;
  whereIsMemberCenterLoginButton?: InputMaybe<Scalars['String']>;
  whereIsMemberCenterLoginButton_contains?: InputMaybe<Scalars['String']>;
  whereIsMemberCenterLoginButton_exists?: InputMaybe<Scalars['Boolean']>;
  whereIsMemberCenterLoginButton_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  whereIsMemberCenterLoginButton_not?: InputMaybe<Scalars['String']>;
  whereIsMemberCenterLoginButton_not_contains?: InputMaybe<Scalars['String']>;
  whereIsMemberCenterLoginButton_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  whereIsMemberCenterTitle?: InputMaybe<Scalars['String']>;
  whereIsMemberCenterTitle_contains?: InputMaybe<Scalars['String']>;
  whereIsMemberCenterTitle_exists?: InputMaybe<Scalars['Boolean']>;
  whereIsMemberCenterTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  whereIsMemberCenterTitle_not?: InputMaybe<Scalars['String']>;
  whereIsMemberCenterTitle_not_contains?: InputMaybe<Scalars['String']>;
  whereIsMemberCenterTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  whereIsMemberCenterVideo_exists?: InputMaybe<Scalars['Boolean']>;
  whereIsMemberCenter_contains?: InputMaybe<Scalars['String']>;
  whereIsMemberCenter_exists?: InputMaybe<Scalars['Boolean']>;
  whereIsMemberCenter_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  whereIsMemberCenter_not?: InputMaybe<Scalars['String']>;
  whereIsMemberCenter_not_contains?: InputMaybe<Scalars['String']>;
  whereIsMemberCenter_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  whyCantIDownloadResearch?: InputMaybe<Scalars['String']>;
  whyCantIDownloadResearchVideo_exists?: InputMaybe<Scalars['Boolean']>;
  whyCantIDownloadResearch_contains?: InputMaybe<Scalars['String']>;
  whyCantIDownloadResearch_exists?: InputMaybe<Scalars['Boolean']>;
  whyCantIDownloadResearch_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  whyCantIDownloadResearch_not?: InputMaybe<Scalars['String']>;
  whyCantIDownloadResearch_not_contains?: InputMaybe<Scalars['String']>;
  whyCantIDownloadResearch_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  whyCantIDownloadTheResearchResultsTitle?: InputMaybe<Scalars['String']>;
  whyCantIDownloadTheResearchResultsTitle_contains?: InputMaybe<Scalars['String']>;
  whyCantIDownloadTheResearchResultsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  whyCantIDownloadTheResearchResultsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  whyCantIDownloadTheResearchResultsTitle_not?: InputMaybe<Scalars['String']>;
  whyCantIDownloadTheResearchResultsTitle_not_contains?: InputMaybe<Scalars['String']>;
  whyCantIDownloadTheResearchResultsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  whyDoIHaveToLogInTitle?: InputMaybe<Scalars['String']>;
  whyDoIHaveToLogInTitle_contains?: InputMaybe<Scalars['String']>;
  whyDoIHaveToLogInTitle_exists?: InputMaybe<Scalars['Boolean']>;
  whyDoIHaveToLogInTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  whyDoIHaveToLogInTitle_not?: InputMaybe<Scalars['String']>;
  whyDoIHaveToLogInTitle_not_contains?: InputMaybe<Scalars['String']>;
  whyDoIHaveToLogInTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  whyDoIHaveToLogin?: InputMaybe<Scalars['String']>;
  whyDoIHaveToLoginVideo_exists?: InputMaybe<Scalars['Boolean']>;
  whyDoIHaveToLogin_contains?: InputMaybe<Scalars['String']>;
  whyDoIHaveToLogin_exists?: InputMaybe<Scalars['Boolean']>;
  whyDoIHaveToLogin_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  whyDoIHaveToLogin_not?: InputMaybe<Scalars['String']>;
  whyDoIHaveToLogin_not_contains?: InputMaybe<Scalars['String']>;
  whyDoIHaveToLogin_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type NewFeaturesPageLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type NewFeaturesPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum NewFeaturesPageOrder {
  HowDoIKnowIfIAmLoggedInTitleAsc = 'howDoIKnowIfIAmLoggedInTitle_ASC',
  HowDoIKnowIfIAmLoggedInTitleDesc = 'howDoIKnowIfIAmLoggedInTitle_DESC',
  HowDoISeeMoreAnnouncementsTitleAsc = 'howDoISeeMoreAnnouncementsTitle_ASC',
  HowDoISeeMoreAnnouncementsTitleDesc = 'howDoISeeMoreAnnouncementsTitle_DESC',
  HowDoISeeMoreEventsTitleAsc = 'howDoISeeMoreEventsTitle_ASC',
  HowDoISeeMoreEventsTitleDesc = 'howDoISeeMoreEventsTitle_DESC',
  HowDoesSearchWorkTitleAsc = 'howDoesSearchWorkTitle_ASC',
  HowDoesSearchWorkTitleDesc = 'howDoesSearchWorkTitle_DESC',
  NewColorButtonTextAsc = 'newColorButtonText_ASC',
  NewColorButtonTextDesc = 'newColorButtonText_DESC',
  NewColorCodeTextAsc = 'newColorCodeText_ASC',
  NewColorCodeTextDesc = 'newColorCodeText_DESC',
  NewColorCodingAsc = 'newColorCoding_ASC',
  NewColorCodingDesc = 'newColorCoding_DESC',
  NewColorLinkAsc = 'newColorLink_ASC',
  NewColorLinkDesc = 'newColorLink_DESC',
  NewEventLinkAsc = 'newEventLink_ASC',
  NewEventLinkDesc = 'newEventLink_DESC',
  NewEventsButtonTextAsc = 'newEventsButtonText_ASC',
  NewEventsButtonTextDesc = 'newEventsButtonText_DESC',
  NewEventsTextAsc = 'newEventsText_ASC',
  NewEventsTextDesc = 'newEventsText_DESC',
  NewEventsAsc = 'newEvents_ASC',
  NewEventsDesc = 'newEvents_DESC',
  NewSearchButtonTextAsc = 'newSearchButtonText_ASC',
  NewSearchButtonTextDesc = 'newSearchButtonText_DESC',
  NewSearchLinkAsc = 'newSearchLink_ASC',
  NewSearchLinkDesc = 'newSearchLink_DESC',
  NewSearchTextAsc = 'newSearchText_ASC',
  NewSearchTextDesc = 'newSearchText_DESC',
  NewSearchAsc = 'newSearch_ASC',
  NewSearchDesc = 'newSearch_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  WhereAreMyCollaborationSitesTitleAsc = 'whereAreMyCollaborationSitesTitle_ASC',
  WhereAreMyCollaborationSitesTitleDesc = 'whereAreMyCollaborationSitesTitle_DESC',
  WhereAreMyMeetingMaterialsTitleAsc = 'whereAreMyMeetingMaterialsTitle_ASC',
  WhereAreMyMeetingMaterialsTitleDesc = 'whereAreMyMeetingMaterialsTitle_DESC',
  WhereAreTheResearchResultsTitleAsc = 'whereAreTheResearchResultsTitle_ASC',
  WhereAreTheResearchResultsTitleDesc = 'whereAreTheResearchResultsTitle_DESC',
  WhereIsMemberCenterLoginButtonAsc = 'whereIsMemberCenterLoginButton_ASC',
  WhereIsMemberCenterLoginButtonDesc = 'whereIsMemberCenterLoginButton_DESC',
  WhereIsMemberCenterTitleAsc = 'whereIsMemberCenterTitle_ASC',
  WhereIsMemberCenterTitleDesc = 'whereIsMemberCenterTitle_DESC',
  WhyCantIDownloadTheResearchResultsTitleAsc = 'whyCantIDownloadTheResearchResultsTitle_ASC',
  WhyCantIDownloadTheResearchResultsTitleDesc = 'whyCantIDownloadTheResearchResultsTitle_DESC',
  WhyDoIHaveToLogInTitleAsc = 'whyDoIHaveToLogInTitle_ASC',
  WhyDoIHaveToLogInTitleDesc = 'whyDoIHaveToLogInTitle_DESC'
}

export type Nomination = {
  adminModifiedBy?: Maybe<Scalars['String']>;
  adminModifiedDate?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['String']>;
  hasAdminUpdatedNomination?: Maybe<Scalars['Boolean']>;
  hasUserUpdatedNomination?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isTeam?: Maybe<Scalars['Boolean']>;
  leadershipDemonstrated: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  linkTitle?: Maybe<Scalars['String']>;
  modifiedBy?: Maybe<Scalars['String']>;
  modifiedDate?: Maybe<Scalars['String']>;
  nominationReason: Scalars['String'];
  nominators?: Maybe<Array<Maybe<TechTransferContact>>>;
  nominees?: Maybe<Array<Maybe<TechTransferContact>>>;
  notes?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
  programLTN: Array<Maybe<Scalars['String']>>;
  projectStatus: Scalars['String'];
  reportApplied: Scalars['String'];
  researchApplied: Scalars['String'];
  sector?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectorLTN: Array<Maybe<Scalars['String']>>;
  status: Status;
  submitDate?: Maybe<Scalars['String']>;
  userModifiedBy?: Maybe<Scalars['String']>;
  userModifiedDate?: Maybe<Scalars['String']>;
  valueRealized: Scalars['String'];
  year: Scalars['String'];
};

export type NominationInput = {
  /** Add/Update/Delete */
  action: Action;
  isTeam?: InputMaybe<Scalars['Boolean']>;
  leadershipDemonstrated: Scalars['String'];
  link?: InputMaybe<Scalars['String']>;
  linkTitle?: InputMaybe<Scalars['String']>;
  nominationId?: InputMaybe<Scalars['String']>;
  nominationReason: Scalars['String'];
  nominators?: InputMaybe<Array<InputMaybe<TtContactInput>>>;
  nominees?: InputMaybe<Array<InputMaybe<TtContactInput>>>;
  notes?: InputMaybe<Scalars['String']>;
  programLTN?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  projectStatus: Scalars['String'];
  reportApplied: Scalars['String'];
  researchApplied: Scalars['String'];
  sectorLTN: Array<InputMaybe<Scalars['String']>>;
  status: Status;
  valueRealized?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
};

export type NominationResponse = {
  nominations?: Maybe<Array<Maybe<Nomination>>>;
  totalRecords?: Maybe<Scalars['Int']>;
};

/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationLandingPage) */
export type NuclearPlantModernizationLandingPage = Entry & {
  bcamHeader?: Maybe<Scalars['String']>;
  bcamIcon?: Maybe<Asset>;
  bcamText?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  downloadBcamSoftwareLink?: Maybe<Entry>;
  downloadMtaSpecLink?: Maybe<Entry>;
  downloadProcessGuideLink?: Maybe<Entry>;
  linkedFrom?: Maybe<NuclearPlantModernizationLandingPageLinkingCollections>;
  modernizationProcessDiagram?: Maybe<Asset>;
  modernizationProcessHeader?: Maybe<Scalars['String']>;
  modernizationProcessIcon?: Maybe<Asset>;
  modernizationProcessText?: Maybe<Scalars['String']>;
  modernizationQuickGuidesHeader?: Maybe<Scalars['String']>;
  modernizationQuickGuidesText?: Maybe<Scalars['String']>;
  mtaIcon?: Maybe<Asset>;
  overviewHeader?: Maybe<Scalars['String']>;
  overviewIcon?: Maybe<Asset>;
  overviewImage?: Maybe<Asset>;
  overviewText?: Maybe<Scalars['String']>;
  sys: Sys;
  tpieHeader?: Maybe<Scalars['String']>;
  tpieText?: Maybe<Scalars['String']>;
  viewBcamListLink?: Maybe<Entry>;
  viewMtaListLink?: Maybe<Entry>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationLandingPage) */
export type NuclearPlantModernizationLandingPageBcamHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationLandingPage) */
export type NuclearPlantModernizationLandingPageBcamIconArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationLandingPage) */
export type NuclearPlantModernizationLandingPageBcamTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationLandingPage) */
export type NuclearPlantModernizationLandingPageDownloadBcamSoftwareLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationLandingPage) */
export type NuclearPlantModernizationLandingPageDownloadMtaSpecLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationLandingPage) */
export type NuclearPlantModernizationLandingPageDownloadProcessGuideLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationLandingPage) */
export type NuclearPlantModernizationLandingPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationLandingPage) */
export type NuclearPlantModernizationLandingPageModernizationProcessDiagramArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationLandingPage) */
export type NuclearPlantModernizationLandingPageModernizationProcessHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationLandingPage) */
export type NuclearPlantModernizationLandingPageModernizationProcessIconArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationLandingPage) */
export type NuclearPlantModernizationLandingPageModernizationProcessTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationLandingPage) */
export type NuclearPlantModernizationLandingPageModernizationQuickGuidesHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationLandingPage) */
export type NuclearPlantModernizationLandingPageModernizationQuickGuidesTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationLandingPage) */
export type NuclearPlantModernizationLandingPageMtaIconArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationLandingPage) */
export type NuclearPlantModernizationLandingPageOverviewHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationLandingPage) */
export type NuclearPlantModernizationLandingPageOverviewIconArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationLandingPage) */
export type NuclearPlantModernizationLandingPageOverviewImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationLandingPage) */
export type NuclearPlantModernizationLandingPageOverviewTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationLandingPage) */
export type NuclearPlantModernizationLandingPageTpieHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationLandingPage) */
export type NuclearPlantModernizationLandingPageTpieTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationLandingPage) */
export type NuclearPlantModernizationLandingPageViewBcamListLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationLandingPage) */
export type NuclearPlantModernizationLandingPageViewMtaListLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type NuclearPlantModernizationLandingPageCollection = {
  items: Array<Maybe<NuclearPlantModernizationLandingPage>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type NuclearPlantModernizationLandingPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<NuclearPlantModernizationLandingPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<NuclearPlantModernizationLandingPageFilter>>>;
  bcamHeader?: InputMaybe<Scalars['String']>;
  bcamHeader_contains?: InputMaybe<Scalars['String']>;
  bcamHeader_exists?: InputMaybe<Scalars['Boolean']>;
  bcamHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bcamHeader_not?: InputMaybe<Scalars['String']>;
  bcamHeader_not_contains?: InputMaybe<Scalars['String']>;
  bcamHeader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bcamIcon_exists?: InputMaybe<Scalars['Boolean']>;
  bcamText?: InputMaybe<Scalars['String']>;
  bcamText_contains?: InputMaybe<Scalars['String']>;
  bcamText_exists?: InputMaybe<Scalars['Boolean']>;
  bcamText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bcamText_not?: InputMaybe<Scalars['String']>;
  bcamText_not_contains?: InputMaybe<Scalars['String']>;
  bcamText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  downloadBcamSoftwareLink_exists?: InputMaybe<Scalars['Boolean']>;
  downloadMtaSpecLink_exists?: InputMaybe<Scalars['Boolean']>;
  downloadProcessGuideLink_exists?: InputMaybe<Scalars['Boolean']>;
  modernizationProcessDiagram_exists?: InputMaybe<Scalars['Boolean']>;
  modernizationProcessHeader?: InputMaybe<Scalars['String']>;
  modernizationProcessHeader_contains?: InputMaybe<Scalars['String']>;
  modernizationProcessHeader_exists?: InputMaybe<Scalars['Boolean']>;
  modernizationProcessHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  modernizationProcessHeader_not?: InputMaybe<Scalars['String']>;
  modernizationProcessHeader_not_contains?: InputMaybe<Scalars['String']>;
  modernizationProcessHeader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  modernizationProcessIcon_exists?: InputMaybe<Scalars['Boolean']>;
  modernizationProcessText?: InputMaybe<Scalars['String']>;
  modernizationProcessText_contains?: InputMaybe<Scalars['String']>;
  modernizationProcessText_exists?: InputMaybe<Scalars['Boolean']>;
  modernizationProcessText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  modernizationProcessText_not?: InputMaybe<Scalars['String']>;
  modernizationProcessText_not_contains?: InputMaybe<Scalars['String']>;
  modernizationProcessText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  modernizationQuickGuidesHeader?: InputMaybe<Scalars['String']>;
  modernizationQuickGuidesHeader_contains?: InputMaybe<Scalars['String']>;
  modernizationQuickGuidesHeader_exists?: InputMaybe<Scalars['Boolean']>;
  modernizationQuickGuidesHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  modernizationQuickGuidesHeader_not?: InputMaybe<Scalars['String']>;
  modernizationQuickGuidesHeader_not_contains?: InputMaybe<Scalars['String']>;
  modernizationQuickGuidesHeader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  modernizationQuickGuidesText?: InputMaybe<Scalars['String']>;
  modernizationQuickGuidesText_contains?: InputMaybe<Scalars['String']>;
  modernizationQuickGuidesText_exists?: InputMaybe<Scalars['Boolean']>;
  modernizationQuickGuidesText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  modernizationQuickGuidesText_not?: InputMaybe<Scalars['String']>;
  modernizationQuickGuidesText_not_contains?: InputMaybe<Scalars['String']>;
  modernizationQuickGuidesText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mtaIcon_exists?: InputMaybe<Scalars['Boolean']>;
  overviewHeader?: InputMaybe<Scalars['String']>;
  overviewHeader_contains?: InputMaybe<Scalars['String']>;
  overviewHeader_exists?: InputMaybe<Scalars['Boolean']>;
  overviewHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  overviewHeader_not?: InputMaybe<Scalars['String']>;
  overviewHeader_not_contains?: InputMaybe<Scalars['String']>;
  overviewHeader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  overviewIcon_exists?: InputMaybe<Scalars['Boolean']>;
  overviewImage_exists?: InputMaybe<Scalars['Boolean']>;
  overviewText?: InputMaybe<Scalars['String']>;
  overviewText_contains?: InputMaybe<Scalars['String']>;
  overviewText_exists?: InputMaybe<Scalars['Boolean']>;
  overviewText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  overviewText_not?: InputMaybe<Scalars['String']>;
  overviewText_not_contains?: InputMaybe<Scalars['String']>;
  overviewText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  tpieHeader?: InputMaybe<Scalars['String']>;
  tpieHeader_contains?: InputMaybe<Scalars['String']>;
  tpieHeader_exists?: InputMaybe<Scalars['Boolean']>;
  tpieHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tpieHeader_not?: InputMaybe<Scalars['String']>;
  tpieHeader_not_contains?: InputMaybe<Scalars['String']>;
  tpieHeader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tpieText?: InputMaybe<Scalars['String']>;
  tpieText_contains?: InputMaybe<Scalars['String']>;
  tpieText_exists?: InputMaybe<Scalars['Boolean']>;
  tpieText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tpieText_not?: InputMaybe<Scalars['String']>;
  tpieText_not_contains?: InputMaybe<Scalars['String']>;
  tpieText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  viewBcamListLink_exists?: InputMaybe<Scalars['Boolean']>;
  viewMtaListLink_exists?: InputMaybe<Scalars['Boolean']>;
};

export type NuclearPlantModernizationLandingPageLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type NuclearPlantModernizationLandingPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum NuclearPlantModernizationLandingPageOrder {
  BcamHeaderAsc = 'bcamHeader_ASC',
  BcamHeaderDesc = 'bcamHeader_DESC',
  ModernizationProcessHeaderAsc = 'modernizationProcessHeader_ASC',
  ModernizationProcessHeaderDesc = 'modernizationProcessHeader_DESC',
  ModernizationQuickGuidesHeaderAsc = 'modernizationQuickGuidesHeader_ASC',
  ModernizationQuickGuidesHeaderDesc = 'modernizationQuickGuidesHeader_DESC',
  OverviewHeaderAsc = 'overviewHeader_ASC',
  OverviewHeaderDesc = 'overviewHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TpieHeaderAsc = 'tpieHeader_ASC',
  TpieHeaderDesc = 'tpieHeader_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationSweepScoreCategory) */
export type NuclearPlantModernizationSweepScoreCategory = Entry & {
  categoryDescription?: Maybe<Scalars['String']>;
  categoryLevel0?: Maybe<Scalars['String']>;
  categoryLevel1?: Maybe<Scalars['String']>;
  categoryLevel2?: Maybe<Scalars['String']>;
  categoryLevel3?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  levels?: Maybe<Array<Maybe<Scalars['String']>>>;
  linkedFrom?: Maybe<NuclearPlantModernizationSweepScoreCategoryLinkingCollections>;
  sys: Sys;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationSweepScoreCategory) */
export type NuclearPlantModernizationSweepScoreCategoryCategoryDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationSweepScoreCategory) */
export type NuclearPlantModernizationSweepScoreCategoryCategoryLevel0Args = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationSweepScoreCategory) */
export type NuclearPlantModernizationSweepScoreCategoryCategoryLevel1Args = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationSweepScoreCategory) */
export type NuclearPlantModernizationSweepScoreCategoryCategoryLevel2Args = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationSweepScoreCategory) */
export type NuclearPlantModernizationSweepScoreCategoryCategoryLevel3Args = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationSweepScoreCategory) */
export type NuclearPlantModernizationSweepScoreCategoryCategoryNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationSweepScoreCategory) */
export type NuclearPlantModernizationSweepScoreCategoryLevelsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationSweepScoreCategory) */
export type NuclearPlantModernizationSweepScoreCategoryLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type NuclearPlantModernizationSweepScoreCategoryCollection = {
  items: Array<Maybe<NuclearPlantModernizationSweepScoreCategory>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type NuclearPlantModernizationSweepScoreCategoryFilter = {
  AND?: InputMaybe<Array<InputMaybe<NuclearPlantModernizationSweepScoreCategoryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<NuclearPlantModernizationSweepScoreCategoryFilter>>>;
  categoryDescription?: InputMaybe<Scalars['String']>;
  categoryDescription_contains?: InputMaybe<Scalars['String']>;
  categoryDescription_exists?: InputMaybe<Scalars['Boolean']>;
  categoryDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  categoryDescription_not?: InputMaybe<Scalars['String']>;
  categoryDescription_not_contains?: InputMaybe<Scalars['String']>;
  categoryDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  categoryLevel0?: InputMaybe<Scalars['String']>;
  categoryLevel0_contains?: InputMaybe<Scalars['String']>;
  categoryLevel0_exists?: InputMaybe<Scalars['Boolean']>;
  categoryLevel0_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  categoryLevel0_not?: InputMaybe<Scalars['String']>;
  categoryLevel0_not_contains?: InputMaybe<Scalars['String']>;
  categoryLevel0_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  categoryLevel1?: InputMaybe<Scalars['String']>;
  categoryLevel1_contains?: InputMaybe<Scalars['String']>;
  categoryLevel1_exists?: InputMaybe<Scalars['Boolean']>;
  categoryLevel1_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  categoryLevel1_not?: InputMaybe<Scalars['String']>;
  categoryLevel1_not_contains?: InputMaybe<Scalars['String']>;
  categoryLevel1_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  categoryLevel2?: InputMaybe<Scalars['String']>;
  categoryLevel2_contains?: InputMaybe<Scalars['String']>;
  categoryLevel2_exists?: InputMaybe<Scalars['Boolean']>;
  categoryLevel2_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  categoryLevel2_not?: InputMaybe<Scalars['String']>;
  categoryLevel2_not_contains?: InputMaybe<Scalars['String']>;
  categoryLevel2_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  categoryLevel3?: InputMaybe<Scalars['String']>;
  categoryLevel3_contains?: InputMaybe<Scalars['String']>;
  categoryLevel3_exists?: InputMaybe<Scalars['Boolean']>;
  categoryLevel3_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  categoryLevel3_not?: InputMaybe<Scalars['String']>;
  categoryLevel3_not_contains?: InputMaybe<Scalars['String']>;
  categoryLevel3_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  categoryName?: InputMaybe<Scalars['String']>;
  categoryName_contains?: InputMaybe<Scalars['String']>;
  categoryName_exists?: InputMaybe<Scalars['Boolean']>;
  categoryName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  categoryName_not?: InputMaybe<Scalars['String']>;
  categoryName_not_contains?: InputMaybe<Scalars['String']>;
  categoryName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  levels_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  levels_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  levels_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  levels_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type NuclearPlantModernizationSweepScoreCategoryLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  nuclearPlantModernizationSweepScoreLegendCollection?: Maybe<NuclearPlantModernizationSweepScoreLegendCollection>;
};


export type NuclearPlantModernizationSweepScoreCategoryLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type NuclearPlantModernizationSweepScoreCategoryLinkingCollectionsNuclearPlantModernizationSweepScoreLegendCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum NuclearPlantModernizationSweepScoreCategoryOrder {
  CategoryDescriptionAsc = 'categoryDescription_ASC',
  CategoryDescriptionDesc = 'categoryDescription_DESC',
  CategoryLevel0Asc = 'categoryLevel0_ASC',
  CategoryLevel0Desc = 'categoryLevel0_DESC',
  CategoryLevel1Asc = 'categoryLevel1_ASC',
  CategoryLevel1Desc = 'categoryLevel1_DESC',
  CategoryLevel2Asc = 'categoryLevel2_ASC',
  CategoryLevel2Desc = 'categoryLevel2_DESC',
  CategoryLevel3Asc = 'categoryLevel3_ASC',
  CategoryLevel3Desc = 'categoryLevel3_DESC',
  CategoryNameAsc = 'categoryName_ASC',
  CategoryNameDesc = 'categoryName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationSweepScoreLegend) */
export type NuclearPlantModernizationSweepScoreLegend = Entry & {
  categoriesCollection?: Maybe<NuclearPlantModernizationSweepScoreLegendCategoriesCollection>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<NuclearPlantModernizationSweepScoreLegendLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationSweepScoreLegend) */
export type NuclearPlantModernizationSweepScoreLegendCategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationSweepScoreLegend) */
export type NuclearPlantModernizationSweepScoreLegendLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/nuclearPlantModernizationSweepScoreLegend) */
export type NuclearPlantModernizationSweepScoreLegendTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type NuclearPlantModernizationSweepScoreLegendCategoriesCollection = {
  items: Array<Maybe<NuclearPlantModernizationSweepScoreCategory>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type NuclearPlantModernizationSweepScoreLegendCollection = {
  items: Array<Maybe<NuclearPlantModernizationSweepScoreLegend>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type NuclearPlantModernizationSweepScoreLegendFilter = {
  AND?: InputMaybe<Array<InputMaybe<NuclearPlantModernizationSweepScoreLegendFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<NuclearPlantModernizationSweepScoreLegendFilter>>>;
  categoriesCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type NuclearPlantModernizationSweepScoreLegendLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type NuclearPlantModernizationSweepScoreLegendLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum NuclearPlantModernizationSweepScoreLegendOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type OpenInvoice = {
  amount?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['String']>;
  companyGroup?: Maybe<Scalars['String']>;
  companyGroupName?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contractOrSalesOrder?: Maybe<Scalars['String']>;
  customerReference?: Maybe<Scalars['String']>;
  dateLoaded?: Maybe<Scalars['String']>;
  daysPastDue?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fiscalPeriod?: Maybe<Scalars['String']>;
  fiscalYear?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  kathyCol?: Maybe<Scalars['String']>;
  netDueDate?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  salesDoc?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  transactionNo?: Maybe<Scalars['String']>;
  transactionType?: Maybe<Scalars['String']>;
};

export type OpenInvoicesResponse = {
  netOpenInvoices?: Maybe<Scalars['Float']>;
  openInvoices?: Maybe<Array<Maybe<OpenInvoice>>>;
  totalOpenInvoices?: Maybe<Scalars['Int']>;
};

export enum OrderAction {
  Add = 'Add',
  Remove = 'Remove',
  Update = 'Update'
}

export type OrderCart = {
  clickThruLicenseAccepted?: Maybe<Scalars['Boolean']>;
  hasAbstract?: Maybe<Scalars['Boolean']>;
  isOrdered?: Maybe<Scalars['Boolean']>;
  isPhysicalCopy?: Maybe<Scalars['Int']>;
  longDescription?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['Int']>;
  panel?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productPrice?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  shortProductId?: Maybe<Scalars['String']>;
  showClickThruAgreement?: Maybe<Scalars['Boolean']>;
  showPart810Agreement?: Maybe<Scalars['Boolean']>;
  submitDate?: Maybe<Scalars['String']>;
};

export type OrderInput = {
  action: OrderAction;
  deliveryNotes?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Array<InputMaybe<OrderItemInput>>>;
};

export type OrderItemInput = {
  isPhysicalCopy?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

export type OrderResponse = {
  downloadableProducts?: Maybe<Array<Maybe<OrderCart>>>;
  enableOrder?: Maybe<Scalars['Boolean']>;
  orderOnlyProducts?: Maybe<Array<Maybe<OrderCart>>>;
  purchaseOnlyProducts?: Maybe<Array<Maybe<OrderCart>>>;
  showPart810Agreement?: Maybe<Scalars['Boolean']>;
  totalPrice?: Maybe<Scalars['Float']>;
  totalQuantity?: Maybe<Scalars['Int']>;
};

export type Pset = {
  projects?: Maybe<Array<Maybe<Site>>>;
};

/**
 * Represents a web page in Compose. DO NOT DELETE [See type
 * definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/page)
 */
export type Page = Entry & {
  content?: Maybe<PageContent>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<PageLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  seo?: Maybe<Seo>;
  slug?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/**
 * Represents a web page in Compose. DO NOT DELETE [See type
 * definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/page)
 */
export type PageContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/**
 * Represents a web page in Compose. DO NOT DELETE [See type
 * definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/page)
 */
export type PageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/**
 * Represents a web page in Compose. DO NOT DELETE [See type
 * definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/page)
 */
export type PageNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Represents a web page in Compose. DO NOT DELETE [See type
 * definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/page)
 */
export type PageSeoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/**
 * Represents a web page in Compose. DO NOT DELETE [See type
 * definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/page)
 */
export type PageSlugArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Represents a web page in Compose. DO NOT DELETE [See type
 * definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/page)
 */
export type PageTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type PageCollection = {
  items: Array<Maybe<Page>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PageContent = PagePreConfiguredTemplates;

export type PageFilter = {
  AND?: InputMaybe<Array<InputMaybe<PageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PageFilter>>>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seo?: InputMaybe<CfSeoNestedFilter>;
  seo_exists?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  slug_contains?: InputMaybe<Scalars['String']>;
  slug_exists?: InputMaybe<Scalars['Boolean']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not?: InputMaybe<Scalars['String']>;
  slug_not_contains?: InputMaybe<Scalars['String']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PageLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type PageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum PageOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Fixed templates self authoring (v2 using compose + launch) [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/pagePreConfiguredTemplates) */
export type PagePreConfiguredTemplates = Entry & {
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<PagePreConfiguredTemplatesLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  sys: Sys;
  templateDataSource?: Maybe<PagePreConfiguredTemplatesTemplateDataSource>;
};


/** Fixed templates self authoring (v2 using compose + launch) [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/pagePreConfiguredTemplates) */
export type PagePreConfiguredTemplatesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Fixed templates self authoring (v2 using compose + launch) [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/pagePreConfiguredTemplates) */
export type PagePreConfiguredTemplatesNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Fixed templates self authoring (v2 using compose + launch) [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/pagePreConfiguredTemplates) */
export type PagePreConfiguredTemplatesTemplateDataSourceArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type PagePreConfiguredTemplatesCollection = {
  items: Array<Maybe<PagePreConfiguredTemplates>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PagePreConfiguredTemplatesFilter = {
  AND?: InputMaybe<Array<InputMaybe<PagePreConfiguredTemplatesFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PagePreConfiguredTemplatesFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  templateDataSource_exists?: InputMaybe<Scalars['Boolean']>;
};

export type PagePreConfiguredTemplatesLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  pageCollection?: Maybe<PageCollection>;
};


export type PagePreConfiguredTemplatesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type PagePreConfiguredTemplatesLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum PagePreConfiguredTemplatesOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type PagePreConfiguredTemplatesTemplateDataSource = AiTemplatePage | BasicTemplate | CardTemplate | MultimediaTemplate | SingleVideoTemplate | ThoughtLeadership | TwoColumnTemplate;

/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/part810Agreement) */
export type Part810Agreement = Entry & {
  contentfulMetadata: ContentfulMetadata;
  itemsCollection?: Maybe<Part810AgreementItemsCollection>;
  linkedFrom?: Maybe<Part810AgreementLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/part810Agreement) */
export type Part810AgreementItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/part810Agreement) */
export type Part810AgreementLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/part810Agreement) */
export type Part810AgreementTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type Part810AgreementCollection = {
  items: Array<Maybe<Part810Agreement>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Part810AgreementFilter = {
  AND?: InputMaybe<Array<InputMaybe<Part810AgreementFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Part810AgreementFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  itemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/part810AgreementItem) */
export type Part810AgreementItem = Entry & {
  agreementText?: Maybe<Part810AgreementItemAgreementText>;
  body?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<Part810AgreementItemLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/part810AgreementItem) */
export type Part810AgreementItemAgreementTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/part810AgreementItem) */
export type Part810AgreementItemBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/part810AgreementItem) */
export type Part810AgreementItemLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/part810AgreementItem) */
export type Part810AgreementItemTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type Part810AgreementItemAgreementText = {
  json: Scalars['JSON'];
  links: Part810AgreementItemAgreementTextLinks;
};

export type Part810AgreementItemAgreementTextAssets = {
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type Part810AgreementItemAgreementTextEntries = {
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type Part810AgreementItemAgreementTextLinks = {
  assets: Part810AgreementItemAgreementTextAssets;
  entries: Part810AgreementItemAgreementTextEntries;
};

export type Part810AgreementItemCollection = {
  items: Array<Maybe<Part810AgreementItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Part810AgreementItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<Part810AgreementItemFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Part810AgreementItemFilter>>>;
  agreementText_contains?: InputMaybe<Scalars['String']>;
  agreementText_exists?: InputMaybe<Scalars['Boolean']>;
  agreementText_not_contains?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Part810AgreementItemLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  part810AgreementCollection?: Maybe<Part810AgreementCollection>;
};


export type Part810AgreementItemLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type Part810AgreementItemLinkingCollectionsPart810AgreementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum Part810AgreementItemOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type Part810AgreementItemsCollection = {
  items: Array<Maybe<Part810AgreementItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Part810AgreementLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type Part810AgreementLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum Part810AgreementOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type ParticipantDetail = {
  addressId?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  businessFax?: Maybe<Scalars['String']>;
  businessPhone?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyGroup?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactUserName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  declineToDisplay?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  enrollmentDate?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  hasAttendedEvents?: Maybe<Scalars['Boolean']>;
  hasDownloads?: Maybe<Scalars['Boolean']>;
  hasFavoritesSubscriptions?: Maybe<Scalars['Boolean']>;
  isMarkedForRemoval?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  lastlogin?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  modifiedDate?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  webIdStatus?: Maybe<Scalars['String']>;
};

export type ParticipantDetailResponse = {
  details?: Maybe<Array<Maybe<ParticipantDetail>>>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export type ParticipantSummary = {
  attendedEvent?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  downloadedProducts?: Maybe<Scalars['Int']>;
  enrollmentCount?: Maybe<Scalars['Int']>;
  hasWebID?: Maybe<Scalars['Int']>;
  isMettOrAdvisor?: Maybe<Scalars['Int']>;
  subscriptionsOrFavorites?: Maybe<Scalars['Int']>;
  totalContacts?: Maybe<Scalars['Int']>;
  webIDactive?: Maybe<Scalars['Int']>;
  webIDinactive?: Maybe<Scalars['Int']>;
};

export type PastUpdates = {
  displayName?: Maybe<Scalars['String']>;
  ltn?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['String']>;
  monthName?: Maybe<Scalars['String']>;
  pdfUrl?: Maybe<Scalars['String']>;
  publishedDate?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type PeopleDirectory = {
  contacts?: Maybe<Array<Maybe<Contact>>>;
  totalRecords?: Maybe<Scalars['Int']>;
};

/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/person) */
export type Person = Entry & {
  address1?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contactData?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['Int']>;
  contentfulMetadata: ContentfulMetadata;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expertise?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  image?: Maybe<Asset>;
  lastName?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<PersonLinkingCollections>;
  middleName?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/person) */
export type PersonAddress1Args = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/person) */
export type PersonBioArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/person) */
export type PersonCityArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/person) */
export type PersonContactDataArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/person) */
export type PersonContactIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/person) */
export type PersonCountryArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/person) */
export type PersonEmailArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/person) */
export type PersonExpertiseArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/person) */
export type PersonFirstNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/person) */
export type PersonImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/person) */
export type PersonLastNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/person) */
export type PersonLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/person) */
export type PersonMiddleNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/person) */
export type PersonMobilePhoneArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/person) */
export type PersonNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/person) */
export type PersonPhoneNumberArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/person) */
export type PersonStateArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/person) */
export type PersonTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/person) */
export type PersonUserNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/person) */
export type PersonZipcodeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type PersonCollection = {
  items: Array<Maybe<Person>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PersonFilter = {
  AND?: InputMaybe<Array<InputMaybe<PersonFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PersonFilter>>>;
  address1?: InputMaybe<Scalars['String']>;
  address1_contains?: InputMaybe<Scalars['String']>;
  address1_exists?: InputMaybe<Scalars['Boolean']>;
  address1_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  address1_not?: InputMaybe<Scalars['String']>;
  address1_not_contains?: InputMaybe<Scalars['String']>;
  address1_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bio?: InputMaybe<Scalars['String']>;
  bio_contains?: InputMaybe<Scalars['String']>;
  bio_exists?: InputMaybe<Scalars['Boolean']>;
  bio_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bio_not?: InputMaybe<Scalars['String']>;
  bio_not_contains?: InputMaybe<Scalars['String']>;
  bio_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  city?: InputMaybe<Scalars['String']>;
  city_contains?: InputMaybe<Scalars['String']>;
  city_exists?: InputMaybe<Scalars['Boolean']>;
  city_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  city_not?: InputMaybe<Scalars['String']>;
  city_not_contains?: InputMaybe<Scalars['String']>;
  city_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contactData?: InputMaybe<Scalars['String']>;
  contactData_contains?: InputMaybe<Scalars['String']>;
  contactData_exists?: InputMaybe<Scalars['Boolean']>;
  contactData_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contactData_not?: InputMaybe<Scalars['String']>;
  contactData_not_contains?: InputMaybe<Scalars['String']>;
  contactData_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contactId?: InputMaybe<Scalars['Int']>;
  contactId_exists?: InputMaybe<Scalars['Boolean']>;
  contactId_gt?: InputMaybe<Scalars['Int']>;
  contactId_gte?: InputMaybe<Scalars['Int']>;
  contactId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  contactId_lt?: InputMaybe<Scalars['Int']>;
  contactId_lte?: InputMaybe<Scalars['Int']>;
  contactId_not?: InputMaybe<Scalars['Int']>;
  contactId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  country?: InputMaybe<Scalars['String']>;
  country_contains?: InputMaybe<Scalars['String']>;
  country_exists?: InputMaybe<Scalars['Boolean']>;
  country_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  country_not?: InputMaybe<Scalars['String']>;
  country_not_contains?: InputMaybe<Scalars['String']>;
  country_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  email?: InputMaybe<Scalars['String']>;
  email_contains?: InputMaybe<Scalars['String']>;
  email_exists?: InputMaybe<Scalars['Boolean']>;
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  email_not?: InputMaybe<Scalars['String']>;
  email_not_contains?: InputMaybe<Scalars['String']>;
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  expertise?: InputMaybe<Scalars['String']>;
  expertise_contains?: InputMaybe<Scalars['String']>;
  expertise_exists?: InputMaybe<Scalars['Boolean']>;
  expertise_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  expertise_not?: InputMaybe<Scalars['String']>;
  expertise_not_contains?: InputMaybe<Scalars['String']>;
  expertise_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  firstName?: InputMaybe<Scalars['String']>;
  firstName_contains?: InputMaybe<Scalars['String']>;
  firstName_exists?: InputMaybe<Scalars['Boolean']>;
  firstName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  firstName_not?: InputMaybe<Scalars['String']>;
  firstName_not_contains?: InputMaybe<Scalars['String']>;
  firstName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastName_contains?: InputMaybe<Scalars['String']>;
  lastName_exists?: InputMaybe<Scalars['Boolean']>;
  lastName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lastName_not?: InputMaybe<Scalars['String']>;
  lastName_not_contains?: InputMaybe<Scalars['String']>;
  lastName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  middleName?: InputMaybe<Scalars['String']>;
  middleName_contains?: InputMaybe<Scalars['String']>;
  middleName_exists?: InputMaybe<Scalars['Boolean']>;
  middleName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  middleName_not?: InputMaybe<Scalars['String']>;
  middleName_not_contains?: InputMaybe<Scalars['String']>;
  middleName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  mobilePhone_contains?: InputMaybe<Scalars['String']>;
  mobilePhone_exists?: InputMaybe<Scalars['Boolean']>;
  mobilePhone_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mobilePhone_not?: InputMaybe<Scalars['String']>;
  mobilePhone_not_contains?: InputMaybe<Scalars['String']>;
  mobilePhone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneNumber_contains?: InputMaybe<Scalars['String']>;
  phoneNumber_exists?: InputMaybe<Scalars['Boolean']>;
  phoneNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  phoneNumber_not?: InputMaybe<Scalars['String']>;
  phoneNumber_not_contains?: InputMaybe<Scalars['String']>;
  phoneNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  state?: InputMaybe<Scalars['String']>;
  state_contains?: InputMaybe<Scalars['String']>;
  state_exists?: InputMaybe<Scalars['Boolean']>;
  state_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  state_not?: InputMaybe<Scalars['String']>;
  state_not_contains?: InputMaybe<Scalars['String']>;
  state_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userName?: InputMaybe<Scalars['String']>;
  userName_contains?: InputMaybe<Scalars['String']>;
  userName_exists?: InputMaybe<Scalars['Boolean']>;
  userName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userName_not?: InputMaybe<Scalars['String']>;
  userName_not_contains?: InputMaybe<Scalars['String']>;
  userName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  zipcode?: InputMaybe<Scalars['String']>;
  zipcode_contains?: InputMaybe<Scalars['String']>;
  zipcode_exists?: InputMaybe<Scalars['Boolean']>;
  zipcode_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  zipcode_not?: InputMaybe<Scalars['String']>;
  zipcode_not_contains?: InputMaybe<Scalars['String']>;
  zipcode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PersonLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  programContactsCollection?: Maybe<ProgramContactsCollection>;
};


export type PersonLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type PersonLinkingCollectionsProgramContactsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum PersonOrder {
  Address1Asc = 'address1_ASC',
  Address1Desc = 'address1_DESC',
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  ContactDataAsc = 'contactData_ASC',
  ContactDataDesc = 'contactData_DESC',
  ContactIdAsc = 'contactId_ASC',
  ContactIdDesc = 'contactId_DESC',
  CountryAsc = 'country_ASC',
  CountryDesc = 'country_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  MiddleNameAsc = 'middleName_ASC',
  MiddleNameDesc = 'middleName_DESC',
  MobilePhoneAsc = 'mobilePhone_ASC',
  MobilePhoneDesc = 'mobilePhone_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PhoneNumberAsc = 'phoneNumber_ASC',
  PhoneNumberDesc = 'phoneNumber_DESC',
  StateAsc = 'state_ASC',
  StateDesc = 'state_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UserNameAsc = 'userName_ASC',
  UserNameDesc = 'userName_DESC',
  ZipcodeAsc = 'zipcode_ASC',
  ZipcodeDesc = 'zipcode_DESC'
}

export type PhoneFax = {
  countryCode?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
};

export type Plant = {
  plantCompanyId?: Maybe<Scalars['String']>;
  plantCompanyName?: Maybe<Scalars['String']>;
  plantDescription?: Maybe<Scalars['String']>;
  plantID?: Maybe<Scalars['String']>;
  plantName?: Maybe<Scalars['String']>;
  plantStatus?: Maybe<Scalars['String']>;
};

export type PlantModernizationModel = {
    areasForSavings?: Maybe<Scalars['String']>;
    asbtractLink?: Maybe<Scalars['String']>;
    avoidedCosts?: Maybe<Scalars['String']>;
    canReduceCost?: Maybe<Scalars['Boolean']>;
    description?: Maybe<Scalars['String']>;
    functionalArea?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    npv?: Maybe<Scalars['String']>;
    npvHidden?: Maybe<Scalars['String']>;
    primaryCost?: Maybe<Scalars['String']>;
    productId?: Maybe<Scalars['String']>;
    publishedDate?: Maybe<Scalars['String']>;
    relatedMTA?: Maybe<Scalars['String']>;
    shortProductId?: Maybe<Scalars['String']>;
};

export type PlantModernizationModelResponse = {
  models?: Maybe<Array<Maybe<PlantModernizationModel>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PortfolioInput = {
  backgroundImageUrl?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  levelName?: InputMaybe<Scalars['String']>;
  levelNumber?: InputMaybe<Scalars['Int']>;
  ltn?: InputMaybe<Scalars['String']>;
  overview?: InputMaybe<Scalars['String']>;
  parentLTN?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
};

export type PortfolioProgram = {
  accomplishments?: Maybe<Scalars['String']>;
  approach?: Maybe<Scalars['String']>;
  currentYearActivities?: Maybe<Scalars['String']>;
  estimatedFunding?: Maybe<Scalars['String']>;
  fullOverviewsURL?: Maybe<Scalars['String']>;
  hasSupplementalProjects?: Maybe<Scalars['Boolean']>;
  lastUpdated?: Maybe<Scalars['String']>;
  levelNumber?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  ltn?: Maybe<Scalars['String']>;
  managerEmail?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
  managerPhone?: Maybe<Scalars['String']>;
  marketValue?: Maybe<Scalars['String']>;
  portfolioYear?: Maybe<Scalars['Int']>;
  programExistsMCYear?: Maybe<Scalars['Boolean']>;
  publicBenefit?: Maybe<Scalars['String']>;
  researchAreaLTN?: Maybe<Scalars['String']>;
  researchAreaName?: Maybe<Scalars['String']>;
  researchProjects?: Maybe<Array<Maybe<PortfolioResearchProject>>>;
  researchValue?: Maybe<Scalars['String']>;
  sectorLTN?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  siteNumber?: Maybe<Scalars['String']>;
  siteType?: Maybe<Scalars['String']>;
  supplementals?: Maybe<Array<Maybe<PortfolioSupplementalProject>>>;
  title?: Maybe<Scalars['String']>;
};

export type PortfolioProgramSupplemental = {
  ltn?: Maybe<Scalars['String']>;
  portfolioYear?: Maybe<Scalars['Int']>;
  sectorLTN?: Maybe<Scalars['String']>;
  siteNumber?: Maybe<Scalars['String']>;
  supplementalProjects?: Maybe<Array<Maybe<PortfolioSupplementalProject>>>;
  title?: Maybe<Scalars['String']>;
};

export type PortfolioResearchArea = {
  levelNumber?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  ltn?: Maybe<Scalars['String']>;
  marketValue?: Maybe<Scalars['String']>;
  portfolioYear?: Maybe<Scalars['Int']>;
  programs?: Maybe<Array<Maybe<PortfolioProgram>>>;
  sectorLTN?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  siteNumber?: Maybe<Scalars['String']>;
  siteType?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type PortfolioResearchProject = {
  isPortfolioPublished?: Maybe<Scalars['Boolean']>;
  keyResearchQuestions?: Maybe<Scalars['String']>;
  lastPublishedDate?: Maybe<Scalars['String']>;
  lastSavedDate?: Maybe<Scalars['String']>;
  lastUpdated?: Maybe<Scalars['String']>;
  levelNumber?: Maybe<Scalars['String']>;
  ltn?: Maybe<Scalars['String']>;
  parentLTN?: Maybe<Scalars['String']>;
  portfolioYear?: Maybe<Scalars['Int']>;
  researchProjects?: Maybe<Array<Maybe<PortfolioResearchProject>>>;
  shortOverview?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/portfolioSection) */
export type PortfolioSection = Entry & {
  body?: Maybe<Scalars['String']>;
  buttonLink?: Maybe<Scalars['String']>;
  buttonLinkBeta?: Maybe<Scalars['String']>;
  buttonLinkBetaExternal?: Maybe<Scalars['String']>;
  buttonLinkBetaUseInternal?: Maybe<Scalars['Boolean']>;
  buttonText?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  image?: Maybe<Asset>;
  linkedFrom?: Maybe<PortfolioSectionLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/portfolioSection) */
export type PortfolioSectionBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/portfolioSection) */
export type PortfolioSectionButtonLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/portfolioSection) */
export type PortfolioSectionButtonLinkBetaArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/portfolioSection) */
export type PortfolioSectionButtonLinkBetaExternalArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/portfolioSection) */
export type PortfolioSectionButtonLinkBetaUseInternalArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/portfolioSection) */
export type PortfolioSectionButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/portfolioSection) */
export type PortfolioSectionImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/portfolioSection) */
export type PortfolioSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/portfolioSection) */
export type PortfolioSectionTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type PortfolioSectionCollection = {
  items: Array<Maybe<PortfolioSection>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PortfolioSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<PortfolioSectionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PortfolioSectionFilter>>>;
  body?: InputMaybe<Scalars['String']>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink?: InputMaybe<Scalars['String']>;
  buttonLinkBeta?: InputMaybe<Scalars['String']>;
  buttonLinkBetaExternal?: InputMaybe<Scalars['String']>;
  buttonLinkBetaExternal_contains?: InputMaybe<Scalars['String']>;
  buttonLinkBetaExternal_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLinkBetaExternal_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLinkBetaExternal_not?: InputMaybe<Scalars['String']>;
  buttonLinkBetaExternal_not_contains?: InputMaybe<Scalars['String']>;
  buttonLinkBetaExternal_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLinkBetaUseInternal?: InputMaybe<Scalars['Boolean']>;
  buttonLinkBetaUseInternal_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLinkBetaUseInternal_not?: InputMaybe<Scalars['Boolean']>;
  buttonLinkBeta_contains?: InputMaybe<Scalars['String']>;
  buttonLinkBeta_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLinkBeta_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLinkBeta_not?: InputMaybe<Scalars['String']>;
  buttonLinkBeta_not_contains?: InputMaybe<Scalars['String']>;
  buttonLinkBeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink_contains?: InputMaybe<Scalars['String']>;
  buttonLink_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink_not?: InputMaybe<Scalars['String']>;
  buttonLink_not_contains?: InputMaybe<Scalars['String']>;
  buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonText_contains?: InputMaybe<Scalars['String']>;
  buttonText_exists?: InputMaybe<Scalars['Boolean']>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText_not?: InputMaybe<Scalars['String']>;
  buttonText_not_contains?: InputMaybe<Scalars['String']>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PortfolioSectionLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  homeCollection?: Maybe<HomeCollection>;
  researchCollection?: Maybe<ResearchCollection>;
};


export type PortfolioSectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type PortfolioSectionLinkingCollectionsHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type PortfolioSectionLinkingCollectionsResearchCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum PortfolioSectionOrder {
  ButtonLinkBetaExternalAsc = 'buttonLinkBetaExternal_ASC',
  ButtonLinkBetaExternalDesc = 'buttonLinkBetaExternal_DESC',
  ButtonLinkBetaUseInternalAsc = 'buttonLinkBetaUseInternal_ASC',
  ButtonLinkBetaUseInternalDesc = 'buttonLinkBetaUseInternal_DESC',
  ButtonLinkBetaAsc = 'buttonLinkBeta_ASC',
  ButtonLinkBetaDesc = 'buttonLinkBeta_DESC',
  ButtonLinkAsc = 'buttonLink_ASC',
  ButtonLinkDesc = 'buttonLink_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type PortfolioSector = {
  backgroundImageUrl?: Maybe<Scalars['String']>;
  hasResearchAreas?: Maybe<Scalars['Boolean']>;
  hasSupplementals?: Maybe<Scalars['Boolean']>;
  imageUrl?: Maybe<Scalars['String']>;
  ltn?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  portfolioYear?: Maybe<Scalars['Int']>;
  researchAreas?: Maybe<Array<Maybe<PortfolioResearchArea>>>;
  siteNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** This model if for the Sector overview pages [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/portfolioSectors) */
export type PortfolioSectors = Entry & {
  backgroundImage?: Maybe<Asset>;
  contentfulMetadata: ContentfulMetadata;
  featuredArticle?: Maybe<Jumbotron>;
  linkedFrom?: Maybe<PortfolioSectorsLinkingCollections>;
  linksCollection?: Maybe<PortfolioSectorsLinksCollection>;
  overview?: Maybe<Scalars['String']>;
  overviewImage?: Maybe<Asset>;
  portfolioYear?: Maybe<Scalars['String']>;
  promotedContentCollection?: Maybe<PortfolioSectorsPromotedContentCollection>;
  sectors?: Maybe<Scalars['String']>;
  sys: Sys;
};


/** This model if for the Sector overview pages [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/portfolioSectors) */
export type PortfolioSectorsBackgroundImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** This model if for the Sector overview pages [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/portfolioSectors) */
export type PortfolioSectorsFeaturedArticleArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** This model if for the Sector overview pages [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/portfolioSectors) */
export type PortfolioSectorsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** This model if for the Sector overview pages [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/portfolioSectors) */
export type PortfolioSectorsLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** This model if for the Sector overview pages [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/portfolioSectors) */
export type PortfolioSectorsOverviewArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** This model if for the Sector overview pages [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/portfolioSectors) */
export type PortfolioSectorsOverviewImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** This model if for the Sector overview pages [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/portfolioSectors) */
export type PortfolioSectorsPortfolioYearArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** This model if for the Sector overview pages [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/portfolioSectors) */
export type PortfolioSectorsPromotedContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** This model if for the Sector overview pages [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/portfolioSectors) */
export type PortfolioSectorsSectorsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type PortfolioSectorsCollection = {
  items: Array<Maybe<PortfolioSectors>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PortfolioSectorsFilter = {
  AND?: InputMaybe<Array<InputMaybe<PortfolioSectorsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PortfolioSectorsFilter>>>;
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  featuredArticle?: InputMaybe<CfJumbotronNestedFilter>;
  featuredArticle_exists?: InputMaybe<Scalars['Boolean']>;
  linksCollection_exists?: InputMaybe<Scalars['Boolean']>;
  overview?: InputMaybe<Scalars['String']>;
  overviewImage_exists?: InputMaybe<Scalars['Boolean']>;
  overview_contains?: InputMaybe<Scalars['String']>;
  overview_exists?: InputMaybe<Scalars['Boolean']>;
  overview_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  overview_not?: InputMaybe<Scalars['String']>;
  overview_not_contains?: InputMaybe<Scalars['String']>;
  overview_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  portfolioYear?: InputMaybe<Scalars['String']>;
  portfolioYear_contains?: InputMaybe<Scalars['String']>;
  portfolioYear_exists?: InputMaybe<Scalars['Boolean']>;
  portfolioYear_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  portfolioYear_not?: InputMaybe<Scalars['String']>;
  portfolioYear_not_contains?: InputMaybe<Scalars['String']>;
  portfolioYear_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  promotedContentCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sectors?: InputMaybe<Scalars['String']>;
  sectors_contains?: InputMaybe<Scalars['String']>;
  sectors_exists?: InputMaybe<Scalars['Boolean']>;
  sectors_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectors_not?: InputMaybe<Scalars['String']>;
  sectors_not_contains?: InputMaybe<Scalars['String']>;
  sectors_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type PortfolioSectorsLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type PortfolioSectorsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type PortfolioSectorsLinksCollection = {
  items: Array<Maybe<LinkGroup>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum PortfolioSectorsOrder {
  PortfolioYearAsc = 'portfolioYear_ASC',
  PortfolioYearDesc = 'portfolioYear_DESC',
  SectorsAsc = 'sectors_ASC',
  SectorsDesc = 'sectors_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type PortfolioSectorsPromotedContentCollection = {
  items: Array<Maybe<StoryCards>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PortfolioSupplementalProject = {
  cacheCreatedDate?: Maybe<Scalars['String']>;
  displayProductID?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentLTN?: Maybe<Scalars['String']>;
  portfolioYear?: Maybe<Scalars['Int']>;
  productID?: Maybe<Scalars['String']>;
  sectorLTN?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  siteNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/**
 * Includes:
 * General Press Releases
 * AI Press Releases
 * AI Announcements
 * AI Upcoming Events
 * AI Use Cases
 * AI EPRI 10 [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/pressRelease)
 */
export type PressRelease = Entry & {
  body?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  customReadMoreLinkText?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  dateSpan?: Maybe<Scalars['String']>;
  image?: Maybe<Asset>;
  linkedFrom?: Maybe<PressReleaseLinkingCollections>;
  location?: Maybe<Scalars['String']>;
  sourceName?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};


/**
 * Includes:
 * General Press Releases
 * AI Press Releases
 * AI Announcements
 * AI Upcoming Events
 * AI Use Cases
 * AI EPRI 10 [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/pressRelease)
 */
export type PressReleaseBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Includes:
 * General Press Releases
 * AI Press Releases
 * AI Announcements
 * AI Upcoming Events
 * AI Use Cases
 * AI EPRI 10 [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/pressRelease)
 */
export type PressReleaseCustomReadMoreLinkTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Includes:
 * General Press Releases
 * AI Press Releases
 * AI Announcements
 * AI Upcoming Events
 * AI Use Cases
 * AI EPRI 10 [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/pressRelease)
 */
export type PressReleaseDateArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Includes:
 * General Press Releases
 * AI Press Releases
 * AI Announcements
 * AI Upcoming Events
 * AI Use Cases
 * AI EPRI 10 [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/pressRelease)
 */
export type PressReleaseDateSpanArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Includes:
 * General Press Releases
 * AI Press Releases
 * AI Announcements
 * AI Upcoming Events
 * AI Use Cases
 * AI EPRI 10 [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/pressRelease)
 */
export type PressReleaseImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/**
 * Includes:
 * General Press Releases
 * AI Press Releases
 * AI Announcements
 * AI Upcoming Events
 * AI Use Cases
 * AI EPRI 10 [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/pressRelease)
 */
export type PressReleaseLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/**
 * Includes:
 * General Press Releases
 * AI Press Releases
 * AI Announcements
 * AI Upcoming Events
 * AI Use Cases
 * AI EPRI 10 [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/pressRelease)
 */
export type PressReleaseLocationArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Includes:
 * General Press Releases
 * AI Press Releases
 * AI Announcements
 * AI Upcoming Events
 * AI Use Cases
 * AI EPRI 10 [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/pressRelease)
 */
export type PressReleaseSourceNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Includes:
 * General Press Releases
 * AI Press Releases
 * AI Announcements
 * AI Upcoming Events
 * AI Use Cases
 * AI EPRI 10 [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/pressRelease)
 */
export type PressReleaseTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Includes:
 * General Press Releases
 * AI Press Releases
 * AI Announcements
 * AI Upcoming Events
 * AI Use Cases
 * AI EPRI 10 [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/pressRelease)
 */
export type PressReleaseUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type PressReleaseCollection = {
  items: Array<Maybe<PressRelease>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PressReleaseFilter = {
  AND?: InputMaybe<Array<InputMaybe<PressReleaseFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PressReleaseFilter>>>;
  body?: InputMaybe<Scalars['String']>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  customReadMoreLinkText?: InputMaybe<Scalars['String']>;
  customReadMoreLinkText_contains?: InputMaybe<Scalars['String']>;
  customReadMoreLinkText_exists?: InputMaybe<Scalars['Boolean']>;
  customReadMoreLinkText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  customReadMoreLinkText_not?: InputMaybe<Scalars['String']>;
  customReadMoreLinkText_not_contains?: InputMaybe<Scalars['String']>;
  customReadMoreLinkText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  date?: InputMaybe<Scalars['DateTime']>;
  dateSpan?: InputMaybe<Scalars['String']>;
  dateSpan_contains?: InputMaybe<Scalars['String']>;
  dateSpan_exists?: InputMaybe<Scalars['Boolean']>;
  dateSpan_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dateSpan_not?: InputMaybe<Scalars['String']>;
  dateSpan_not_contains?: InputMaybe<Scalars['String']>;
  dateSpan_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  date_exists?: InputMaybe<Scalars['Boolean']>;
  date_gt?: InputMaybe<Scalars['DateTime']>;
  date_gte?: InputMaybe<Scalars['DateTime']>;
  date_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  date_lt?: InputMaybe<Scalars['DateTime']>;
  date_lte?: InputMaybe<Scalars['DateTime']>;
  date_not?: InputMaybe<Scalars['DateTime']>;
  date_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['String']>;
  location_contains?: InputMaybe<Scalars['String']>;
  location_exists?: InputMaybe<Scalars['Boolean']>;
  location_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  location_not?: InputMaybe<Scalars['String']>;
  location_not_contains?: InputMaybe<Scalars['String']>;
  location_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sourceName?: InputMaybe<Scalars['String']>;
  sourceName_contains?: InputMaybe<Scalars['String']>;
  sourceName_exists?: InputMaybe<Scalars['Boolean']>;
  sourceName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sourceName_not?: InputMaybe<Scalars['String']>;
  sourceName_not_contains?: InputMaybe<Scalars['String']>;
  sourceName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/**
 * Used in conjunction with Generic Content type to display curated lists of
 * content. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/pressReleaseLanding)
 */
export type PressReleaseLanding = Entry & {
  contentCollection?: Maybe<PressReleaseLandingContentCollection>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<PressReleaseLandingLinkingCollections>;
  relativePath?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  viewMoreButtonText?: Maybe<Scalars['String']>;
};


/**
 * Used in conjunction with Generic Content type to display curated lists of
 * content. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/pressReleaseLanding)
 */
export type PressReleaseLandingContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/**
 * Used in conjunction with Generic Content type to display curated lists of
 * content. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/pressReleaseLanding)
 */
export type PressReleaseLandingLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/**
 * Used in conjunction with Generic Content type to display curated lists of
 * content. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/pressReleaseLanding)
 */
export type PressReleaseLandingRelativePathArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Used in conjunction with Generic Content type to display curated lists of
 * content. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/pressReleaseLanding)
 */
export type PressReleaseLandingTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Used in conjunction with Generic Content type to display curated lists of
 * content. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/pressReleaseLanding)
 */
export type PressReleaseLandingViewMoreButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type PressReleaseLandingCollection = {
  items: Array<Maybe<PressReleaseLanding>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PressReleaseLandingContentCollection = {
  items: Array<Maybe<PressRelease>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PressReleaseLandingFilter = {
  AND?: InputMaybe<Array<InputMaybe<PressReleaseLandingFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PressReleaseLandingFilter>>>;
  contentCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  relativePath?: InputMaybe<Scalars['String']>;
  relativePath_contains?: InputMaybe<Scalars['String']>;
  relativePath_exists?: InputMaybe<Scalars['Boolean']>;
  relativePath_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  relativePath_not?: InputMaybe<Scalars['String']>;
  relativePath_not_contains?: InputMaybe<Scalars['String']>;
  relativePath_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  viewMoreButtonText?: InputMaybe<Scalars['String']>;
  viewMoreButtonText_contains?: InputMaybe<Scalars['String']>;
  viewMoreButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  viewMoreButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  viewMoreButtonText_not?: InputMaybe<Scalars['String']>;
  viewMoreButtonText_not_contains?: InputMaybe<Scalars['String']>;
  viewMoreButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PressReleaseLandingLinkingCollections = {
  aiPageCollection?: Maybe<AiPageCollection>;
  aiTemplatePageCollection?: Maybe<AiTemplatePageCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type PressReleaseLandingLinkingCollectionsAiPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type PressReleaseLandingLinkingCollectionsAiTemplatePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type PressReleaseLandingLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum PressReleaseLandingOrder {
  RelativePathAsc = 'relativePath_ASC',
  RelativePathDesc = 'relativePath_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ViewMoreButtonTextAsc = 'viewMoreButtonText_ASC',
  ViewMoreButtonTextDesc = 'viewMoreButtonText_DESC'
}

export type PressReleaseLinkingCollections = {
  aiPageCollection?: Maybe<AiPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
  mediaResourcesCollection?: Maybe<MediaResourcesCollection>;
  ndeToolLandingPageCollection?: Maybe<NdeToolLandingPageCollection>;
  pressReleaseLandingCollection?: Maybe<PressReleaseLandingCollection>;
  thoughtLeadershipCollection?: Maybe<ThoughtLeadershipCollection>;
};


export type PressReleaseLinkingCollectionsAiPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type PressReleaseLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type PressReleaseLinkingCollectionsMediaResourcesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type PressReleaseLinkingCollectionsNdeToolLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type PressReleaseLinkingCollectionsPressReleaseLandingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type PressReleaseLinkingCollectionsThoughtLeadershipCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum PressReleaseOrder {
  CustomReadMoreLinkTextAsc = 'customReadMoreLinkText_ASC',
  CustomReadMoreLinkTextDesc = 'customReadMoreLinkText_DESC',
  DateSpanAsc = 'dateSpan_ASC',
  DateSpanDesc = 'dateSpan_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  LocationAsc = 'location_ASC',
  LocationDesc = 'location_DESC',
  SourceNameAsc = 'sourceName_ASC',
  SourceNameDesc = 'sourceName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/privacyStatement) */
export type PrivacyStatement = Entry & {
  contentfulMetadata: ContentfulMetadata;
  intro?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<PrivacyStatementLinkingCollections>;
  overviewColumnOne?: Maybe<Scalars['String']>;
  overviewColumnTwo?: Maybe<Scalars['String']>;
  overviewHeader?: Maybe<Scalars['String']>;
  overviewRow?: Maybe<Scalars['String']>;
  sectionsCollection?: Maybe<PrivacyStatementSectionsCollection>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/privacyStatement) */
export type PrivacyStatementIntroArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/privacyStatement) */
export type PrivacyStatementLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/privacyStatement) */
export type PrivacyStatementOverviewColumnOneArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/privacyStatement) */
export type PrivacyStatementOverviewColumnTwoArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/privacyStatement) */
export type PrivacyStatementOverviewHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/privacyStatement) */
export type PrivacyStatementOverviewRowArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/privacyStatement) */
export type PrivacyStatementSectionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/privacyStatement) */
export type PrivacyStatementTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type PrivacyStatementCollection = {
  items: Array<Maybe<PrivacyStatement>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/privacyStatementContent) */
export type PrivacyStatementContent = Entry & {
  body?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<PrivacyStatementContentLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/privacyStatementContent) */
export type PrivacyStatementContentBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/privacyStatementContent) */
export type PrivacyStatementContentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/privacyStatementContent) */
export type PrivacyStatementContentTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type PrivacyStatementContentCollection = {
  items: Array<Maybe<PrivacyStatementContent>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PrivacyStatementContentFilter = {
  AND?: InputMaybe<Array<InputMaybe<PrivacyStatementContentFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PrivacyStatementContentFilter>>>;
  body?: InputMaybe<Scalars['String']>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PrivacyStatementContentLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  privacyStatementCollection?: Maybe<PrivacyStatementCollection>;
};


export type PrivacyStatementContentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type PrivacyStatementContentLinkingCollectionsPrivacyStatementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum PrivacyStatementContentOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type PrivacyStatementFilter = {
  AND?: InputMaybe<Array<InputMaybe<PrivacyStatementFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PrivacyStatementFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  intro?: InputMaybe<Scalars['String']>;
  intro_contains?: InputMaybe<Scalars['String']>;
  intro_exists?: InputMaybe<Scalars['Boolean']>;
  intro_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  intro_not?: InputMaybe<Scalars['String']>;
  intro_not_contains?: InputMaybe<Scalars['String']>;
  intro_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  overviewColumnOne?: InputMaybe<Scalars['String']>;
  overviewColumnOne_contains?: InputMaybe<Scalars['String']>;
  overviewColumnOne_exists?: InputMaybe<Scalars['Boolean']>;
  overviewColumnOne_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  overviewColumnOne_not?: InputMaybe<Scalars['String']>;
  overviewColumnOne_not_contains?: InputMaybe<Scalars['String']>;
  overviewColumnOne_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  overviewColumnTwo?: InputMaybe<Scalars['String']>;
  overviewColumnTwo_contains?: InputMaybe<Scalars['String']>;
  overviewColumnTwo_exists?: InputMaybe<Scalars['Boolean']>;
  overviewColumnTwo_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  overviewColumnTwo_not?: InputMaybe<Scalars['String']>;
  overviewColumnTwo_not_contains?: InputMaybe<Scalars['String']>;
  overviewColumnTwo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  overviewHeader?: InputMaybe<Scalars['String']>;
  overviewHeader_contains?: InputMaybe<Scalars['String']>;
  overviewHeader_exists?: InputMaybe<Scalars['Boolean']>;
  overviewHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  overviewHeader_not?: InputMaybe<Scalars['String']>;
  overviewHeader_not_contains?: InputMaybe<Scalars['String']>;
  overviewHeader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  overviewRow?: InputMaybe<Scalars['String']>;
  overviewRow_contains?: InputMaybe<Scalars['String']>;
  overviewRow_exists?: InputMaybe<Scalars['Boolean']>;
  overviewRow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  overviewRow_not?: InputMaybe<Scalars['String']>;
  overviewRow_not_contains?: InputMaybe<Scalars['String']>;
  overviewRow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PrivacyStatementLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type PrivacyStatementLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum PrivacyStatementOrder {
  OverviewHeaderAsc = 'overviewHeader_ASC',
  OverviewHeaderDesc = 'overviewHeader_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type PrivacyStatementSectionsCollection = {
  items: Array<Maybe<PrivacyStatementContent>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ProductBaseType = {
  displayLink?: Maybe<Scalars['String']>;
  iD?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  originalProductId?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  shortProductId?: Maybe<Scalars['String']>;
};

export type ProductDownloadType = {
  accessLogStatus?: Maybe<Scalars['String']>;
  agreementMessage?: Maybe<Scalars['String']>;
  iPAddress?: Maybe<Scalars['String']>;
  isPart810?: Maybe<Scalars['Boolean']>;
  isRestricted?: Maybe<Scalars['Boolean']>;
  pageCount?: Maybe<Scalars['Int']>;
  productDocumentType?: Maybe<Scalars['String']>;
  productFileName?: Maybe<Scalars['String']>;
  productFileSize?: Maybe<Scalars['String']>;
  productFileType?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productLicenseCode?: Maybe<Scalars['String']>;
  productLongDescription?: Maybe<Scalars['String']>;
  productMarketSector?: Maybe<Scalars['String']>;
  productPublishedDate?: Maybe<Scalars['String']>;
  productTypeCategory?: Maybe<Scalars['String']>;
  productUserAuthorized?: Maybe<Scalars['Boolean']>;
  productUserHasAccess?: Maybe<Scalars['Boolean']>;
  purchaseOption?: Maybe<Scalars['String']>;
  requester?: Maybe<Scalars['String']>;
  showPart810Agreement?: Maybe<Scalars['Boolean']>;
  statusErrorMessage?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  synopsisMessage?: Maybe<Scalars['String']>;
};

export type ProductType = {
  abstractURL?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  displaylink?: Maybe<Scalars['String']>;
  documentType?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
  fundedOnly?: Maybe<Scalars['Int']>;
  fundingType?: Maybe<Scalars['String']>;
  hasAbstract?: Maybe<Scalars['Boolean']>;
  hasAccess?: Maybe<Scalars['Boolean']>;
  hasSynopsis?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  implementationCategory?: Maybe<Scalars['String']>;
  implementationType?: Maybe<Scalars['String']>;
  isBookmarked?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  longDescription?: Maybe<Scalars['String']>;
  originalProductId?: Maybe<Scalars['String']>;
  pageCount?: Maybe<Scalars['Int']>;
  productType?: Maybe<Scalars['String']>;
  programLTN?: Maybe<Scalars['String']>;
  programName?: Maybe<Scalars['String']>;
  programNumber?: Maybe<Scalars['String']>;
  programYear?: Maybe<Scalars['String']>;
  projectLTN?: Maybe<Scalars['String']>;
  publishedDate?: Maybe<Scalars['String']>;
  purchaseOption?: Maybe<Scalars['String']>;
  sectorLTN?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  shortProductId?: Maybe<Scalars['String']>;
  siteSortKey?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusComment?: Maybe<Scalars['String']>;
  supplementalOnly?: Maybe<Scalars['Int']>;
  toolTip?: Maybe<Scalars['String']>;
  typeCategory?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userPrice?: Maybe<Scalars['String']>;
};

export type Products = {
  data?: Maybe<Array<Maybe<ProductType>>>;
  hasCategory?: Maybe<Scalars['Boolean']>;
  totalRecords?: Maybe<Scalars['Int']>;
};

/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/profileCard) */
export type ProfileCard = Entry & {
  areasOfExpertise?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  email?: Maybe<Scalars['String']>;
  image?: Maybe<Asset>;
  linkedFrom?: Maybe<ProfileCardLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/profileCard) */
export type ProfileCardAreasOfExpertiseArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/profileCard) */
export type ProfileCardEmailArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/profileCard) */
export type ProfileCardImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/profileCard) */
export type ProfileCardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/profileCard) */
export type ProfileCardNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/profileCard) */
export type ProfileCardPhoneArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/profileCard) */
export type ProfileCardTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ProfileCardCollection = {
  items: Array<Maybe<ProfileCard>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ProfileCardFilter = {
  AND?: InputMaybe<Array<InputMaybe<ProfileCardFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ProfileCardFilter>>>;
  areasOfExpertise?: InputMaybe<Scalars['String']>;
  areasOfExpertise_contains?: InputMaybe<Scalars['String']>;
  areasOfExpertise_exists?: InputMaybe<Scalars['Boolean']>;
  areasOfExpertise_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  areasOfExpertise_not?: InputMaybe<Scalars['String']>;
  areasOfExpertise_not_contains?: InputMaybe<Scalars['String']>;
  areasOfExpertise_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  email?: InputMaybe<Scalars['String']>;
  email_contains?: InputMaybe<Scalars['String']>;
  email_exists?: InputMaybe<Scalars['Boolean']>;
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  email_not?: InputMaybe<Scalars['String']>;
  email_not_contains?: InputMaybe<Scalars['String']>;
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  phone?: InputMaybe<Scalars['String']>;
  phone_contains?: InputMaybe<Scalars['String']>;
  phone_exists?: InputMaybe<Scalars['Boolean']>;
  phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  phone_not?: InputMaybe<Scalars['String']>;
  phone_not_contains?: InputMaybe<Scalars['String']>;
  phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ProfileCardLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  mediaResourcesCollection?: Maybe<MediaResourcesCollection>;
  racContactsCollection?: Maybe<RacContactsCollection>;
  researchAdvisoryCommitteeHomeCollection?: Maybe<ResearchAdvisoryCommitteeHomeCollection>;
};


export type ProfileCardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ProfileCardLinkingCollectionsMediaResourcesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ProfileCardLinkingCollectionsRacContactsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ProfileCardLinkingCollectionsResearchAdvisoryCommitteeHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ProfileCardOrder {
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type ProfileInput = {
    address1: Scalars['String'];
    address2?: InputMaybe<Scalars['String']>;
    address3?: InputMaybe<Scalars['String']>;
    addressCity: Scalars['String'];
    addressCountry: Scalars['String'];
    addressCountryCode: Scalars['String'];
    addressId?: InputMaybe<Scalars['String']>;
    addressPostalCode: Scalars['String'];
    addressState: Scalars['String'];
    companyId?: InputMaybe<Scalars['String']>;
    companyName: Scalars['String'];
    contactAlternateBusinessMail?: InputMaybe<Scalars['String']>;
    contactBusinessMail: Scalars['String'];
    contactBusinessMailCopy?: InputMaybe<Scalars['String']>;
    contactFax?: InputMaybe<InputPhoneFax>;
    contactFirstName: Scalars['String'];
    contactId: Scalars['String'];
    contactLastName: Scalars['String'];
    contactMiddleName?: InputMaybe<Scalars['String']>;
    contactPhone: InputPhoneFax;
    contactPreferredName?: InputMaybe<Scalars['String']>;
    contactRoleType?: InputMaybe<Scalars['String']>;
    contactUserName: Scalars['String'];
    declineToDisplay?: InputMaybe<Scalars['Boolean']>;
    isContractor?: InputMaybe<Scalars['Boolean']>;
    mailStop?: InputMaybe<Scalars['String']>;
    notes?: InputMaybe<Scalars['String']>;
    plantId?: InputMaybe<Scalars['String']>;
    plantName?: InputMaybe<Scalars['String']>;
    salutation?: InputMaybe<Scalars['String']>;
    title: Scalars['String'];
};

export type ProfileResponse = {
  errorCode?: Maybe<Scalars['Int']>;
  errorMessage?: Maybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/programContacts) */
export type ProgramContacts = Entry & {
  contentfulMetadata: ContentfulMetadata;
  leadershipCollection?: Maybe<ProgramContactsLeadershipCollection>;
  linkedFrom?: Maybe<ProgramContactsLinkingCollections>;
  programExpertiseList?: Maybe<Asset>;
  programLtn?: Maybe<Scalars['String']>;
  programName?: Maybe<Scalars['String']>;
  staffCollection?: Maybe<ProgramContactsStaffCollection>;
  sys: Sys;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/programContacts) */
export type ProgramContactsLeadershipCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/programContacts) */
export type ProgramContactsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/programContacts) */
export type ProgramContactsProgramExpertiseListArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/programContacts) */
export type ProgramContactsProgramLtnArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/programContacts) */
export type ProgramContactsProgramNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/programContacts) */
export type ProgramContactsStaffCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type ProgramContactsCollection = {
  items: Array<Maybe<ProgramContacts>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ProgramContactsFilter = {
  AND?: InputMaybe<Array<InputMaybe<ProgramContactsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ProgramContactsFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  leadershipCollection_exists?: InputMaybe<Scalars['Boolean']>;
  programExpertiseList_exists?: InputMaybe<Scalars['Boolean']>;
  programLTN?: InputMaybe<Scalars['String']>;
  programLTN_contains?: InputMaybe<Scalars['String']>;
  programLTN_exists?: InputMaybe<Scalars['Boolean']>;
  programLTN_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  programLTN_not?: InputMaybe<Scalars['String']>;
  programLTN_not_contains?: InputMaybe<Scalars['String']>;
  programLTN_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  programName?: InputMaybe<Scalars['String']>;
  programName_contains?: InputMaybe<Scalars['String']>;
  programName_exists?: InputMaybe<Scalars['Boolean']>;
  programName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  programName_not?: InputMaybe<Scalars['String']>;
  programName_not_contains?: InputMaybe<Scalars['String']>;
  programName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  staffCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type ProgramContactsLeadershipCollection = {
  items: Array<Maybe<Person>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ProgramContactsLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type ProgramContactsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ProgramContactsOrder {
  ProgramLtnAsc = 'programLTN_ASC',
  ProgramLtnDesc = 'programLTN_DESC',
  ProgramNameAsc = 'programName_ASC',
  ProgramNameDesc = 'programName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type ProgramContactsStaffCollection = {
  items: Array<Maybe<Person>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ProgramParticipationDetail = {
  amount?: Maybe<Scalars['Float']>;
  fundingLevel?: Maybe<Scalars['String']>;
  fundingYear?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['String']>;
  productLongDescription?: Maybe<Scalars['String']>;
  programName?: Maybe<Scalars['String']>;
  programNumber?: Maybe<Scalars['String']>;
  researchArea?: Maybe<Scalars['String']>;
  sectorLTN?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
};

export type ProgramParticipationDetailResponse = {
  details?: Maybe<Array<Maybe<ProgramParticipationDetail>>>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export type ProgramParticipationSummary = {
  arpParticipation?: Maybe<Scalars['String']>;
  programName?: Maybe<Scalars['String']>;
  programNumber?: Maybe<Scalars['String']>;
  sector?: Maybe<Scalars['String']>;
  sectorLTN?: Maybe<Scalars['String']>;
};

export type ProgramParticipationSummaryResponse = {
  noOfPages?: Maybe<Scalars['Int']>;
  summary?: Maybe<Array<Maybe<ProgramParticipationSummary>>>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export type ProgramSupplementals = {
  programLTN?: Maybe<Scalars['String']>;
  programNumber?: Maybe<Scalars['String']>;
  programTitle?: Maybe<Scalars['String']>;
  sectorLTN?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
  siteSortKey?: Maybe<Scalars['String']>;
  supplementals?: Maybe<Array<Maybe<ProductType>>>;
};

export type ProjectOverviewUpdate = {
  fundingType?: Maybe<Scalars['String']>;
  hasPOF?: Maybe<Scalars['Boolean']>;
  hasPSU?: Maybe<Scalars['Boolean']>;
  lastPublishYear?: Maybe<Scalars['String']>;
  levelNumber?: Maybe<Scalars['String']>;
  ltn?: Maybe<Scalars['String']>;
  overviewHTML?: Maybe<Scalars['String']>;
  overviewPublishedDate?: Maybe<Scalars['String']>;
  overviewPublishedYear?: Maybe<Scalars['String']>;
  pastUpdates?: Maybe<Array<Maybe<PastUpdates>>>;
  pdfUrl?: Maybe<Scalars['String']>;
  projectLead?: Maybe<Scalars['String']>;
  projectLeadEmail?: Maybe<Scalars['String']>;
  projectLeadPhone?: Maybe<Scalars['String']>;
  statusPublishedDate?: Maybe<Scalars['String']>;
  statusPublishedMonth?: Maybe<Scalars['String']>;
  statusPublishedYear?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updateHTML?: Maybe<Scalars['String']>;
};

export type ProjectsResponse = {
  futureProjects?: Maybe<Array<Maybe<ResearchProject>>>;
  hasApplicablePrograms?: Maybe<Scalars['Boolean']>;
  jointProjects?: Maybe<Array<Maybe<CollaborativeProject>>>;
  projects?: Maybe<Array<Maybe<ResearchProject>>>;
};

/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/protect) */
export type Protect = Entry & {
  childrenCollection?: Maybe<ProtectChildrenCollection>;
  contentfulMetadata: ContentfulMetadata;
  internalNameEntryTitle?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<ProtectLinkingCollections>;
  swod?: Maybe<Array<Maybe<Scalars['String']>>>;
  sys: Sys;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/protect) */
export type ProtectChildrenCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/protect) */
export type ProtectInternalNameEntryTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/protect) */
export type ProtectLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/protect) */
export type ProtectSwodArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ProtectChildrenCollection = {
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ProtectCollection = {
  items: Array<Maybe<Protect>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ProtectFilter = {
  AND?: InputMaybe<Array<InputMaybe<ProtectFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ProtectFilter>>>;
  childrenCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  internalNameEntryTitle?: InputMaybe<Scalars['String']>;
  internalNameEntryTitle_contains?: InputMaybe<Scalars['String']>;
  internalNameEntryTitle_exists?: InputMaybe<Scalars['Boolean']>;
  internalNameEntryTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalNameEntryTitle_not?: InputMaybe<Scalars['String']>;
  internalNameEntryTitle_not_contains?: InputMaybe<Scalars['String']>;
  internalNameEntryTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  swod_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  swod_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  swod_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  swod_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type ProtectLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type ProtectLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ProtectOrder {
  InternalNameEntryTitleAsc = 'internalNameEntryTitle_ASC',
  InternalNameEntryTitleDesc = 'internalNameEntryTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/publicAttachment) */
export type PublicAttachment = Entry & {
  contentfulMetadata: ContentfulMetadata;
  fileAttachment?: Maybe<Asset>;
  linkedFrom?: Maybe<PublicAttachmentLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/publicAttachment) */
export type PublicAttachmentFileAttachmentArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/publicAttachment) */
export type PublicAttachmentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/publicAttachment) */
export type PublicAttachmentTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type PublicAttachmentCollection = {
  items: Array<Maybe<PublicAttachment>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PublicAttachmentFilter = {
  AND?: InputMaybe<Array<InputMaybe<PublicAttachmentFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PublicAttachmentFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  fileAttachment_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PublicAttachmentLinkingCollections = {
  announcementCollection?: Maybe<AnnouncementCollection>;
  entryCollection?: Maybe<EntryCollection>;
  xLprLandingPageCollection?: Maybe<XLprLandingPageCollection>;
};


export type PublicAttachmentLinkingCollectionsAnnouncementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type PublicAttachmentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type PublicAttachmentLinkingCollectionsXLprLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum PublicAttachmentOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type Query = {
  aboutEpri?: Maybe<AboutEpri>;
  aboutEpriCollection?: Maybe<AboutEpriCollection>;
  advisoryBoard?: Maybe<AdvisoryBoard>;
  advisoryBoardCard?: Maybe<AdvisoryBoardCard>;
  advisoryBoardCardCollection?: Maybe<AdvisoryBoardCardCollection>;
  advisoryBoardCollection?: Maybe<AdvisoryBoardCollection>;
  aiComponent?: Maybe<AiComponent>;
  aiComponentCollection?: Maybe<AiComponentCollection>;
  aiPage?: Maybe<AiPage>;
  aiPageCollection?: Maybe<AiPageCollection>;
  aiTemplatePage?: Maybe<AiTemplatePage>;
  aiTemplatePageCollection?: Maybe<AiTemplatePageCollection>;
  announcement?: Maybe<Announcement>;
  announcementCollection?: Maybe<AnnouncementCollection>;
  announcementWrapper?: Maybe<AnnouncementWrapper>;
  announcementWrapperCollection?: Maybe<AnnouncementWrapperCollection>;
  asset?: Maybe<Asset>;
  assetCollection?: Maybe<AssetCollection>;
  basicTemplate?: Maybe<BasicTemplate>;
  basicTemplateCollection?: Maybe<BasicTemplateCollection>;
  boardOfDirectorCard?: Maybe<BoardOfDirectorCard>;
  boardOfDirectorCardCollection?: Maybe<BoardOfDirectorCardCollection>;
  boardOfDirectors?: Maybe<BoardOfDirectors>;
  boardOfDirectorsCollection?: Maybe<BoardOfDirectorsCollection>;
  button?: Maybe<Button>;
  buttonCollection?: Maybe<ButtonCollection>;
  bwrvip03?: Maybe<Bwrvip03>;
  bwrvip03Collection?: Maybe<Bwrvip03Collection>;
  bwrvip03Section?: Maybe<Bwrvip03Section>;
  bwrvip03SectionCollection?: Maybe<Bwrvip03SectionCollection>;
  cardTemplate?: Maybe<CardTemplate>;
  cardTemplateCollection?: Maybe<CardTemplateCollection>;
  careers?: Maybe<Careers>;
  careersCollection?: Maybe<CareersCollection>;
  contact?: Maybe<Contact>;
  contactCollection?: Maybe<ContactCollection>;
  copyright?: Maybe<Copyright>;
  copyrightCollection?: Maybe<CopyrightCollection>;
  coresprayPipingLandingPage?: Maybe<CoresprayPipingLandingPage>;
  coresprayPipingLandingPageCollection?: Maybe<CoresprayPipingLandingPageCollection>;
  customerService?: Maybe<CustomerService>;
  customerServiceCollection?: Maybe<CustomerServiceCollection>;
  entryCollection?: Maybe<EntryCollection>;
  epriU?: Maybe<EpriU>;
  epriUCollection?: Maybe<EpriUCollection>;
  epri_BCam?: Maybe<Array<Maybe<BCam>>>;
  /** Query to return export data for comittee advisors */
  epri_GetAdvisorCommitteeExportData?: Maybe<Array<Maybe<CommitteeExportDetail>>>;
  /** Query to return a company's SDF/TC Funding percentage by sector */
  epri_SDFTCBySectorSummary?: Maybe<Array<Maybe<SdftcSummaryBySector>>>;
  /** Query to get Training details for a company, sector, date range */
  epri_TrainingDetailsReport?: Maybe<TrainingDetailResponse>;
  /** get abstract product details by productid */
  epri_abstract?: Maybe<UserAbstractType>;
  epri_access?: Maybe<Array<Maybe<Scalars['String']>>>;
  epri_accountExecutives?: Maybe<Array<Maybe<AccountExecutive>>>;
  epri_acgSummary?: Maybe<SubsidiaryResponse>;
  /** Query to return a contact's engagement Activity */
  epri_advisorActivity?: Maybe<AdvisorActivity>;
  /** Query to get list of available roles for advisors */
  epri_advisorRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  epri_allHierarchy?: Maybe<Array<Maybe<Site>>>;
  /** Query returns a company's all Individial Access details for export */
  epri_allIndividualAccessDetails?: Maybe<Array<Maybe<IndividualAccessDetail>>>;
  epri_allUserfavorites?: Maybe<FavoritesCollection>;
  epri_announcementUpdates?: Maybe<Array<Maybe<AnnouncementUpdate>>>;
  epri_attendedTrainings?: Maybe<Array<Maybe<LmsTranscript>>>;
  epri_bookmarks?: Maybe<BookmarksResponse>;
  epri_byTopic?: Maybe<ResearchByTopic>;
  /** Query to check if a particular user exists in a company group or not */
  epri_checkUser?: Maybe<CommitteeAdvisor>;
  /** Query returns deliverables and associated data for a company. Pass eligibleOnly as 1 for MyCDR and 0 for All Products. */
  epri_clientDeliverables?: Maybe<ClientDeliverableResponse>;
  /** Query to get Client Deliverables Summary for a company */
  epri_clientDeliverablesSummary?: Maybe<Array<Maybe<ClientDeliverableSummary>>>;
  /** Query returns list of available advisors to a commitee */
  epri_committeeAdvisors?: Maybe<Array<Maybe<CommitteeAdvisor>>>;
  epri_committeeContacts?: Maybe<CommitteeContacts>;
  /** get list of committees for a provided LTN */
  epri_committees?: Maybe<Array<Maybe<Committee>>>;
  /**  options: ADV, CDR, MEM, MET, MYMETT, ALL, LMS, LMS_ONLY, PUBLIC_AND_LMS */
  epri_companies?: Maybe<Array<Maybe<Company>>>;
  epri_companyByID?: Maybe<Company>;
  /** Query to get a company participants details */
  epri_companyParticipantDetails?: Maybe<ParticipantDetailResponse>;
  /** Query to get a company participants summary details */
  epri_companyParticipantSummary?: Maybe<Array<Maybe<ParticipantSummary>>>;
  epri_contact?: Maybe<Contact>;
  epri_contacts?: Maybe<PeopleDirectory>;
  /** get list of countries asn states */
  epri_countries?: Maybe<Array<Maybe<Country>>>;
  epri_event?: Maybe<UserEvent>;
  /** Query to get Event details for a company, sector, date range */
  epri_eventDetailsReport?: Maybe<EventDetailResponse>;
  /** Query to get Events/Invitees Summary for a company and date range */
  epri_eventInviteesSummary?: Maybe<Array<Maybe<EventInviteeSummary>>>;
  epri_eventdashboard?: Maybe<EventDashboard>;
  epri_events?: Maybe<Array<Maybe<UserEvent>>>;
  epri_examinationBasisList?: Maybe<ExaminationBasisResponse>;
  epri_examinationCategories?: Maybe<ExaminationCategoriesResponse>;
  epri_examinationDocumentDetails?: Maybe<ExaminationDocument>;
  epri_examinationDocuments?: Maybe<ExaminationDocumentResponse>;
  epri_examinationItemDetails?: Maybe<ExaminationItem>;
  /** Query returns existing product credits for a company */
  epri_existingProductCredits?: Maybe<ExistingProductCreditResponse>;
  epri_exportACGData?: Maybe<Array<Maybe<AcgMember>>>;
  /** Query to return list of chapters/chapter details */
  epri_getBWVRIPChapters?: Maybe<Array<Maybe<Chapter>>>;
  /** Query to return list of reports for a Vendor */
  epri_getBWVRIPReports?: Maybe<Array<Maybe<BwvripReport>>>;
  /** Query returns Vendors */
  epri_getBWVRIPVendors?: Maybe<Array<Maybe<Vendor>>>;
  /** Query to return products in a user's cart */
  epri_getProductsInCart?: Maybe<OrderResponse>;
  /** Query to return list of Weld options for a Vendor and BWR Model selected. bwrModel can be passed as 2, 3-5, 6 */
  epri_getWeldOptionsByVendor?: Maybe<VendorModelWelds>;
  epri_hierarchy?: Maybe<Array<Maybe<Site>>>;
  /** Query returns Individial Access details for a sector */
  epri_individualAccessDetails?: Maybe<Array<Maybe<IndividualAccessDetailsResponse>>>;
  /** Query to return list of assigned contacts to a Fourth Condition Product */
  epri_individualAccessProductContacts?: Maybe<Array<Maybe<IndividualAccessContact>>>;
  /** Query returns Individual Access (4th Condition) products  summary by a sector. This returns 2 part response - Individual Access summary count and list of products with unassigned contacts by a sector */
  epri_individualAccessSummary?: Maybe<IndividualAccessSummaryResponse>;
  /** Query to get Invitee details for a company, sector, date range */
  epri_inviteeDetailsReport?: Maybe<InviteeDetailResponse>;
  epri_links?: Maybe<Array<Maybe<NavLink>>>;
  /** get list of lookup attributes for techtransfer based on Type. Type could be - ApplicationBenefits,RecognitionType,ProjectStatus */
  epri_lookupAttributes?: Maybe<Array<Maybe<TtLookup>>>;
  /** Query to return METT Supplementals for a company */
  epri_mettSupplementals?: Maybe<Array<Maybe<MettSupplemental>>>;
  /** Return a Nomination Details */
  epri_nomination?: Maybe<Nomination>;
  /** Returns list of nominations based on provide criteria */
  epri_nominations?: Maybe<NominationResponse>;
  /** Query to return Open Invoices for a Company */
  epri_openInvoices?: Maybe<OpenInvoicesResponse>;
  epri_plantModernizationGuides?: Maybe<ModernizationGuideResponse>;
  epri_plantModernizationModel?: Maybe<PlantModernizationModelResponse>;
  /** Query to get list of Plants */
  epri_plants?: Maybe<Array<Maybe<Plant>>>;
  /** Return list of sectors */
  epri_portfoliosectors?: Maybe<Array<Maybe<PortfolioSector>>>;
  /** get basic product details by productid */
  epri_product?: Maybe<ProductType>;
  /** get product download details by productid */
  epri_productDownload?: Maybe<ProductDownloadType>;
  /** get basic products list */
  epri_products?: Maybe<Array<Maybe<ProductType>>>;
  /** get basic products by tabType like 'Research Support' / 'Research Result' / 'Top Products' / 'In Development'. sortColumn can be product_datePublished,product_dateExpected */
  epri_productsByType?: Maybe<Products>;
  /** Query to get a user's profile */
  epri_profile?: Maybe<EnrollUser>;
  /** get a particular program details */
  epri_program?: Maybe<PortfolioProgram>;
  epri_programFavorites?: Maybe<Array<Maybe<FavoriteSite>>>;
  /** Query to return Program Participartion funding deatils */
  epri_programParticipationDetails?: Maybe<ProgramParticipationDetailResponse>;
  /** Query to return Program Participartion funding Summary */
  epri_programParticipationSummary?: Maybe<ProgramParticipationSummaryResponse>;
  epri_programProjects?: Maybe<Array<Maybe<Site>>>;
  /** Get supplemental products grouped by the Programs for Sector */
  epri_programSupplementals?: Maybe<Array<Maybe<ProgramSupplementals>>>;
  /** get list of programs for a portfolio year */
  epri_programs?: Maybe<Array<Maybe<PortfolioProgram>>>;
  epri_programsByResearchArea?: Maybe<Array<Maybe<Site>>>;
  /** returns overview details of a project for a provided year */
  epri_projectOverview?: Maybe<ProjectOverviewUpdate>;
  /** returns overview details of a project for a provided year */
  epri_projectStatusUpdate?: Maybe<ProjectOverviewUpdate>;
  /** returns list of projects with overviews and/or status updates grouped by project set */
  epri_projects?: Maybe<ProjectsResponse>;
  /** Query to get list of all salutations */
  epri_salutations?: Maybe<Array<Maybe<Salutation>>>;
  /** get a sector details with research areas */
  epri_sector?: Maybe<PortfolioSector>;
  /** Query returns company advisors details */
  epri_sectorAdvisorDetails?: Maybe<Array<Maybe<CommitteeSite>>>;
  /** Query returns advisors summary by a sector. This returns 2 part response - Advisors summary count and list of eligible and not represented committees by a sector */
  epri_sectorAdvisorSummary?: Maybe<SummaryResponse>;
  /** Query returns list of all advisors for a sector with a summary of their activity */
  epri_sectorAdvisors?: Maybe<Array<Maybe<CommitteeAdvisor>>>;
  /** Query to return a company's Self Directed Funding details */
  epri_selfDirectedFundingDetails?: Maybe<Array<Maybe<FundingDetailResponse>>>;
  /** Query to return a company's Self Directed Funding Summary */
  epri_selfDirectedFundingSummary?: Maybe<Array<Maybe<FundingSummaryResponse>>>;
  /** Query to pull summary of requests for a user in a session */
  epri_sendAdvisorNotifications?: Maybe<Scalars['Int']>;
  /** Query to pull summary of individual contact requests for a user in a session */
  epri_sendIndividualAccessRequestNotifications?: Maybe<Scalars['Int']>;
  /** Get Site details */
  epri_site?: Maybe<Site>;
  /** get list of sites basing on input filters. Type Can be passed as Sector/Program/Project Set/Project/Collaboration depending on what data is being requested */
  epri_sites?: Maybe<Array<Maybe<Site>>>;
  /** Get Subscription details for the logged in user */
  epri_subscriptionDetails?: Maybe<SubscriptionDetails>;
  epri_subsidiaryPendingRequests?: Maybe<SubsidiaryAffiliateResponse>;
  /** Query to return a Company's Supplemental Funding Details */
  epri_supplementalFundingDetails?: Maybe<SupplementalFundingDetailResponse>;
  /** Query to return a Company's Supplemental Funding Summary */
  epri_supplementalFundingSummary?: Maybe<SupplementalFundingSummaryResponse>;
  /** get list of all Supplemental projects */
  epri_supplementals?: Maybe<Array<Maybe<PortfolioProgramSupplemental>>>;
  /** Query to return a company's Tailored Collaboration Funding details */
  epri_tailoredCollabFundingDetails?: Maybe<Array<Maybe<FundingDetailResponse>>>;
  /** Query to return a company's Tailored Collaboration Funding Summary */
  epri_tailoredCollabFundingSummary?: Maybe<Array<Maybe<FundingSummaryResponse>>>;
  /** Query to return Top Download Products report */
  epri_topDownloadProductsReport?: Maybe<TopDownloadProductDetailResponse>;
  epri_training?: Maybe<Training>;
  epri_trainings?: Maybe<Array<Maybe<Training>>>;
  /** Query to get Trainings Summary for a company and date range */
  epri_trainingsSummary?: Maybe<Array<Maybe<TrainingsSummary>>>;
  /** returns list of type categories based on Research Result or Research Support */
  epri_typeCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Query to return user's download report */
  epri_usersOrderReport?: Maybe<DownloadUsersResponse>;
  /** Query to return Who Ordered What details for a companyGroup */
  epri_whoOrderedWhatDetails?: Maybe<WhoOrderedWhatResponse>;
  /** Query to return Who Ordered What Summary for a companyGroup */
  epri_whoOrderedWhatSummary?: Maybe<Array<Maybe<WhoOrderedWhatSummary>>>;
  /** Query to return a Company's Yearly Funding Details */
  epri_yearlyFundingDetails?: Maybe<YearlyFundingDetailResponse>;
  /** Query to return a Company's yearly Funding Summary */
  epri_yearlyFundingSummary?: Maybe<YearlyFundingSummaryResponse>;
  eprijournal_archivePost?: Maybe<Eprijournal_ArchivePost>;
  eprijournal_archivePostCollection?: Maybe<Eprijournal_ArchivePostCollection>;
  eprijournal_articleLinks?: Maybe<Eprijournal_ArticleLinks>;
  eprijournal_articleLinksCollection?: Maybe<Eprijournal_ArticleLinksCollection>;
  eprijournal_asset?: Maybe<Eprijournal_Asset>;
  eprijournal_assetCollection?: Maybe<Eprijournal_AssetCollection>;
  eprijournal_author?: Maybe<Eprijournal_Author>;
  eprijournal_authorCollection?: Maybe<Eprijournal_AuthorCollection>;
  eprijournal_category?: Maybe<Eprijournal_Category>;
  eprijournal_categoryCollection?: Maybe<Eprijournal_CategoryCollection>;
  eprijournal_entryCollection?: Maybe<Eprijournal_EntryCollection>;
  eprijournal_jumbotron?: Maybe<Eprijournal_Jumbotron>;
  eprijournal_jumbotronCollection?: Maybe<Eprijournal_JumbotronCollection>;
  eprijournal_mainCategory?: Maybe<Eprijournal_MainCategory>;
  eprijournal_mainCategoryCollection?: Maybe<Eprijournal_MainCategoryCollection>;
  eprijournal_page?: Maybe<Eprijournal_Page>;
  eprijournal_pageCollection?: Maybe<Eprijournal_PageCollection>;
  eprijournal_people?: Maybe<Eprijournal_People>;
  eprijournal_peopleCollection?: Maybe<Eprijournal_PeopleCollection>;
  eprijournal_podcastPost?: Maybe<Eprijournal_PodcastPost>;
  eprijournal_podcastPostCollection?: Maybe<Eprijournal_PodcastPostCollection>;
  eprijournal_post?: Maybe<Eprijournal_Post>;
  eprijournal_postCollection?: Maybe<Eprijournal_PostCollection>;
  eprijournal_reportPost?: Maybe<Eprijournal_ReportPost>;
  eprijournal_reportPostCollection?: Maybe<Eprijournal_ReportPostCollection>;
  eprijournal_rssFeedUrl?: Maybe<Eprijournal_RssFeedUrl>;
  eprijournal_rssFeedUrlCollection?: Maybe<Eprijournal_RssFeedUrlCollection>;
  eprijournal_shortText?: Maybe<Eprijournal_ShortText>;
  eprijournal_shortTextCollection?: Maybe<Eprijournal_ShortTextCollection>;
  eprijournal_storyCards?: Maybe<Eprijournal_StoryCards>;
  eprijournal_storyCardsCollection?: Maybe<Eprijournal_StoryCardsCollection>;
  eprijournal_subCategory?: Maybe<Eprijournal_SubCategory>;
  eprijournal_subCategoryCollection?: Maybe<Eprijournal_SubCategoryCollection>;
  eprijournal_tag?: Maybe<Eprijournal_Tag>;
  eprijournal_tagCollection?: Maybe<Eprijournal_TagCollection>;
  eprijournal_wrapper?: Maybe<Eprijournal_Wrapper>;
  eprijournal_wrapperCollection?: Maybe<Eprijournal_WrapperCollection>;
  eventcourseFeaturedList?: Maybe<EventcourseFeaturedList>;
  eventcourseFeaturedListCollection?: Maybe<EventcourseFeaturedListCollection>;
  eventcourseSection?: Maybe<EventcourseSection>;
  eventcourseSectionCollection?: Maybe<EventcourseSectionCollection>;
  events?: Maybe<Events>;
  eventsCollection?: Maybe<EventsCollection>;
  faq?: Maybe<Faq>;
  faqCollection?: Maybe<FaqCollection>;
  faqItem?: Maybe<FaqItem>;
  faqItemCollection?: Maybe<FaqItemCollection>;
  featuredReport?: Maybe<FeaturedReport>;
  featuredReportCollection?: Maybe<FeaturedReportCollection>;
  featuredResearcherSection?: Maybe<FeaturedResearcherSection>;
  featuredResearcherSectionCollection?: Maybe<FeaturedResearcherSectionCollection>;
  findCareerSection?: Maybe<FindCareerSection>;
  findCareerSectionCollection?: Maybe<FindCareerSectionCollection>;
  footer?: Maybe<Footer>;
  footerCollection?: Maybe<FooterCollection>;
  galleryTemplate?: Maybe<GalleryTemplate>;
  galleryTemplateCollection?: Maybe<GalleryTemplateCollection>;
  genericTitleAndBodyContent?: Maybe<GenericTitleAndBodyContent>;
  genericTitleAndBodyContentCollection?: Maybe<GenericTitleAndBodyContentCollection>;
  governance?: Maybe<Governance>;
  governanceCollection?: Maybe<GovernanceCollection>;
  governanceContent?: Maybe<GovernanceContent>;
  governanceContentCollection?: Maybe<GovernanceContentCollection>;
  hbuDemoData?: Maybe<HbuDemoData>;
  hbuDemoDataCollection?: Maybe<HbuDemoDataCollection>;
  header?: Maybe<Header>;
  headerCollection?: Maybe<HeaderCollection>;
  hello: Scalars['String'];
  help?: Maybe<Help>;
  helpCollection?: Maybe<HelpCollection>;
  helpTab?: Maybe<HelpTab>;
  helpTabCollection?: Maybe<HelpTabCollection>;
  home?: Maybe<Home>;
  homeCollection?: Maybe<HomeCollection>;
  issueRequests?: Maybe<IssueRequests>;
  issueRequestsCollection?: Maybe<IssueRequestsCollection>;
  jumbotron?: Maybe<Jumbotron>;
  jumbotronCollection?: Maybe<JumbotronCollection>;
  leaderCard?: Maybe<LeaderCard>;
  leaderCardCollection?: Maybe<LeaderCardCollection>;
  leaders?: Maybe<Leaders>;
  leadersCollection?: Maybe<LeadersCollection>;
  link?: Maybe<Link>;
  linkCollection?: Maybe<LinkCollection>;
  linkGroup?: Maybe<LinkGroup>;
  linkGroupCollection?: Maybe<LinkGroupCollection>;
  listComponent?: Maybe<ListComponent>;
  listComponentCollection?: Maybe<ListComponentCollection>;
  locationTab?: Maybe<LocationTab>;
  locationTabCollection?: Maybe<LocationTabCollection>;
  locations?: Maybe<Locations>;
  locationsCollection?: Maybe<LocationsCollection>;
  ltn?: Maybe<Ltn>;
  ltnCollection?: Maybe<LtnCollection>;
  ltnLandingPage?: Maybe<LtnLandingPage>;
  ltnLandingPageCollection?: Maybe<LtnLandingPageCollection>;
  markdown?: Maybe<Markdown>;
  markdownCollection?: Maybe<MarkdownCollection>;
  mediaHits?: Maybe<MediaHits>;
  mediaHitsCollection?: Maybe<MediaHitsCollection>;
  mediaResources?: Maybe<MediaResources>;
  mediaResourcesCollection?: Maybe<MediaResourcesCollection>;
  multimediaTemplate?: Maybe<MultimediaTemplate>;
  multimediaTemplateCollection?: Maybe<MultimediaTemplateCollection>;
  ndeToolLandingPage?: Maybe<NdeToolLandingPage>;
  ndeToolLandingPageCollection?: Maybe<NdeToolLandingPageCollection>;
  newFeaturesPage?: Maybe<NewFeaturesPage>;
  newFeaturesPageCollection?: Maybe<NewFeaturesPageCollection>;
  nuclearPlantModernizationLandingPage?: Maybe<NuclearPlantModernizationLandingPage>;
  nuclearPlantModernizationLandingPageCollection?: Maybe<NuclearPlantModernizationLandingPageCollection>;
  nuclearPlantModernizationSweepScoreCategory?: Maybe<NuclearPlantModernizationSweepScoreCategory>;
  nuclearPlantModernizationSweepScoreCategoryCollection?: Maybe<NuclearPlantModernizationSweepScoreCategoryCollection>;
  nuclearPlantModernizationSweepScoreLegend?: Maybe<NuclearPlantModernizationSweepScoreLegend>;
  nuclearPlantModernizationSweepScoreLegendCollection?: Maybe<NuclearPlantModernizationSweepScoreLegendCollection>;
  page?: Maybe<Page>;
  pageCollection?: Maybe<PageCollection>;
  pagePreConfiguredTemplates?: Maybe<PagePreConfiguredTemplates>;
  pagePreConfiguredTemplatesCollection?: Maybe<PagePreConfiguredTemplatesCollection>;
  part810Agreement?: Maybe<Part810Agreement>;
  part810AgreementCollection?: Maybe<Part810AgreementCollection>;
  part810AgreementItem?: Maybe<Part810AgreementItem>;
  part810AgreementItemCollection?: Maybe<Part810AgreementItemCollection>;
  person?: Maybe<Person>;
  personCollection?: Maybe<PersonCollection>;
  portfolioSection?: Maybe<PortfolioSection>;
  portfolioSectionCollection?: Maybe<PortfolioSectionCollection>;
  portfolioSectors?: Maybe<PortfolioSectors>;
  portfolioSectorsCollection?: Maybe<PortfolioSectorsCollection>;
  pressRelease?: Maybe<PressRelease>;
  pressReleaseCollection?: Maybe<PressReleaseCollection>;
  pressReleaseLanding?: Maybe<PressReleaseLanding>;
  pressReleaseLandingCollection?: Maybe<PressReleaseLandingCollection>;
  privacyStatement?: Maybe<PrivacyStatement>;
  privacyStatementCollection?: Maybe<PrivacyStatementCollection>;
  privacyStatementContent?: Maybe<PrivacyStatementContent>;
  privacyStatementContentCollection?: Maybe<PrivacyStatementContentCollection>;
  profileCard?: Maybe<ProfileCard>;
  profileCardCollection?: Maybe<ProfileCardCollection>;
  programContacts?: Maybe<ProgramContacts>;
  programContactsCollection?: Maybe<ProgramContactsCollection>;
  protect?: Maybe<Protect>;
  protectCollection?: Maybe<ProtectCollection>;
  publicAttachment?: Maybe<PublicAttachment>;
  publicAttachmentCollection?: Maybe<PublicAttachmentCollection>;
  racContacts?: Maybe<RacContacts>;
  racContactsCollection?: Maybe<RacContactsCollection>;
  report?: Maybe<Report>;
  reportCollection?: Maybe<ReportCollection>;
  research?: Maybe<Research>;
  researchAdvisoryCommitteeHome?: Maybe<ResearchAdvisoryCommitteeHome>;
  researchAdvisoryCommitteeHomeCollection?: Maybe<ResearchAdvisoryCommitteeHomeCollection>;
  researchCollection?: Maybe<ResearchCollection>;
  researchLandingPage?: Maybe<ResearchLandingPage>;
  researchLandingPageCollection?: Maybe<ResearchLandingPageCollection>;
  researchLandingTabs?: Maybe<ResearchLandingTabs>;
  researchLandingTabsCollection?: Maybe<ResearchLandingTabsCollection>;
  resourceDocument?: Maybe<ResourceDocument>;
  resourceDocumentCollection?: Maybe<ResourceDocumentCollection>;
  securedAttachment?: Maybe<SecuredAttachment>;
  securedAttachmentCollection?: Maybe<SecuredAttachmentCollection>;
  seo?: Maybe<Seo>;
  seoCollection?: Maybe<SeoCollection>;
  singleVideoTemplate?: Maybe<SingleVideoTemplate>;
  singleVideoTemplateCollection?: Maybe<SingleVideoTemplateCollection>;
  sitemap?: Maybe<Sitemap>;
  sitemapCollection?: Maybe<SitemapCollection>;
  storyCards?: Maybe<StoryCards>;
  storyCardsCollection?: Maybe<StoryCardsCollection>;
  tabs?: Maybe<Tabs>;
  tabsCollection?: Maybe<TabsCollection>;
  termsOfUse?: Maybe<TermsOfUse>;
  termsOfUseCollection?: Maybe<TermsOfUseCollection>;
  termsOfUseContent?: Maybe<TermsOfUseContent>;
  termsOfUseContentCollection?: Maybe<TermsOfUseContentCollection>;
  thoughtLeadership?: Maybe<ThoughtLeadership>;
  thoughtLeadershipCollection?: Maybe<ThoughtLeadershipCollection>;
  thoughtLeadershipDownloads?: Maybe<ThoughtLeadershipDownloads>;
  thoughtLeadershipDownloadsCollection?: Maybe<ThoughtLeadershipDownloadsCollection>;
  twoColumnTemplate?: Maybe<TwoColumnTemplate>;
  twoColumnTemplateCollection?: Maybe<TwoColumnTemplateCollection>;
  videoTemplate?: Maybe<VideoTemplate>;
  videoTemplateCollection?: Maybe<VideoTemplateCollection>;
  xLprLandingPage?: Maybe<XLprLandingPage>;
  xLprLandingPageCollection?: Maybe<XLprLandingPageCollection>;
  xlprLandingPage?: Maybe<XlprLandingPage>;
  xlprLandingPageCollection?: Maybe<XlprLandingPageCollection>;
};


export type QueryAboutEpriArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAboutEpriCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AboutEpriOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AboutEpriFilter>;
};


export type QueryAdvisoryBoardArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAdvisoryBoardCardArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAdvisoryBoardCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AdvisoryBoardCardOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdvisoryBoardCardFilter>;
};


export type QueryAdvisoryBoardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AdvisoryBoardOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdvisoryBoardFilter>;
};


export type QueryAiComponentArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAiComponentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AiComponentOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AiComponentFilter>;
};


export type QueryAiPageArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAiPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AiPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AiPageFilter>;
};


export type QueryAiTemplatePageArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAiTemplatePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AiTemplatePageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AiTemplatePageFilter>;
};


export type QueryAnnouncementArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAnnouncementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AnnouncementOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AnnouncementFilter>;
};


export type QueryAnnouncementWrapperArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAnnouncementWrapperCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AnnouncementWrapperOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AnnouncementWrapperFilter>;
};


export type QueryAssetArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAssetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AssetOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetFilter>;
};


export type QueryBasicTemplateArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBasicTemplateCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BasicTemplateOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BasicTemplateFilter>;
};


export type QueryBoardOfDirectorCardArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBoardOfDirectorCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BoardOfDirectorCardOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BoardOfDirectorCardFilter>;
};


export type QueryBoardOfDirectorsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBoardOfDirectorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<BoardOfDirectorsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BoardOfDirectorsFilter>;
};


export type QueryButtonArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryButtonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ButtonOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ButtonFilter>;
};


export type QueryBwrvip03Args = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBwrvip03CollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<Bwrvip03Order>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Bwrvip03Filter>;
};


export type QueryBwrvip03SectionArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBwrvip03SectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<Bwrvip03SectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Bwrvip03SectionFilter>;
};


export type QueryCardTemplateArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCardTemplateCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CardTemplateOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CardTemplateFilter>;
};


export type QueryCareersArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCareersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CareersOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CareersFilter>;
};


export type QueryContactArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryContactCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ContactOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContactFilter>;
};


export type QueryCopyrightArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCopyrightCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CopyrightOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CopyrightFilter>;
};


export type QueryCoresprayPipingLandingPageArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCoresprayPipingLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CoresprayPipingLandingPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoresprayPipingLandingPageFilter>;
};


export type QueryCustomerServiceArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCustomerServiceCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CustomerServiceOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CustomerServiceFilter>;
};


export type QueryEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<EntryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EntryFilter>;
};


export type QueryEpriUArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryEpriUCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<EpriUOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EpriUFilter>;
};


export type QueryEpri_BCamArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_GetAdvisorCommitteeExportDataArgs = {
  companyGroup: Scalars['String'];
};


export type QueryEpri_SdftcBySectorSummaryArgs = {
  companyId: Scalars['String'];
};


export type QueryEpri_TrainingDetailsReportArgs = {
  companyGroup: Scalars['String'];
  endDate?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sectorLTN?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  startRecord?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_AbstractArgs = {
  langCode?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
};


export type QueryEpri_AccountExecutivesArgs = {
  displayType?: InputMaybe<DisplayType>;
  keyword?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  region: RegionCode;
  startRecord?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_AcgSummaryArgs = {
  companyId: Scalars['String'];
  year?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_AdvisorActivityArgs = {
  contactId: Scalars['String'];
};


export type QueryEpri_AllHierarchyArgs = {
  langCode?: InputMaybe<Scalars['String']>;
  ltn?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortDirection>;
};


export type QueryEpri_AllIndividualAccessDetailsArgs = {
  companyId: Scalars['String'];
  sectorLTN?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_AnnouncementUpdatesArgs = {
  pageSize?: InputMaybe<Scalars['Int']>;
  startRecord?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_AttendedTrainingsArgs = {
  pageSize?: InputMaybe<Scalars['Int']>;
  startRecord?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_BookmarksArgs = {
  id?: InputMaybe<Scalars['String']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  type: BookmarkType;
};


export type QueryEpri_ByTopicArgs = {
  LTN: Scalars['String'];
  language?: InputMaybe<Scalars['String']>;
  subTopicId?: InputMaybe<Scalars['String']>;
  topicId?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_CheckUserArgs = {
  companyGroup?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_ClientDeliverablesArgs = {
  companyGroup: Scalars['String'];
  eligibleOnly?: InputMaybe<Scalars['Int']>;
  sectorLTN?: InputMaybe<Scalars['String']>;
  year: Scalars['Int'];
};


export type QueryEpri_ClientDeliverablesSummaryArgs = {
  companyId: Scalars['String'];
};


export type QueryEpri_CommitteeAdvisorsArgs = {
  committeeId: Scalars['String'];
  companyGroup: Scalars['String'];
};


export type QueryEpri_CommitteeContactsArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_CommitteesArgs = {
  langCode?: InputMaybe<Scalars['String']>;
  ltn?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_CompaniesArgs = {
  excludeACG?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<CompanyOption>;
  searchCompanyName?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_CompanyByIdArgs = {
  id: Scalars['Int'];
};


export type QueryEpri_CompanyParticipantDetailsArgs = {
  columnClicked?: InputMaybe<ColumnClicked>;
  companyId?: InputMaybe<Scalars['String']>;
  contactId?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_CompanyParticipantSummaryArgs = {
  companyId?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_ContactsArgs = {
  allInGroup?: InputMaybe<Scalars['Int']>;
  city?: InputMaybe<Scalars['String']>;
  committeeId?: InputMaybe<Scalars['String']>;
  companyGroup?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  displayType?: InputMaybe<DisplayType>;
  excludeContactsInProduct?: InputMaybe<Scalars['String']>;
  favoritesOnly?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  hasEmailUserName?: InputMaybe<Scalars['Int']>;
  includeCommittees?: InputMaybe<Scalars['Int']>;
  isAdvisor?: InputMaybe<Scalars['Int']>;
  isMett?: InputMaybe<Scalars['Int']>;
  isSalesRep?: InputMaybe<Scalars['Int']>;
  keyword?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  ltn?: InputMaybe<Scalars['String']>;
  overrideDeclineToDisplay?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<RegionCode>;
  startRecord?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
  view?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_CountriesArgs = {
  excludeEmbargoed?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  langCode?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_EventArgs = {
  id: Scalars['String'];
  ltn?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_EventDetailsReportArgs = {
  companyGroup: Scalars['String'];
  endDate?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sectorLTN?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  startRecord?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_EventInviteesSummaryArgs = {
  companyGroup: Scalars['String'];
  endDate?: InputMaybe<Scalars['String']>;
  sectorLTN?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_EventdashboardArgs = {
  language?: InputMaybe<Scalars['String']>;
  pagesize?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_EventsArgs = {
  attendedOnly?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<Scalars['String']>;
  enddate?: InputMaybe<Scalars['String']>;
  featuredOnly?: InputMaybe<Scalars['Int']>;
  invitedOnly?: InputMaybe<Scalars['Int']>;
  ltn?: InputMaybe<Scalars['String']>;
  onCalendar?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  registeredOnly?: InputMaybe<Scalars['Int']>;
  sectorltn?: InputMaybe<Scalars['String']>;
  sortColumn?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
  startRecord?: InputMaybe<Scalars['Int']>;
  startdate?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_ExaminationBasisListArgs = {
  acceptance?: InputMaybe<Scalars['String']>;
  analysisType?: InputMaybe<Scalars['String']>;
  basisId?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<Scalars['Int']>;
  componentGroups?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  startRecord?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_ExaminationCategoriesArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
  categoryName?: InputMaybe<Scalars['String']>;
  classNumber?: InputMaybe<Scalars['Int']>;
  componentGroup?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  figureNumber?: InputMaybe<Scalars['String']>;
  itemId?: InputMaybe<Scalars['String']>;
  itemNumber?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  startRecord?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_ExaminationDocumentDetailsArgs = {
  documentId?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_ExaminationDocumentsArgs = {
  basisId?: InputMaybe<Scalars['Int']>;
  basisType?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['Int']>;
  categoryName?: InputMaybe<Scalars['String']>;
  componentGroups?: InputMaybe<Scalars['String']>;
  documentNumber?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  publisher?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  startRecord?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_ExaminationItemDetailsArgs = {
  itemId?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_ExistingProductCreditsArgs = {
  companyId: Scalars['String'];
  pageSize?: InputMaybe<Scalars['Int']>;
  startRecord?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_ExportAcgDataArgs = {
  companyId: Scalars['String'];
  option: ExportType;
};


export type QueryEpri_GetBwvripChaptersArgs = {
  name?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_GetBwvripReportsArgs = {
  active?: InputMaybe<Scalars['String']>;
  bwranyModel?: InputMaybe<Scalars['String']>;
  bwrmodel1?: InputMaybe<Scalars['String']>;
  bwrmodel2?: InputMaybe<Scalars['String']>;
  bwrmodel3?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  include?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  reportNumber?: InputMaybe<Scalars['String']>;
  revisionForm?: InputMaybe<Scalars['String']>;
  startRecord?: InputMaybe<Scalars['Int']>;
  vendor?: InputMaybe<Scalars['String']>;
  weld1?: InputMaybe<Scalars['String']>;
  weld2?: InputMaybe<Scalars['String']>;
  weld3?: InputMaybe<Scalars['String']>;
  weld4?: InputMaybe<Scalars['String']>;
  weld5?: InputMaybe<Scalars['String']>;
  weld6?: InputMaybe<Scalars['String']>;
  weld7?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_GetBwvripVendorsArgs = {
  name?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_GetProductsInCartArgs = {
  orderId?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_GetWeldOptionsByVendorArgs = {
  bwrmodel: Scalars['String'];
  vendor: Scalars['String'];
};


export type QueryEpri_HierarchyArgs = {
  langCode?: InputMaybe<Scalars['String']>;
  ltn?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_IndividualAccessDetailsArgs = {
  companyId: Scalars['String'];
  sectorLTN: Scalars['String'];
};


export type QueryEpri_IndividualAccessProductContactsArgs = {
  companyGroup: Scalars['String'];
  productId?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_IndividualAccessSummaryArgs = {
  companyId: Scalars['String'];
};


export type QueryEpri_InviteeDetailsReportArgs = {
  companyGroup: Scalars['String'];
  endDate?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sectorLTN?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  startRecord?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_LinksArgs = {
  ltn?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_LookupAttributesArgs = {
  type: Scalars['String'];
};


export type QueryEpri_MettSupplementalsArgs = {
  companyId: Scalars['String'];
};


export type QueryEpri_NominationArgs = {
  id: Scalars['String'];
  status?: InputMaybe<Status>;
};


export type QueryEpri_NominationsArgs = {
  createdBy?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sectorLTN?: InputMaybe<Scalars['String']>;
  startRecord?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  year?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_OpenInvoicesArgs = {
  companyId: Scalars['String'];
  pageSize?: InputMaybe<Scalars['Int']>;
  startRecord?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_PlantModernizationGuidesArgs = {
  functionalArea?: InputMaybe<Scalars['String']>;
  secondary?: InputMaybe<Scalars['String']>;
  tertiary?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_PlantModernizationModelArgs = {
  functionalArea?: InputMaybe<Scalars['String']>;
  secondary?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_PlantsArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  plantId?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_PortfoliosectorsArgs = {
  langcode?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_ProductArgs = {
  langCode?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
  user: Scalars['String'];
};


export type QueryEpri_ProductDownloadArgs = {
  ipAddress?: InputMaybe<Scalars['String']>;
  langCode?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
};


export type QueryEpri_ProductsArgs = {
  langCode?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  startRecord?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  user: Scalars['String'];
};


export type QueryEpri_ProductsByTypeArgs = {
  documentType?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['String']>;
  fundedOnly?: InputMaybe<Scalars['Int']>;
  langCode?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  siteLTN?: InputMaybe<Scalars['String']>;
  sortColumn?: InputMaybe<Scalars['String']>;
  startRecord?: InputMaybe<Scalars['Int']>;
  supplementalOnly?: InputMaybe<Scalars['Int']>;
  tabType?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  typeCategory?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_ProgramArgs = {
  langCode?: InputMaybe<Scalars['String']>;
  programLTN: Scalars['String'];
  year: Scalars['String'];
};


export type QueryEpri_ProgramFavoritesArgs = {
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_ProgramParticipationDetailsArgs = {
  companyGroup: Scalars['String'];
  pageSize?: InputMaybe<Scalars['Int']>;
  sortColumn?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
  startRecord?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_ProgramParticipationSummaryArgs = {
  companyGroup: Scalars['String'];
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sortColumn?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_ProgramProjectsArgs = {
  langCode?: InputMaybe<Scalars['String']>;
  ltn?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_ProgramSupplementalsArgs = {
  ltn: Scalars['String'];
};


export type QueryEpri_ProgramsArgs = {
  langCode?: InputMaybe<Scalars['String']>;
  sectorLTN?: InputMaybe<Scalars['String']>;
  year: Scalars['String'];
};


export type QueryEpri_ProgramsByResearchAreaArgs = {
  langCode?: InputMaybe<Scalars['String']>;
  sectorLTN?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_ProjectOverviewArgs = {
  langCode?: InputMaybe<Scalars['String']>;
  ltn: Scalars['String'];
  year?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_ProjectStatusUpdateArgs = {
  langCode?: InputMaybe<Scalars['String']>;
  ltn: Scalars['String'];
  month?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_ProjectsArgs = {
  langCode?: InputMaybe<Scalars['String']>;
  programLTN: Scalars['String'];
  year?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_SectorArgs = {
  langCode?: InputMaybe<Scalars['String']>;
  sectorLTN: Scalars['String'];
  year: Scalars['String'];
};


export type QueryEpri_SectorAdvisorDetailsArgs = {
  companyGroup: Scalars['String'];
  sectorLTN: Scalars['String'];
};


export type QueryEpri_SectorAdvisorSummaryArgs = {
  companyGroup: Scalars['String'];
};


export type QueryEpri_SectorAdvisorsArgs = {
  companyGroup: Scalars['String'];
  sectorLTN?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_SelfDirectedFundingDetailsArgs = {
  companyId: Scalars['String'];
  fundingYear?: InputMaybe<Scalars['Int']>;
  sectorLTN?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_SelfDirectedFundingSummaryArgs = {
  companyId: Scalars['String'];
  fundingYear?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_SendAdvisorNotificationsArgs = {
  requestId: Scalars['Int'];
};


export type QueryEpri_SendIndividualAccessRequestNotificationsArgs = {
  requestId: Scalars['Int'];
};


export type QueryEpri_SiteArgs = {
  langCode?: InputMaybe<Scalars['String']>;
  ltn: Scalars['String'];
};


export type QueryEpri_SitesArgs = {
  isFavorite?: InputMaybe<Scalars['Int']>;
  ltn?: InputMaybe<Scalars['String']>;
  parentLTN?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_SubsidiaryPendingRequestsArgs = {
  companyId: Scalars['String'];
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_SupplementalFundingDetailsArgs = {
  companyId: Scalars['String'];
  pageSize?: InputMaybe<Scalars['Int']>;
  sortColumn?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
  startRecord?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_SupplementalFundingSummaryArgs = {
  companyId: Scalars['String'];
  pageSize?: InputMaybe<Scalars['Int']>;
  sortColumn?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
  startRecord?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_SupplementalsArgs = {
  langCode?: InputMaybe<Scalars['String']>;
  sectorLTN?: InputMaybe<Scalars['String']>;
  year: Scalars['String'];
};


export type QueryEpri_TailoredCollabFundingDetailsArgs = {
  companyId: Scalars['String'];
  fundingYear?: InputMaybe<Scalars['Int']>;
  sectorLTN?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_TailoredCollabFundingSummaryArgs = {
  companyId: Scalars['String'];
  fundingYear?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_TopDownloadProductsReportArgs = {
  companyGroup: Scalars['String'];
  orderYear?: InputMaybe<Scalars['Int']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sectorLTN?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_TrainingArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_TrainingsArgs = {
  attendedOnly?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<Scalars['String']>;
  enddate?: InputMaybe<Scalars['String']>;
  featuredOnly?: InputMaybe<Scalars['Int']>;
  invitedOnly?: InputMaybe<Scalars['Int']>;
  ltn?: InputMaybe<Scalars['String']>;
  onCalendar?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  registeredOnly?: InputMaybe<Scalars['Int']>;
  sectorltn?: InputMaybe<Scalars['String']>;
  startRecord?: InputMaybe<Scalars['Int']>;
  startdate?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_TrainingsSummaryArgs = {
  companyGroup: Scalars['String'];
  dashboard?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_TypeCategoriesArgs = {
  searchTerm?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_UsersOrderReportArgs = {
  companyGroup: Scalars['String'];
  orderYear?: InputMaybe<Scalars['Int']>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sectorLTN?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_WhoOrderedWhatDetailsArgs = {
  companyGroup: Scalars['String'];
  orderEndDate?: InputMaybe<Scalars['String']>;
  orderNumber?: InputMaybe<Scalars['Int']>;
  orderStartDate?: InputMaybe<Scalars['String']>;
  orderYear?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  products?: InputMaybe<Scalars['String']>;
  sectorLTN?: InputMaybe<Scalars['String']>;
  startRecord?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_WhoOrderedWhatSummaryArgs = {
  companyGroup: Scalars['String'];
  orderEndDate?: InputMaybe<Scalars['String']>;
  orderStartDate?: InputMaybe<Scalars['String']>;
  orderYear?: InputMaybe<Scalars['Int']>;
  products?: InputMaybe<Scalars['String']>;
};


export type QueryEpri_YearlyFundingDetailsArgs = {
  companyId: Scalars['String'];
  pageSize?: InputMaybe<Scalars['Int']>;
  sortColumn?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
  startRecord?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};


export type QueryEpri_YearlyFundingSummaryArgs = {
  companyId: Scalars['String'];
  pageSize?: InputMaybe<Scalars['Int']>;
  sortColumn?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
  startRecord?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};


export type QueryEprijournal_ArchivePostArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryEprijournal_ArchivePostCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<Eprijournal_ArchivePostOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Eprijournal_ArchivePostFilter>;
};


export type QueryEprijournal_ArticleLinksArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryEprijournal_ArticleLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<Eprijournal_ArticleLinksOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Eprijournal_ArticleLinksFilter>;
};


export type QueryEprijournal_AssetArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryEprijournal_AssetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<Eprijournal_AssetOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Eprijournal_AssetFilter>;
};


export type QueryEprijournal_AuthorArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryEprijournal_AuthorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<Eprijournal_AuthorOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Eprijournal_AuthorFilter>;
};


export type QueryEprijournal_CategoryArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryEprijournal_CategoryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<Eprijournal_CategoryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Eprijournal_CategoryFilter>;
};


export type QueryEprijournal_EntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<Eprijournal_EntryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Eprijournal_EntryFilter>;
};


export type QueryEprijournal_JumbotronArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryEprijournal_JumbotronCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<Eprijournal_JumbotronOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Eprijournal_JumbotronFilter>;
};


export type QueryEprijournal_MainCategoryArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryEprijournal_MainCategoryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<Eprijournal_MainCategoryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Eprijournal_MainCategoryFilter>;
};


export type QueryEprijournal_PageArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryEprijournal_PageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<Eprijournal_PageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Eprijournal_PageFilter>;
};


export type QueryEprijournal_PeopleArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryEprijournal_PeopleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<Eprijournal_PeopleOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Eprijournal_PeopleFilter>;
};


export type QueryEprijournal_PodcastPostArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryEprijournal_PodcastPostCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<Eprijournal_PodcastPostOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Eprijournal_PodcastPostFilter>;
};


export type QueryEprijournal_PostArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryEprijournal_PostCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<Eprijournal_PostOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Eprijournal_PostFilter>;
};


export type QueryEprijournal_ReportPostArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryEprijournal_ReportPostCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<Eprijournal_ReportPostOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Eprijournal_ReportPostFilter>;
};


export type QueryEprijournal_RssFeedUrlArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryEprijournal_RssFeedUrlCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<Eprijournal_RssFeedUrlOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Eprijournal_RssFeedUrlFilter>;
};


export type QueryEprijournal_ShortTextArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryEprijournal_ShortTextCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<Eprijournal_ShortTextOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Eprijournal_ShortTextFilter>;
};


export type QueryEprijournal_StoryCardsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryEprijournal_StoryCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<Eprijournal_StoryCardsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Eprijournal_StoryCardsFilter>;
};


export type QueryEprijournal_SubCategoryArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryEprijournal_SubCategoryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<Eprijournal_SubCategoryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Eprijournal_SubCategoryFilter>;
};


export type QueryEprijournal_TagArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryEprijournal_TagCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<Eprijournal_TagOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Eprijournal_TagFilter>;
};


export type QueryEprijournal_WrapperArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryEprijournal_WrapperCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<Eprijournal_WrapperOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Eprijournal_WrapperFilter>;
};


export type QueryEventcourseFeaturedListArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryEventcourseFeaturedListCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<EventcourseFeaturedListOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventcourseFeaturedListFilter>;
};


export type QueryEventcourseSectionArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryEventcourseSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<EventcourseSectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventcourseSectionFilter>;
};


export type QueryEventsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryEventsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<EventsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventsFilter>;
};


export type QueryFaqArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryFaqCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FaqOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqFilter>;
};


export type QueryFaqItemArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryFaqItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FaqItemOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqItemFilter>;
};


export type QueryFeaturedReportArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryFeaturedReportCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FeaturedReportOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeaturedReportFilter>;
};


export type QueryFeaturedResearcherSectionArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryFeaturedResearcherSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FeaturedResearcherSectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeaturedResearcherSectionFilter>;
};


export type QueryFindCareerSectionArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryFindCareerSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FindCareerSectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FindCareerSectionFilter>;
};


export type QueryFooterArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryFooterCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<FooterOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FooterFilter>;
};


export type QueryGalleryTemplateArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGalleryTemplateCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<GalleryTemplateOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GalleryTemplateFilter>;
};


export type QueryGenericTitleAndBodyContentArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGenericTitleAndBodyContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<GenericTitleAndBodyContentOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GenericTitleAndBodyContentFilter>;
};


export type QueryGovernanceArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGovernanceCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<GovernanceOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GovernanceFilter>;
};


export type QueryGovernanceContentArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGovernanceContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<GovernanceContentOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GovernanceContentFilter>;
};


export type QueryHbuDemoDataArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryHbuDemoDataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<HbuDemoDataOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HbuDemoDataFilter>;
};


export type QueryHeaderArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryHeaderCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<HeaderOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HeaderFilter>;
};


export type QueryHelpArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryHelpCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<HelpOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HelpFilter>;
};


export type QueryHelpTabArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryHelpTabCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<HelpTabOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HelpTabFilter>;
};


export type QueryHomeArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<HomeOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HomeFilter>;
};


export type QueryIssueRequestsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryIssueRequestsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<IssueRequestsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<IssueRequestsFilter>;
};


export type QueryJumbotronArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryJumbotronCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<JumbotronOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<JumbotronFilter>;
};


export type QueryLeaderCardArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLeaderCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<LeaderCardOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LeaderCardFilter>;
};


export type QueryLeadersArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLeadersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<LeadersOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LeadersFilter>;
};


export type QueryLinkArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<LinkOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LinkFilter>;
};


export type QueryLinkGroupArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLinkGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<LinkGroupOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LinkGroupFilter>;
};


export type QueryListComponentArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryListComponentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ListComponentOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ListComponentFilter>;
};


export type QueryLocationTabArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLocationTabCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<LocationTabOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LocationTabFilter>;
};


export type QueryLocationsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLocationsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<LocationsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LocationsFilter>;
};


export type QueryLtnArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLtnCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<LtnOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LtnFilter>;
};


export type QueryLtnLandingPageArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLtnLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<LtnLandingPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LtnLandingPageFilter>;
};


export type QueryMarkdownArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryMarkdownCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MarkdownOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MarkdownFilter>;
};


export type QueryMediaHitsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryMediaHitsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MediaHitsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MediaHitsFilter>;
};


export type QueryMediaResourcesArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryMediaResourcesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MediaResourcesOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MediaResourcesFilter>;
};


export type QueryMultimediaTemplateArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryMultimediaTemplateCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<MultimediaTemplateOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MultimediaTemplateFilter>;
};


export type QueryNdeToolLandingPageArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryNdeToolLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<NdeToolLandingPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NdeToolLandingPageFilter>;
};


export type QueryNewFeaturesPageArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryNewFeaturesPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<NewFeaturesPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NewFeaturesPageFilter>;
};


export type QueryNuclearPlantModernizationLandingPageArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryNuclearPlantModernizationLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<NuclearPlantModernizationLandingPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NuclearPlantModernizationLandingPageFilter>;
};


export type QueryNuclearPlantModernizationSweepScoreCategoryArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryNuclearPlantModernizationSweepScoreCategoryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<NuclearPlantModernizationSweepScoreCategoryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NuclearPlantModernizationSweepScoreCategoryFilter>;
};


export type QueryNuclearPlantModernizationSweepScoreLegendArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryNuclearPlantModernizationSweepScoreLegendCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<NuclearPlantModernizationSweepScoreLegendOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NuclearPlantModernizationSweepScoreLegendFilter>;
};


export type QueryPageArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageFilter>;
};


export type QueryPagePreConfiguredTemplatesArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPagePreConfiguredTemplatesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PagePreConfiguredTemplatesOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PagePreConfiguredTemplatesFilter>;
};


export type QueryPart810AgreementArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPart810AgreementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<Part810AgreementOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Part810AgreementFilter>;
};


export type QueryPart810AgreementItemArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPart810AgreementItemCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<Part810AgreementItemOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Part810AgreementItemFilter>;
};


export type QueryPersonArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPersonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PersonOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PersonFilter>;
};


export type QueryPortfolioSectionArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPortfolioSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PortfolioSectionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PortfolioSectionFilter>;
};


export type QueryPortfolioSectorsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPortfolioSectorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PortfolioSectorsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PortfolioSectorsFilter>;
};


export type QueryPressReleaseArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPressReleaseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PressReleaseOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PressReleaseFilter>;
};


export type QueryPressReleaseLandingArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPressReleaseLandingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PressReleaseLandingOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PressReleaseLandingFilter>;
};


export type QueryPrivacyStatementArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPrivacyStatementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PrivacyStatementOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PrivacyStatementFilter>;
};


export type QueryPrivacyStatementContentArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPrivacyStatementContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PrivacyStatementContentOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PrivacyStatementContentFilter>;
};


export type QueryProfileCardArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryProfileCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ProfileCardOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProfileCardFilter>;
};


export type QueryProgramContactsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryProgramContactsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ProgramContactsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProgramContactsFilter>;
};


export type QueryProtectArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryProtectCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ProtectOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProtectFilter>;
};


export type QueryPublicAttachmentArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPublicAttachmentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<PublicAttachmentOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PublicAttachmentFilter>;
};


export type QueryRacContactsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryRacContactsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<RacContactsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RacContactsFilter>;
};


export type QueryReportArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryReportCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ReportOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReportFilter>;
};


export type QueryResearchArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryResearchAdvisoryCommitteeHomeArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryResearchAdvisoryCommitteeHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ResearchAdvisoryCommitteeHomeOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ResearchAdvisoryCommitteeHomeFilter>;
};


export type QueryResearchCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ResearchOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ResearchFilter>;
};


export type QueryResearchLandingPageArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryResearchLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ResearchLandingPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ResearchLandingPageFilter>;
};


export type QueryResearchLandingTabsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryResearchLandingTabsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ResearchLandingTabsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ResearchLandingTabsFilter>;
};


export type QueryResourceDocumentArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryResourceDocumentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ResourceDocumentOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ResourceDocumentFilter>;
};


export type QuerySecuredAttachmentArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySecuredAttachmentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SecuredAttachmentOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SecuredAttachmentFilter>;
};


export type QuerySeoArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySeoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SeoOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SeoFilter>;
};


export type QuerySingleVideoTemplateArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySingleVideoTemplateCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SingleVideoTemplateOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SingleVideoTemplateFilter>;
};


export type QuerySitemapArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySitemapCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SitemapOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SitemapFilter>;
};


export type QueryStoryCardsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryStoryCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<StoryCardsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoryCardsFilter>;
};


export type QueryTabsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryTabsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<TabsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TabsFilter>;
};


export type QueryTermsOfUseArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryTermsOfUseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<TermsOfUseOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TermsOfUseFilter>;
};


export type QueryTermsOfUseContentArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryTermsOfUseContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<TermsOfUseContentOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TermsOfUseContentFilter>;
};


export type QueryThoughtLeadershipArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryThoughtLeadershipCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ThoughtLeadershipOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ThoughtLeadershipFilter>;
};


export type QueryThoughtLeadershipDownloadsArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryThoughtLeadershipDownloadsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<ThoughtLeadershipDownloadsOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ThoughtLeadershipDownloadsFilter>;
};


export type QueryTwoColumnTemplateArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryTwoColumnTemplateCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<TwoColumnTemplateOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TwoColumnTemplateFilter>;
};


export type QueryVideoTemplateArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryVideoTemplateCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<VideoTemplateOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoTemplateFilter>;
};


export type QueryXLprLandingPageArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryXLprLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<XLprLandingPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<XLprLandingPageFilter>;
};


export type QueryXlprLandingPageArgs = {
  id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


export type QueryXlprLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<XlprLandingPageOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<XlprLandingPageFilter>;
};

/** Research Advisory Committee Contacts [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/racContacts) */
export type RacContacts = Entry & {
  contactsCollection?: Maybe<RacContactsContactsCollection>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<RacContactsLinkingCollections>;
  sys: Sys;
};


/** Research Advisory Committee Contacts [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/racContacts) */
export type RacContactsContactsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Research Advisory Committee Contacts [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/racContacts) */
export type RacContactsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RacContactsCollection = {
  items: Array<Maybe<RacContacts>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type RacContactsContactsCollection = {
  items: Array<Maybe<ProfileCard>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type RacContactsFilter = {
  AND?: InputMaybe<Array<InputMaybe<RacContactsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<RacContactsFilter>>>;
  contactsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export type RacContactsLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type RacContactsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum RacContactsOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type RecommendedProductType = {
  displaylink?: Maybe<Scalars['String']>;
  iD?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  originalProductId?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['String']>;
  recommendedProductID?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  shortProductId?: Maybe<Scalars['String']>;
  weighting?: Maybe<Scalars['Int']>;
};

export enum RegionCode {
  All = 'ALL',
  Internatl = 'INTERNATL',
  Namerica = 'NAMERICA'
}

/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/report) */
export type Report = Entry & {
  body?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  date?: Maybe<Scalars['DateTime']>;
  link?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<ReportLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/report) */
export type ReportBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/report) */
export type ReportDateArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/report) */
export type ReportLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/report) */
export type ReportLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/report) */
export type ReportTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ReportCollection = {
  items: Array<Maybe<Report>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ReportFilter = {
  AND?: InputMaybe<Array<InputMaybe<ReportFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ReportFilter>>>;
  body?: InputMaybe<Scalars['String']>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  date?: InputMaybe<Scalars['DateTime']>;
  date_exists?: InputMaybe<Scalars['Boolean']>;
  date_gt?: InputMaybe<Scalars['DateTime']>;
  date_gte?: InputMaybe<Scalars['DateTime']>;
  date_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  date_lt?: InputMaybe<Scalars['DateTime']>;
  date_lte?: InputMaybe<Scalars['DateTime']>;
  date_not?: InputMaybe<Scalars['DateTime']>;
  date_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  link?: InputMaybe<Scalars['String']>;
  link_contains?: InputMaybe<Scalars['String']>;
  link_exists?: InputMaybe<Scalars['Boolean']>;
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  link_not?: InputMaybe<Scalars['String']>;
  link_not_contains?: InputMaybe<Scalars['String']>;
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ReportLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  researchCollection?: Maybe<ResearchCollection>;
};


export type ReportLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ReportLinkingCollectionsResearchCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ReportOrder {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum RequestType {
  Add = 'Add',
  Delete = 'Delete',
  New = 'New',
  Update = 'Update'
}

/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type Research = Entry & {
  contentfulMetadata: ContentfulMetadata;
  dialogCancel?: Maybe<Scalars['String']>;
  dialogCity?: Maybe<Scalars['String']>;
  dialogCompanyHint?: Maybe<Scalars['String']>;
  dialogCompanyName?: Maybe<Scalars['String']>;
  dialogCountry?: Maybe<Scalars['String']>;
  dialogDismissNotification?: Maybe<Scalars['String']>;
  dialogDownloadDifficulty?: Maybe<Scalars['String']>;
  dialogDownloadSubmit?: Maybe<Scalars['String']>;
  dialogDownloadSubmitFailure?: Maybe<Scalars['String']>;
  dialogDownloadSubmitSuccess?: Maybe<Scalars['String']>;
  dialogEmail?: Maybe<Scalars['String']>;
  dialogFirstName?: Maybe<Scalars['String']>;
  dialogIAccept?: Maybe<Scalars['String']>;
  dialogLastName?: Maybe<Scalars['String']>;
  dialogMarket?: Maybe<Scalars['String']>;
  dialogMarketOptions?: Maybe<Scalars['JSON']>;
  dialogOther?: Maybe<Scalars['String']>;
  dialogPhone?: Maybe<Scalars['String']>;
  dialogPrivacyPolicyAcknowledge?: Maybe<Scalars['String']>;
  dialogRequired?: Maybe<Scalars['String']>;
  dialogSource?: Maybe<Scalars['String']>;
  dialogSourceOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  dialogState?: Maybe<Scalars['String']>;
  dialogSubscribe?: Maybe<Scalars['String']>;
  dialogTitle?: Maybe<Scalars['String']>;
  dialogValidEmail?: Maybe<Scalars['String']>;
  featuredReport?: Maybe<FeaturedReport>;
  featuredReportTitle?: Maybe<Scalars['String']>;
  featuredResearcher?: Maybe<FeaturedResearcherSection>;
  journalTitle?: Maybe<Scalars['String']>;
  jumbotron?: Maybe<Jumbotron>;
  linkedFrom?: Maybe<ResearchLinkingCollections>;
  portfolio?: Maybe<PortfolioSection>;
  reportTitle?: Maybe<Scalars['String']>;
  reportsCollection?: Maybe<ResearchReportsCollection>;
  storyCardsCollection?: Maybe<ResearchStoryCardsCollection>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogCancelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogCityArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogCompanyHintArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogCompanyNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogCountryArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogDismissNotificationArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogDownloadDifficultyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogDownloadSubmitArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogDownloadSubmitFailureArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogDownloadSubmitSuccessArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogEmailArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogFirstNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogIAcceptArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogLastNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogMarketArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogMarketOptionsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogOtherArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogPhoneArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogPrivacyPolicyAcknowledgeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogRequiredArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogSourceArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogSourceOptionsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogStateArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogSubscribeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchDialogValidEmailArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchFeaturedReportArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchFeaturedReportTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchFeaturedResearcherArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchJournalTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchJumbotronArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchPortfolioArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchReportTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchReportsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchStoryCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/research) */
export type ResearchTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** RAC content [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchAdvisoryCommitteeHome) */
export type ResearchAdvisoryCommitteeHome = Entry & {
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<ResearchAdvisoryCommitteeHomeLinkingCollections>;
  overview?: Maybe<Scalars['String']>;
  overviewImage?: Maybe<Asset>;
  racContact?: Maybe<ProfileCard>;
  racContactsCollection?: Maybe<ResearchAdvisoryCommitteeHomeRacContactsCollection>;
  roadmapTitle?: Maybe<Scalars['String']>;
  roadmapsCollection?: Maybe<ResearchAdvisoryCommitteeHomeRoadmapsCollection>;
  section1CardsCollection?: Maybe<ResearchAdvisoryCommitteeHomeSection1cardsCollection>;
  shortOverview?: Maybe<Scalars['String']>;
  sys: Sys;
  title1?: Maybe<Scalars['String']>;
};


/** RAC content [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchAdvisoryCommitteeHome) */
export type ResearchAdvisoryCommitteeHomeLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** RAC content [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchAdvisoryCommitteeHome) */
export type ResearchAdvisoryCommitteeHomeOverviewArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** RAC content [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchAdvisoryCommitteeHome) */
export type ResearchAdvisoryCommitteeHomeOverviewImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** RAC content [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchAdvisoryCommitteeHome) */
export type ResearchAdvisoryCommitteeHomeRacContactArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** RAC content [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchAdvisoryCommitteeHome) */
export type ResearchAdvisoryCommitteeHomeRacContactsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** RAC content [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchAdvisoryCommitteeHome) */
export type ResearchAdvisoryCommitteeHomeRoadmapTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** RAC content [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchAdvisoryCommitteeHome) */
export type ResearchAdvisoryCommitteeHomeRoadmapsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** RAC content [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchAdvisoryCommitteeHome) */
export type ResearchAdvisoryCommitteeHomeSection1CardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** RAC content [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchAdvisoryCommitteeHome) */
export type ResearchAdvisoryCommitteeHomeShortOverviewArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** RAC content [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchAdvisoryCommitteeHome) */
export type ResearchAdvisoryCommitteeHomeTitle1Args = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ResearchAdvisoryCommitteeHomeCollection = {
  items: Array<Maybe<ResearchAdvisoryCommitteeHome>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ResearchAdvisoryCommitteeHomeFilter = {
  AND?: InputMaybe<Array<InputMaybe<ResearchAdvisoryCommitteeHomeFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ResearchAdvisoryCommitteeHomeFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  overview?: InputMaybe<Scalars['String']>;
  overviewImage_exists?: InputMaybe<Scalars['Boolean']>;
  overview_contains?: InputMaybe<Scalars['String']>;
  overview_exists?: InputMaybe<Scalars['Boolean']>;
  overview_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  overview_not?: InputMaybe<Scalars['String']>;
  overview_not_contains?: InputMaybe<Scalars['String']>;
  overview_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  racContact?: InputMaybe<CfProfileCardNestedFilter>;
  racContact_exists?: InputMaybe<Scalars['Boolean']>;
  racContactsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  roadmapTitle?: InputMaybe<Scalars['String']>;
  roadmapTitle_contains?: InputMaybe<Scalars['String']>;
  roadmapTitle_exists?: InputMaybe<Scalars['Boolean']>;
  roadmapTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  roadmapTitle_not?: InputMaybe<Scalars['String']>;
  roadmapTitle_not_contains?: InputMaybe<Scalars['String']>;
  roadmapTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  roadmapsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  section1cardsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  shortOverview?: InputMaybe<Scalars['String']>;
  shortOverview_contains?: InputMaybe<Scalars['String']>;
  shortOverview_exists?: InputMaybe<Scalars['Boolean']>;
  shortOverview_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  shortOverview_not?: InputMaybe<Scalars['String']>;
  shortOverview_not_contains?: InputMaybe<Scalars['String']>;
  shortOverview_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title1?: InputMaybe<Scalars['String']>;
  title1_contains?: InputMaybe<Scalars['String']>;
  title1_exists?: InputMaybe<Scalars['Boolean']>;
  title1_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title1_not?: InputMaybe<Scalars['String']>;
  title1_not_contains?: InputMaybe<Scalars['String']>;
  title1_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ResearchAdvisoryCommitteeHomeLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type ResearchAdvisoryCommitteeHomeLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ResearchAdvisoryCommitteeHomeOrder {
  RoadmapTitleAsc = 'roadmapTitle_ASC',
  RoadmapTitleDesc = 'roadmapTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  Title1Asc = 'title1_ASC',
  Title1Desc = 'title1_DESC'
}

export type ResearchAdvisoryCommitteeHomeRacContactsCollection = {
  items: Array<Maybe<ProfileCard>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ResearchAdvisoryCommitteeHomeRoadmapsCollection = {
  items: Array<Maybe<StoryCards>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ResearchAdvisoryCommitteeHomeSection1cardsCollection = {
  items: Array<Maybe<StoryCards>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ResearchArea = {
  programs?: Maybe<Array<Maybe<ResearchProgram>>>;
};

export type ResearchByTopic = {
  lTN?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Maybe<Language>>>;
  topics?: Maybe<Array<Maybe<Topic>>>;
};

export type ResearchCollection = {
  items: Array<Maybe<Research>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ResearchFilter = {
  AND?: InputMaybe<Array<InputMaybe<ResearchFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ResearchFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  dialogCancel?: InputMaybe<Scalars['String']>;
  dialogCancel_contains?: InputMaybe<Scalars['String']>;
  dialogCancel_exists?: InputMaybe<Scalars['Boolean']>;
  dialogCancel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogCancel_not?: InputMaybe<Scalars['String']>;
  dialogCancel_not_contains?: InputMaybe<Scalars['String']>;
  dialogCancel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogCity?: InputMaybe<Scalars['String']>;
  dialogCity_contains?: InputMaybe<Scalars['String']>;
  dialogCity_exists?: InputMaybe<Scalars['Boolean']>;
  dialogCity_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogCity_not?: InputMaybe<Scalars['String']>;
  dialogCity_not_contains?: InputMaybe<Scalars['String']>;
  dialogCity_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogCompanyHint?: InputMaybe<Scalars['String']>;
  dialogCompanyHint_contains?: InputMaybe<Scalars['String']>;
  dialogCompanyHint_exists?: InputMaybe<Scalars['Boolean']>;
  dialogCompanyHint_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogCompanyHint_not?: InputMaybe<Scalars['String']>;
  dialogCompanyHint_not_contains?: InputMaybe<Scalars['String']>;
  dialogCompanyHint_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogCompanyName?: InputMaybe<Scalars['String']>;
  dialogCompanyName_contains?: InputMaybe<Scalars['String']>;
  dialogCompanyName_exists?: InputMaybe<Scalars['Boolean']>;
  dialogCompanyName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogCompanyName_not?: InputMaybe<Scalars['String']>;
  dialogCompanyName_not_contains?: InputMaybe<Scalars['String']>;
  dialogCompanyName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogCountry?: InputMaybe<Scalars['String']>;
  dialogCountry_contains?: InputMaybe<Scalars['String']>;
  dialogCountry_exists?: InputMaybe<Scalars['Boolean']>;
  dialogCountry_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogCountry_not?: InputMaybe<Scalars['String']>;
  dialogCountry_not_contains?: InputMaybe<Scalars['String']>;
  dialogCountry_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogDismissNotification?: InputMaybe<Scalars['String']>;
  dialogDismissNotification_contains?: InputMaybe<Scalars['String']>;
  dialogDismissNotification_exists?: InputMaybe<Scalars['Boolean']>;
  dialogDismissNotification_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogDismissNotification_not?: InputMaybe<Scalars['String']>;
  dialogDismissNotification_not_contains?: InputMaybe<Scalars['String']>;
  dialogDismissNotification_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogDownloadDifficulty?: InputMaybe<Scalars['String']>;
  dialogDownloadDifficulty_contains?: InputMaybe<Scalars['String']>;
  dialogDownloadDifficulty_exists?: InputMaybe<Scalars['Boolean']>;
  dialogDownloadDifficulty_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogDownloadDifficulty_not?: InputMaybe<Scalars['String']>;
  dialogDownloadDifficulty_not_contains?: InputMaybe<Scalars['String']>;
  dialogDownloadDifficulty_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogDownloadSubmit?: InputMaybe<Scalars['String']>;
  dialogDownloadSubmitFailure?: InputMaybe<Scalars['String']>;
  dialogDownloadSubmitFailure_contains?: InputMaybe<Scalars['String']>;
  dialogDownloadSubmitFailure_exists?: InputMaybe<Scalars['Boolean']>;
  dialogDownloadSubmitFailure_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogDownloadSubmitFailure_not?: InputMaybe<Scalars['String']>;
  dialogDownloadSubmitFailure_not_contains?: InputMaybe<Scalars['String']>;
  dialogDownloadSubmitFailure_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogDownloadSubmitSuccess?: InputMaybe<Scalars['String']>;
  dialogDownloadSubmitSuccess_contains?: InputMaybe<Scalars['String']>;
  dialogDownloadSubmitSuccess_exists?: InputMaybe<Scalars['Boolean']>;
  dialogDownloadSubmitSuccess_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogDownloadSubmitSuccess_not?: InputMaybe<Scalars['String']>;
  dialogDownloadSubmitSuccess_not_contains?: InputMaybe<Scalars['String']>;
  dialogDownloadSubmitSuccess_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogDownloadSubmit_contains?: InputMaybe<Scalars['String']>;
  dialogDownloadSubmit_exists?: InputMaybe<Scalars['Boolean']>;
  dialogDownloadSubmit_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogDownloadSubmit_not?: InputMaybe<Scalars['String']>;
  dialogDownloadSubmit_not_contains?: InputMaybe<Scalars['String']>;
  dialogDownloadSubmit_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogEmail?: InputMaybe<Scalars['String']>;
  dialogEmail_contains?: InputMaybe<Scalars['String']>;
  dialogEmail_exists?: InputMaybe<Scalars['Boolean']>;
  dialogEmail_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogEmail_not?: InputMaybe<Scalars['String']>;
  dialogEmail_not_contains?: InputMaybe<Scalars['String']>;
  dialogEmail_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogFirstName?: InputMaybe<Scalars['String']>;
  dialogFirstName_contains?: InputMaybe<Scalars['String']>;
  dialogFirstName_exists?: InputMaybe<Scalars['Boolean']>;
  dialogFirstName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogFirstName_not?: InputMaybe<Scalars['String']>;
  dialogFirstName_not_contains?: InputMaybe<Scalars['String']>;
  dialogFirstName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogIAccept?: InputMaybe<Scalars['String']>;
  dialogIAccept_contains?: InputMaybe<Scalars['String']>;
  dialogIAccept_exists?: InputMaybe<Scalars['Boolean']>;
  dialogIAccept_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogIAccept_not?: InputMaybe<Scalars['String']>;
  dialogIAccept_not_contains?: InputMaybe<Scalars['String']>;
  dialogIAccept_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogLastName?: InputMaybe<Scalars['String']>;
  dialogLastName_contains?: InputMaybe<Scalars['String']>;
  dialogLastName_exists?: InputMaybe<Scalars['Boolean']>;
  dialogLastName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogLastName_not?: InputMaybe<Scalars['String']>;
  dialogLastName_not_contains?: InputMaybe<Scalars['String']>;
  dialogLastName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogMarket?: InputMaybe<Scalars['String']>;
  dialogMarket_contains?: InputMaybe<Scalars['String']>;
  dialogMarket_exists?: InputMaybe<Scalars['Boolean']>;
  dialogMarket_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogMarket_not?: InputMaybe<Scalars['String']>;
  dialogMarket_not_contains?: InputMaybe<Scalars['String']>;
  dialogMarket_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogOther?: InputMaybe<Scalars['String']>;
  dialogOther_contains?: InputMaybe<Scalars['String']>;
  dialogOther_exists?: InputMaybe<Scalars['Boolean']>;
  dialogOther_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogOther_not?: InputMaybe<Scalars['String']>;
  dialogOther_not_contains?: InputMaybe<Scalars['String']>;
  dialogOther_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogPhone?: InputMaybe<Scalars['String']>;
  dialogPhone_contains?: InputMaybe<Scalars['String']>;
  dialogPhone_exists?: InputMaybe<Scalars['Boolean']>;
  dialogPhone_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogPhone_not?: InputMaybe<Scalars['String']>;
  dialogPhone_not_contains?: InputMaybe<Scalars['String']>;
  dialogPhone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogPrivacyPolicyAcknowledge?: InputMaybe<Scalars['String']>;
  dialogPrivacyPolicyAcknowledge_contains?: InputMaybe<Scalars['String']>;
  dialogPrivacyPolicyAcknowledge_exists?: InputMaybe<Scalars['Boolean']>;
  dialogPrivacyPolicyAcknowledge_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogPrivacyPolicyAcknowledge_not?: InputMaybe<Scalars['String']>;
  dialogPrivacyPolicyAcknowledge_not_contains?: InputMaybe<Scalars['String']>;
  dialogPrivacyPolicyAcknowledge_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogRequired?: InputMaybe<Scalars['String']>;
  dialogRequired_contains?: InputMaybe<Scalars['String']>;
  dialogRequired_exists?: InputMaybe<Scalars['Boolean']>;
  dialogRequired_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogRequired_not?: InputMaybe<Scalars['String']>;
  dialogRequired_not_contains?: InputMaybe<Scalars['String']>;
  dialogRequired_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogSource?: InputMaybe<Scalars['String']>;
  dialogSourceOptions_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogSourceOptions_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogSourceOptions_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogSourceOptions_exists?: InputMaybe<Scalars['Boolean']>;
  dialogSource_contains?: InputMaybe<Scalars['String']>;
  dialogSource_exists?: InputMaybe<Scalars['Boolean']>;
  dialogSource_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogSource_not?: InputMaybe<Scalars['String']>;
  dialogSource_not_contains?: InputMaybe<Scalars['String']>;
  dialogSource_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogState?: InputMaybe<Scalars['String']>;
  dialogState_contains?: InputMaybe<Scalars['String']>;
  dialogState_exists?: InputMaybe<Scalars['Boolean']>;
  dialogState_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogState_not?: InputMaybe<Scalars['String']>;
  dialogState_not_contains?: InputMaybe<Scalars['String']>;
  dialogState_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogSubscribe?: InputMaybe<Scalars['String']>;
  dialogSubscribe_contains?: InputMaybe<Scalars['String']>;
  dialogSubscribe_exists?: InputMaybe<Scalars['Boolean']>;
  dialogSubscribe_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogSubscribe_not?: InputMaybe<Scalars['String']>;
  dialogSubscribe_not_contains?: InputMaybe<Scalars['String']>;
  dialogSubscribe_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogTitle?: InputMaybe<Scalars['String']>;
  dialogTitle_contains?: InputMaybe<Scalars['String']>;
  dialogTitle_exists?: InputMaybe<Scalars['Boolean']>;
  dialogTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogTitle_not?: InputMaybe<Scalars['String']>;
  dialogTitle_not_contains?: InputMaybe<Scalars['String']>;
  dialogTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogValidEmail?: InputMaybe<Scalars['String']>;
  dialogValidEmail_contains?: InputMaybe<Scalars['String']>;
  dialogValidEmail_exists?: InputMaybe<Scalars['Boolean']>;
  dialogValidEmail_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dialogValidEmail_not?: InputMaybe<Scalars['String']>;
  dialogValidEmail_not_contains?: InputMaybe<Scalars['String']>;
  dialogValidEmail_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  featuredReport?: InputMaybe<CfFeaturedReportNestedFilter>;
  featuredReportTitle?: InputMaybe<Scalars['String']>;
  featuredReportTitle_contains?: InputMaybe<Scalars['String']>;
  featuredReportTitle_exists?: InputMaybe<Scalars['Boolean']>;
  featuredReportTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  featuredReportTitle_not?: InputMaybe<Scalars['String']>;
  featuredReportTitle_not_contains?: InputMaybe<Scalars['String']>;
  featuredReportTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  featuredReport_exists?: InputMaybe<Scalars['Boolean']>;
  featuredResearcher?: InputMaybe<CfFeaturedResearcherSectionNestedFilter>;
  featuredResearcher_exists?: InputMaybe<Scalars['Boolean']>;
  journalTitle?: InputMaybe<Scalars['String']>;
  journalTitle_contains?: InputMaybe<Scalars['String']>;
  journalTitle_exists?: InputMaybe<Scalars['Boolean']>;
  journalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  journalTitle_not?: InputMaybe<Scalars['String']>;
  journalTitle_not_contains?: InputMaybe<Scalars['String']>;
  journalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jumbotron?: InputMaybe<CfJumbotronNestedFilter>;
  jumbotron_exists?: InputMaybe<Scalars['Boolean']>;
  portfolio?: InputMaybe<CfPortfolioSectionNestedFilter>;
  portfolio_exists?: InputMaybe<Scalars['Boolean']>;
  reportTitle?: InputMaybe<Scalars['String']>;
  reportTitle_contains?: InputMaybe<Scalars['String']>;
  reportTitle_exists?: InputMaybe<Scalars['Boolean']>;
  reportTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  reportTitle_not?: InputMaybe<Scalars['String']>;
  reportTitle_not_contains?: InputMaybe<Scalars['String']>;
  reportTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  reportsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  storyCardsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchLandingPage) */
export type ResearchLandingPage = Entry & {
  contentfulMetadata: ContentfulMetadata;
  headline?: Maybe<Scalars['String']>;
  jumbotronImage?: Maybe<Asset>;
  linkedFrom?: Maybe<ResearchLandingPageLinkingCollections>;
  researchLandingPageJumbotronText?: Maybe<Scalars['String']>;
  sectorTabsCollection?: Maybe<ResearchLandingPageSectorTabsCollection>;
  sys: Sys;
  textArea?: Maybe<ResearchLandingPageTextArea>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchLandingPage) */
export type ResearchLandingPageHeadlineArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchLandingPage) */
export type ResearchLandingPageJumbotronImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchLandingPage) */
export type ResearchLandingPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchLandingPage) */
export type ResearchLandingPageResearchLandingPageJumbotronTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchLandingPage) */
export type ResearchLandingPageSectorTabsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchLandingPage) */
export type ResearchLandingPageTextAreaArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ResearchLandingPageCollection = {
  items: Array<Maybe<ResearchLandingPage>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ResearchLandingPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ResearchLandingPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ResearchLandingPageFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  headline?: InputMaybe<Scalars['String']>;
  headline_contains?: InputMaybe<Scalars['String']>;
  headline_exists?: InputMaybe<Scalars['Boolean']>;
  headline_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  headline_not?: InputMaybe<Scalars['String']>;
  headline_not_contains?: InputMaybe<Scalars['String']>;
  headline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jumbotronImage_exists?: InputMaybe<Scalars['Boolean']>;
  researchLandingPageJumbotronText?: InputMaybe<Scalars['String']>;
  researchLandingPageJumbotronText_contains?: InputMaybe<Scalars['String']>;
  researchLandingPageJumbotronText_exists?: InputMaybe<Scalars['Boolean']>;
  researchLandingPageJumbotronText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  researchLandingPageJumbotronText_not?: InputMaybe<Scalars['String']>;
  researchLandingPageJumbotronText_not_contains?: InputMaybe<Scalars['String']>;
  researchLandingPageJumbotronText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectorTabsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  textArea_contains?: InputMaybe<Scalars['String']>;
  textArea_exists?: InputMaybe<Scalars['Boolean']>;
  textArea_not_contains?: InputMaybe<Scalars['String']>;
};

export type ResearchLandingPageLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type ResearchLandingPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ResearchLandingPageOrder {
  HeadlineAsc = 'headline_ASC',
  HeadlineDesc = 'headline_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type ResearchLandingPageSectorTabsCollection = {
  items: Array<Maybe<ResearchLandingTabs>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ResearchLandingPageTextArea = {
  json: Scalars['JSON'];
  links: ResearchLandingPageTextAreaLinks;
};

export type ResearchLandingPageTextAreaAssets = {
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type ResearchLandingPageTextAreaEntries = {
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type ResearchLandingPageTextAreaLinks = {
  assets: ResearchLandingPageTextAreaAssets;
  entries: ResearchLandingPageTextAreaEntries;
};

/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchLandingTabs) */
export type ResearchLandingTabs = Entry & {
  contentfulMetadata: ContentfulMetadata;
  featuredReseacher?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<ResearchLandingTabsLinkingCollections>;
  productId?: Maybe<Scalars['String']>;
  productImage?: Maybe<Asset>;
  researcherPhoto?: Maybe<Asset>;
  researcherText?: Maybe<Scalars['String']>;
  researcherTitle?: Maybe<Scalars['String']>;
  sectorLtn?: Maybe<Scalars['String']>;
  sectorText?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  viewDetailsLink?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchLandingTabs) */
export type ResearchLandingTabsFeaturedReseacherArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchLandingTabs) */
export type ResearchLandingTabsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchLandingTabs) */
export type ResearchLandingTabsProductIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchLandingTabs) */
export type ResearchLandingTabsProductImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchLandingTabs) */
export type ResearchLandingTabsResearcherPhotoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchLandingTabs) */
export type ResearchLandingTabsResearcherTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchLandingTabs) */
export type ResearchLandingTabsResearcherTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchLandingTabs) */
export type ResearchLandingTabsSectorLtnArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchLandingTabs) */
export type ResearchLandingTabsSectorTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchLandingTabs) */
export type ResearchLandingTabsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/researchLandingTabs) */
export type ResearchLandingTabsViewDetailsLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ResearchLandingTabsCollection = {
  items: Array<Maybe<ResearchLandingTabs>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ResearchLandingTabsFilter = {
  AND?: InputMaybe<Array<InputMaybe<ResearchLandingTabsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ResearchLandingTabsFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  featuredReseacher?: InputMaybe<Scalars['String']>;
  featuredReseacher_contains?: InputMaybe<Scalars['String']>;
  featuredReseacher_exists?: InputMaybe<Scalars['Boolean']>;
  featuredReseacher_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  featuredReseacher_not?: InputMaybe<Scalars['String']>;
  featuredReseacher_not_contains?: InputMaybe<Scalars['String']>;
  featuredReseacher_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  productId?: InputMaybe<Scalars['String']>;
  productId_contains?: InputMaybe<Scalars['String']>;
  productId_exists?: InputMaybe<Scalars['Boolean']>;
  productId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  productId_not?: InputMaybe<Scalars['String']>;
  productId_not_contains?: InputMaybe<Scalars['String']>;
  productId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  productImage_exists?: InputMaybe<Scalars['Boolean']>;
  researcherPhoto_exists?: InputMaybe<Scalars['Boolean']>;
  researcherText?: InputMaybe<Scalars['String']>;
  researcherText_contains?: InputMaybe<Scalars['String']>;
  researcherText_exists?: InputMaybe<Scalars['Boolean']>;
  researcherText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  researcherText_not?: InputMaybe<Scalars['String']>;
  researcherText_not_contains?: InputMaybe<Scalars['String']>;
  researcherText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  researcherTitle?: InputMaybe<Scalars['String']>;
  researcherTitle_contains?: InputMaybe<Scalars['String']>;
  researcherTitle_exists?: InputMaybe<Scalars['Boolean']>;
  researcherTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  researcherTitle_not?: InputMaybe<Scalars['String']>;
  researcherTitle_not_contains?: InputMaybe<Scalars['String']>;
  researcherTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectorLtn?: InputMaybe<Scalars['String']>;
  sectorLtn_contains?: InputMaybe<Scalars['String']>;
  sectorLtn_exists?: InputMaybe<Scalars['Boolean']>;
  sectorLtn_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectorLtn_not?: InputMaybe<Scalars['String']>;
  sectorLtn_not_contains?: InputMaybe<Scalars['String']>;
  sectorLtn_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectorText?: InputMaybe<Scalars['String']>;
  sectorText_contains?: InputMaybe<Scalars['String']>;
  sectorText_exists?: InputMaybe<Scalars['Boolean']>;
  sectorText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectorText_not?: InputMaybe<Scalars['String']>;
  sectorText_not_contains?: InputMaybe<Scalars['String']>;
  sectorText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  viewDetailsLink?: InputMaybe<Scalars['String']>;
  viewDetailsLink_contains?: InputMaybe<Scalars['String']>;
  viewDetailsLink_exists?: InputMaybe<Scalars['Boolean']>;
  viewDetailsLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  viewDetailsLink_not?: InputMaybe<Scalars['String']>;
  viewDetailsLink_not_contains?: InputMaybe<Scalars['String']>;
  viewDetailsLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ResearchLandingTabsLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  epriUCollection?: Maybe<EpriUCollection>;
  researchLandingPageCollection?: Maybe<ResearchLandingPageCollection>;
};


export type ResearchLandingTabsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ResearchLandingTabsLinkingCollectionsEpriUCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ResearchLandingTabsLinkingCollectionsResearchLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ResearchLandingTabsOrder {
  FeaturedReseacherAsc = 'featuredReseacher_ASC',
  FeaturedReseacherDesc = 'featuredReseacher_DESC',
  ProductIdAsc = 'productId_ASC',
  ProductIdDesc = 'productId_DESC',
  ResearcherTextAsc = 'researcherText_ASC',
  ResearcherTextDesc = 'researcherText_DESC',
  ResearcherTitleAsc = 'researcherTitle_ASC',
  ResearcherTitleDesc = 'researcherTitle_DESC',
  SectorLtnAsc = 'sectorLtn_ASC',
  SectorLtnDesc = 'sectorLtn_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ViewDetailsLinkAsc = 'viewDetailsLink_ASC',
  ViewDetailsLinkDesc = 'viewDetailsLink_DESC'
}

export type ResearchLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type ResearchLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ResearchOrder {
  DialogCancelAsc = 'dialogCancel_ASC',
  DialogCancelDesc = 'dialogCancel_DESC',
  DialogCityAsc = 'dialogCity_ASC',
  DialogCityDesc = 'dialogCity_DESC',
  DialogCompanyHintAsc = 'dialogCompanyHint_ASC',
  DialogCompanyHintDesc = 'dialogCompanyHint_DESC',
  DialogCompanyNameAsc = 'dialogCompanyName_ASC',
  DialogCompanyNameDesc = 'dialogCompanyName_DESC',
  DialogCountryAsc = 'dialogCountry_ASC',
  DialogCountryDesc = 'dialogCountry_DESC',
  DialogDismissNotificationAsc = 'dialogDismissNotification_ASC',
  DialogDismissNotificationDesc = 'dialogDismissNotification_DESC',
  DialogDownloadDifficultyAsc = 'dialogDownloadDifficulty_ASC',
  DialogDownloadDifficultyDesc = 'dialogDownloadDifficulty_DESC',
  DialogDownloadSubmitFailureAsc = 'dialogDownloadSubmitFailure_ASC',
  DialogDownloadSubmitFailureDesc = 'dialogDownloadSubmitFailure_DESC',
  DialogDownloadSubmitSuccessAsc = 'dialogDownloadSubmitSuccess_ASC',
  DialogDownloadSubmitSuccessDesc = 'dialogDownloadSubmitSuccess_DESC',
  DialogDownloadSubmitAsc = 'dialogDownloadSubmit_ASC',
  DialogDownloadSubmitDesc = 'dialogDownloadSubmit_DESC',
  DialogEmailAsc = 'dialogEmail_ASC',
  DialogEmailDesc = 'dialogEmail_DESC',
  DialogFirstNameAsc = 'dialogFirstName_ASC',
  DialogFirstNameDesc = 'dialogFirstName_DESC',
  DialogIAcceptAsc = 'dialogIAccept_ASC',
  DialogIAcceptDesc = 'dialogIAccept_DESC',
  DialogLastNameAsc = 'dialogLastName_ASC',
  DialogLastNameDesc = 'dialogLastName_DESC',
  DialogMarketAsc = 'dialogMarket_ASC',
  DialogMarketDesc = 'dialogMarket_DESC',
  DialogOtherAsc = 'dialogOther_ASC',
  DialogOtherDesc = 'dialogOther_DESC',
  DialogPhoneAsc = 'dialogPhone_ASC',
  DialogPhoneDesc = 'dialogPhone_DESC',
  DialogPrivacyPolicyAcknowledgeAsc = 'dialogPrivacyPolicyAcknowledge_ASC',
  DialogPrivacyPolicyAcknowledgeDesc = 'dialogPrivacyPolicyAcknowledge_DESC',
  DialogRequiredAsc = 'dialogRequired_ASC',
  DialogRequiredDesc = 'dialogRequired_DESC',
  DialogSourceAsc = 'dialogSource_ASC',
  DialogSourceDesc = 'dialogSource_DESC',
  DialogStateAsc = 'dialogState_ASC',
  DialogStateDesc = 'dialogState_DESC',
  DialogSubscribeAsc = 'dialogSubscribe_ASC',
  DialogSubscribeDesc = 'dialogSubscribe_DESC',
  DialogTitleAsc = 'dialogTitle_ASC',
  DialogTitleDesc = 'dialogTitle_DESC',
  DialogValidEmailAsc = 'dialogValidEmail_ASC',
  DialogValidEmailDesc = 'dialogValidEmail_DESC',
  FeaturedReportTitleAsc = 'featuredReportTitle_ASC',
  FeaturedReportTitleDesc = 'featuredReportTitle_DESC',
  JournalTitleAsc = 'journalTitle_ASC',
  JournalTitleDesc = 'journalTitle_DESC',
  ReportTitleAsc = 'reportTitle_ASC',
  ReportTitleDesc = 'reportTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type ResearchProgram = {
  projectSets?: Maybe<Array<Maybe<Pset>>>;
};

export type ResearchProject = {
  applicablePrograms?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['String']>;
  fundingType?: Maybe<Scalars['String']>;
  hasPOF?: Maybe<Scalars['Boolean']>;
  hasPSU?: Maybe<Scalars['Boolean']>;
  isMCPublished?: Maybe<Scalars['Boolean']>;
  keyResearchQuestions?: Maybe<Scalars['String']>;
  lastPublishYear?: Maybe<Scalars['String']>;
  lastUpdated?: Maybe<Scalars['String']>;
  levelNumber?: Maybe<Scalars['String']>;
  ltn?: Maybe<Scalars['String']>;
  objective?: Maybe<Scalars['String']>;
  overviewPublishedDate?: Maybe<Scalars['Float']>;
  overviewPublishedYear?: Maybe<Scalars['String']>;
  parentLTN?: Maybe<Scalars['String']>;
  portfolioYear?: Maybe<Scalars['Int']>;
  researchProjects?: Maybe<Array<Maybe<ResearchProject>>>;
  schedule?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  shortOverview?: Maybe<Scalars['String']>;
  statusPublishedDate?: Maybe<Scalars['String']>;
  statusPublishedMonth?: Maybe<Scalars['String']>;
  statusPublishedYear?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ResearchReportsCollection = {
  items: Array<Maybe<Report>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ResearchStoryCardsCollection = {
  items: Array<Maybe<StoryCards>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/resourceDocument) */
export type ResourceDocument = Entry & {
  abstractId?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  link?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<ResourceDocumentLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/resourceDocument) */
export type ResourceDocumentAbstractIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/resourceDocument) */
export type ResourceDocumentLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/resourceDocument) */
export type ResourceDocumentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/resourceDocument) */
export type ResourceDocumentTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ResourceDocumentCollection = {
  items: Array<Maybe<ResourceDocument>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ResourceDocumentFilter = {
  AND?: InputMaybe<Array<InputMaybe<ResourceDocumentFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ResourceDocumentFilter>>>;
  abstractId?: InputMaybe<Scalars['String']>;
  abstractId_contains?: InputMaybe<Scalars['String']>;
  abstractId_exists?: InputMaybe<Scalars['Boolean']>;
  abstractId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  abstractId_not?: InputMaybe<Scalars['String']>;
  abstractId_not_contains?: InputMaybe<Scalars['String']>;
  abstractId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  link?: InputMaybe<Scalars['String']>;
  link_contains?: InputMaybe<Scalars['String']>;
  link_exists?: InputMaybe<Scalars['Boolean']>;
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  link_not?: InputMaybe<Scalars['String']>;
  link_not_contains?: InputMaybe<Scalars['String']>;
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ResourceDocumentLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  mediaResourcesCollection?: Maybe<MediaResourcesCollection>;
};


export type ResourceDocumentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ResourceDocumentLinkingCollectionsMediaResourcesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ResourceDocumentOrder {
  AbstractIdAsc = 'abstractId_ASC',
  AbstractIdDesc = 'abstractId_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type SdftcSummaryBySector = {
  amount?: Maybe<Scalars['Float']>;
  companyGroup?: Maybe<Scalars['String']>;
  percentOfTotal?: Maybe<Scalars['Float']>;
  sectorLTN?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
  shortSectorName?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type Salutation = {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SectorAdvisors = {
  NoOfAdvisors?: Maybe<Scalars['Int']>;
  NotRepresented?: Maybe<Scalars['Int']>;
  eligibleCommittees?: Maybe<Scalars['Int']>;
  represented?: Maybe<Scalars['Int']>;
  sectorLTN?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
  sectorShortName?: Maybe<Scalars['String']>;
};

/** Secured attachments for announcements, and possibly other use cases [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/securedAttachment) */
export type SecuredAttachment = Entry & {
  attachmentData?: Maybe<Scalars['JSON']>;
  contentfulMetadata: ContentfulMetadata;
  id?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<SecuredAttachmentLinkingCollections>;
  securedAttachment?: Maybe<Scalars['String']>;
  swod?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Secured attachments for announcements, and possibly other use cases [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/securedAttachment) */
export type SecuredAttachmentAttachmentDataArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Secured attachments for announcements, and possibly other use cases [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/securedAttachment) */
export type SecuredAttachmentIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Secured attachments for announcements, and possibly other use cases [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/securedAttachment) */
export type SecuredAttachmentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Secured attachments for announcements, and possibly other use cases [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/securedAttachment) */
export type SecuredAttachmentSecuredAttachmentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Secured attachments for announcements, and possibly other use cases [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/securedAttachment) */
export type SecuredAttachmentSwodArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Secured attachments for announcements, and possibly other use cases [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/securedAttachment) */
export type SecuredAttachmentTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type SecuredAttachmentCollection = {
  items: Array<Maybe<SecuredAttachment>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SecuredAttachmentFilter = {
  AND?: InputMaybe<Array<InputMaybe<SecuredAttachmentFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SecuredAttachmentFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  id?: InputMaybe<Scalars['String']>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_exists?: InputMaybe<Scalars['Boolean']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_not?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  securedAttachment?: InputMaybe<Scalars['String']>;
  securedAttachment_contains?: InputMaybe<Scalars['String']>;
  securedAttachment_exists?: InputMaybe<Scalars['Boolean']>;
  securedAttachment_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  securedAttachment_not?: InputMaybe<Scalars['String']>;
  securedAttachment_not_contains?: InputMaybe<Scalars['String']>;
  securedAttachment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  swod?: InputMaybe<Scalars['String']>;
  swod_contains?: InputMaybe<Scalars['String']>;
  swod_exists?: InputMaybe<Scalars['Boolean']>;
  swod_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  swod_not?: InputMaybe<Scalars['String']>;
  swod_not_contains?: InputMaybe<Scalars['String']>;
  swod_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SecuredAttachmentLinkingCollections = {
  announcementCollection?: Maybe<AnnouncementCollection>;
  entryCollection?: Maybe<EntryCollection>;
  xLprLandingPageCollection?: Maybe<XLprLandingPageCollection>;
};


export type SecuredAttachmentLinkingCollectionsAnnouncementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type SecuredAttachmentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type SecuredAttachmentLinkingCollectionsXLprLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SecuredAttachmentOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SecuredAttachmentAsc = 'securedAttachment_ASC',
  SecuredAttachmentDesc = 'securedAttachment_DESC',
  SwodAsc = 'swod_ASC',
  SwodDesc = 'swod_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/**
 * SEO Metadata for web pages in Compose. DO NOT DELETE [See type
 * definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/seo)
 */
export type Seo = Entry & {
  contentfulMetadata: ContentfulMetadata;
  description?: Maybe<Scalars['String']>;
  keywords?: Maybe<Array<Maybe<Scalars['String']>>>;
  linkedFrom?: Maybe<SeoLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  noFollow?: Maybe<Scalars['Boolean']>;
  noIndex?: Maybe<Scalars['Boolean']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/**
 * SEO Metadata for web pages in Compose. DO NOT DELETE [See type
 * definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/seo)
 */
export type SeoDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * SEO Metadata for web pages in Compose. DO NOT DELETE [See type
 * definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/seo)
 */
export type SeoKeywordsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * SEO Metadata for web pages in Compose. DO NOT DELETE [See type
 * definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/seo)
 */
export type SeoLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/**
 * SEO Metadata for web pages in Compose. DO NOT DELETE [See type
 * definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/seo)
 */
export type SeoNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * SEO Metadata for web pages in Compose. DO NOT DELETE [See type
 * definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/seo)
 */
export type SeoNoFollowArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * SEO Metadata for web pages in Compose. DO NOT DELETE [See type
 * definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/seo)
 */
export type SeoNoIndexArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * SEO Metadata for web pages in Compose. DO NOT DELETE [See type
 * definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/seo)
 */
export type SeoTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type SeoCollection = {
  items: Array<Maybe<Seo>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SeoFilter = {
  AND?: InputMaybe<Array<InputMaybe<SeoFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SeoFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  no_follow?: InputMaybe<Scalars['Boolean']>;
  no_follow_exists?: InputMaybe<Scalars['Boolean']>;
  no_follow_not?: InputMaybe<Scalars['Boolean']>;
  no_index?: InputMaybe<Scalars['Boolean']>;
  no_index_exists?: InputMaybe<Scalars['Boolean']>;
  no_index_not?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SeoLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  pageCollection?: Maybe<PageCollection>;
};


export type SeoLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type SeoLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SeoOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoFollowAsc = 'no_follow_ASC',
  NoFollowDesc = 'no_follow_DESC',
  NoIndexAsc = 'no_index_ASC',
  NoIndexDesc = 'no_index_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum Severity {
  Error = 'Error',
  Information = 'Information',
  Verbose = 'Verbose',
  Warning = 'Warning'
}

/**
 * Includes: Jumbotron, left column content, right column video, full width content
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/singleVideoTemplate)
 */
export type SingleVideoTemplate = Entry & {
  additionalVideos?: Maybe<Array<Maybe<Scalars['String']>>>;
  contentfulMetadata: ContentfulMetadata;
  fullWidthBody?: Maybe<Scalars['String']>;
  jumbotron?: Maybe<Jumbotron>;
  leftColumnBody?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<SingleVideoTemplateLinkingCollections>;
  permalink?: Maybe<Scalars['String']>;
  redirect?: Maybe<Scalars['String']>;
  rightColumnVideo?: Maybe<Scalars['String']>;
  sys: Sys;
  tabsCollection?: Maybe<SingleVideoTemplateTabsCollection>;
  title?: Maybe<Scalars['String']>;
};


/**
 * Includes: Jumbotron, left column content, right column video, full width content
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/singleVideoTemplate)
 */
export type SingleVideoTemplateAdditionalVideosArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Includes: Jumbotron, left column content, right column video, full width content
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/singleVideoTemplate)
 */
export type SingleVideoTemplateFullWidthBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Includes: Jumbotron, left column content, right column video, full width content
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/singleVideoTemplate)
 */
export type SingleVideoTemplateJumbotronArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/**
 * Includes: Jumbotron, left column content, right column video, full width content
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/singleVideoTemplate)
 */
export type SingleVideoTemplateLeftColumnBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Includes: Jumbotron, left column content, right column video, full width content
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/singleVideoTemplate)
 */
export type SingleVideoTemplateLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/**
 * Includes: Jumbotron, left column content, right column video, full width content
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/singleVideoTemplate)
 */
export type SingleVideoTemplatePermalinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Includes: Jumbotron, left column content, right column video, full width content
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/singleVideoTemplate)
 */
export type SingleVideoTemplateRedirectArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Includes: Jumbotron, left column content, right column video, full width content
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/singleVideoTemplate)
 */
export type SingleVideoTemplateRightColumnVideoArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Includes: Jumbotron, left column content, right column video, full width content
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/singleVideoTemplate)
 */
export type SingleVideoTemplateTabsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/**
 * Includes: Jumbotron, left column content, right column video, full width content
 * and tabber. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/singleVideoTemplate)
 */
export type SingleVideoTemplateTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type SingleVideoTemplateCollection = {
  items: Array<Maybe<SingleVideoTemplate>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SingleVideoTemplateFilter = {
  AND?: InputMaybe<Array<InputMaybe<SingleVideoTemplateFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SingleVideoTemplateFilter>>>;
  additionalVideos_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  additionalVideos_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  additionalVideos_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  additionalVideos_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  fullWidthBody?: InputMaybe<Scalars['String']>;
  fullWidthBody_contains?: InputMaybe<Scalars['String']>;
  fullWidthBody_exists?: InputMaybe<Scalars['Boolean']>;
  fullWidthBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fullWidthBody_not?: InputMaybe<Scalars['String']>;
  fullWidthBody_not_contains?: InputMaybe<Scalars['String']>;
  fullWidthBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jumbotron?: InputMaybe<CfJumbotronNestedFilter>;
  jumbotron_exists?: InputMaybe<Scalars['Boolean']>;
  leftColumnBody?: InputMaybe<Scalars['String']>;
  leftColumnBody_contains?: InputMaybe<Scalars['String']>;
  leftColumnBody_exists?: InputMaybe<Scalars['Boolean']>;
  leftColumnBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  leftColumnBody_not?: InputMaybe<Scalars['String']>;
  leftColumnBody_not_contains?: InputMaybe<Scalars['String']>;
  leftColumnBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  permalink?: InputMaybe<Scalars['String']>;
  permalink_contains?: InputMaybe<Scalars['String']>;
  permalink_exists?: InputMaybe<Scalars['Boolean']>;
  permalink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  permalink_not?: InputMaybe<Scalars['String']>;
  permalink_not_contains?: InputMaybe<Scalars['String']>;
  permalink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redirect?: InputMaybe<Scalars['String']>;
  redirect_contains?: InputMaybe<Scalars['String']>;
  redirect_exists?: InputMaybe<Scalars['Boolean']>;
  redirect_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redirect_not?: InputMaybe<Scalars['String']>;
  redirect_not_contains?: InputMaybe<Scalars['String']>;
  redirect_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rightColumnVideo?: InputMaybe<Scalars['String']>;
  rightColumnVideo_contains?: InputMaybe<Scalars['String']>;
  rightColumnVideo_exists?: InputMaybe<Scalars['Boolean']>;
  rightColumnVideo_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rightColumnVideo_not?: InputMaybe<Scalars['String']>;
  rightColumnVideo_not_contains?: InputMaybe<Scalars['String']>;
  rightColumnVideo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  tabsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SingleVideoTemplateLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  pagePreConfiguredTemplatesCollection?: Maybe<PagePreConfiguredTemplatesCollection>;
};


export type SingleVideoTemplateLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type SingleVideoTemplateLinkingCollectionsPagePreConfiguredTemplatesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SingleVideoTemplateOrder {
  PermalinkAsc = 'permalink_ASC',
  PermalinkDesc = 'permalink_DESC',
  RedirectAsc = 'redirect_ASC',
  RedirectDesc = 'redirect_DESC',
  RightColumnVideoAsc = 'rightColumnVideo_ASC',
  RightColumnVideoDesc = 'rightColumnVideo_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type SingleVideoTemplateTabsCollection = {
  items: Array<Maybe<Tabs>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Site = {
  accomplishments?: Maybe<Scalars['String']>;
  approach?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['String']>;
  cyObjectives?: Maybe<Scalars['String']>;
  displayTitle?: Maybe<Scalars['String']>;
  estimatedFunding?: Maybe<Scalars['String']>;
  fundingStatus?: Maybe<Scalars['Int']>;
  hasChildNodes?: Maybe<Scalars['Boolean']>;
  hasCollaboration?: Maybe<Scalars['Boolean']>;
  hasCommittees?: Maybe<Scalars['Boolean']>;
  hasInDevelopment?: Maybe<Scalars['Boolean']>;
  hasPOF?: Maybe<Scalars['Boolean']>;
  hasProjectSets?: Maybe<Scalars['Boolean']>;
  hasProjectStatus?: Maybe<Scalars['Boolean']>;
  hasProjects?: Maybe<Scalars['Boolean']>;
  hasResearchByTopic?: Maybe<Scalars['Boolean']>;
  hasSubscribed?: Maybe<Scalars['Boolean']>;
  hasSupplementalProjects?: Maybe<Scalars['Boolean']>;
  howToApply?: Maybe<Scalars['String']>;
  impact?: Maybe<Scalars['String']>;
  isFavorite?: Maybe<Scalars['Boolean']>;
  keyResearchQuestion?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  longDescription?: Maybe<Scalars['String']>;
  ltn?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  parentLTN?: Maybe<Scalars['String']>;
  pdfUrl?: Maybe<Scalars['String']>;
  pmContactId?: Maybe<Scalars['String']>;
  pmEmail?: Maybe<Scalars['String']>;
  pmFax?: Maybe<Scalars['String']>;
  pmFirstName?: Maybe<Scalars['String']>;
  pmFullName?: Maybe<Scalars['String']>;
  pmLastName?: Maybe<Scalars['String']>;
  pmPhone?: Maybe<Scalars['String']>;
  pmTitle?: Maybe<Scalars['String']>;
  portfolioYear?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  programLTN?: Maybe<Scalars['String']>;
  researchAreaLTN?: Maybe<Scalars['String']>;
  researchValue?: Maybe<Scalars['String']>;
  sectorLTN?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
  shortOverview?: Maybe<Scalars['String']>;
  sortKey?: Maybe<Scalars['String']>;
  subscriptionSchedule?: Maybe<Scalars['String']>;
  subsites?: Maybe<Array<Maybe<Site>>>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SiteCommittee = {
  advisors?: Maybe<Array<Maybe<CommitteeAdvisor>>>;
  committeeId?: Maybe<Scalars['Int']>;
  committeeName?: Maybe<Scalars['String']>;
  invitationOnly?: Maybe<Scalars['Boolean']>;
  showAddAdvisor?: Maybe<Scalars['Boolean']>;
  siteNumber?: Maybe<Scalars['String']>;
};

/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/sitemap) */
export type Sitemap = Entry & {
  body?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  introText?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<SitemapLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/sitemap) */
export type SitemapBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/sitemap) */
export type SitemapIntroTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/sitemap) */
export type SitemapLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/sitemap) */
export type SitemapTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type SitemapCollection = {
  items: Array<Maybe<Sitemap>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SitemapFilter = {
  AND?: InputMaybe<Array<InputMaybe<SitemapFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SitemapFilter>>>;
  body?: InputMaybe<Scalars['String']>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  introText?: InputMaybe<Scalars['String']>;
  introText_contains?: InputMaybe<Scalars['String']>;
  introText_exists?: InputMaybe<Scalars['Boolean']>;
  introText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  introText_not?: InputMaybe<Scalars['String']>;
  introText_not_contains?: InputMaybe<Scalars['String']>;
  introText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SitemapLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type SitemapLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum SitemapOrder {
  IntroTextAsc = 'introText_ASC',
  IntroTextDesc = 'introText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type StaffDetail = {
  cventId?: Maybe<Scalars['String']>;
  displayOnWebSite?: Maybe<Scalars['Boolean']>;
  eventId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  staffEmailAddress?: Maybe<Scalars['String']>;
  staffFirstName?: Maybe<Scalars['String']>;
  staffLastName?: Maybe<Scalars['String']>;
  staffRole?: Maybe<Scalars['String']>;
};

export type State = {
  countryId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum Status {
  Publish = 'Publish',
  Save = 'Save'
}

/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/storyCards) */
export type StoryCards = Entry & {
  body?: Maybe<Scalars['String']>;
  buttonLink?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  image?: Maybe<Asset>;
  linkedFrom?: Maybe<StoryCardsLinkingCollections>;
  publicOrMemberOnly?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['Boolean']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/storyCards) */
export type StoryCardsBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/storyCards) */
export type StoryCardsButtonLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/storyCards) */
export type StoryCardsButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/storyCards) */
export type StoryCardsImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/storyCards) */
export type StoryCardsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/storyCards) */
export type StoryCardsPublicOrMemberOnlyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/storyCards) */
export type StoryCardsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/storyCards) */
export type StoryCardsVideoArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type StoryCardsCollection = {
  items: Array<Maybe<StoryCards>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type StoryCardsFilter = {
  AND?: InputMaybe<Array<InputMaybe<StoryCardsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<StoryCardsFilter>>>;
  body?: InputMaybe<Scalars['String']>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink?: InputMaybe<Scalars['String']>;
  buttonLink_contains?: InputMaybe<Scalars['String']>;
  buttonLink_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink_not?: InputMaybe<Scalars['String']>;
  buttonLink_not_contains?: InputMaybe<Scalars['String']>;
  buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonText_contains?: InputMaybe<Scalars['String']>;
  buttonText_exists?: InputMaybe<Scalars['Boolean']>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText_not?: InputMaybe<Scalars['String']>;
  buttonText_not_contains?: InputMaybe<Scalars['String']>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  publicOrMemberOnly?: InputMaybe<Scalars['String']>;
  publicOrMemberOnly_contains?: InputMaybe<Scalars['String']>;
  publicOrMemberOnly_exists?: InputMaybe<Scalars['Boolean']>;
  publicOrMemberOnly_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicOrMemberOnly_not?: InputMaybe<Scalars['String']>;
  publicOrMemberOnly_not_contains?: InputMaybe<Scalars['String']>;
  publicOrMemberOnly_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  video?: InputMaybe<Scalars['Boolean']>;
  video_exists?: InputMaybe<Scalars['Boolean']>;
  video_not?: InputMaybe<Scalars['Boolean']>;
};

export type StoryCardsLinkingCollections = {
  cardTemplateCollection?: Maybe<CardTemplateCollection>;
  entryCollection?: Maybe<EntryCollection>;
  epriUCollection?: Maybe<EpriUCollection>;
  eventsCollection?: Maybe<EventsCollection>;
  galleryTemplateCollection?: Maybe<GalleryTemplateCollection>;
  homeCollection?: Maybe<HomeCollection>;
  ltnLandingPageCollection?: Maybe<LtnLandingPageCollection>;
  multimediaTemplateCollection?: Maybe<MultimediaTemplateCollection>;
  portfolioSectorsCollection?: Maybe<PortfolioSectorsCollection>;
  researchAdvisoryCommitteeHomeCollection?: Maybe<ResearchAdvisoryCommitteeHomeCollection>;
  researchCollection?: Maybe<ResearchCollection>;
  thoughtLeadershipCollection?: Maybe<ThoughtLeadershipCollection>;
  xLprLandingPageCollection?: Maybe<XLprLandingPageCollection>;
};


export type StoryCardsLinkingCollectionsCardTemplateCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type StoryCardsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type StoryCardsLinkingCollectionsEpriUCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type StoryCardsLinkingCollectionsEventsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type StoryCardsLinkingCollectionsGalleryTemplateCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type StoryCardsLinkingCollectionsHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type StoryCardsLinkingCollectionsLtnLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type StoryCardsLinkingCollectionsMultimediaTemplateCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type StoryCardsLinkingCollectionsPortfolioSectorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type StoryCardsLinkingCollectionsResearchAdvisoryCommitteeHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type StoryCardsLinkingCollectionsResearchCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type StoryCardsLinkingCollectionsThoughtLeadershipCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type StoryCardsLinkingCollectionsXLprLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum StoryCardsOrder {
  ButtonLinkAsc = 'buttonLink_ASC',
  ButtonLinkDesc = 'buttonLink_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  PublicOrMemberOnlyAsc = 'publicOrMemberOnly_ASC',
  PublicOrMemberOnlyDesc = 'publicOrMemberOnly_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC'
}

export type SubscriptionDetails = {
  /** Daily/Weekly/Biweekly */
  frequency?: Maybe<Scalars['String']>;
  /** 0 Indicates no and 1 indicates yes */
  hasTIsubscription?: Maybe<Scalars['Int']>;
  /** Contact Id */
  id?: Maybe<Scalars['String']>;
  /** 1 indicates 'All Updates', 2 indicates 'Products Only' */
  isSubscribed?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
};

export type SubscriptionInput = {
  ltn?: InputMaybe<Scalars['String']>;
  /** 1 for subscribe/0 for unsubscribe/2 for Products Only updates */
  subscribe?: InputMaybe<Scalars['Int']>;
  /** Schedule can be Daily/Weekly/Biweekly */
  subscribeSchedule?: InputMaybe<SubscriptionSchedule>;
};

export enum SubscriptionSchedule {
  Biweekly = 'Biweekly',
  Daily = 'Daily',
  Weekly = 'Weekly'
}

export enum SubsidiaryAction {
  Add = 'Add',
  Confirm = 'Confirm',
  New = 'New',
  Remove = 'Remove',
  Update = 'Update'
}

export type SubsidiaryAffiliateRequest = {
  acg?: Maybe<Scalars['String']>;
  acgType?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  addressCity?: Maybe<Scalars['String']>;
  addressCountryCode?: Maybe<Scalars['String']>;
  addressCountryDesc?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['String']>;
  addressPostalCode?: Maybe<Scalars['String']>;
  addressState?: Maybe<Scalars['String']>;
  adminUserName?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  confirmDate?: Maybe<Scalars['String']>;
  confirmedBy?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  domainNames?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  internalNotes?: Maybe<Scalars['String']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
  legalEntityName?: Maybe<Scalars['String']>;
  membershipYear?: Maybe<Scalars['String']>;
  nonBinding?: Maybe<Scalars['Boolean']>;
  nonUtility?: Maybe<Scalars['Boolean']>;
  notifyDate?: Maybe<Scalars['String']>;
  onHold?: Maybe<Scalars['Boolean']>;
  rejectDate?: Maybe<Scalars['String']>;
  requestDate?: Maybe<Scalars['String']>;
  requestedBy?: Maybe<Scalars['String']>;
  requesterFullName?: Maybe<Scalars['String']>;
  sendDate?: Maybe<Scalars['String']>;
  statusNotes?: Maybe<Scalars['String']>;
  subsidiaryContactAddress?: Maybe<Scalars['String']>;
  subsidiaryContactEmail?: Maybe<Scalars['String']>;
  subsidiaryContactName?: Maybe<Scalars['String']>;
  updateDate?: Maybe<Scalars['String']>;
};

export type SubsidiaryAffiliateResponse = {
  noOfPages?: Maybe<Scalars['Int']>;
  requests?: Maybe<Array<Maybe<SubsidiaryAffiliateRequest>>>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export type SubsidiaryRequestInput = {
  acg?: InputMaybe<Scalars['String']>;
  action: SubsidiaryAction;
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  addressCity?: InputMaybe<Scalars['String']>;
  addressCountryCode?: InputMaybe<Scalars['String']>;
  addressCountryDesc?: InputMaybe<Scalars['String']>;
  addressId?: InputMaybe<Scalars['String']>;
  addressPostalCode?: InputMaybe<Scalars['String']>;
  addressState?: InputMaybe<Scalars['String']>;
  adminUserName?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  confirmDate?: InputMaybe<Scalars['String']>;
  confirmedBy?: InputMaybe<Scalars['String']>;
  contactAddress1?: InputMaybe<Scalars['String']>;
  contactAddress2?: InputMaybe<Scalars['String']>;
  contactAddressCity?: InputMaybe<Scalars['String']>;
  contactAddressCountryCode?: InputMaybe<Scalars['String']>;
  contactAddressCountryDesc?: InputMaybe<Scalars['String']>;
  contactAddressPostalCode?: InputMaybe<Scalars['String']>;
  contactAddressState?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  defaultFunderCompanyId: Scalars['String'];
  defaultFunderCompanyName: Scalars['String'];
  domainNames?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  internalNotes?: InputMaybe<Scalars['String']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']>;
  membershipYear?: InputMaybe<Scalars['String']>;
  nonBinding?: InputMaybe<Scalars['Boolean']>;
  nonUtility?: InputMaybe<Scalars['Boolean']>;
  notifyDate?: InputMaybe<Scalars['String']>;
  onHold?: InputMaybe<Scalars['Boolean']>;
  rejectDate?: InputMaybe<Scalars['String']>;
  requestDate?: InputMaybe<Scalars['String']>;
  requestedBy?: InputMaybe<Scalars['String']>;
  requestingUserEmail: Scalars['String'];
  requestingUserFullName: Scalars['String'];
  selectedCompanyId: Scalars['String'];
  sendDate?: InputMaybe<Scalars['String']>;
  statusNotes?: InputMaybe<Scalars['String']>;
  subsidiaryContactEmail?: InputMaybe<Scalars['String']>;
  subsidiaryContactName?: InputMaybe<Scalars['String']>;
  updateDate?: InputMaybe<Scalars['String']>;
};

export type SubsidiaryResponse = {
  acg?: Maybe<Scalars['String']>;
  acgMembers?: Maybe<Array<Maybe<AcgMember>>>;
  acgName?: Maybe<Scalars['String']>;
  acgParticipants?: Maybe<Array<Maybe<AcgMember>>>;
  acgType?: Maybe<Scalars['String']>;
  canEditACGMember?: Maybe<Scalars['Boolean']>;
  changesExistInNextYear?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  hasOtherACGParticipants?: Maybe<Scalars['Boolean']>;
  lastApprovedBy?: Maybe<Scalars['String']>;
  lastApprovedDate?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
  startRecord?: Maybe<Scalars['Int']>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export type SummaryResponse = {
  eligibleNotRepresentedCommittees?: Maybe<Array<Maybe<EligibleNotRepresentedBySector>>>;
  sectoradvisors?: Maybe<Array<Maybe<SectorAdvisors>>>;
};

export type SupplementalFundingDetail = {
  companyGroup?: Maybe<Scalars['String']>;
  executedBSA?: Maybe<Scalars['Float']>;
  executedCF?: Maybe<Scalars['Float']>;
  executedSDF?: Maybe<Scalars['Float']>;
  executedTC?: Maybe<Scalars['Float']>;
  executedTOTAL?: Maybe<Scalars['Float']>;
  fundingLevel?: Maybe<Scalars['String']>;
  fundingYear?: Maybe<Scalars['Int']>;
  pendingBSA?: Maybe<Scalars['Float']>;
  pendingCF?: Maybe<Scalars['Float']>;
  pendingSDF?: Maybe<Scalars['Float']>;
  pendingTC?: Maybe<Scalars['Float']>;
  pendingTOTAL?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  productLongDescription?: Maybe<Scalars['String']>;
  programLTN?: Maybe<Scalars['String']>;
  programName?: Maybe<Scalars['String']>;
  programNumber?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  projectLTN?: Maybe<Scalars['String']>;
  researchArea?: Maybe<Scalars['String']>;
  researchAreaLTN?: Maybe<Scalars['String']>;
  researchAreaName?: Maybe<Scalars['String']>;
  sectorLTN?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
  shortSectorName?: Maybe<Scalars['String']>;
};

export type SupplementalFundingDetailResponse = {
  details?: Maybe<Array<Maybe<SupplementalFundingDetail>>>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export type SupplementalFundingSummary = {
  companyGroup?: Maybe<Scalars['String']>;
  executedBSA?: Maybe<Scalars['Float']>;
  executedCF?: Maybe<Scalars['Float']>;
  executedSDF?: Maybe<Scalars['Float']>;
  executedTC?: Maybe<Scalars['Float']>;
  executedTOTAL?: Maybe<Scalars['Float']>;
  fundingYear?: Maybe<Scalars['Int']>;
  pendingBSA?: Maybe<Scalars['Float']>;
  pendingCF?: Maybe<Scalars['Float']>;
  pendingSDF?: Maybe<Scalars['Float']>;
  pendingTC?: Maybe<Scalars['Float']>;
  pendingTOTAL?: Maybe<Scalars['Float']>;
};

export type SupplementalFundingSummaryResponse = {
  summary?: Maybe<Array<Maybe<SupplementalFundingSummary>>>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export type Sys = {
  environmentId: Scalars['String'];
  firstPublishedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  publishedVersion?: Maybe<Scalars['Int']>;
  spaceId: Scalars['String'];
};

export type SysFilter = {
  firstPublishedAt?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_exists?: InputMaybe<Scalars['Boolean']>;
  firstPublishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  firstPublishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_not?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  id?: InputMaybe<Scalars['String']>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_exists?: InputMaybe<Scalars['Boolean']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_not?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  publishedAt_exists?: InputMaybe<Scalars['Boolean']>;
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedVersion?: InputMaybe<Scalars['Float']>;
  publishedVersion_exists?: InputMaybe<Scalars['Boolean']>;
  publishedVersion_gt?: InputMaybe<Scalars['Float']>;
  publishedVersion_gte?: InputMaybe<Scalars['Float']>;
  publishedVersion_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  publishedVersion_lt?: InputMaybe<Scalars['Float']>;
  publishedVersion_lte?: InputMaybe<Scalars['Float']>;
  publishedVersion_not?: InputMaybe<Scalars['Float']>;
  publishedVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

export type TtContactInput = {
  company: Scalars['String'];
  countryCode?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  nominationId?: InputMaybe<Scalars['Int']>;
  phone: Scalars['String'];
  phoneExtension?: InputMaybe<Scalars['String']>;
  salutation?: InputMaybe<Scalars['String']>;
  supervisor?: InputMaybe<TtSupervisorInput>;
};

export type TtLookUpInput = {
  name?: InputMaybe<Scalars['String']>;
  nominationId?: InputMaybe<Scalars['String']>;
};

export type TtLookup = {
  name?: Maybe<Scalars['String']>;
};

export type TtSupervisorInput = {
  company?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  nominationId?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneExtension?: InputMaybe<Scalars['String']>;
  salutation?: InputMaybe<Scalars['String']>;
};

/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/tabs) */
export type Tabs = Entry & {
  buttonLink?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<TabsLinkingCollections>;
  storyBody?: Maybe<Scalars['String']>;
  storyTitle?: Maybe<Scalars['String']>;
  sys: Sys;
  tabTitle?: Maybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/tabs) */
export type TabsButtonLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/tabs) */
export type TabsButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/tabs) */
export type TabsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/tabs) */
export type TabsStoryBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/tabs) */
export type TabsStoryTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/tabs) */
export type TabsTabTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type TabsCollection = {
  items: Array<Maybe<Tabs>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TabsFilter = {
  AND?: InputMaybe<Array<InputMaybe<TabsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TabsFilter>>>;
  buttonLink?: InputMaybe<Scalars['String']>;
  buttonLink_contains?: InputMaybe<Scalars['String']>;
  buttonLink_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink_not?: InputMaybe<Scalars['String']>;
  buttonLink_not_contains?: InputMaybe<Scalars['String']>;
  buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonText_contains?: InputMaybe<Scalars['String']>;
  buttonText_exists?: InputMaybe<Scalars['Boolean']>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText_not?: InputMaybe<Scalars['String']>;
  buttonText_not_contains?: InputMaybe<Scalars['String']>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  storyBody?: InputMaybe<Scalars['String']>;
  storyBody_contains?: InputMaybe<Scalars['String']>;
  storyBody_exists?: InputMaybe<Scalars['Boolean']>;
  storyBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  storyBody_not?: InputMaybe<Scalars['String']>;
  storyBody_not_contains?: InputMaybe<Scalars['String']>;
  storyBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  storyTitle?: InputMaybe<Scalars['String']>;
  storyTitle_contains?: InputMaybe<Scalars['String']>;
  storyTitle_exists?: InputMaybe<Scalars['Boolean']>;
  storyTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  storyTitle_not?: InputMaybe<Scalars['String']>;
  storyTitle_not_contains?: InputMaybe<Scalars['String']>;
  storyTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  tabTitle?: InputMaybe<Scalars['String']>;
  tabTitle_contains?: InputMaybe<Scalars['String']>;
  tabTitle_exists?: InputMaybe<Scalars['Boolean']>;
  tabTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tabTitle_not?: InputMaybe<Scalars['String']>;
  tabTitle_not_contains?: InputMaybe<Scalars['String']>;
  tabTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TabsLinkingCollections = {
  aiTemplatePageCollection?: Maybe<AiTemplatePageCollection>;
  cardTemplateCollection?: Maybe<CardTemplateCollection>;
  careersCollection?: Maybe<CareersCollection>;
  coresprayPipingLandingPageCollection?: Maybe<CoresprayPipingLandingPageCollection>;
  entryCollection?: Maybe<EntryCollection>;
  homeCollection?: Maybe<HomeCollection>;
  multimediaTemplateCollection?: Maybe<MultimediaTemplateCollection>;
  singleVideoTemplateCollection?: Maybe<SingleVideoTemplateCollection>;
  twoColumnTemplateCollection?: Maybe<TwoColumnTemplateCollection>;
};


export type TabsLinkingCollectionsAiTemplatePageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type TabsLinkingCollectionsCardTemplateCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type TabsLinkingCollectionsCareersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type TabsLinkingCollectionsCoresprayPipingLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type TabsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type TabsLinkingCollectionsHomeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type TabsLinkingCollectionsMultimediaTemplateCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type TabsLinkingCollectionsSingleVideoTemplateCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type TabsLinkingCollectionsTwoColumnTemplateCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum TabsOrder {
  ButtonLinkAsc = 'buttonLink_ASC',
  ButtonLinkDesc = 'buttonLink_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  StoryTitleAsc = 'storyTitle_ASC',
  StoryTitleDesc = 'storyTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TabTitleAsc = 'tabTitle_ASC',
  TabTitleDesc = 'tabTitle_DESC'
}

export type TechTransferContact = {
  company: Scalars['String'];
  countryCode?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  nominationId?: Maybe<Scalars['Int']>;
  phone: Scalars['String'];
  phoneExtension?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  supervisor?: Maybe<TechTransferSupervisor>;
};

export type TechTransferSupervisor = {
  company?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nominationId?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  phoneExtension?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
};

/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/termsOfUse) */
export type TermsOfUse = Entry & {
  columnOneCollection?: Maybe<TermsOfUseColumnOneCollection>;
  columnTwoCollection?: Maybe<TermsOfUseColumnTwoCollection>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<TermsOfUseLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/termsOfUse) */
export type TermsOfUseColumnOneCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/termsOfUse) */
export type TermsOfUseColumnTwoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/termsOfUse) */
export type TermsOfUseLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/termsOfUse) */
export type TermsOfUseTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type TermsOfUseCollection = {
  items: Array<Maybe<TermsOfUse>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TermsOfUseColumnOneCollection = {
  items: Array<Maybe<TermsOfUseContent>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TermsOfUseColumnTwoCollection = {
  items: Array<Maybe<TermsOfUseContent>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/termsOfUseContent) */
export type TermsOfUseContent = Entry & {
  body?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<TermsOfUseContentLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/termsOfUseContent) */
export type TermsOfUseContentBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/termsOfUseContent) */
export type TermsOfUseContentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/termsOfUseContent) */
export type TermsOfUseContentTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type TermsOfUseContentCollection = {
  items: Array<Maybe<TermsOfUseContent>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TermsOfUseContentFilter = {
  AND?: InputMaybe<Array<InputMaybe<TermsOfUseContentFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TermsOfUseContentFilter>>>;
  body?: InputMaybe<Scalars['String']>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TermsOfUseContentLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  termsOfUseCollection?: Maybe<TermsOfUseCollection>;
};


export type TermsOfUseContentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type TermsOfUseContentLinkingCollectionsTermsOfUseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum TermsOfUseContentOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type TermsOfUseFilter = {
  AND?: InputMaybe<Array<InputMaybe<TermsOfUseFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TermsOfUseFilter>>>;
  columnOneCollection_exists?: InputMaybe<Scalars['Boolean']>;
  columnTwoCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TermsOfUseLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type TermsOfUseLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum TermsOfUseOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadership = Entry & {
  aiInitiativeImage?: Maybe<Asset>;
  contentfulMetadata: ContentfulMetadata;
  cyberInitiativeImage?: Maybe<Asset>;
  downloadsArchiveCollection?: Maybe<ThoughtLeadershipDownloadsArchiveCollection>;
  facebook?: Maybe<AiComponent>;
  featuredDownload?: Maybe<StoryCards>;
  featuredDownloadHeader?: Maybe<Scalars['String']>;
  indoorAgInitiativeImage?: Maybe<Asset>;
  initiativesCollection?: Maybe<ThoughtLeadershipInitiativesCollection>;
  initiativesHeader?: Maybe<Scalars['String']>;
  jumbotron?: Maybe<Jumbotron>;
  latestDownloadsCollection?: Maybe<ThoughtLeadershipLatestDownloadsCollection>;
  latestDownloadsHeader?: Maybe<Scalars['String']>;
  latestDownloadsImage?: Maybe<Asset>;
  latestVideo?: Maybe<StoryCards>;
  latestVideoHeader?: Maybe<Scalars['String']>;
  leftColumnBody?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<ThoughtLeadershipLinkingCollections>;
  linkedIn?: Maybe<AiComponent>;
  newsHeader?: Maybe<Scalars['String']>;
  newsItemsCollection?: Maybe<ThoughtLeadershipNewsItemsCollection>;
  permalink?: Maybe<Scalars['String']>;
  readMoreButtonText?: Maybe<Scalars['String']>;
  redirect?: Maybe<Scalars['String']>;
  rightColumnBody?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  twitter?: Maybe<AiComponent>;
  updatesCollection?: Maybe<ThoughtLeadershipUpdatesCollection>;
  updatesHeader?: Maybe<Scalars['String']>;
  viewMoreArchiveText?: Maybe<Scalars['String']>;
  viewMoreButtonText?: Maybe<Scalars['String']>;
  youtubeEmbed?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipAiInitiativeImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipCyberInitiativeImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipDownloadsArchiveCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipFacebookArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipFeaturedDownloadArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipFeaturedDownloadHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipIndoorAgInitiativeImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipInitiativesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipInitiativesHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipJumbotronArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipLatestDownloadsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipLatestDownloadsHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipLatestDownloadsImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipLatestVideoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipLatestVideoHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipLeftColumnBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipLinkedInArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipNewsHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipNewsItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipPermalinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipReadMoreButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipRedirectArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipRightColumnBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipTwitterArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipUpdatesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipUpdatesHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipViewMoreArchiveTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipViewMoreButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** EPRI Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadership) */
export type ThoughtLeadershipYoutubeEmbedArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ThoughtLeadershipCollection = {
  items: Array<Maybe<ThoughtLeadership>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

/** Download information for Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadershipDownloads) */
export type ThoughtLeadershipDownloads = Entry & {
  contentfulMetadata: ContentfulMetadata;
  date?: Maybe<Scalars['DateTime']>;
  linkedFrom?: Maybe<ThoughtLeadershipDownloadsLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};


/** Download information for Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadershipDownloads) */
export type ThoughtLeadershipDownloadsDateArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Download information for Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadershipDownloads) */
export type ThoughtLeadershipDownloadsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Download information for Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadershipDownloads) */
export type ThoughtLeadershipDownloadsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Download information for Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadershipDownloads) */
export type ThoughtLeadershipDownloadsTypeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Download information for Thought Leadership Page [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/thoughtLeadershipDownloads) */
export type ThoughtLeadershipDownloadsUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type ThoughtLeadershipDownloadsArchiveCollection = {
  items: Array<Maybe<ThoughtLeadershipDownloads>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ThoughtLeadershipDownloadsCollection = {
  items: Array<Maybe<ThoughtLeadershipDownloads>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ThoughtLeadershipDownloadsFilter = {
  AND?: InputMaybe<Array<InputMaybe<ThoughtLeadershipDownloadsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ThoughtLeadershipDownloadsFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  date?: InputMaybe<Scalars['DateTime']>;
  date_exists?: InputMaybe<Scalars['Boolean']>;
  date_gt?: InputMaybe<Scalars['DateTime']>;
  date_gte?: InputMaybe<Scalars['DateTime']>;
  date_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  date_lt?: InputMaybe<Scalars['DateTime']>;
  date_lte?: InputMaybe<Scalars['DateTime']>;
  date_not?: InputMaybe<Scalars['DateTime']>;
  date_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type?: InputMaybe<Scalars['String']>;
  type_contains?: InputMaybe<Scalars['String']>;
  type_exists?: InputMaybe<Scalars['Boolean']>;
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type_not?: InputMaybe<Scalars['String']>;
  type_not_contains?: InputMaybe<Scalars['String']>;
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ThoughtLeadershipDownloadsLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  thoughtLeadershipCollection?: Maybe<ThoughtLeadershipCollection>;
};


export type ThoughtLeadershipDownloadsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ThoughtLeadershipDownloadsLinkingCollectionsThoughtLeadershipCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum ThoughtLeadershipDownloadsOrder {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export type ThoughtLeadershipFilter = {
  AND?: InputMaybe<Array<InputMaybe<ThoughtLeadershipFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ThoughtLeadershipFilter>>>;
  aiInitiativeImage_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  cyberInitiativeImage_exists?: InputMaybe<Scalars['Boolean']>;
  downloadsArchiveCollection_exists?: InputMaybe<Scalars['Boolean']>;
  facebook?: InputMaybe<CfAiComponentNestedFilter>;
  facebook_exists?: InputMaybe<Scalars['Boolean']>;
  featuredDownload?: InputMaybe<CfStoryCardsNestedFilter>;
  featuredDownloadHeader?: InputMaybe<Scalars['String']>;
  featuredDownloadHeader_contains?: InputMaybe<Scalars['String']>;
  featuredDownloadHeader_exists?: InputMaybe<Scalars['Boolean']>;
  featuredDownloadHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  featuredDownloadHeader_not?: InputMaybe<Scalars['String']>;
  featuredDownloadHeader_not_contains?: InputMaybe<Scalars['String']>;
  featuredDownloadHeader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  featuredDownload_exists?: InputMaybe<Scalars['Boolean']>;
  indoorAgInitiativeImage_exists?: InputMaybe<Scalars['Boolean']>;
  initiativesCollection_exists?: InputMaybe<Scalars['Boolean']>;
  initiativesHeader?: InputMaybe<Scalars['String']>;
  initiativesHeader_contains?: InputMaybe<Scalars['String']>;
  initiativesHeader_exists?: InputMaybe<Scalars['Boolean']>;
  initiativesHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  initiativesHeader_not?: InputMaybe<Scalars['String']>;
  initiativesHeader_not_contains?: InputMaybe<Scalars['String']>;
  initiativesHeader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jumbotron?: InputMaybe<CfJumbotronNestedFilter>;
  jumbotron_exists?: InputMaybe<Scalars['Boolean']>;
  latestDownloadsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  latestDownloadsHeader?: InputMaybe<Scalars['String']>;
  latestDownloadsHeader_contains?: InputMaybe<Scalars['String']>;
  latestDownloadsHeader_exists?: InputMaybe<Scalars['Boolean']>;
  latestDownloadsHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  latestDownloadsHeader_not?: InputMaybe<Scalars['String']>;
  latestDownloadsHeader_not_contains?: InputMaybe<Scalars['String']>;
  latestDownloadsHeader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  latestDownloadsImage_exists?: InputMaybe<Scalars['Boolean']>;
  latestVideo?: InputMaybe<CfStoryCardsNestedFilter>;
  latestVideoHeader?: InputMaybe<Scalars['String']>;
  latestVideoHeader_contains?: InputMaybe<Scalars['String']>;
  latestVideoHeader_exists?: InputMaybe<Scalars['Boolean']>;
  latestVideoHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  latestVideoHeader_not?: InputMaybe<Scalars['String']>;
  latestVideoHeader_not_contains?: InputMaybe<Scalars['String']>;
  latestVideoHeader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  latestVideo_exists?: InputMaybe<Scalars['Boolean']>;
  leftColumnBody?: InputMaybe<Scalars['String']>;
  leftColumnBody_contains?: InputMaybe<Scalars['String']>;
  leftColumnBody_exists?: InputMaybe<Scalars['Boolean']>;
  leftColumnBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  leftColumnBody_not?: InputMaybe<Scalars['String']>;
  leftColumnBody_not_contains?: InputMaybe<Scalars['String']>;
  leftColumnBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  linkedIn?: InputMaybe<CfAiComponentNestedFilter>;
  linkedIn_exists?: InputMaybe<Scalars['Boolean']>;
  newsHeader?: InputMaybe<Scalars['String']>;
  newsHeader_contains?: InputMaybe<Scalars['String']>;
  newsHeader_exists?: InputMaybe<Scalars['Boolean']>;
  newsHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newsHeader_not?: InputMaybe<Scalars['String']>;
  newsHeader_not_contains?: InputMaybe<Scalars['String']>;
  newsHeader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newsItemsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  permalink?: InputMaybe<Scalars['String']>;
  permalink_contains?: InputMaybe<Scalars['String']>;
  permalink_exists?: InputMaybe<Scalars['Boolean']>;
  permalink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  permalink_not?: InputMaybe<Scalars['String']>;
  permalink_not_contains?: InputMaybe<Scalars['String']>;
  permalink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readMoreButtonText?: InputMaybe<Scalars['String']>;
  readMoreButtonText_contains?: InputMaybe<Scalars['String']>;
  readMoreButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  readMoreButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readMoreButtonText_not?: InputMaybe<Scalars['String']>;
  readMoreButtonText_not_contains?: InputMaybe<Scalars['String']>;
  readMoreButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redirect?: InputMaybe<Scalars['String']>;
  redirect_contains?: InputMaybe<Scalars['String']>;
  redirect_exists?: InputMaybe<Scalars['Boolean']>;
  redirect_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redirect_not?: InputMaybe<Scalars['String']>;
  redirect_not_contains?: InputMaybe<Scalars['String']>;
  redirect_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rightColumnBody?: InputMaybe<Scalars['String']>;
  rightColumnBody_contains?: InputMaybe<Scalars['String']>;
  rightColumnBody_exists?: InputMaybe<Scalars['Boolean']>;
  rightColumnBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rightColumnBody_not?: InputMaybe<Scalars['String']>;
  rightColumnBody_not_contains?: InputMaybe<Scalars['String']>;
  rightColumnBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  twitter?: InputMaybe<CfAiComponentNestedFilter>;
  twitter_exists?: InputMaybe<Scalars['Boolean']>;
  updatesCollection_exists?: InputMaybe<Scalars['Boolean']>;
  updatesHeader?: InputMaybe<Scalars['String']>;
  updatesHeader_contains?: InputMaybe<Scalars['String']>;
  updatesHeader_exists?: InputMaybe<Scalars['Boolean']>;
  updatesHeader_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  updatesHeader_not?: InputMaybe<Scalars['String']>;
  updatesHeader_not_contains?: InputMaybe<Scalars['String']>;
  updatesHeader_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  viewMoreArchiveText?: InputMaybe<Scalars['String']>;
  viewMoreArchiveText_contains?: InputMaybe<Scalars['String']>;
  viewMoreArchiveText_exists?: InputMaybe<Scalars['Boolean']>;
  viewMoreArchiveText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  viewMoreArchiveText_not?: InputMaybe<Scalars['String']>;
  viewMoreArchiveText_not_contains?: InputMaybe<Scalars['String']>;
  viewMoreArchiveText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  viewMoreButtonText?: InputMaybe<Scalars['String']>;
  viewMoreButtonText_contains?: InputMaybe<Scalars['String']>;
  viewMoreButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  viewMoreButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  viewMoreButtonText_not?: InputMaybe<Scalars['String']>;
  viewMoreButtonText_not_contains?: InputMaybe<Scalars['String']>;
  viewMoreButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  youtubeEmbed_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  youtubeEmbed_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  youtubeEmbed_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  youtubeEmbed_exists?: InputMaybe<Scalars['Boolean']>;
};

export type ThoughtLeadershipInitiativesCollection = {
  items: Array<Maybe<PressRelease>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ThoughtLeadershipLatestDownloadsCollection = {
  items: Array<Maybe<ThoughtLeadershipDownloads>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ThoughtLeadershipLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  pagePreConfiguredTemplatesCollection?: Maybe<PagePreConfiguredTemplatesCollection>;
};


export type ThoughtLeadershipLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type ThoughtLeadershipLinkingCollectionsPagePreConfiguredTemplatesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type ThoughtLeadershipNewsItemsCollection = {
  items: Array<Maybe<PressRelease>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ThoughtLeadershipOrder {
  FeaturedDownloadHeaderAsc = 'featuredDownloadHeader_ASC',
  FeaturedDownloadHeaderDesc = 'featuredDownloadHeader_DESC',
  InitiativesHeaderAsc = 'initiativesHeader_ASC',
  InitiativesHeaderDesc = 'initiativesHeader_DESC',
  LatestDownloadsHeaderAsc = 'latestDownloadsHeader_ASC',
  LatestDownloadsHeaderDesc = 'latestDownloadsHeader_DESC',
  LatestVideoHeaderAsc = 'latestVideoHeader_ASC',
  LatestVideoHeaderDesc = 'latestVideoHeader_DESC',
  NewsHeaderAsc = 'newsHeader_ASC',
  NewsHeaderDesc = 'newsHeader_DESC',
  PermalinkAsc = 'permalink_ASC',
  PermalinkDesc = 'permalink_DESC',
  ReadMoreButtonTextAsc = 'readMoreButtonText_ASC',
  ReadMoreButtonTextDesc = 'readMoreButtonText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatesHeaderAsc = 'updatesHeader_ASC',
  UpdatesHeaderDesc = 'updatesHeader_DESC',
  ViewMoreArchiveTextAsc = 'viewMoreArchiveText_ASC',
  ViewMoreArchiveTextDesc = 'viewMoreArchiveText_DESC',
  ViewMoreButtonTextAsc = 'viewMoreButtonText_ASC',
  ViewMoreButtonTextDesc = 'viewMoreButtonText_DESC'
}

export type ThoughtLeadershipUpdatesCollection = {
  items: Array<Maybe<PressRelease>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TopDownloadProductDetail = {
  abstractLink?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type TopDownloadProductDetailResponse = {
  details?: Maybe<Array<Maybe<TopDownloadProductDetail>>>;
  noOfPages?: Maybe<Scalars['Int']>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export type Topic = {
  description?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  hasCategory?: Maybe<Scalars['Boolean']>;
  hasMultiple?: Maybe<Scalars['Boolean']>;
  header?: Maybe<Scalars['Boolean']>;
  iD?: Maybe<Scalars['String']>;
  languageCode?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  parentID?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<ByTopicProduct>>>;
  sort?: Maybe<Scalars['String']>;
  subTopicDescription?: Maybe<Scalars['String']>;
  subTopicFooter?: Maybe<Scalars['String']>;
  subTopicHasCategory?: Maybe<Scalars['Boolean']>;
  subTopicHeader?: Maybe<Scalars['String']>;
  subTopicId?: Maybe<Scalars['String']>;
  subTopics?: Maybe<Array<Maybe<Topic>>>;
  topicEqualsSubTopic?: Maybe<Scalars['Boolean']>;
};

export type Training = {
  PDH?: Maybe<Scalars['String']>;
  competencies?: Maybe<Scalars['String']>;
  createDate?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  curriculumPriceAmount?: Maybe<Scalars['String']>;
  curriculumPriceCurrency?: Maybe<Scalars['String']>;
  curriculumType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dressCode?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  eventNumber?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['String']>;
  isBookmarked?: Maybe<Scalars['Boolean']>;
  languages?: Maybe<Array<Maybe<TrainingLanguages>>>;
  lmsEventId?: Maybe<Scalars['String']>;
  lmsTrainingType?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  materialType?: Maybe<Scalars['String']>;
  materialUrl?: Maybe<Scalars['String']>;
  modifyDate?: Maybe<Scalars['String']>;
  objectId?: Maybe<Scalars['String']>;
  objectUrl?: Maybe<Scalars['String']>;
  objectives?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  productID?: Maybe<Scalars['String']>;
  programLTN?: Maybe<Scalars['String']>;
  programName?: Maybe<Scalars['String']>;
  programNumber?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  sectorLTN?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
  subjects?: Maybe<Array<Maybe<TrainingSubjects>>>;
  targetAudience?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  trainingSubType?: Maybe<Scalars['String']>;
  trainingType?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  wbsNumber?: Maybe<Scalars['String']>;
};

export type TrainingDetail = {
  classType?: Maybe<Scalars['String']>;
  companyGroup?: Maybe<Scalars['String']>;
  completedDate?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  createDate?: Maybe<Scalars['String']>;
  meetingName?: Maybe<Scalars['String']>;
  pdh?: Maybe<Scalars['String']>;
  productID?: Maybe<Scalars['String']>;
  programName?: Maybe<Scalars['String']>;
  programNumber?: Maybe<Scalars['String']>;
  projectID?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  projectSetID?: Maybe<Scalars['String']>;
  projectSetName?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['String']>;
  researchArea?: Maybe<Scalars['String']>;
  sectorLTN?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
  shortSectorName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusChangeDate?: Maybe<Scalars['String']>;
  transcriptScore?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  userFirstName?: Maybe<Scalars['String']>;
  userFullName?: Maybe<Scalars['String']>;
  userLastName?: Maybe<Scalars['String']>;
};

export type TrainingDetailResponse = {
  details?: Maybe<Array<Maybe<TrainingDetail>>>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export type TrainingLanguages = {
  id?: Maybe<Scalars['String']>;
  lmsEventId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type TrainingSubjects = {
  id?: Maybe<Scalars['Int']>;
  lmsEventId?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  parentTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type TrainingsSummary = {
  countCBT?: Maybe<Scalars['Int']>;
  countClassroom?: Maybe<Scalars['Int']>;
  countDistanceLearning?: Maybe<Scalars['Int']>;
  countOther?: Maybe<Scalars['Int']>;
  currentYearRegistrants?: Maybe<Scalars['Int']>;
  previousYearRegistrants?: Maybe<Scalars['Int']>;
  sectorLTN?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
  shortSectorName?: Maybe<Scalars['String']>;
  totalCompleted?: Maybe<Scalars['Int']>;
  totalEvents?: Maybe<Scalars['Int']>;
  totalPDH?: Maybe<Scalars['Float']>;
  totalRegistrants?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** Includes: Left column body, right column image, right column body below image. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/twoColumnTemplate) */
export type TwoColumnTemplate = Entry & {
  componentLevel?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  image?: Maybe<Asset>;
  leftColumnBody?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<TwoColumnTemplateLinkingCollections>;
  permalink?: Maybe<Scalars['String']>;
  redirect?: Maybe<Scalars['String']>;
  rightColumnBody?: Maybe<Scalars['String']>;
  sys: Sys;
  tabsCollection?: Maybe<TwoColumnTemplateTabsCollection>;
  title?: Maybe<Scalars['String']>;
};


/** Includes: Left column body, right column image, right column body below image. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/twoColumnTemplate) */
export type TwoColumnTemplateComponentLevelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Includes: Left column body, right column image, right column body below image. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/twoColumnTemplate) */
export type TwoColumnTemplateImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Includes: Left column body, right column image, right column body below image. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/twoColumnTemplate) */
export type TwoColumnTemplateLeftColumnBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Includes: Left column body, right column image, right column body below image. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/twoColumnTemplate) */
export type TwoColumnTemplateLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Includes: Left column body, right column image, right column body below image. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/twoColumnTemplate) */
export type TwoColumnTemplatePermalinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Includes: Left column body, right column image, right column body below image. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/twoColumnTemplate) */
export type TwoColumnTemplateRedirectArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Includes: Left column body, right column image, right column body below image. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/twoColumnTemplate) */
export type TwoColumnTemplateRightColumnBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Includes: Left column body, right column image, right column body below image. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/twoColumnTemplate) */
export type TwoColumnTemplateTabsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Includes: Left column body, right column image, right column body below image. [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/twoColumnTemplate) */
export type TwoColumnTemplateTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type TwoColumnTemplateCollection = {
  items: Array<Maybe<TwoColumnTemplate>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TwoColumnTemplateFilter = {
  AND?: InputMaybe<Array<InputMaybe<TwoColumnTemplateFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TwoColumnTemplateFilter>>>;
  componentLevel?: InputMaybe<Scalars['String']>;
  componentLevel_contains?: InputMaybe<Scalars['String']>;
  componentLevel_exists?: InputMaybe<Scalars['Boolean']>;
  componentLevel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  componentLevel_not?: InputMaybe<Scalars['String']>;
  componentLevel_not_contains?: InputMaybe<Scalars['String']>;
  componentLevel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  leftColumnBody?: InputMaybe<Scalars['String']>;
  leftColumnBody_contains?: InputMaybe<Scalars['String']>;
  leftColumnBody_exists?: InputMaybe<Scalars['Boolean']>;
  leftColumnBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  leftColumnBody_not?: InputMaybe<Scalars['String']>;
  leftColumnBody_not_contains?: InputMaybe<Scalars['String']>;
  leftColumnBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  permalink?: InputMaybe<Scalars['String']>;
  permalink_contains?: InputMaybe<Scalars['String']>;
  permalink_exists?: InputMaybe<Scalars['Boolean']>;
  permalink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  permalink_not?: InputMaybe<Scalars['String']>;
  permalink_not_contains?: InputMaybe<Scalars['String']>;
  permalink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redirect?: InputMaybe<Scalars['String']>;
  redirect_contains?: InputMaybe<Scalars['String']>;
  redirect_exists?: InputMaybe<Scalars['Boolean']>;
  redirect_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redirect_not?: InputMaybe<Scalars['String']>;
  redirect_not_contains?: InputMaybe<Scalars['String']>;
  redirect_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rightColumnBody?: InputMaybe<Scalars['String']>;
  rightColumnBody_contains?: InputMaybe<Scalars['String']>;
  rightColumnBody_exists?: InputMaybe<Scalars['Boolean']>;
  rightColumnBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rightColumnBody_not?: InputMaybe<Scalars['String']>;
  rightColumnBody_not_contains?: InputMaybe<Scalars['String']>;
  rightColumnBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  tabsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TwoColumnTemplateLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
  pagePreConfiguredTemplatesCollection?: Maybe<PagePreConfiguredTemplatesCollection>;
};


export type TwoColumnTemplateLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type TwoColumnTemplateLinkingCollectionsPagePreConfiguredTemplatesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum TwoColumnTemplateOrder {
  ComponentLevelAsc = 'componentLevel_ASC',
  ComponentLevelDesc = 'componentLevel_DESC',
  PermalinkAsc = 'permalink_ASC',
  PermalinkDesc = 'permalink_DESC',
  RedirectAsc = 'redirect_ASC',
  RedirectDesc = 'redirect_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type TwoColumnTemplateTabsCollection = {
  items: Array<Maybe<Tabs>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Update = {
  changeType?: Maybe<Scalars['String']>;
  downloadable?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['String']>;
  itemURL?: Maybe<Scalars['String']>;
  modifiedBy?: Maybe<Scalars['String']>;
  modifiedDateTime?: Maybe<Scalars['String']>;
  parentLTN?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productStatus?: Maybe<Scalars['String']>;
  productToolTip?: Maybe<Scalars['String']>;
  siteLTN?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  siteNumber?: Maybe<Scalars['String']>;
  siteOwner?: Maybe<Scalars['String']>;
  siteType?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type UserAbstractType = {
  abstractFileName?: Maybe<Scalars['String']>;
  abstractPublishedDate?: Maybe<Scalars['String']>;
  abstractStatus?: Maybe<Scalars['String']>;
  accessLogStatus?: Maybe<Scalars['String']>;
  availabilityCode?: Maybe<Scalars['String']>;
  committeeId?: Maybe<Scalars['String']>;
  documentType?: Maybe<Scalars['String']>;
  downloadCount?: Maybe<Scalars['Int']>;
  expectedDate?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
  fundingType?: Maybe<Scalars['String']>;
  hasAbstract?: Maybe<Scalars['Boolean']>;
  hasAccess?: Maybe<Scalars['Boolean']>;
  hasSynopsis?: Maybe<Scalars['Boolean']>;
  implementationCategory?: Maybe<Scalars['String']>;
  implementationNotes?: Maybe<Scalars['String']>;
  implementationType?: Maybe<Scalars['String']>;
  implementationTypeNotes?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isBookmarked?: Maybe<Scalars['Boolean']>;
  isDownloadable?: Maybe<Scalars['Boolean']>;
  isFavorite?: Maybe<Scalars['Boolean']>;
  isOrderable?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  keywordsList?: Maybe<Array<Maybe<KeywordType>>>;
  licenseAgreementEnd?: Maybe<Scalars['String']>;
  licenseAgreementPartA?: Maybe<Scalars['String']>;
  licenseAgreementPartB?: Maybe<Scalars['String']>;
  licenseAgreementPartC?: Maybe<Scalars['String']>;
  licenseType?: Maybe<Scalars['String']>;
  longAbstract?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  newVersionList?: Maybe<Array<Maybe<ProductBaseType>>>;
  orderURL?: Maybe<Scalars['String']>;
  pageCount?: Maybe<Scalars['String']>;
  priceDisplay?: Maybe<Scalars['String']>;
  pricingMessage?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  programLTN?: Maybe<Scalars['String']>;
  programName?: Maybe<Scalars['String']>;
  programNumber?: Maybe<Scalars['String']>;
  programYear?: Maybe<Scalars['String']>;
  projectLTN?: Maybe<Scalars['String']>;
  projectManager?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  publishedDate?: Maybe<Scalars['String']>;
  purchaseOption?: Maybe<Scalars['String']>;
  rawPrice?: Maybe<Scalars['String']>;
  recommendedProductList?: Maybe<Array<Maybe<RecommendedProductType>>>;
  relatedMaterialsList?: Maybe<Array<Maybe<ProductBaseType>>>;
  relatedVolumeList?: Maybe<Array<Maybe<ProductBaseType>>>;
  requestedBy?: Maybe<Scalars['String']>;
  researchArea?: Maybe<Scalars['String']>;
  sectorLTN?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
  shortAbstract?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  shortProductId?: Maybe<Scalars['String']>;
  showClickThruAgreement?: Maybe<Scalars['Boolean']>;
  showDownload?: Maybe<Scalars['Boolean']>;
  showLogin?: Maybe<Scalars['Boolean']>;
  showNQAMsg?: Maybe<Scalars['Boolean']>;
  showOrderButton?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  statusComment?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  supersededBy?: Maybe<Scalars['String']>;
  supersedes?: Maybe<Scalars['String']>;
  synopsisHTML?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  toolTip?: Maybe<Scalars['String']>;
  typeCategory?: Maybe<Scalars['String']>;
  userPrice?: Maybe<Scalars['String']>;
};

export type UserDownloadDetail = {
  contactName?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type UserEvent = {
  accommodation?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  agenda?: Maybe<Scalars['String']>;
  associations?: Maybe<Array<Maybe<Association>>>;
  attachments?: Maybe<Scalars['String']>;
  categories?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  coordinatorPhone?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  cventId?: Maybe<Scalars['String']>;
  cventInviteeStatus?: Maybe<Scalars['String']>;
  cventRSVPByDate?: Maybe<Scalars['String']>;
  cventRegistrationStatus?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  endDateTime?: Maybe<Scalars['String']>;
  eventImage?: Maybe<Scalars['String']>;
  eventStatus?: Maybe<Scalars['String']>;
  eventType?: Maybe<Scalars['String']>;
  eventUniqueId: Scalars['String'];
  eventregUrl?: Maybe<Scalars['String']>;
  favorite?: Maybe<Scalars['Boolean']>;
  fees?: Maybe<Array<Maybe<Fee>>>;
  goalsObjectives?: Maybe<Scalars['String']>;
  hasAttachments?: Maybe<Scalars['Boolean']>;
  imageName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imgLocation?: Maybe<Scalars['String']>;
  isBookmarked?: Maybe<Scalars['Boolean']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  lmsProvider?: Maybe<Scalars['String']>;
  lmsTrainingType?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  logistics?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  media?: Maybe<Array<Maybe<EventMedia>>>;
  onCalendar?: Maybe<Scalars['Boolean']>;
  plannerEmail?: Maybe<Scalars['String']>;
  plannerName?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  preferredUrl?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productSector?: Maybe<Scalars['String']>;
  programLTN?: Maybe<Scalars['String']>;
  programName?: Maybe<Scalars['String']>;
  sector?: Maybe<Scalars['String']>;
  sectorLTN?: Maybe<Scalars['String']>;
  showRegistrationClosed?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  speImage?: Maybe<Scalars['String']>;
  staffDetails?: Maybe<Array<Maybe<StaffDetail>>>;
  startDateTime?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  trainingType?: Maybe<Scalars['String']>;
  unixEndDateTime?: Maybe<Scalars['String']>;
  unixstartDateTime?: Maybe<Scalars['String']>;
  whoShouldAttend?: Maybe<Scalars['String']>;
};

export type Vendor = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
};

export type VendorModelWelds = {
  vendorName?: Maybe<Scalars['String']>;
  welds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Includes: Left column text, right column video(s). [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/videoTemplate) */
export type VideoTemplate = Entry & {
  body?: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  image?: Maybe<Asset>;
  linkedFrom?: Maybe<VideoTemplateLinkingCollections>;
  permalink?: Maybe<Scalars['String']>;
  redirect?: Maybe<Scalars['String']>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  videoOne?: Maybe<Scalars['String']>;
  videoTwo?: Maybe<Scalars['String']>;
};


/** Includes: Left column text, right column video(s). [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/videoTemplate) */
export type VideoTemplateBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Includes: Left column text, right column video(s). [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/videoTemplate) */
export type VideoTemplateImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Includes: Left column text, right column video(s). [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/videoTemplate) */
export type VideoTemplateLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Includes: Left column text, right column video(s). [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/videoTemplate) */
export type VideoTemplatePermalinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Includes: Left column text, right column video(s). [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/videoTemplate) */
export type VideoTemplateRedirectArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Includes: Left column text, right column video(s). [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/videoTemplate) */
export type VideoTemplateTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Includes: Left column text, right column video(s). [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/videoTemplate) */
export type VideoTemplateVideoOneArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Includes: Left column text, right column video(s). [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/videoTemplate) */
export type VideoTemplateVideoTwoArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type VideoTemplateCollection = {
  items: Array<Maybe<VideoTemplate>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type VideoTemplateFilter = {
  AND?: InputMaybe<Array<InputMaybe<VideoTemplateFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<VideoTemplateFilter>>>;
  body?: InputMaybe<Scalars['String']>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  permalink?: InputMaybe<Scalars['String']>;
  permalink_contains?: InputMaybe<Scalars['String']>;
  permalink_exists?: InputMaybe<Scalars['Boolean']>;
  permalink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  permalink_not?: InputMaybe<Scalars['String']>;
  permalink_not_contains?: InputMaybe<Scalars['String']>;
  permalink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redirect?: InputMaybe<Scalars['String']>;
  redirect_contains?: InputMaybe<Scalars['String']>;
  redirect_exists?: InputMaybe<Scalars['Boolean']>;
  redirect_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redirect_not?: InputMaybe<Scalars['String']>;
  redirect_not_contains?: InputMaybe<Scalars['String']>;
  redirect_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoOne?: InputMaybe<Scalars['String']>;
  videoOne_contains?: InputMaybe<Scalars['String']>;
  videoOne_exists?: InputMaybe<Scalars['Boolean']>;
  videoOne_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoOne_not?: InputMaybe<Scalars['String']>;
  videoOne_not_contains?: InputMaybe<Scalars['String']>;
  videoOne_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoTwo?: InputMaybe<Scalars['String']>;
  videoTwo_contains?: InputMaybe<Scalars['String']>;
  videoTwo_exists?: InputMaybe<Scalars['Boolean']>;
  videoTwo_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoTwo_not?: InputMaybe<Scalars['String']>;
  videoTwo_not_contains?: InputMaybe<Scalars['String']>;
  videoTwo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type VideoTemplateLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type VideoTemplateLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum VideoTemplateOrder {
  PermalinkAsc = 'permalink_ASC',
  PermalinkDesc = 'permalink_DESC',
  RedirectAsc = 'redirect_ASC',
  RedirectDesc = 'redirect_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  VideoOneAsc = 'videoOne_ASC',
  VideoOneDesc = 'videoOne_DESC',
  VideoTwoAsc = 'videoTwo_ASC',
  VideoTwoDesc = 'videoTwo_DESC'
}

export type WhoOrderedWhatDetail = {
  abstractLink?: Maybe<Scalars['String']>;
  companyGroup?: Maybe<Scalars['Int']>;
  companyGroupDesc?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  companyName?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  implementationCategory?: Maybe<Scalars['String']>;
  implementationCategoryNotes?: Maybe<Scalars['String']>;
  implementationType?: Maybe<Scalars['String']>;
  implementationTypeNotes?: Maybe<Scalars['String']>;
  orderDate?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderSource?: Maybe<Scalars['String']>;
  orderYear?: Maybe<Scalars['Int']>;
  pmv?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  programLTN?: Maybe<Scalars['String']>;
  programName?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  researchArea?: Maybe<Scalars['String']>;
  sectorLTN?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
  sectorShortName?: Maybe<Scalars['String']>;
};

export type WhoOrderedWhatResponse = {
  data?: Maybe<Array<Maybe<WhoOrderedWhatDetail>>>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export type WhoOrderedWhatSummary = {
  productsOrdered?: Maybe<Scalars['Int']>;
  quantityOrdered?: Maybe<Scalars['Int']>;
  sectorLTN?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
  sectorShortName?: Maybe<Scalars['String']>;
};

/** XLPR [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/xLprLandingPage) */
export type XLprLandingPage = Entry & {
  announcementsCollection?: Maybe<XLprLandingPageAnnouncementsCollection>;
  contentfulMetadata: ContentfulMetadata;
  faQs?: Maybe<XLprLandingPageFaQs>;
  headerLine?: Maybe<Scalars['String']>;
  informationCardsCollection?: Maybe<XLprLandingPageInformationCardsCollection>;
  issueRequestAttachmentsCollection?: Maybe<XLprLandingPageIssueRequestAttachmentsCollection>;
  issueRequests?: Maybe<XLprLandingPageIssueRequests>;
  jumbotronImage?: Maybe<Asset>;
  linkedFrom?: Maybe<XLprLandingPageLinkingCollections>;
  linksCollection?: Maybe<XLprLandingPageLinksCollection>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
  viewMoreButtonLink?: Maybe<Scalars['String']>;
  viewMoreButtonText?: Maybe<Scalars['String']>;
  xLprLandingPageJumbotronText?: Maybe<Scalars['String']>;
};


/** XLPR [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/xLprLandingPage) */
export type XLprLandingPageAnnouncementsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** XLPR [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/xLprLandingPage) */
export type XLprLandingPageFaQsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** XLPR [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/xLprLandingPage) */
export type XLprLandingPageHeaderLineArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** XLPR [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/xLprLandingPage) */
export type XLprLandingPageInformationCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** XLPR [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/xLprLandingPage) */
export type XLprLandingPageIssueRequestAttachmentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** XLPR [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/xLprLandingPage) */
export type XLprLandingPageIssueRequestsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** XLPR [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/xLprLandingPage) */
export type XLprLandingPageJumbotronImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** XLPR [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/xLprLandingPage) */
export type XLprLandingPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** XLPR [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/xLprLandingPage) */
export type XLprLandingPageLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** XLPR [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/xLprLandingPage) */
export type XLprLandingPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** XLPR [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/xLprLandingPage) */
export type XLprLandingPageViewMoreButtonLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** XLPR [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/xLprLandingPage) */
export type XLprLandingPageViewMoreButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** XLPR [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/xLprLandingPage) */
export type XLprLandingPageXLprLandingPageJumbotronTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type XLprLandingPageAnnouncementsCollection = {
  items: Array<Maybe<Announcement>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type XLprLandingPageCollection = {
  items: Array<Maybe<XLprLandingPage>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type XLprLandingPageFaQs = {
  json: Scalars['JSON'];
  links: XLprLandingPageFaQsLinks;
};

export type XLprLandingPageFaQsAssets = {
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type XLprLandingPageFaQsEntries = {
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type XLprLandingPageFaQsLinks = {
  assets: XLprLandingPageFaQsAssets;
  entries: XLprLandingPageFaQsEntries;
};

export type XLprLandingPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<XLprLandingPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<XLprLandingPageFilter>>>;
  announcementsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  faQs_contains?: InputMaybe<Scalars['String']>;
  faQs_exists?: InputMaybe<Scalars['Boolean']>;
  faQs_not_contains?: InputMaybe<Scalars['String']>;
  headerLine?: InputMaybe<Scalars['String']>;
  headerLine_contains?: InputMaybe<Scalars['String']>;
  headerLine_exists?: InputMaybe<Scalars['Boolean']>;
  headerLine_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  headerLine_not?: InputMaybe<Scalars['String']>;
  headerLine_not_contains?: InputMaybe<Scalars['String']>;
  headerLine_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  informationCardsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  issueRequestAttachmentsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  issueRequests_contains?: InputMaybe<Scalars['String']>;
  issueRequests_exists?: InputMaybe<Scalars['Boolean']>;
  issueRequests_not_contains?: InputMaybe<Scalars['String']>;
  jumbotronImage_exists?: InputMaybe<Scalars['Boolean']>;
  linksCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  viewMoreButtonLink?: InputMaybe<Scalars['String']>;
  viewMoreButtonLink_contains?: InputMaybe<Scalars['String']>;
  viewMoreButtonLink_exists?: InputMaybe<Scalars['Boolean']>;
  viewMoreButtonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  viewMoreButtonLink_not?: InputMaybe<Scalars['String']>;
  viewMoreButtonLink_not_contains?: InputMaybe<Scalars['String']>;
  viewMoreButtonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  viewMoreButtonText?: InputMaybe<Scalars['String']>;
  viewMoreButtonText_contains?: InputMaybe<Scalars['String']>;
  viewMoreButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  viewMoreButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  viewMoreButtonText_not?: InputMaybe<Scalars['String']>;
  viewMoreButtonText_not_contains?: InputMaybe<Scalars['String']>;
  viewMoreButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  xLprLandingPageJumbotronText?: InputMaybe<Scalars['String']>;
  xLprLandingPageJumbotronText_contains?: InputMaybe<Scalars['String']>;
  xLprLandingPageJumbotronText_exists?: InputMaybe<Scalars['Boolean']>;
  xLprLandingPageJumbotronText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  xLprLandingPageJumbotronText_not?: InputMaybe<Scalars['String']>;
  xLprLandingPageJumbotronText_not_contains?: InputMaybe<Scalars['String']>;
  xLprLandingPageJumbotronText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type XLprLandingPageInformationCardsCollection = {
  items: Array<Maybe<StoryCards>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type XLprLandingPageIssueRequestAttachmentsCollection = {
  items: Array<Maybe<XLprLandingPageIssueRequestAttachmentsItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type XLprLandingPageIssueRequestAttachmentsItem = PublicAttachment | SecuredAttachment;

export type XLprLandingPageIssueRequests = {
  json: Scalars['JSON'];
  links: XLprLandingPageIssueRequestsLinks;
};

export type XLprLandingPageIssueRequestsAssets = {
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type XLprLandingPageIssueRequestsEntries = {
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type XLprLandingPageIssueRequestsLinks = {
  assets: XLprLandingPageIssueRequestsAssets;
  entries: XLprLandingPageIssueRequestsEntries;
};

export type XLprLandingPageLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type XLprLandingPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type XLprLandingPageLinksCollection = {
  items: Array<Maybe<Link>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum XLprLandingPageOrder {
  HeaderLineAsc = 'headerLine_ASC',
  HeaderLineDesc = 'headerLine_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ViewMoreButtonLinkAsc = 'viewMoreButtonLink_ASC',
  ViewMoreButtonLinkDesc = 'viewMoreButtonLink_DESC',
  ViewMoreButtonTextAsc = 'viewMoreButtonText_ASC',
  ViewMoreButtonTextDesc = 'viewMoreButtonText_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/xlprLandingPage) */
export type XlprLandingPage = Entry & {
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<XlprLandingPageLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/xlprLandingPage) */
export type XlprLandingPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/ucu418cgcnau/content_types/xlprLandingPage) */
export type XlprLandingPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type XlprLandingPageCollection = {
  items: Array<Maybe<XlprLandingPage>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type XlprLandingPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<XlprLandingPageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<XlprLandingPageFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type XlprLandingPageLinkingCollections = {
  entryCollection?: Maybe<EntryCollection>;
};


export type XlprLandingPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum XlprLandingPageOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type YearlyFundingDetail = {
  companyGroup?: Maybe<Scalars['String']>;
  executedARP?: Maybe<Scalars['Float']>;
  executedBSA?: Maybe<Scalars['Float']>;
  executedCF?: Maybe<Scalars['Float']>;
  executedTCMATCH?: Maybe<Scalars['Float']>;
  executedTOTAL?: Maybe<Scalars['Float']>;
  fundingLevel?: Maybe<Scalars['String']>;
  fundingYear?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['String']>;
  productLongDescription?: Maybe<Scalars['String']>;
  programLTN?: Maybe<Scalars['String']>;
  programName?: Maybe<Scalars['String']>;
  programNumber?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  projectLTN?: Maybe<Scalars['String']>;
  researchArea?: Maybe<Scalars['String']>;
  researchAreaLTN?: Maybe<Scalars['String']>;
  researchAreaName?: Maybe<Scalars['String']>;
  sectorLTN?: Maybe<Scalars['String']>;
  sectorName?: Maybe<Scalars['String']>;
  shortSectorName?: Maybe<Scalars['String']>;
};

export type YearlyFundingDetailResponse = {
  details?: Maybe<Array<Maybe<YearlyFundingDetail>>>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export type YearlyFundingSummary = {
  companyGroup?: Maybe<Scalars['String']>;
  executedARP?: Maybe<Scalars['Float']>;
  executedBSA?: Maybe<Scalars['Float']>;
  executedCF?: Maybe<Scalars['Float']>;
  executedTCMATCH?: Maybe<Scalars['Float']>;
  executedTOTAL?: Maybe<Scalars['Float']>;
  fundingYear?: Maybe<Scalars['Int']>;
};

export type YearlyFundingSummaryResponse = {
  noOfPages?: Maybe<Scalars['Int']>;
  summary?: Maybe<Array<Maybe<YearlyFundingSummary>>>;
  totalRecords?: Maybe<Scalars['Int']>;
};

export type CfAiComponentNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfAiComponentNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfAiComponentNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  entryTitle?: InputMaybe<Scalars['String']>;
  entryTitle_contains?: InputMaybe<Scalars['String']>;
  entryTitle_exists?: InputMaybe<Scalars['Boolean']>;
  entryTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  entryTitle_not?: InputMaybe<Scalars['String']>;
  entryTitle_not_contains?: InputMaybe<Scalars['String']>;
  entryTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  entryType?: InputMaybe<Scalars['String']>;
  entryType_contains?: InputMaybe<Scalars['String']>;
  entryType_exists?: InputMaybe<Scalars['Boolean']>;
  entryType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  entryType_not?: InputMaybe<Scalars['String']>;
  entryType_not_contains?: InputMaybe<Scalars['String']>;
  entryType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  industryNewsDatePublished?: InputMaybe<Scalars['DateTime']>;
  industryNewsDatePublished_exists?: InputMaybe<Scalars['Boolean']>;
  industryNewsDatePublished_gt?: InputMaybe<Scalars['DateTime']>;
  industryNewsDatePublished_gte?: InputMaybe<Scalars['DateTime']>;
  industryNewsDatePublished_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  industryNewsDatePublished_lt?: InputMaybe<Scalars['DateTime']>;
  industryNewsDatePublished_lte?: InputMaybe<Scalars['DateTime']>;
  industryNewsDatePublished_not?: InputMaybe<Scalars['DateTime']>;
  industryNewsDatePublished_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  industryNewsSourceSite?: InputMaybe<Scalars['String']>;
  industryNewsSourceSite_contains?: InputMaybe<Scalars['String']>;
  industryNewsSourceSite_exists?: InputMaybe<Scalars['Boolean']>;
  industryNewsSourceSite_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  industryNewsSourceSite_not?: InputMaybe<Scalars['String']>;
  industryNewsSourceSite_not_contains?: InputMaybe<Scalars['String']>;
  industryNewsSourceSite_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  industryNewsTitle?: InputMaybe<Scalars['String']>;
  industryNewsTitle_contains?: InputMaybe<Scalars['String']>;
  industryNewsTitle_exists?: InputMaybe<Scalars['Boolean']>;
  industryNewsTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  industryNewsTitle_not?: InputMaybe<Scalars['String']>;
  industryNewsTitle_not_contains?: InputMaybe<Scalars['String']>;
  industryNewsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  industryNewsUrl?: InputMaybe<Scalars['String']>;
  industryNewsUrl_contains?: InputMaybe<Scalars['String']>;
  industryNewsUrl_exists?: InputMaybe<Scalars['Boolean']>;
  industryNewsUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  industryNewsUrl_not?: InputMaybe<Scalars['String']>;
  industryNewsUrl_not_contains?: InputMaybe<Scalars['String']>;
  industryNewsUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  simpleCardBody?: InputMaybe<Scalars['String']>;
  simpleCardBody_contains?: InputMaybe<Scalars['String']>;
  simpleCardBody_exists?: InputMaybe<Scalars['Boolean']>;
  simpleCardBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  simpleCardBody_not?: InputMaybe<Scalars['String']>;
  simpleCardBody_not_contains?: InputMaybe<Scalars['String']>;
  simpleCardBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  simpleCardButtonText?: InputMaybe<Scalars['String']>;
  simpleCardButtonText_contains?: InputMaybe<Scalars['String']>;
  simpleCardButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  simpleCardButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  simpleCardButtonText_not?: InputMaybe<Scalars['String']>;
  simpleCardButtonText_not_contains?: InputMaybe<Scalars['String']>;
  simpleCardButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  simpleCardButtonUrl?: InputMaybe<Scalars['String']>;
  simpleCardButtonUrl_contains?: InputMaybe<Scalars['String']>;
  simpleCardButtonUrl_exists?: InputMaybe<Scalars['Boolean']>;
  simpleCardButtonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  simpleCardButtonUrl_not?: InputMaybe<Scalars['String']>;
  simpleCardButtonUrl_not_contains?: InputMaybe<Scalars['String']>;
  simpleCardButtonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  simpleCardImage_exists?: InputMaybe<Scalars['Boolean']>;
  simpleCardTitle?: InputMaybe<Scalars['String']>;
  simpleCardTitle_contains?: InputMaybe<Scalars['String']>;
  simpleCardTitle_exists?: InputMaybe<Scalars['Boolean']>;
  simpleCardTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  simpleCardTitle_not?: InputMaybe<Scalars['String']>;
  simpleCardTitle_not_contains?: InputMaybe<Scalars['String']>;
  simpleCardTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaContent?: InputMaybe<Scalars['String']>;
  socialMediaContent_contains?: InputMaybe<Scalars['String']>;
  socialMediaContent_exists?: InputMaybe<Scalars['Boolean']>;
  socialMediaContent_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaContent_not?: InputMaybe<Scalars['String']>;
  socialMediaContent_not_contains?: InputMaybe<Scalars['String']>;
  socialMediaContent_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaDateTime?: InputMaybe<Scalars['DateTime']>;
  socialMediaDateTime_exists?: InputMaybe<Scalars['Boolean']>;
  socialMediaDateTime_gt?: InputMaybe<Scalars['DateTime']>;
  socialMediaDateTime_gte?: InputMaybe<Scalars['DateTime']>;
  socialMediaDateTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  socialMediaDateTime_lt?: InputMaybe<Scalars['DateTime']>;
  socialMediaDateTime_lte?: InputMaybe<Scalars['DateTime']>;
  socialMediaDateTime_not?: InputMaybe<Scalars['DateTime']>;
  socialMediaDateTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  socialMediaIcon_exists?: InputMaybe<Scalars['Boolean']>;
  socialMediaSource?: InputMaybe<Scalars['String']>;
  socialMediaSource_contains?: InputMaybe<Scalars['String']>;
  socialMediaSource_exists?: InputMaybe<Scalars['Boolean']>;
  socialMediaSource_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaSource_not?: InputMaybe<Scalars['String']>;
  socialMediaSource_not_contains?: InputMaybe<Scalars['String']>;
  socialMediaSource_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaUrl?: InputMaybe<Scalars['String']>;
  socialMediaUrl_contains?: InputMaybe<Scalars['String']>;
  socialMediaUrl_exists?: InputMaybe<Scalars['Boolean']>;
  socialMediaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaUrl_not?: InputMaybe<Scalars['String']>;
  socialMediaUrl_not_contains?: InputMaybe<Scalars['String']>;
  socialMediaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  testimonialBody?: InputMaybe<Scalars['String']>;
  testimonialBody_contains?: InputMaybe<Scalars['String']>;
  testimonialBody_exists?: InputMaybe<Scalars['Boolean']>;
  testimonialBody_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  testimonialBody_not?: InputMaybe<Scalars['String']>;
  testimonialBody_not_contains?: InputMaybe<Scalars['String']>;
  testimonialBody_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  testimonialName?: InputMaybe<Scalars['String']>;
  testimonialName_contains?: InputMaybe<Scalars['String']>;
  testimonialName_exists?: InputMaybe<Scalars['Boolean']>;
  testimonialName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  testimonialName_not?: InputMaybe<Scalars['String']>;
  testimonialName_not_contains?: InputMaybe<Scalars['String']>;
  testimonialName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  testimonialTitle?: InputMaybe<Scalars['String']>;
  testimonialTitle_contains?: InputMaybe<Scalars['String']>;
  testimonialTitle_exists?: InputMaybe<Scalars['Boolean']>;
  testimonialTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  testimonialTitle_not?: InputMaybe<Scalars['String']>;
  testimonialTitle_not_contains?: InputMaybe<Scalars['String']>;
  testimonialTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfAnnouncementWrapperNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfAnnouncementWrapperNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfAnnouncementWrapperNestedFilter>>>;
  announcementCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfFeaturedReportNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFeaturedReportNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFeaturedReportNestedFilter>>>;
  abstractId?: InputMaybe<Scalars['String']>;
  abstractId_contains?: InputMaybe<Scalars['String']>;
  abstractId_exists?: InputMaybe<Scalars['Boolean']>;
  abstractId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  abstractId_not?: InputMaybe<Scalars['String']>;
  abstractId_not_contains?: InputMaybe<Scalars['String']>;
  abstractId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body?: InputMaybe<Scalars['String']>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink?: InputMaybe<Scalars['String']>;
  buttonLink_contains?: InputMaybe<Scalars['String']>;
  buttonLink_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink_not?: InputMaybe<Scalars['String']>;
  buttonLink_not_contains?: InputMaybe<Scalars['String']>;
  buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonText_contains?: InputMaybe<Scalars['String']>;
  buttonText_exists?: InputMaybe<Scalars['Boolean']>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText_not?: InputMaybe<Scalars['String']>;
  buttonText_not_contains?: InputMaybe<Scalars['String']>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfFeaturedResearcherSectionNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFeaturedResearcherSectionNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFeaturedResearcherSectionNestedFilter>>>;
  bio?: InputMaybe<Scalars['String']>;
  bio_contains?: InputMaybe<Scalars['String']>;
  bio_exists?: InputMaybe<Scalars['Boolean']>;
  bio_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bio_not?: InputMaybe<Scalars['String']>;
  bio_not_contains?: InputMaybe<Scalars['String']>;
  bio_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink?: InputMaybe<Scalars['String']>;
  buttonLinkBeta?: InputMaybe<Scalars['String']>;
  buttonLinkBeta_contains?: InputMaybe<Scalars['String']>;
  buttonLinkBeta_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLinkBeta_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLinkBeta_not?: InputMaybe<Scalars['String']>;
  buttonLinkBeta_not_contains?: InputMaybe<Scalars['String']>;
  buttonLinkBeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink_contains?: InputMaybe<Scalars['String']>;
  buttonLink_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink_not?: InputMaybe<Scalars['String']>;
  buttonLink_not_contains?: InputMaybe<Scalars['String']>;
  buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonText_contains?: InputMaybe<Scalars['String']>;
  buttonText_exists?: InputMaybe<Scalars['Boolean']>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText_not?: InputMaybe<Scalars['String']>;
  buttonText_not_contains?: InputMaybe<Scalars['String']>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  jobTitle_contains?: InputMaybe<Scalars['String']>;
  jobTitle_exists?: InputMaybe<Scalars['Boolean']>;
  jobTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jobTitle_not?: InputMaybe<Scalars['String']>;
  jobTitle_not_contains?: InputMaybe<Scalars['String']>;
  jobTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  researcherName?: InputMaybe<Scalars['String']>;
  researcherName_contains?: InputMaybe<Scalars['String']>;
  researcherName_exists?: InputMaybe<Scalars['Boolean']>;
  researcherName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  researcherName_not?: InputMaybe<Scalars['String']>;
  researcherName_not_contains?: InputMaybe<Scalars['String']>;
  researcherName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfFindCareerSectionNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFindCareerSectionNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfFindCareerSectionNestedFilter>>>;
  buttonLink?: InputMaybe<Scalars['String']>;
  buttonLink_contains?: InputMaybe<Scalars['String']>;
  buttonLink_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink_not?: InputMaybe<Scalars['String']>;
  buttonLink_not_contains?: InputMaybe<Scalars['String']>;
  buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonText_contains?: InputMaybe<Scalars['String']>;
  buttonText_exists?: InputMaybe<Scalars['Boolean']>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText_not?: InputMaybe<Scalars['String']>;
  buttonText_not_contains?: InputMaybe<Scalars['String']>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  slug?: InputMaybe<Scalars['String']>;
  slug_contains?: InputMaybe<Scalars['String']>;
  slug_exists?: InputMaybe<Scalars['Boolean']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not?: InputMaybe<Scalars['String']>;
  slug_not_contains?: InputMaybe<Scalars['String']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
};

export type CfJumbotronNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfJumbotronNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfJumbotronNestedFilter>>>;
  actionButtonsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  body?: InputMaybe<Scalars['String']>;
  bodyMarkdown?: InputMaybe<Scalars['String']>;
  bodyMarkdown_contains?: InputMaybe<Scalars['String']>;
  bodyMarkdown_exists?: InputMaybe<Scalars['Boolean']>;
  bodyMarkdown_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bodyMarkdown_not?: InputMaybe<Scalars['String']>;
  bodyMarkdown_not_contains?: InputMaybe<Scalars['String']>;
  bodyMarkdown_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink?: InputMaybe<Scalars['String']>;
  buttonLink_contains?: InputMaybe<Scalars['String']>;
  buttonLink_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink_not?: InputMaybe<Scalars['String']>;
  buttonLink_not_contains?: InputMaybe<Scalars['String']>;
  buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonText_contains?: InputMaybe<Scalars['String']>;
  buttonText_exists?: InputMaybe<Scalars['Boolean']>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText_not?: InputMaybe<Scalars['String']>;
  buttonText_not_contains?: InputMaybe<Scalars['String']>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  entryTitle?: InputMaybe<Scalars['String']>;
  entryTitle_contains?: InputMaybe<Scalars['String']>;
  entryTitle_exists?: InputMaybe<Scalars['Boolean']>;
  entryTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  entryTitle_not?: InputMaybe<Scalars['String']>;
  entryTitle_not_contains?: InputMaybe<Scalars['String']>;
  entryTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fifthButtonText?: InputMaybe<Scalars['String']>;
  fifthButtonText_contains?: InputMaybe<Scalars['String']>;
  fifthButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  fifthButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fifthButtonText_not?: InputMaybe<Scalars['String']>;
  fifthButtonText_not_contains?: InputMaybe<Scalars['String']>;
  fifthButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fifthButtonUrl?: InputMaybe<Scalars['String']>;
  fifthButtonUrl_contains?: InputMaybe<Scalars['String']>;
  fifthButtonUrl_exists?: InputMaybe<Scalars['Boolean']>;
  fifthButtonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fifthButtonUrl_not?: InputMaybe<Scalars['String']>;
  fifthButtonUrl_not_contains?: InputMaybe<Scalars['String']>;
  fifthButtonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fourthButtonText?: InputMaybe<Scalars['String']>;
  fourthButtonText_contains?: InputMaybe<Scalars['String']>;
  fourthButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  fourthButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fourthButtonText_not?: InputMaybe<Scalars['String']>;
  fourthButtonText_not_contains?: InputMaybe<Scalars['String']>;
  fourthButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fourthButtonUrl?: InputMaybe<Scalars['String']>;
  fourthButtonUrl_contains?: InputMaybe<Scalars['String']>;
  fourthButtonUrl_exists?: InputMaybe<Scalars['Boolean']>;
  fourthButtonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fourthButtonUrl_not?: InputMaybe<Scalars['String']>;
  fourthButtonUrl_not_contains?: InputMaybe<Scalars['String']>;
  fourthButtonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  preferredUi?: InputMaybe<Scalars['String']>;
  preferredUi_contains?: InputMaybe<Scalars['String']>;
  preferredUi_exists?: InputMaybe<Scalars['Boolean']>;
  preferredUi_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  preferredUi_not?: InputMaybe<Scalars['String']>;
  preferredUi_not_contains?: InputMaybe<Scalars['String']>;
  preferredUi_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  secondaryButtonLink?: InputMaybe<Scalars['String']>;
  secondaryButtonLink_contains?: InputMaybe<Scalars['String']>;
  secondaryButtonLink_exists?: InputMaybe<Scalars['Boolean']>;
  secondaryButtonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  secondaryButtonLink_not?: InputMaybe<Scalars['String']>;
  secondaryButtonLink_not_contains?: InputMaybe<Scalars['String']>;
  secondaryButtonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  secondaryButtonText?: InputMaybe<Scalars['String']>;
  secondaryButtonText_contains?: InputMaybe<Scalars['String']>;
  secondaryButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  secondaryButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  secondaryButtonText_not?: InputMaybe<Scalars['String']>;
  secondaryButtonText_not_contains?: InputMaybe<Scalars['String']>;
  secondaryButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sixthButtonText?: InputMaybe<Scalars['String']>;
  sixthButtonText_contains?: InputMaybe<Scalars['String']>;
  sixthButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  sixthButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sixthButtonText_not?: InputMaybe<Scalars['String']>;
  sixthButtonText_not_contains?: InputMaybe<Scalars['String']>;
  sixthButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sixthButtonUrl?: InputMaybe<Scalars['String']>;
  sixthButtonUrl_contains?: InputMaybe<Scalars['String']>;
  sixthButtonUrl_exists?: InputMaybe<Scalars['Boolean']>;
  sixthButtonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sixthButtonUrl_not?: InputMaybe<Scalars['String']>;
  sixthButtonUrl_not_contains?: InputMaybe<Scalars['String']>;
  sixthButtonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subtitle?: InputMaybe<Scalars['String']>;
  subtitle_contains?: InputMaybe<Scalars['String']>;
  subtitle_exists?: InputMaybe<Scalars['Boolean']>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subtitle_not?: InputMaybe<Scalars['String']>;
  subtitle_not_contains?: InputMaybe<Scalars['String']>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  thirdButtonText?: InputMaybe<Scalars['String']>;
  thirdButtonText_contains?: InputMaybe<Scalars['String']>;
  thirdButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  thirdButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  thirdButtonText_not?: InputMaybe<Scalars['String']>;
  thirdButtonText_not_contains?: InputMaybe<Scalars['String']>;
  thirdButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  thirdButtonUrl?: InputMaybe<Scalars['String']>;
  thirdButtonUrl_contains?: InputMaybe<Scalars['String']>;
  thirdButtonUrl_exists?: InputMaybe<Scalars['Boolean']>;
  thirdButtonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  thirdButtonUrl_not?: InputMaybe<Scalars['String']>;
  thirdButtonUrl_not_contains?: InputMaybe<Scalars['String']>;
  thirdButtonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoThumbnail_exists?: InputMaybe<Scalars['Boolean']>;
};

export type CfLinkGroupNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfLinkGroupNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfLinkGroupNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  groupName?: InputMaybe<Scalars['String']>;
  groupName_contains?: InputMaybe<Scalars['String']>;
  groupName_exists?: InputMaybe<Scalars['Boolean']>;
  groupName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  groupName_not?: InputMaybe<Scalars['String']>;
  groupName_not_contains?: InputMaybe<Scalars['String']>;
  groupName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  linksCollection_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
};

export type CfPortfolioSectionNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPortfolioSectionNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfPortfolioSectionNestedFilter>>>;
  body?: InputMaybe<Scalars['String']>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink?: InputMaybe<Scalars['String']>;
  buttonLinkBeta?: InputMaybe<Scalars['String']>;
  buttonLinkBetaExternal?: InputMaybe<Scalars['String']>;
  buttonLinkBetaExternal_contains?: InputMaybe<Scalars['String']>;
  buttonLinkBetaExternal_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLinkBetaExternal_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLinkBetaExternal_not?: InputMaybe<Scalars['String']>;
  buttonLinkBetaExternal_not_contains?: InputMaybe<Scalars['String']>;
  buttonLinkBetaExternal_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLinkBetaUseInternal?: InputMaybe<Scalars['Boolean']>;
  buttonLinkBetaUseInternal_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLinkBetaUseInternal_not?: InputMaybe<Scalars['Boolean']>;
  buttonLinkBeta_contains?: InputMaybe<Scalars['String']>;
  buttonLinkBeta_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLinkBeta_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLinkBeta_not?: InputMaybe<Scalars['String']>;
  buttonLinkBeta_not_contains?: InputMaybe<Scalars['String']>;
  buttonLinkBeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink_contains?: InputMaybe<Scalars['String']>;
  buttonLink_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink_not?: InputMaybe<Scalars['String']>;
  buttonLink_not_contains?: InputMaybe<Scalars['String']>;
  buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonText_contains?: InputMaybe<Scalars['String']>;
  buttonText_exists?: InputMaybe<Scalars['Boolean']>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText_not?: InputMaybe<Scalars['String']>;
  buttonText_not_contains?: InputMaybe<Scalars['String']>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfPressReleaseLandingNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPressReleaseLandingNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfPressReleaseLandingNestedFilter>>>;
  contentCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  relativePath?: InputMaybe<Scalars['String']>;
  relativePath_contains?: InputMaybe<Scalars['String']>;
  relativePath_exists?: InputMaybe<Scalars['Boolean']>;
  relativePath_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  relativePath_not?: InputMaybe<Scalars['String']>;
  relativePath_not_contains?: InputMaybe<Scalars['String']>;
  relativePath_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  viewMoreButtonText?: InputMaybe<Scalars['String']>;
  viewMoreButtonText_contains?: InputMaybe<Scalars['String']>;
  viewMoreButtonText_exists?: InputMaybe<Scalars['Boolean']>;
  viewMoreButtonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  viewMoreButtonText_not?: InputMaybe<Scalars['String']>;
  viewMoreButtonText_not_contains?: InputMaybe<Scalars['String']>;
  viewMoreButtonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfProfileCardNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfProfileCardNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfProfileCardNestedFilter>>>;
  areasOfExpertise?: InputMaybe<Scalars['String']>;
  areasOfExpertise_contains?: InputMaybe<Scalars['String']>;
  areasOfExpertise_exists?: InputMaybe<Scalars['Boolean']>;
  areasOfExpertise_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  areasOfExpertise_not?: InputMaybe<Scalars['String']>;
  areasOfExpertise_not_contains?: InputMaybe<Scalars['String']>;
  areasOfExpertise_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  email?: InputMaybe<Scalars['String']>;
  email_contains?: InputMaybe<Scalars['String']>;
  email_exists?: InputMaybe<Scalars['Boolean']>;
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  email_not?: InputMaybe<Scalars['String']>;
  email_not_contains?: InputMaybe<Scalars['String']>;
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  phone?: InputMaybe<Scalars['String']>;
  phone_contains?: InputMaybe<Scalars['String']>;
  phone_exists?: InputMaybe<Scalars['Boolean']>;
  phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  phone_not?: InputMaybe<Scalars['String']>;
  phone_not_contains?: InputMaybe<Scalars['String']>;
  phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfSeoNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSeoNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfSeoNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  no_follow?: InputMaybe<Scalars['Boolean']>;
  no_follow_exists?: InputMaybe<Scalars['Boolean']>;
  no_follow_not?: InputMaybe<Scalars['Boolean']>;
  no_index?: InputMaybe<Scalars['Boolean']>;
  no_index_exists?: InputMaybe<Scalars['Boolean']>;
  no_index_not?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfStoryCardsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfStoryCardsNestedFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CfStoryCardsNestedFilter>>>;
  body?: InputMaybe<Scalars['String']>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink?: InputMaybe<Scalars['String']>;
  buttonLink_contains?: InputMaybe<Scalars['String']>;
  buttonLink_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink_not?: InputMaybe<Scalars['String']>;
  buttonLink_not_contains?: InputMaybe<Scalars['String']>;
  buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonText_contains?: InputMaybe<Scalars['String']>;
  buttonText_exists?: InputMaybe<Scalars['Boolean']>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText_not?: InputMaybe<Scalars['String']>;
  buttonText_not_contains?: InputMaybe<Scalars['String']>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  publicOrMemberOnly?: InputMaybe<Scalars['String']>;
  publicOrMemberOnly_contains?: InputMaybe<Scalars['String']>;
  publicOrMemberOnly_exists?: InputMaybe<Scalars['Boolean']>;
  publicOrMemberOnly_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicOrMemberOnly_not?: InputMaybe<Scalars['String']>;
  publicOrMemberOnly_not_contains?: InputMaybe<Scalars['String']>;
  publicOrMemberOnly_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  video?: InputMaybe<Scalars['Boolean']>;
  video_exists?: InputMaybe<Scalars['Boolean']>;
  video_not?: InputMaybe<Scalars['Boolean']>;
};

/** Post for the Archive Section of the Epri Journal app [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/archivePost) */
export type Eprijournal_ArchivePost = Eprijournal_Entry & {
  contentfulMetadata: Eprijournal_ContentfulMetadata;
  createdDate?: Maybe<Scalars['eprijournal_DateTime']>;
  image?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<Eprijournal_ArchivePostLinkingCollections>;
  postPreview?: Maybe<Scalars['String']>;
  sys: Eprijournal_Sys;
  title?: Maybe<Scalars['String']>;
};


/** Post for the Archive Section of the Epri Journal app [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/archivePost) */
export type Eprijournal_ArchivePostCreatedDateArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Post for the Archive Section of the Epri Journal app [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/archivePost) */
export type Eprijournal_ArchivePostImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Post for the Archive Section of the Epri Journal app [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/archivePost) */
export type Eprijournal_ArchivePostLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Post for the Archive Section of the Epri Journal app [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/archivePost) */
export type Eprijournal_ArchivePostLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Post for the Archive Section of the Epri Journal app [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/archivePost) */
export type Eprijournal_ArchivePostPostPreviewArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Post for the Archive Section of the Epri Journal app [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/archivePost) */
export type Eprijournal_ArchivePostTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type Eprijournal_ArchivePostCollection = {
  items: Array<Maybe<Eprijournal_ArchivePost>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Eprijournal_ArchivePostFilter = {
  AND?: InputMaybe<Array<InputMaybe<Eprijournal_ArchivePostFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Eprijournal_ArchivePostFilter>>>;
  contentfulMetadata?: InputMaybe<Eprijournal_ContentfulMetadataFilter>;
  createdDate?: InputMaybe<Scalars['eprijournal_DateTime']>;
  createdDate_exists?: InputMaybe<Scalars['Boolean']>;
  createdDate_gt?: InputMaybe<Scalars['eprijournal_DateTime']>;
  createdDate_gte?: InputMaybe<Scalars['eprijournal_DateTime']>;
  createdDate_in?: InputMaybe<Array<InputMaybe<Scalars['eprijournal_DateTime']>>>;
  createdDate_lt?: InputMaybe<Scalars['eprijournal_DateTime']>;
  createdDate_lte?: InputMaybe<Scalars['eprijournal_DateTime']>;
  createdDate_not?: InputMaybe<Scalars['eprijournal_DateTime']>;
  createdDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['eprijournal_DateTime']>>>;
  image?: InputMaybe<Scalars['String']>;
  image_contains?: InputMaybe<Scalars['String']>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  image_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image_not?: InputMaybe<Scalars['String']>;
  image_not_contains?: InputMaybe<Scalars['String']>;
  image_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  link?: InputMaybe<Scalars['String']>;
  link_contains?: InputMaybe<Scalars['String']>;
  link_exists?: InputMaybe<Scalars['Boolean']>;
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  link_not?: InputMaybe<Scalars['String']>;
  link_not_contains?: InputMaybe<Scalars['String']>;
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  postPreview?: InputMaybe<Scalars['String']>;
  postPreview_contains?: InputMaybe<Scalars['String']>;
  postPreview_exists?: InputMaybe<Scalars['Boolean']>;
  postPreview_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  postPreview_not?: InputMaybe<Scalars['String']>;
  postPreview_not_contains?: InputMaybe<Scalars['String']>;
  postPreview_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<Eprijournal_SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Eprijournal_ArchivePostLinkingCollections = {
  entryCollection?: Maybe<Eprijournal_EntryCollection>;
};


export type Eprijournal_ArchivePostLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum Eprijournal_ArchivePostOrder {
  CreatedDateAsc = 'createdDate_ASC',
  CreatedDateDesc = 'createdDate_DESC',
  ImageAsc = 'image_ASC',
  ImageDesc = 'image_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  PostPreviewAsc = 'postPreview_ASC',
  PostPreviewDesc = 'postPreview_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Small data type containing an article title and topic name with URL link. [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/articleLinks) */
export type Eprijournal_ArticleLinks = Eprijournal_Entry & {
  contentfulMetadata: Eprijournal_ContentfulMetadata;
  link?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<Eprijournal_ArticleLinksLinkingCollections>;
  sys: Eprijournal_Sys;
  title?: Maybe<Scalars['String']>;
  topicName?: Maybe<Scalars['String']>;
};


/** Small data type containing an article title and topic name with URL link. [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/articleLinks) */
export type Eprijournal_ArticleLinksLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Small data type containing an article title and topic name with URL link. [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/articleLinks) */
export type Eprijournal_ArticleLinksLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Small data type containing an article title and topic name with URL link. [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/articleLinks) */
export type Eprijournal_ArticleLinksTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Small data type containing an article title and topic name with URL link. [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/articleLinks) */
export type Eprijournal_ArticleLinksTopicNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type Eprijournal_ArticleLinksCollection = {
  items: Array<Maybe<Eprijournal_ArticleLinks>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Eprijournal_ArticleLinksFilter = {
  AND?: InputMaybe<Array<InputMaybe<Eprijournal_ArticleLinksFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Eprijournal_ArticleLinksFilter>>>;
  contentfulMetadata?: InputMaybe<Eprijournal_ContentfulMetadataFilter>;
  link?: InputMaybe<Scalars['String']>;
  link_contains?: InputMaybe<Scalars['String']>;
  link_exists?: InputMaybe<Scalars['Boolean']>;
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  link_not?: InputMaybe<Scalars['String']>;
  link_not_contains?: InputMaybe<Scalars['String']>;
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<Eprijournal_SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  topicName?: InputMaybe<Scalars['String']>;
  topicName_contains?: InputMaybe<Scalars['String']>;
  topicName_exists?: InputMaybe<Scalars['Boolean']>;
  topicName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  topicName_not?: InputMaybe<Scalars['String']>;
  topicName_not_contains?: InputMaybe<Scalars['String']>;
  topicName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Eprijournal_ArticleLinksLinkingCollections = {
  entryCollection?: Maybe<Eprijournal_EntryCollection>;
};


export type Eprijournal_ArticleLinksLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum Eprijournal_ArticleLinksOrder {
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TopicNameAsc = 'topicName_ASC',
  TopicNameDesc = 'topicName_DESC'
}

/** Represents a binary file in a space. An asset can be any file type. */
export type Eprijournal_Asset = {
  contentType?: Maybe<Scalars['String']>;
  contentfulMetadata: Eprijournal_ContentfulMetadata;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  linkedFrom?: Maybe<Eprijournal_AssetLinkingCollections>;
  size?: Maybe<Scalars['Int']>;
  sys: Eprijournal_Sys;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type Eprijournal_AssetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type Eprijournal_AssetUrlArgs = {
  transform?: InputMaybe<Eprijournal_ImageTransformOptions>;
};

export type Eprijournal_AssetCollection = {
  items: Array<Maybe<Eprijournal_Asset>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Eprijournal_AssetFilter = {
  AND?: InputMaybe<Array<InputMaybe<Eprijournal_AssetFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Eprijournal_AssetFilter>>>;
  contentType?: InputMaybe<Scalars['String']>;
  contentType_contains?: InputMaybe<Scalars['String']>;
  contentType_exists?: InputMaybe<Scalars['Boolean']>;
  contentType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentType_not?: InputMaybe<Scalars['String']>;
  contentType_not_contains?: InputMaybe<Scalars['String']>;
  contentType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<Eprijournal_ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fileName?: InputMaybe<Scalars['String']>;
  fileName_contains?: InputMaybe<Scalars['String']>;
  fileName_exists?: InputMaybe<Scalars['Boolean']>;
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fileName_not?: InputMaybe<Scalars['String']>;
  fileName_not_contains?: InputMaybe<Scalars['String']>;
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  height?: InputMaybe<Scalars['Int']>;
  height_exists?: InputMaybe<Scalars['Boolean']>;
  height_gt?: InputMaybe<Scalars['Int']>;
  height_gte?: InputMaybe<Scalars['Int']>;
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  height_lt?: InputMaybe<Scalars['Int']>;
  height_lte?: InputMaybe<Scalars['Int']>;
  height_not?: InputMaybe<Scalars['Int']>;
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  size?: InputMaybe<Scalars['Int']>;
  size_exists?: InputMaybe<Scalars['Boolean']>;
  size_gt?: InputMaybe<Scalars['Int']>;
  size_gte?: InputMaybe<Scalars['Int']>;
  size_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  size_lt?: InputMaybe<Scalars['Int']>;
  size_lte?: InputMaybe<Scalars['Int']>;
  size_not?: InputMaybe<Scalars['Int']>;
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  sys?: InputMaybe<Eprijournal_SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  width?: InputMaybe<Scalars['Int']>;
  width_exists?: InputMaybe<Scalars['Boolean']>;
  width_gt?: InputMaybe<Scalars['Int']>;
  width_gte?: InputMaybe<Scalars['Int']>;
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  width_lt?: InputMaybe<Scalars['Int']>;
  width_lte?: InputMaybe<Scalars['Int']>;
  width_not?: InputMaybe<Scalars['Int']>;
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type Eprijournal_AssetLinkingCollections = {
  entryCollection?: Maybe<Eprijournal_EntryCollection>;
  jumbotronCollection?: Maybe<Eprijournal_JumbotronCollection>;
  pageCollection?: Maybe<Eprijournal_PageCollection>;
  peopleCollection?: Maybe<Eprijournal_PeopleCollection>;
  podcastPostCollection?: Maybe<Eprijournal_PodcastPostCollection>;
  reportPostCollection?: Maybe<Eprijournal_ReportPostCollection>;
  storyCardsCollection?: Maybe<Eprijournal_StoryCardsCollection>;
  subCategoryCollection?: Maybe<Eprijournal_SubCategoryCollection>;
};


export type Eprijournal_AssetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type Eprijournal_AssetLinkingCollectionsJumbotronCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type Eprijournal_AssetLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type Eprijournal_AssetLinkingCollectionsPeopleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type Eprijournal_AssetLinkingCollectionsPodcastPostCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type Eprijournal_AssetLinkingCollectionsReportPostCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type Eprijournal_AssetLinkingCollectionsStoryCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type Eprijournal_AssetLinkingCollectionsSubCategoryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum Eprijournal_AssetOrder {
  ContentTypeAsc = 'contentType_ASC',
  ContentTypeDesc = 'contentType_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC'
}

/**
 * Author of a post, or other media type [See type
 * definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/author)
 */
export type Eprijournal_Author = Eprijournal_Entry & {
  contentfulMetadata: Eprijournal_ContentfulMetadata;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<Eprijournal_AuthorLinkingCollections>;
  sys: Eprijournal_Sys;
};


/**
 * Author of a post, or other media type [See type
 * definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/author)
 */
export type Eprijournal_AuthorDisplayNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Author of a post, or other media type [See type
 * definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/author)
 */
export type Eprijournal_AuthorEmailArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Author of a post, or other media type [See type
 * definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/author)
 */
export type Eprijournal_AuthorFirstNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Author of a post, or other media type [See type
 * definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/author)
 */
export type Eprijournal_AuthorLastNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/**
 * Author of a post, or other media type [See type
 * definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/author)
 */
export type Eprijournal_AuthorLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Eprijournal_AuthorCollection = {
  items: Array<Maybe<Eprijournal_Author>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Eprijournal_AuthorFilter = {
  AND?: InputMaybe<Array<InputMaybe<Eprijournal_AuthorFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Eprijournal_AuthorFilter>>>;
  contentfulMetadata?: InputMaybe<Eprijournal_ContentfulMetadataFilter>;
  displayName?: InputMaybe<Scalars['String']>;
  displayName_contains?: InputMaybe<Scalars['String']>;
  displayName_exists?: InputMaybe<Scalars['Boolean']>;
  displayName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  displayName_not?: InputMaybe<Scalars['String']>;
  displayName_not_contains?: InputMaybe<Scalars['String']>;
  displayName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  email?: InputMaybe<Scalars['String']>;
  email_contains?: InputMaybe<Scalars['String']>;
  email_exists?: InputMaybe<Scalars['Boolean']>;
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  email_not?: InputMaybe<Scalars['String']>;
  email_not_contains?: InputMaybe<Scalars['String']>;
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  firstName?: InputMaybe<Scalars['String']>;
  firstName_contains?: InputMaybe<Scalars['String']>;
  firstName_exists?: InputMaybe<Scalars['Boolean']>;
  firstName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  firstName_not?: InputMaybe<Scalars['String']>;
  firstName_not_contains?: InputMaybe<Scalars['String']>;
  firstName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lastName?: InputMaybe<Scalars['String']>;
  lastName_contains?: InputMaybe<Scalars['String']>;
  lastName_exists?: InputMaybe<Scalars['Boolean']>;
  lastName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lastName_not?: InputMaybe<Scalars['String']>;
  lastName_not_contains?: InputMaybe<Scalars['String']>;
  lastName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<Eprijournal_SysFilter>;
};

export type Eprijournal_AuthorLinkingCollections = {
  entryCollection?: Maybe<Eprijournal_EntryCollection>;
};


export type Eprijournal_AuthorLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum Eprijournal_AuthorOrder {
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/category) */
export type Eprijournal_Category = Eprijournal_Entry & {
  contentfulMetadata: Eprijournal_ContentfulMetadata;
  linkedFrom?: Maybe<Eprijournal_CategoryLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  sys: Eprijournal_Sys;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/category) */
export type Eprijournal_CategoryLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/category) */
export type Eprijournal_CategoryNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/category) */
export type Eprijournal_CategorySlugArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type Eprijournal_CategoryCollection = {
  items: Array<Maybe<Eprijournal_Category>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Eprijournal_CategoryFilter = {
  AND?: InputMaybe<Array<InputMaybe<Eprijournal_CategoryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Eprijournal_CategoryFilter>>>;
  contentfulMetadata?: InputMaybe<Eprijournal_ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug?: InputMaybe<Scalars['String']>;
  slug_contains?: InputMaybe<Scalars['String']>;
  slug_exists?: InputMaybe<Scalars['Boolean']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not?: InputMaybe<Scalars['String']>;
  slug_not_contains?: InputMaybe<Scalars['String']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<Eprijournal_SysFilter>;
};

export type Eprijournal_CategoryLinkingCollections = {
  entryCollection?: Maybe<Eprijournal_EntryCollection>;
};


export type Eprijournal_CategoryLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum Eprijournal_CategoryOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Eprijournal_ContentfulMetadata = {
  tags: Array<Maybe<Eprijournal_ContentfulTag>>;
};

export type Eprijournal_ContentfulMetadataFilter = {
  tags?: InputMaybe<Eprijournal_ContentfulMetadataTagsFilter>;
  tags_exists?: InputMaybe<Scalars['Boolean']>;
};

export type Eprijournal_ContentfulMetadataTagsFilter = {
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/**
 * Represents a tag entity for finding and organizing content easily.
 *     Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type Eprijournal_ContentfulTag = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Eprijournal_Entry = {
  contentfulMetadata: Eprijournal_ContentfulMetadata;
  sys: Eprijournal_Sys;
};

export type Eprijournal_EntryCollection = {
  items: Array<Maybe<Eprijournal_Entry>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Eprijournal_EntryFilter = {
  AND?: InputMaybe<Array<InputMaybe<Eprijournal_EntryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Eprijournal_EntryFilter>>>;
  contentfulMetadata?: InputMaybe<Eprijournal_ContentfulMetadataFilter>;
  sys?: InputMaybe<Eprijournal_SysFilter>;
};

export enum Eprijournal_EntryOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum Eprijournal_ImageFormat {
  Avif = 'AVIF',
  /** JPG image format. */
  Jpg = 'JPG',
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JpgProgressive = 'JPG_PROGRESSIVE',
  /** PNG image format */
  Png = 'PNG',
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  Png8 = 'PNG8',
  /** WebP image format. */
  Webp = 'WEBP'
}

export enum Eprijournal_ImageResizeFocus {
  /** Focus the resizing on the bottom. */
  Bottom = 'BOTTOM',
  /** Focus the resizing on the bottom left. */
  BottomLeft = 'BOTTOM_LEFT',
  /** Focus the resizing on the bottom right. */
  BottomRight = 'BOTTOM_RIGHT',
  /** Focus the resizing on the center. */
  Center = 'CENTER',
  /** Focus the resizing on the largest face. */
  Face = 'FACE',
  /** Focus the resizing on the area containing all the faces. */
  Faces = 'FACES',
  /** Focus the resizing on the left. */
  Left = 'LEFT',
  /** Focus the resizing on the right. */
  Right = 'RIGHT',
  /** Focus the resizing on the top. */
  Top = 'TOP',
  /** Focus the resizing on the top left. */
  TopLeft = 'TOP_LEFT',
  /** Focus the resizing on the top right. */
  TopRight = 'TOP_RIGHT'
}

export enum Eprijournal_ImageResizeStrategy {
  /** Crops a part of the original image to fit into the specified dimensions. */
  Crop = 'CROP',
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  Fill = 'FILL',
  /** Resizes the image to fit into the specified dimensions. */
  Fit = 'FIT',
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  Pad = 'PAD',
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  Scale = 'SCALE',
  /** Creates a thumbnail from the image. */
  Thumb = 'THUMB'
}

export type Eprijournal_ImageTransformOptions = {
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor?: InputMaybe<Scalars['eprijournal_HexColor']>;
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius?: InputMaybe<Scalars['Int']>;
  /** Desired image format. Defaults to the original image format. */
  format?: InputMaybe<Eprijournal_ImageFormat>;
  /** Desired height in pixels. Defaults to the original image height. */
  height?: InputMaybe<Scalars['eprijournal_Dimension']>;
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality?: InputMaybe<Scalars['eprijournal_Quality']>;
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus?: InputMaybe<Eprijournal_ImageResizeFocus>;
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy?: InputMaybe<Eprijournal_ImageResizeStrategy>;
  /** Desired width in pixels. Defaults to the original image width. */
  width?: InputMaybe<Scalars['eprijournal_Dimension']>;
};

/** Component [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/jumbotron) */
export type Eprijournal_Jumbotron = Eprijournal_Entry & {
  body?: Maybe<Scalars['String']>;
  bodyMarkdown?: Maybe<Scalars['String']>;
  buttonLink?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  contentfulMetadata: Eprijournal_ContentfulMetadata;
  image?: Maybe<Eprijournal_Asset>;
  linkedFrom?: Maybe<Eprijournal_JumbotronLinkingCollections>;
  subtitle?: Maybe<Scalars['String']>;
  sys: Eprijournal_Sys;
  title?: Maybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/jumbotron) */
export type Eprijournal_JumbotronBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/jumbotron) */
export type Eprijournal_JumbotronBodyMarkdownArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/jumbotron) */
export type Eprijournal_JumbotronButtonLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/jumbotron) */
export type Eprijournal_JumbotronButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/jumbotron) */
export type Eprijournal_JumbotronImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/jumbotron) */
export type Eprijournal_JumbotronLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/jumbotron) */
export type Eprijournal_JumbotronSubtitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/jumbotron) */
export type Eprijournal_JumbotronTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type Eprijournal_JumbotronCollection = {
  items: Array<Maybe<Eprijournal_Jumbotron>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Eprijournal_JumbotronFilter = {
  AND?: InputMaybe<Array<InputMaybe<Eprijournal_JumbotronFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Eprijournal_JumbotronFilter>>>;
  body?: InputMaybe<Scalars['String']>;
  bodyMarkdown?: InputMaybe<Scalars['String']>;
  bodyMarkdown_contains?: InputMaybe<Scalars['String']>;
  bodyMarkdown_exists?: InputMaybe<Scalars['Boolean']>;
  bodyMarkdown_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bodyMarkdown_not?: InputMaybe<Scalars['String']>;
  bodyMarkdown_not_contains?: InputMaybe<Scalars['String']>;
  bodyMarkdown_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink?: InputMaybe<Scalars['String']>;
  buttonLink_contains?: InputMaybe<Scalars['String']>;
  buttonLink_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink_not?: InputMaybe<Scalars['String']>;
  buttonLink_not_contains?: InputMaybe<Scalars['String']>;
  buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonText_contains?: InputMaybe<Scalars['String']>;
  buttonText_exists?: InputMaybe<Scalars['Boolean']>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText_not?: InputMaybe<Scalars['String']>;
  buttonText_not_contains?: InputMaybe<Scalars['String']>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<Eprijournal_ContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  subtitle?: InputMaybe<Scalars['String']>;
  subtitle_contains?: InputMaybe<Scalars['String']>;
  subtitle_exists?: InputMaybe<Scalars['Boolean']>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subtitle_not?: InputMaybe<Scalars['String']>;
  subtitle_not_contains?: InputMaybe<Scalars['String']>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<Eprijournal_SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Eprijournal_JumbotronLinkingCollections = {
  entryCollection?: Maybe<Eprijournal_EntryCollection>;
  pageCollection?: Maybe<Eprijournal_PageCollection>;
};


export type Eprijournal_JumbotronLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type Eprijournal_JumbotronLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum Eprijournal_JumbotronOrder {
  ButtonLinkAsc = 'buttonLink_ASC',
  ButtonLinkDesc = 'buttonLink_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/mainCategory) */
export type Eprijournal_MainCategory = Eprijournal_Entry & {
  categoryName?: Maybe<Scalars['String']>;
  categorySlug?: Maybe<Scalars['String']>;
  contentfulMetadata: Eprijournal_ContentfulMetadata;
  linkedFrom?: Maybe<Eprijournal_MainCategoryLinkingCollections>;
  sys: Eprijournal_Sys;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/mainCategory) */
export type Eprijournal_MainCategoryCategoryNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/mainCategory) */
export type Eprijournal_MainCategoryCategorySlugArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/mainCategory) */
export type Eprijournal_MainCategoryLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Eprijournal_MainCategoryCollection = {
  items: Array<Maybe<Eprijournal_MainCategory>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Eprijournal_MainCategoryFilter = {
  AND?: InputMaybe<Array<InputMaybe<Eprijournal_MainCategoryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Eprijournal_MainCategoryFilter>>>;
  categoryName?: InputMaybe<Scalars['String']>;
  categoryName_contains?: InputMaybe<Scalars['String']>;
  categoryName_exists?: InputMaybe<Scalars['Boolean']>;
  categoryName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  categoryName_not?: InputMaybe<Scalars['String']>;
  categoryName_not_contains?: InputMaybe<Scalars['String']>;
  categoryName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  categorySlug?: InputMaybe<Scalars['String']>;
  categorySlug_contains?: InputMaybe<Scalars['String']>;
  categorySlug_exists?: InputMaybe<Scalars['Boolean']>;
  categorySlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  categorySlug_not?: InputMaybe<Scalars['String']>;
  categorySlug_not_contains?: InputMaybe<Scalars['String']>;
  categorySlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<Eprijournal_ContentfulMetadataFilter>;
  sys?: InputMaybe<Eprijournal_SysFilter>;
};

export type Eprijournal_MainCategoryLinkingCollections = {
  entryCollection?: Maybe<Eprijournal_EntryCollection>;
};


export type Eprijournal_MainCategoryLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum Eprijournal_MainCategoryOrder {
  CategoryNameAsc = 'categoryName_ASC',
  CategoryNameDesc = 'categoryName_DESC',
  CategorySlugAsc = 'categorySlug_ASC',
  CategorySlugDesc = 'categorySlug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/page) */
export type Eprijournal_Page = Eprijournal_Entry & {
  contentfulMetadata: Eprijournal_ContentfulMetadata;
  editorialPostsCollection?: Maybe<Eprijournal_PageEditorialPostsCollection>;
  featuredVideo?: Maybe<Eprijournal_Jumbotron>;
  firstSectionBackground?: Maybe<Eprijournal_Asset>;
  firstSectionCardsCollection?: Maybe<Eprijournal_PageFirstSectionCardsCollection>;
  firstSectionJumbotron?: Maybe<Eprijournal_Jumbotron>;
  linkedFrom?: Maybe<Eprijournal_PageLinkingCollections>;
  pageTitle?: Maybe<Scalars['String']>;
  secondSectionTitle?: Maybe<Scalars['String']>;
  sys: Eprijournal_Sys;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/page) */
export type Eprijournal_PageEditorialPostsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/page) */
export type Eprijournal_PageFeaturedVideoArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/page) */
export type Eprijournal_PageFirstSectionBackgroundArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/page) */
export type Eprijournal_PageFirstSectionCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/page) */
export type Eprijournal_PageFirstSectionJumbotronArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/page) */
export type Eprijournal_PageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/page) */
export type Eprijournal_PagePageTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/page) */
export type Eprijournal_PageSecondSectionTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type Eprijournal_PageCollection = {
  items: Array<Maybe<Eprijournal_Page>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Eprijournal_PageEditorialPostsCollection = {
  items: Array<Maybe<Eprijournal_Post>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Eprijournal_PageFilter = {
  AND?: InputMaybe<Array<InputMaybe<Eprijournal_PageFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Eprijournal_PageFilter>>>;
  contentfulMetadata?: InputMaybe<Eprijournal_ContentfulMetadataFilter>;
  editorialPostsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  featuredVideo?: InputMaybe<Eprijournal_CfJumbotronNestedFilter>;
  featuredVideo_exists?: InputMaybe<Scalars['Boolean']>;
  firstSectionBackground_exists?: InputMaybe<Scalars['Boolean']>;
  firstSectionCardsCollection_exists?: InputMaybe<Scalars['Boolean']>;
  firstSectionJumbotron?: InputMaybe<Eprijournal_CfJumbotronNestedFilter>;
  firstSectionJumbotron_exists?: InputMaybe<Scalars['Boolean']>;
  pageTitle?: InputMaybe<Scalars['String']>;
  pageTitle_contains?: InputMaybe<Scalars['String']>;
  pageTitle_exists?: InputMaybe<Scalars['Boolean']>;
  pageTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pageTitle_not?: InputMaybe<Scalars['String']>;
  pageTitle_not_contains?: InputMaybe<Scalars['String']>;
  pageTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  secondSectionTitle?: InputMaybe<Scalars['String']>;
  secondSectionTitle_contains?: InputMaybe<Scalars['String']>;
  secondSectionTitle_exists?: InputMaybe<Scalars['Boolean']>;
  secondSectionTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  secondSectionTitle_not?: InputMaybe<Scalars['String']>;
  secondSectionTitle_not_contains?: InputMaybe<Scalars['String']>;
  secondSectionTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<Eprijournal_SysFilter>;
};

export type Eprijournal_PageFirstSectionCardsCollection = {
  items: Array<Maybe<Eprijournal_StoryCards>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Eprijournal_PageLinkingCollections = {
  entryCollection?: Maybe<Eprijournal_EntryCollection>;
};


export type Eprijournal_PageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum Eprijournal_PageOrder {
  PageTitleAsc = 'pageTitle_ASC',
  PageTitleDesc = 'pageTitle_DESC',
  SecondSectionTitleAsc = 'secondSectionTitle_ASC',
  SecondSectionTitleDesc = 'secondSectionTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Post Contributors [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/people) */
export type Eprijournal_People = Eprijournal_Entry & {
  contentfulMetadata: Eprijournal_ContentfulMetadata;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Eprijournal_Asset>;
  linkedFrom?: Maybe<Eprijournal_PeopleLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  sys: Eprijournal_Sys;
};


/** Post Contributors [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/people) */
export type Eprijournal_PeopleDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Post Contributors [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/people) */
export type Eprijournal_PeopleImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Post Contributors [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/people) */
export type Eprijournal_PeopleLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Post Contributors [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/people) */
export type Eprijournal_PeopleNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type Eprijournal_PeopleCollection = {
  items: Array<Maybe<Eprijournal_People>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Eprijournal_PeopleFilter = {
  AND?: InputMaybe<Array<InputMaybe<Eprijournal_PeopleFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Eprijournal_PeopleFilter>>>;
  contentfulMetadata?: InputMaybe<Eprijournal_ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<Eprijournal_SysFilter>;
};

export type Eprijournal_PeopleLinkingCollections = {
  entryCollection?: Maybe<Eprijournal_EntryCollection>;
};


export type Eprijournal_PeopleLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum Eprijournal_PeopleOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Content model for podcasts [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/podcastPost) */
export type Eprijournal_PodcastPost = Eprijournal_Entry & {
  authorCollection?: Maybe<Eprijournal_PodcastPostAuthorCollection>;
  contentfulMetadata: Eprijournal_ContentfulMetadata;
  created?: Maybe<Scalars['eprijournal_DateTime']>;
  description?: Maybe<Scalars['String']>;
  linkedFrom?: Maybe<Eprijournal_PodcastPostLinkingCollections>;
  podcastLink?: Maybe<Scalars['String']>;
  postImage?: Maybe<Eprijournal_Asset>;
  postPreview?: Maybe<Scalars['String']>;
  sys: Eprijournal_Sys;
  title?: Maybe<Scalars['String']>;
};


/** Content model for podcasts [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/podcastPost) */
export type Eprijournal_PodcastPostAuthorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Content model for podcasts [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/podcastPost) */
export type Eprijournal_PodcastPostCreatedArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Content model for podcasts [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/podcastPost) */
export type Eprijournal_PodcastPostDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Content model for podcasts [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/podcastPost) */
export type Eprijournal_PodcastPostLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Content model for podcasts [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/podcastPost) */
export type Eprijournal_PodcastPostPodcastLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Content model for podcasts [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/podcastPost) */
export type Eprijournal_PodcastPostPostImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Content model for podcasts [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/podcastPost) */
export type Eprijournal_PodcastPostPostPreviewArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Content model for podcasts [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/podcastPost) */
export type Eprijournal_PodcastPostTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type Eprijournal_PodcastPostAuthorCollection = {
  items: Array<Maybe<Eprijournal_Entry>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Eprijournal_PodcastPostCollection = {
  items: Array<Maybe<Eprijournal_PodcastPost>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Eprijournal_PodcastPostFilter = {
  AND?: InputMaybe<Array<InputMaybe<Eprijournal_PodcastPostFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Eprijournal_PodcastPostFilter>>>;
  authorCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<Eprijournal_ContentfulMetadataFilter>;
  created?: InputMaybe<Scalars['eprijournal_DateTime']>;
  created_exists?: InputMaybe<Scalars['Boolean']>;
  created_gt?: InputMaybe<Scalars['eprijournal_DateTime']>;
  created_gte?: InputMaybe<Scalars['eprijournal_DateTime']>;
  created_in?: InputMaybe<Array<InputMaybe<Scalars['eprijournal_DateTime']>>>;
  created_lt?: InputMaybe<Scalars['eprijournal_DateTime']>;
  created_lte?: InputMaybe<Scalars['eprijournal_DateTime']>;
  created_not?: InputMaybe<Scalars['eprijournal_DateTime']>;
  created_not_in?: InputMaybe<Array<InputMaybe<Scalars['eprijournal_DateTime']>>>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  podcastLink?: InputMaybe<Scalars['String']>;
  podcastLink_contains?: InputMaybe<Scalars['String']>;
  podcastLink_exists?: InputMaybe<Scalars['Boolean']>;
  podcastLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  podcastLink_not?: InputMaybe<Scalars['String']>;
  podcastLink_not_contains?: InputMaybe<Scalars['String']>;
  podcastLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  postImage_exists?: InputMaybe<Scalars['Boolean']>;
  postPreview?: InputMaybe<Scalars['String']>;
  postPreview_contains?: InputMaybe<Scalars['String']>;
  postPreview_exists?: InputMaybe<Scalars['Boolean']>;
  postPreview_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  postPreview_not?: InputMaybe<Scalars['String']>;
  postPreview_not_contains?: InputMaybe<Scalars['String']>;
  postPreview_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<Eprijournal_SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Eprijournal_PodcastPostLinkingCollections = {
  entryCollection?: Maybe<Eprijournal_EntryCollection>;
};


export type Eprijournal_PodcastPostLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum Eprijournal_PodcastPostOrder {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  PodcastLinkAsc = 'podcastLink_ASC',
  PodcastLinkDesc = 'podcastLink_DESC',
  PostPreviewAsc = 'postPreview_ASC',
  PostPreviewDesc = 'postPreview_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/post) */
export type Eprijournal_Post = Eprijournal_Entry & {
  artist?: Maybe<Scalars['String']>;
  contentfulMetadata: Eprijournal_ContentfulMetadata;
  created?: Maybe<Scalars['eprijournal_DateTime']>;
  featuredImage?: Maybe<Scalars['String']>;
  featuredImageCaption?: Maybe<Scalars['String']>;
  introduction?: Maybe<Scalars['String']>;
  keywords?: Maybe<Array<Maybe<Scalars['String']>>>;
  linkedFrom?: Maybe<Eprijournal_PostLinkingCollections>;
  postAuthor?: Maybe<Scalars['String']>;
  postContent?: Maybe<Scalars['String']>;
  postPreview?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Scalars['eprijournal_JSON']>;
  seoSlug?: Maybe<Scalars['String']>;
  subCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  subHeading?: Maybe<Scalars['String']>;
  sys: Eprijournal_Sys;
  templateNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/post) */
export type Eprijournal_PostArtistArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/post) */
export type Eprijournal_PostCreatedArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/post) */
export type Eprijournal_PostFeaturedImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/post) */
export type Eprijournal_PostFeaturedImageCaptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/post) */
export type Eprijournal_PostIntroductionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/post) */
export type Eprijournal_PostKeywordsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/post) */
export type Eprijournal_PostLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/post) */
export type Eprijournal_PostPostAuthorArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/post) */
export type Eprijournal_PostPostContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/post) */
export type Eprijournal_PostPostPreviewArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/post) */
export type Eprijournal_PostSeoFieldsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/post) */
export type Eprijournal_PostSeoSlugArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/post) */
export type Eprijournal_PostSubCategoriesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/post) */
export type Eprijournal_PostSubHeadingArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/post) */
export type Eprijournal_PostTemplateNumberArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/post) */
export type Eprijournal_PostTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type Eprijournal_PostCollection = {
  items: Array<Maybe<Eprijournal_Post>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Eprijournal_PostFilter = {
  AND?: InputMaybe<Array<InputMaybe<Eprijournal_PostFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Eprijournal_PostFilter>>>;
  PostContent?: InputMaybe<Scalars['String']>;
  PostContent_contains?: InputMaybe<Scalars['String']>;
  PostContent_exists?: InputMaybe<Scalars['Boolean']>;
  PostContent_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  PostContent_not?: InputMaybe<Scalars['String']>;
  PostContent_not_contains?: InputMaybe<Scalars['String']>;
  PostContent_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  artist?: InputMaybe<Scalars['String']>;
  artist_contains?: InputMaybe<Scalars['String']>;
  artist_exists?: InputMaybe<Scalars['Boolean']>;
  artist_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  artist_not?: InputMaybe<Scalars['String']>;
  artist_not_contains?: InputMaybe<Scalars['String']>;
  artist_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<Eprijournal_ContentfulMetadataFilter>;
  created?: InputMaybe<Scalars['eprijournal_DateTime']>;
  created_exists?: InputMaybe<Scalars['Boolean']>;
  created_gt?: InputMaybe<Scalars['eprijournal_DateTime']>;
  created_gte?: InputMaybe<Scalars['eprijournal_DateTime']>;
  created_in?: InputMaybe<Array<InputMaybe<Scalars['eprijournal_DateTime']>>>;
  created_lt?: InputMaybe<Scalars['eprijournal_DateTime']>;
  created_lte?: InputMaybe<Scalars['eprijournal_DateTime']>;
  created_not?: InputMaybe<Scalars['eprijournal_DateTime']>;
  created_not_in?: InputMaybe<Array<InputMaybe<Scalars['eprijournal_DateTime']>>>;
  featuredImage?: InputMaybe<Scalars['String']>;
  featuredImageCaption?: InputMaybe<Scalars['String']>;
  featuredImageCaption_contains?: InputMaybe<Scalars['String']>;
  featuredImageCaption_exists?: InputMaybe<Scalars['Boolean']>;
  featuredImageCaption_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  featuredImageCaption_not?: InputMaybe<Scalars['String']>;
  featuredImageCaption_not_contains?: InputMaybe<Scalars['String']>;
  featuredImageCaption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  featuredImage_contains?: InputMaybe<Scalars['String']>;
  featuredImage_exists?: InputMaybe<Scalars['Boolean']>;
  featuredImage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  featuredImage_not?: InputMaybe<Scalars['String']>;
  featuredImage_not_contains?: InputMaybe<Scalars['String']>;
  featuredImage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  introduction?: InputMaybe<Scalars['String']>;
  introduction_contains?: InputMaybe<Scalars['String']>;
  introduction_exists?: InputMaybe<Scalars['Boolean']>;
  introduction_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  introduction_not?: InputMaybe<Scalars['String']>;
  introduction_not_contains?: InputMaybe<Scalars['String']>;
  introduction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_exists?: InputMaybe<Scalars['Boolean']>;
  postAuthor?: InputMaybe<Scalars['String']>;
  postAuthor_contains?: InputMaybe<Scalars['String']>;
  postAuthor_exists?: InputMaybe<Scalars['Boolean']>;
  postAuthor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  postAuthor_not?: InputMaybe<Scalars['String']>;
  postAuthor_not_contains?: InputMaybe<Scalars['String']>;
  postAuthor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  postPreview?: InputMaybe<Scalars['String']>;
  postPreview_contains?: InputMaybe<Scalars['String']>;
  postPreview_exists?: InputMaybe<Scalars['Boolean']>;
  postPreview_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  postPreview_not?: InputMaybe<Scalars['String']>;
  postPreview_not_contains?: InputMaybe<Scalars['String']>;
  postPreview_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seoSlug?: InputMaybe<Scalars['String']>;
  seoSlug_contains?: InputMaybe<Scalars['String']>;
  seoSlug_exists?: InputMaybe<Scalars['Boolean']>;
  seoSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seoSlug_not?: InputMaybe<Scalars['String']>;
  seoSlug_not_contains?: InputMaybe<Scalars['String']>;
  seoSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subCategories_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subCategories_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subCategories_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subCategories_exists?: InputMaybe<Scalars['Boolean']>;
  subHeading?: InputMaybe<Scalars['String']>;
  subHeading_contains?: InputMaybe<Scalars['String']>;
  subHeading_exists?: InputMaybe<Scalars['Boolean']>;
  subHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subHeading_not?: InputMaybe<Scalars['String']>;
  subHeading_not_contains?: InputMaybe<Scalars['String']>;
  subHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<Eprijournal_SysFilter>;
  templateNumber?: InputMaybe<Scalars['String']>;
  templateNumber_contains?: InputMaybe<Scalars['String']>;
  templateNumber_exists?: InputMaybe<Scalars['Boolean']>;
  templateNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  templateNumber_not?: InputMaybe<Scalars['String']>;
  templateNumber_not_contains?: InputMaybe<Scalars['String']>;
  templateNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Eprijournal_PostLinkingCollections = {
  entryCollection?: Maybe<Eprijournal_EntryCollection>;
  pageCollection?: Maybe<Eprijournal_PageCollection>;
};


export type Eprijournal_PostLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type Eprijournal_PostLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum Eprijournal_PostOrder {
  ArtistAsc = 'artist_ASC',
  ArtistDesc = 'artist_DESC',
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  FeaturedImageCaptionAsc = 'featuredImageCaption_ASC',
  FeaturedImageCaptionDesc = 'featuredImageCaption_DESC',
  FeaturedImageAsc = 'featuredImage_ASC',
  FeaturedImageDesc = 'featuredImage_DESC',
  PostAuthorAsc = 'postAuthor_ASC',
  PostAuthorDesc = 'postAuthor_DESC',
  PostPreviewAsc = 'postPreview_ASC',
  PostPreviewDesc = 'postPreview_DESC',
  SeoSlugAsc = 'seoSlug_ASC',
  SeoSlugDesc = 'seoSlug_DESC',
  SubHeadingAsc = 'subHeading_ASC',
  SubHeadingDesc = 'subHeading_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TemplateNumberAsc = 'templateNumber_ASC',
  TemplateNumberDesc = 'templateNumber_DESC'
}

/** Content for the Reports & Software Page [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/reportPost) */
export type Eprijournal_ReportPost = Eprijournal_Entry & {
  authorCollection?: Maybe<Eprijournal_ReportPostAuthorCollection>;
  content?: Maybe<Scalars['String']>;
  contentfulMetadata: Eprijournal_ContentfulMetadata;
  created?: Maybe<Scalars['eprijournal_DateTime']>;
  linkedFrom?: Maybe<Eprijournal_ReportPostLinkingCollections>;
  postImage?: Maybe<Eprijournal_Asset>;
  reportLink?: Maybe<Scalars['String']>;
  sys: Eprijournal_Sys;
  title?: Maybe<Scalars['String']>;
};


/** Content for the Reports & Software Page [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/reportPost) */
export type Eprijournal_ReportPostAuthorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Content for the Reports & Software Page [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/reportPost) */
export type Eprijournal_ReportPostContentArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Content for the Reports & Software Page [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/reportPost) */
export type Eprijournal_ReportPostCreatedArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Content for the Reports & Software Page [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/reportPost) */
export type Eprijournal_ReportPostLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Content for the Reports & Software Page [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/reportPost) */
export type Eprijournal_ReportPostPostImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Content for the Reports & Software Page [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/reportPost) */
export type Eprijournal_ReportPostReportLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Content for the Reports & Software Page [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/reportPost) */
export type Eprijournal_ReportPostTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type Eprijournal_ReportPostAuthorCollection = {
  items: Array<Maybe<Eprijournal_Entry>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Eprijournal_ReportPostCollection = {
  items: Array<Maybe<Eprijournal_ReportPost>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Eprijournal_ReportPostFilter = {
  AND?: InputMaybe<Array<InputMaybe<Eprijournal_ReportPostFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Eprijournal_ReportPostFilter>>>;
  authorCollection_exists?: InputMaybe<Scalars['Boolean']>;
  content?: InputMaybe<Scalars['String']>;
  content_contains?: InputMaybe<Scalars['String']>;
  content_exists?: InputMaybe<Scalars['Boolean']>;
  content_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  content_not?: InputMaybe<Scalars['String']>;
  content_not_contains?: InputMaybe<Scalars['String']>;
  content_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<Eprijournal_ContentfulMetadataFilter>;
  created?: InputMaybe<Scalars['eprijournal_DateTime']>;
  created_exists?: InputMaybe<Scalars['Boolean']>;
  created_gt?: InputMaybe<Scalars['eprijournal_DateTime']>;
  created_gte?: InputMaybe<Scalars['eprijournal_DateTime']>;
  created_in?: InputMaybe<Array<InputMaybe<Scalars['eprijournal_DateTime']>>>;
  created_lt?: InputMaybe<Scalars['eprijournal_DateTime']>;
  created_lte?: InputMaybe<Scalars['eprijournal_DateTime']>;
  created_not?: InputMaybe<Scalars['eprijournal_DateTime']>;
  created_not_in?: InputMaybe<Array<InputMaybe<Scalars['eprijournal_DateTime']>>>;
  postImage_exists?: InputMaybe<Scalars['Boolean']>;
  reportLink?: InputMaybe<Scalars['String']>;
  reportLink_contains?: InputMaybe<Scalars['String']>;
  reportLink_exists?: InputMaybe<Scalars['Boolean']>;
  reportLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  reportLink_not?: InputMaybe<Scalars['String']>;
  reportLink_not_contains?: InputMaybe<Scalars['String']>;
  reportLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<Eprijournal_SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Eprijournal_ReportPostLinkingCollections = {
  entryCollection?: Maybe<Eprijournal_EntryCollection>;
};


export type Eprijournal_ReportPostLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum Eprijournal_ReportPostOrder {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  ReportLinkAsc = 'reportLink_ASC',
  ReportLinkDesc = 'reportLink_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** URL for rss feed. [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/rssFeedUrl) */
export type Eprijournal_RssFeedUrl = Eprijournal_Entry & {
  contentfulMetadata: Eprijournal_ContentfulMetadata;
  isBlog?: Maybe<Scalars['Boolean']>;
  isPodcast?: Maybe<Scalars['Boolean']>;
  linkedFrom?: Maybe<Eprijournal_RssFeedUrlLinkingCollections>;
  sys: Eprijournal_Sys;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};


/** URL for rss feed. [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/rssFeedUrl) */
export type Eprijournal_RssFeedUrlIsBlogArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** URL for rss feed. [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/rssFeedUrl) */
export type Eprijournal_RssFeedUrlIsPodcastArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** URL for rss feed. [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/rssFeedUrl) */
export type Eprijournal_RssFeedUrlLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** URL for rss feed. [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/rssFeedUrl) */
export type Eprijournal_RssFeedUrlTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** URL for rss feed. [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/rssFeedUrl) */
export type Eprijournal_RssFeedUrlUrlArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type Eprijournal_RssFeedUrlCollection = {
  items: Array<Maybe<Eprijournal_RssFeedUrl>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Eprijournal_RssFeedUrlFilter = {
  AND?: InputMaybe<Array<InputMaybe<Eprijournal_RssFeedUrlFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Eprijournal_RssFeedUrlFilter>>>;
  contentfulMetadata?: InputMaybe<Eprijournal_ContentfulMetadataFilter>;
  isBlog?: InputMaybe<Scalars['Boolean']>;
  isBlog_exists?: InputMaybe<Scalars['Boolean']>;
  isBlog_not?: InputMaybe<Scalars['Boolean']>;
  isPodcast?: InputMaybe<Scalars['Boolean']>;
  isPodcast_exists?: InputMaybe<Scalars['Boolean']>;
  isPodcast_not?: InputMaybe<Scalars['Boolean']>;
  sys?: InputMaybe<Eprijournal_SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Scalars['String']>;
  url_contains?: InputMaybe<Scalars['String']>;
  url_exists?: InputMaybe<Scalars['Boolean']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not?: InputMaybe<Scalars['String']>;
  url_not_contains?: InputMaybe<Scalars['String']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Eprijournal_RssFeedUrlLinkingCollections = {
  entryCollection?: Maybe<Eprijournal_EntryCollection>;
};


export type Eprijournal_RssFeedUrlLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum Eprijournal_RssFeedUrlOrder {
  IsBlogAsc = 'isBlog_ASC',
  IsBlogDesc = 'isBlog_DESC',
  IsPodcastAsc = 'isPodcast_ASC',
  IsPodcastDesc = 'isPodcast_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/shortText) */
export type Eprijournal_ShortText = Eprijournal_Entry & {
  contentfulMetadata: Eprijournal_ContentfulMetadata;
  linkedFrom?: Maybe<Eprijournal_ShortTextLinkingCollections>;
  sys: Eprijournal_Sys;
  text?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/shortText) */
export type Eprijournal_ShortTextLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/shortText) */
export type Eprijournal_ShortTextTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type Eprijournal_ShortTextCollection = {
  items: Array<Maybe<Eprijournal_ShortText>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Eprijournal_ShortTextFilter = {
  AND?: InputMaybe<Array<InputMaybe<Eprijournal_ShortTextFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Eprijournal_ShortTextFilter>>>;
  contentfulMetadata?: InputMaybe<Eprijournal_ContentfulMetadataFilter>;
  sys?: InputMaybe<Eprijournal_SysFilter>;
  text?: InputMaybe<Scalars['String']>;
  text_contains?: InputMaybe<Scalars['String']>;
  text_exists?: InputMaybe<Scalars['Boolean']>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  text_not?: InputMaybe<Scalars['String']>;
  text_not_contains?: InputMaybe<Scalars['String']>;
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Eprijournal_ShortTextLinkingCollections = {
  entryCollection?: Maybe<Eprijournal_EntryCollection>;
};


export type Eprijournal_ShortTextLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum Eprijournal_ShortTextOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC'
}

/** Component [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/storyCards) */
export type Eprijournal_StoryCards = Eprijournal_Entry & {
  body?: Maybe<Scalars['String']>;
  buttonLink?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  contentfulMetadata: Eprijournal_ContentfulMetadata;
  image?: Maybe<Eprijournal_Asset>;
  linkedFrom?: Maybe<Eprijournal_StoryCardsLinkingCollections>;
  publicOrMemberOnly?: Maybe<Scalars['String']>;
  sys: Eprijournal_Sys;
  title?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['Boolean']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/storyCards) */
export type Eprijournal_StoryCardsBodyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/storyCards) */
export type Eprijournal_StoryCardsButtonLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/storyCards) */
export type Eprijournal_StoryCardsButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/storyCards) */
export type Eprijournal_StoryCardsImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/storyCards) */
export type Eprijournal_StoryCardsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Component [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/storyCards) */
export type Eprijournal_StoryCardsPublicOrMemberOnlyArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/storyCards) */
export type Eprijournal_StoryCardsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** Component [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/storyCards) */
export type Eprijournal_StoryCardsVideoArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type Eprijournal_StoryCardsCollection = {
  items: Array<Maybe<Eprijournal_StoryCards>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Eprijournal_StoryCardsFilter = {
  AND?: InputMaybe<Array<InputMaybe<Eprijournal_StoryCardsFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Eprijournal_StoryCardsFilter>>>;
  body?: InputMaybe<Scalars['String']>;
  body_contains?: InputMaybe<Scalars['String']>;
  body_exists?: InputMaybe<Scalars['Boolean']>;
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not?: InputMaybe<Scalars['String']>;
  body_not_contains?: InputMaybe<Scalars['String']>;
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink?: InputMaybe<Scalars['String']>;
  buttonLink_contains?: InputMaybe<Scalars['String']>;
  buttonLink_exists?: InputMaybe<Scalars['Boolean']>;
  buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonLink_not?: InputMaybe<Scalars['String']>;
  buttonLink_not_contains?: InputMaybe<Scalars['String']>;
  buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText?: InputMaybe<Scalars['String']>;
  buttonText_contains?: InputMaybe<Scalars['String']>;
  buttonText_exists?: InputMaybe<Scalars['Boolean']>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  buttonText_not?: InputMaybe<Scalars['String']>;
  buttonText_not_contains?: InputMaybe<Scalars['String']>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata?: InputMaybe<Eprijournal_ContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars['Boolean']>;
  publicOrMemberOnly?: InputMaybe<Scalars['String']>;
  publicOrMemberOnly_contains?: InputMaybe<Scalars['String']>;
  publicOrMemberOnly_exists?: InputMaybe<Scalars['Boolean']>;
  publicOrMemberOnly_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicOrMemberOnly_not?: InputMaybe<Scalars['String']>;
  publicOrMemberOnly_not_contains?: InputMaybe<Scalars['String']>;
  publicOrMemberOnly_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<Eprijournal_SysFilter>;
  title?: InputMaybe<Scalars['String']>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_exists?: InputMaybe<Scalars['Boolean']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  video?: InputMaybe<Scalars['Boolean']>;
  video_exists?: InputMaybe<Scalars['Boolean']>;
  video_not?: InputMaybe<Scalars['Boolean']>;
};

export type Eprijournal_StoryCardsLinkingCollections = {
  entryCollection?: Maybe<Eprijournal_EntryCollection>;
  pageCollection?: Maybe<Eprijournal_PageCollection>;
};


export type Eprijournal_StoryCardsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type Eprijournal_StoryCardsLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum Eprijournal_StoryCardsOrder {
  ButtonLinkAsc = 'buttonLink_ASC',
  ButtonLinkDesc = 'buttonLink_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  PublicOrMemberOnlyAsc = 'publicOrMemberOnly_ASC',
  PublicOrMemberOnlyDesc = 'publicOrMemberOnly_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  VideoAsc = 'video_ASC',
  VideoDesc = 'video_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/subCategory) */
export type Eprijournal_SubCategory = Eprijournal_Entry & {
  contentfulMetadata: Eprijournal_ContentfulMetadata;
  description?: Maybe<Scalars['String']>;
  homepageimage?: Maybe<Eprijournal_Asset>;
  linkedFrom?: Maybe<Eprijournal_SubCategoryLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  sys: Eprijournal_Sys;
  topicImage?: Maybe<Eprijournal_Asset>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/subCategory) */
export type Eprijournal_SubCategoryDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/subCategory) */
export type Eprijournal_SubCategoryHomepageimageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/subCategory) */
export type Eprijournal_SubCategoryLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/subCategory) */
export type Eprijournal_SubCategoryNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/subCategory) */
export type Eprijournal_SubCategorySlugArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/subCategory) */
export type Eprijournal_SubCategoryTopicImageArgs = {
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
};

export type Eprijournal_SubCategoryCollection = {
  items: Array<Maybe<Eprijournal_SubCategory>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Eprijournal_SubCategoryFilter = {
  AND?: InputMaybe<Array<InputMaybe<Eprijournal_SubCategoryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Eprijournal_SubCategoryFilter>>>;
  contentfulMetadata?: InputMaybe<Eprijournal_ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_exists?: InputMaybe<Scalars['Boolean']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homepageimage_exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug?: InputMaybe<Scalars['String']>;
  slug_contains?: InputMaybe<Scalars['String']>;
  slug_exists?: InputMaybe<Scalars['Boolean']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not?: InputMaybe<Scalars['String']>;
  slug_not_contains?: InputMaybe<Scalars['String']>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<Eprijournal_SysFilter>;
  topicImage_exists?: InputMaybe<Scalars['Boolean']>;
};

export type Eprijournal_SubCategoryLinkingCollections = {
  entryCollection?: Maybe<Eprijournal_EntryCollection>;
};


export type Eprijournal_SubCategoryLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum Eprijournal_SubCategoryOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Eprijournal_Sys = {
  environmentId: Scalars['String'];
  firstPublishedAt?: Maybe<Scalars['eprijournal_DateTime']>;
  id: Scalars['String'];
  publishedAt?: Maybe<Scalars['eprijournal_DateTime']>;
  publishedVersion?: Maybe<Scalars['Int']>;
  spaceId: Scalars['String'];
};

export type Eprijournal_SysFilter = {
  firstPublishedAt?: InputMaybe<Scalars['eprijournal_DateTime']>;
  firstPublishedAt_exists?: InputMaybe<Scalars['Boolean']>;
  firstPublishedAt_gt?: InputMaybe<Scalars['eprijournal_DateTime']>;
  firstPublishedAt_gte?: InputMaybe<Scalars['eprijournal_DateTime']>;
  firstPublishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['eprijournal_DateTime']>>>;
  firstPublishedAt_lt?: InputMaybe<Scalars['eprijournal_DateTime']>;
  firstPublishedAt_lte?: InputMaybe<Scalars['eprijournal_DateTime']>;
  firstPublishedAt_not?: InputMaybe<Scalars['eprijournal_DateTime']>;
  firstPublishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['eprijournal_DateTime']>>>;
  id?: InputMaybe<Scalars['String']>;
  id_contains?: InputMaybe<Scalars['String']>;
  id_exists?: InputMaybe<Scalars['Boolean']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_not?: InputMaybe<Scalars['String']>;
  id_not_contains?: InputMaybe<Scalars['String']>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publishedAt?: InputMaybe<Scalars['eprijournal_DateTime']>;
  publishedAt_exists?: InputMaybe<Scalars['Boolean']>;
  publishedAt_gt?: InputMaybe<Scalars['eprijournal_DateTime']>;
  publishedAt_gte?: InputMaybe<Scalars['eprijournal_DateTime']>;
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['eprijournal_DateTime']>>>;
  publishedAt_lt?: InputMaybe<Scalars['eprijournal_DateTime']>;
  publishedAt_lte?: InputMaybe<Scalars['eprijournal_DateTime']>;
  publishedAt_not?: InputMaybe<Scalars['eprijournal_DateTime']>;
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['eprijournal_DateTime']>>>;
  publishedVersion?: InputMaybe<Scalars['Float']>;
  publishedVersion_exists?: InputMaybe<Scalars['Boolean']>;
  publishedVersion_gt?: InputMaybe<Scalars['Float']>;
  publishedVersion_gte?: InputMaybe<Scalars['Float']>;
  publishedVersion_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  publishedVersion_lt?: InputMaybe<Scalars['Float']>;
  publishedVersion_lte?: InputMaybe<Scalars['Float']>;
  publishedVersion_not?: InputMaybe<Scalars['Float']>;
  publishedVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/tag) */
export type Eprijournal_Tag = Eprijournal_Entry & {
  contentfulMetadata: Eprijournal_ContentfulMetadata;
  linkedFrom?: Maybe<Eprijournal_TagLinkingCollections>;
  sys: Eprijournal_Sys;
  tagId?: Maybe<Scalars['Int']>;
  tagName?: Maybe<Scalars['String']>;
  tagSlug?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/tag) */
export type Eprijournal_TagLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/tag) */
export type Eprijournal_TagTagIdArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/tag) */
export type Eprijournal_TagTagNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/tag) */
export type Eprijournal_TagTagSlugArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type Eprijournal_TagCollection = {
  items: Array<Maybe<Eprijournal_Tag>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Eprijournal_TagFilter = {
  AND?: InputMaybe<Array<InputMaybe<Eprijournal_TagFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Eprijournal_TagFilter>>>;
  contentfulMetadata?: InputMaybe<Eprijournal_ContentfulMetadataFilter>;
  sys?: InputMaybe<Eprijournal_SysFilter>;
  tagId?: InputMaybe<Scalars['Int']>;
  tagId_exists?: InputMaybe<Scalars['Boolean']>;
  tagId_gt?: InputMaybe<Scalars['Int']>;
  tagId_gte?: InputMaybe<Scalars['Int']>;
  tagId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  tagId_lt?: InputMaybe<Scalars['Int']>;
  tagId_lte?: InputMaybe<Scalars['Int']>;
  tagId_not?: InputMaybe<Scalars['Int']>;
  tagId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  tagName?: InputMaybe<Scalars['String']>;
  tagName_contains?: InputMaybe<Scalars['String']>;
  tagName_exists?: InputMaybe<Scalars['Boolean']>;
  tagName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tagName_not?: InputMaybe<Scalars['String']>;
  tagName_not_contains?: InputMaybe<Scalars['String']>;
  tagName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tagSlug?: InputMaybe<Scalars['String']>;
  tagSlug_contains?: InputMaybe<Scalars['String']>;
  tagSlug_exists?: InputMaybe<Scalars['Boolean']>;
  tagSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tagSlug_not?: InputMaybe<Scalars['String']>;
  tagSlug_not_contains?: InputMaybe<Scalars['String']>;
  tagSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Eprijournal_TagLinkingCollections = {
  entryCollection?: Maybe<Eprijournal_EntryCollection>;
};


export type Eprijournal_TagLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum Eprijournal_TagOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagIdAsc = 'tagId_ASC',
  TagIdDesc = 'tagId_DESC'
}

/** Wrapper around array of fields [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/wrapper) */
export type Eprijournal_Wrapper = Eprijournal_Entry & {
  childrenCollection?: Maybe<Eprijournal_WrapperChildrenCollection>;
  contentfulMetadata: Eprijournal_ContentfulMetadata;
  linkedFrom?: Maybe<Eprijournal_WrapperLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  sys: Eprijournal_Sys;
};


/** Wrapper around array of fields [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/wrapper) */
export type Eprijournal_WrapperChildrenCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Wrapper around array of fields [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/wrapper) */
export type Eprijournal_WrapperLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Wrapper around array of fields [See type definition](https://app.contentful.com/spaces/z3y9nu90tqli/content_types/wrapper) */
export type Eprijournal_WrapperNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type Eprijournal_WrapperChildrenCollection = {
  items: Array<Maybe<Eprijournal_Entry>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Eprijournal_WrapperCollection = {
  items: Array<Maybe<Eprijournal_Wrapper>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type Eprijournal_WrapperFilter = {
  AND?: InputMaybe<Array<InputMaybe<Eprijournal_WrapperFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<Eprijournal_WrapperFilter>>>;
  childrenCollection_exists?: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata?: InputMaybe<Eprijournal_ContentfulMetadataFilter>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_exists?: InputMaybe<Scalars['Boolean']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys?: InputMaybe<Eprijournal_SysFilter>;
};

export type Eprijournal_WrapperLinkingCollections = {
  entryCollection?: Maybe<Eprijournal_EntryCollection>;
};


export type Eprijournal_WrapperLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  preview?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum Eprijournal_WrapperOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Eprijournal_CfJumbotronNestedFilter = {
    AND?: InputMaybe<Array<InputMaybe<Eprijournal_CfJumbotronNestedFilter>>>;
    OR?: InputMaybe<Array<InputMaybe<Eprijournal_CfJumbotronNestedFilter>>>;
    body?: InputMaybe<Scalars['String']>;
    bodyMarkdown?: InputMaybe<Scalars['String']>;
    bodyMarkdown_contains?: InputMaybe<Scalars['String']>;
    bodyMarkdown_exists?: InputMaybe<Scalars['Boolean']>;
    bodyMarkdown_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    bodyMarkdown_not?: InputMaybe<Scalars['String']>;
    bodyMarkdown_not_contains?: InputMaybe<Scalars['String']>;
    bodyMarkdown_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    body_contains?: InputMaybe<Scalars['String']>;
    body_exists?: InputMaybe<Scalars['Boolean']>;
    body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    body_not?: InputMaybe<Scalars['String']>;
    body_not_contains?: InputMaybe<Scalars['String']>;
    body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    buttonLink?: InputMaybe<Scalars['String']>;
    buttonLink_contains?: InputMaybe<Scalars['String']>;
    buttonLink_exists?: InputMaybe<Scalars['Boolean']>;
    buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    buttonLink_not?: InputMaybe<Scalars['String']>;
    buttonLink_not_contains?: InputMaybe<Scalars['String']>;
    buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    buttonText?: InputMaybe<Scalars['String']>;
    buttonText_contains?: InputMaybe<Scalars['String']>;
    buttonText_exists?: InputMaybe<Scalars['Boolean']>;
    buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    buttonText_not?: InputMaybe<Scalars['String']>;
    buttonText_not_contains?: InputMaybe<Scalars['String']>;
    buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    contentfulMetadata?: InputMaybe<Eprijournal_ContentfulMetadataFilter>;
    image_exists?: InputMaybe<Scalars['Boolean']>;
    subtitle?: InputMaybe<Scalars['String']>;
    subtitle_contains?: InputMaybe<Scalars['String']>;
    subtitle_exists?: InputMaybe<Scalars['Boolean']>;
    subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    subtitle_not?: InputMaybe<Scalars['String']>;
    subtitle_not_contains?: InputMaybe<Scalars['String']>;
    subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sys?: InputMaybe<Eprijournal_SysFilter>;
    title?: InputMaybe<Scalars['String']>;
    title_contains?: InputMaybe<Scalars['String']>;
    title_exists?: InputMaybe<Scalars['Boolean']>;
    title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title_not?: InputMaybe<Scalars['String']>;
    title_not_contains?: InputMaybe<Scalars['String']>;
    title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GetNuclearPlantModernizationBcamModelsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNuclearPlantModernizationBcamModelsQuery = { epri_plantModernizationModel?: { totalCount?: number | null | undefined, models?: Array<{ functionalArea?: string | null | undefined, productId?: string | null | undefined, description?: string | null | undefined, shortProductId?: string | null | undefined, asbtractLink?: string | null | undefined, npv?: string | null | undefined, notes?: string | null | undefined, npvHidden?: string | null | undefined, publishedDate?: string | null | undefined, areasForSavings?: string | null | undefined, relatedMTA?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type GetFooterInfoQueryVariables = Exact<{
    preview?: InputMaybe<Scalars['Boolean']>;
    locale?: InputMaybe<Scalars['String']>;
}>;


export type GetFooterInfoQuery = { footer?: { address?: string | null | undefined, telephone?: string | null | undefined, established?: string | null | undefined, googleMapsAddressLink?: string | null | undefined, footerLinks?: { linksCollection?: { items: Array<{ name?: string | null | undefined, url?: string | null | undefined } | null | undefined> } | null | undefined } | null | undefined, socialMediaLinks?: { linksCollection?: { items: Array<{ name?: string | null | undefined, url?: string | null | undefined } | null | undefined> } | null | undefined } | null | undefined } | null | undefined };

export type GetNuclearPlantModernizationLandingPageQueryVariables = Exact<{
    id: Scalars['String'];
    langCode: Scalars['String'];
}>;


export type GetNuclearPlantModernizationLandingPageQuery = {
    nuclearPlantModernizationLandingPage?: {
        overviewHeader?: string | null | undefined, overviewText?: string | null | undefined, modernizationProcessHeader?: string | null | undefined, modernizationProcessText?: string | null | undefined, bcamHeader?: string | null | undefined, bcamText?: string | null | undefined, modernizationQuickGuidesHeader?: string | null | undefined, modernizationQuickGuidesText?: string | null | undefined, tpieHeader?: string | null | undefined, tpieText?: string | null | undefined, overviewIcon?: { url?: string | null | undefined } | null | undefined, overviewImage?: { url?: string | null | undefined } | null | undefined, modernizationProcessIcon?: { url?: string | null | undefined } | null | undefined, modernizationProcessDiagram?: { url?: string | null | undefined } | null | undefined, downloadProcessGuideLink?: { name?: string | null | undefined, url?: string | null | undefined } | {} | null | undefined, bcamIcon?: { url?: string | null | undefined } | null | undefined, viewBcamListLink?: { name?: string | null | undefined, url?: string | null | undefined } | {} | null | undefined, downloadBcamSoftwareLink?: { name?: string | null | undefined, url?: string | null | undefined } | {} | null | undefined, downloadMtaSpecLink?: { name?: string | null | undefined, url?: string | null | undefined } | {} | null | undefined, viewMtaListLink?: { name?: string | null | undefined, url?: string | null | undefined } | {} | null | undefined
    } | null | undefined
};

export type GetPlantModernizationTechnologyAssessmentGuidesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlantModernizationTechnologyAssessmentGuidesQuery = { epri_plantModernizationGuides?: { totalCount?: number | null | undefined, guides?: Array<{ mqgNumber?: string | null | undefined, title?: string | null | undefined, schedule?: string | null | undefined, functionalAreas?: Array<string | null | undefined> | null | undefined, cost?: string | null | undefined, savings?: string | null | undefined, payback?: string | null | undefined, applicability?: string | null | undefined, licensingReadiness?: string | null | undefined, technologyReadiness?: string | null | undefined, implementProficiency?: string | null | undefined, pdfLink?: string | null | undefined, publishedDate?: string | null | undefined, applicableBCAM?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type GetPlantModernizationMtaSweepScoreLegendQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlantModernizationMtaSweepScoreLegendQuery = { nuclearPlantModernizationSweepScoreLegend?: { categoriesCollection?: { items: Array<{ categoryName?: string | null | undefined, categoryDescription?: string | null | undefined, categoryLevel0?: string | null | undefined, categoryLevel1?: string | null | undefined, categoryLevel2?: string | null | undefined, categoryLevel3?: string | null | undefined } | null | undefined> } | null | undefined } | null | undefined };

export type GetBcamByIdQueryVariables = Exact<{
    id?: InputMaybe<Scalars['Int']>;
}>;


export type GetBcamByIdQuery = {
    epri_BCam?: Array<{
        id?: number | null | undefined, costOfCapital?: number | null | undefined, createdBy?: string | null | undefined, createdDate?: string | null | undefined, description?: string | null | undefined, fteCost?: number | null | undefined, fteHours?: number | null | undefined, materialSoftware?: number | null | undefined, modelRun?: string | null | undefined, modifiedDate?: string | null | undefined, oneTimeEngineeringContract?: number | null | undefined, oneTimeHardware?: number | null | undefined, oneTimeInstallationContract?: number | null | undefined, oneTimeLabor?: number | null | undefined, oneTimeSoftware?: number | null | undefined, ongoingHours?: number | null | undefined, onlineContractServiceSaving?: number | null | undefined, onlineLaborSaving?: number | null | undefined, onlineMaterialInventorySaving?: number | null | undefined, outageContractServiceSaving?: number | null | undefined, outageLaborSaving?: number | null | undefined, outageMaterialInventorySaving?: number | null | undefined, outages?: Array<number | null | undefined> | null | undefined, projectName?: string | null | undefined, qualitativeBenefits?: Array<string | null | undefined> | null | undefined, reactors?: number | null | undefined, refuelCycle?: number | null | undefined
    } | null | undefined> | null | undefined
};

export type GetAllBcamsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllBcamsQuery = {
    epri_BCam?: Array<{
        id?: number | null | undefined, costOfCapital?: number | null | undefined, createdBy?: string | null | undefined, createdDate?: string | null | undefined, description?: string | null | undefined, fteCost?: number | null | undefined, fteHours?: number | null | undefined, materialSoftware?: number | null | undefined, modelRun?: string | null | undefined, modifiedDate?: string | null | undefined, oneTimeEngineeringContract?: number | null | undefined, oneTimeHardware?: number | null | undefined, oneTimeInstallationContract?: number | null | undefined, oneTimeLabor?: number | null | undefined, oneTimeSoftware?: number | null | undefined, ongoingHours?: number | null | undefined, onlineContractServiceSaving?: number | null | undefined, onlineLaborSaving?: number | null | undefined, onlineMaterialInventorySaving?: number | null | undefined, outageContractServiceSaving?: number | null | undefined, outageLaborSaving?: number | null | undefined, outageMaterialInventorySaving?: number | null | undefined, outages?: Array<number | null | undefined> | null | undefined, projectName?: string | null | undefined, qualitativeBenefits?: Array<string | null | undefined> | null | undefined, reactors?: number | null | undefined, refuelCycle?: number | null | undefined
    } | null | undefined> | null | undefined
};

export type SaveBcamMutationVariables = Exact<{
    input: BcamInput;
}>;


export type SaveBcamMutation = { epri_postBcamRequest?: number | null | undefined };

export type DeleteBcamMutationVariables = Exact<{
    id: Scalars['Int'];
}>;


export type DeleteBcamMutation = { epri_postBcamRequest?: number | null | undefined };

export type GetBcamsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBcamsQuery = { epri_BCam?: Array<{ id?: number | null | undefined, modelRun?: string | null | undefined, modifiedDate?: string | null | undefined, projectName?: string | null | undefined } | null | undefined> | null | undefined };


export const GetNuclearPlantModernizationBcamModelsDocument = gql`
    query GetNuclearPlantModernizationBcamModels {
        epri_plantModernizationModel {
            models {
                functionalArea
                productId
                description
                shortProductId
                asbtractLink
                npv
                notes
                npvHidden
                publishedDate
                areasForSavings
                relatedMTA
            }
            totalCount
        }
    }
`;

/**
 * __useGetNuclearPlantModernizationBcamModelsQuery__
 *
 * To run a query within a React component, call `useGetNuclearPlantModernizationBcamModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNuclearPlantModernizationBcamModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNuclearPlantModernizationBcamModelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNuclearPlantModernizationBcamModelsQuery(baseOptions?: Apollo.QueryHookOptions<GetNuclearPlantModernizationBcamModelsQuery, GetNuclearPlantModernizationBcamModelsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetNuclearPlantModernizationBcamModelsQuery, GetNuclearPlantModernizationBcamModelsQueryVariables>(GetNuclearPlantModernizationBcamModelsDocument, options);
}

export function useGetNuclearPlantModernizationBcamModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNuclearPlantModernizationBcamModelsQuery, GetNuclearPlantModernizationBcamModelsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetNuclearPlantModernizationBcamModelsQuery, GetNuclearPlantModernizationBcamModelsQueryVariables>(GetNuclearPlantModernizationBcamModelsDocument, options);
}

export type GetNuclearPlantModernizationBcamModelsQueryHookResult = ReturnType<typeof useGetNuclearPlantModernizationBcamModelsQuery>;
export type GetNuclearPlantModernizationBcamModelsLazyQueryHookResult = ReturnType<typeof useGetNuclearPlantModernizationBcamModelsLazyQuery>;
export type GetNuclearPlantModernizationBcamModelsQueryResult = Apollo.QueryResult<GetNuclearPlantModernizationBcamModelsQuery, GetNuclearPlantModernizationBcamModelsQueryVariables>;
export const GetFooterInfoDocument = gql`
    query GetFooterInfo($preview: Boolean, $locale: String) {
        footer(id: "4sMozRfKbKgaGMsEusiKa0", preview: $preview, locale: $locale) {
            address
            telephone
            established
            footerLinks {
                linksCollection {
                    items {
                        name
                        url
                    }
                }
            }
            socialMediaLinks {
                linksCollection {
                    items {
                        name
                        url
                    }
                }
            }
            googleMapsAddressLink
        }
    }
`;

/**
 * __useGetFooterInfoQuery__
 *
 * To run a query within a React component, call `useGetFooterInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFooterInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFooterInfoQuery({
 *   variables: {
 *      preview: // value for 'preview'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetFooterInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetFooterInfoQuery, GetFooterInfoQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetFooterInfoQuery, GetFooterInfoQueryVariables>(GetFooterInfoDocument, options);
}

export function useGetFooterInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFooterInfoQuery, GetFooterInfoQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetFooterInfoQuery, GetFooterInfoQueryVariables>(GetFooterInfoDocument, options);
}

export type GetFooterInfoQueryHookResult = ReturnType<typeof useGetFooterInfoQuery>;
export type GetFooterInfoLazyQueryHookResult = ReturnType<typeof useGetFooterInfoLazyQuery>;
export type GetFooterInfoQueryResult = Apollo.QueryResult<GetFooterInfoQuery, GetFooterInfoQueryVariables>;
export const GetNuclearPlantModernizationLandingPageDocument = gql`
    query GetNuclearPlantModernizationLandingPage($id: String!, $langCode: String!) {
        nuclearPlantModernizationLandingPage(id: $id, locale: $langCode) {
            overviewIcon {
                url
            }
            overviewHeader
            overviewText
            overviewImage {
                url
            }
            modernizationProcessIcon {
                url
            }
            modernizationProcessHeader
            modernizationProcessText
            modernizationProcessDiagram {
                url
            }
            downloadProcessGuideLink {
                ... on Link {
                    name
                    url
                }
            }
            bcamIcon {
                url
            }
            bcamHeader
            bcamText
            viewBcamListLink {
                ... on Link {
                    name
                    url
                }
            }
            downloadBcamSoftwareLink {
                ... on Link {
                    name
                    url
                }
            }
            modernizationQuickGuidesHeader
            modernizationQuickGuidesText
            downloadMtaSpecLink {
                ... on Link {
                    name
                    url
                }
            }
            viewMtaListLink {
                ... on Link {
                    name
                    url
                }
            }
            tpieHeader
            tpieText
        }
    }
`;

/**
 * __useGetNuclearPlantModernizationLandingPageQuery__
 *
 * To run a query within a React component, call `useGetNuclearPlantModernizationLandingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNuclearPlantModernizationLandingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNuclearPlantModernizationLandingPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      langCode: // value for 'langCode'
 *   },
 * });
 */
export function useGetNuclearPlantModernizationLandingPageQuery(baseOptions: Apollo.QueryHookOptions<GetNuclearPlantModernizationLandingPageQuery, GetNuclearPlantModernizationLandingPageQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetNuclearPlantModernizationLandingPageQuery, GetNuclearPlantModernizationLandingPageQueryVariables>(GetNuclearPlantModernizationLandingPageDocument, options);
}

export function useGetNuclearPlantModernizationLandingPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNuclearPlantModernizationLandingPageQuery, GetNuclearPlantModernizationLandingPageQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetNuclearPlantModernizationLandingPageQuery, GetNuclearPlantModernizationLandingPageQueryVariables>(GetNuclearPlantModernizationLandingPageDocument, options);
}

export type GetNuclearPlantModernizationLandingPageQueryHookResult = ReturnType<typeof useGetNuclearPlantModernizationLandingPageQuery>;
export type GetNuclearPlantModernizationLandingPageLazyQueryHookResult = ReturnType<typeof useGetNuclearPlantModernizationLandingPageLazyQuery>;
export type GetNuclearPlantModernizationLandingPageQueryResult = Apollo.QueryResult<GetNuclearPlantModernizationLandingPageQuery, GetNuclearPlantModernizationLandingPageQueryVariables>;
export const GetPlantModernizationTechnologyAssessmentGuidesDocument = gql`
    query GetPlantModernizationTechnologyAssessmentGuides {
        epri_plantModernizationGuides(
            functionalArea: null
            secondary: null
            tertiary: null
        ) {
            totalCount
            guides {
                mqgNumber
                title
                schedule
                functionalAreas
                cost
                savings
                payback
                applicability
                licensingReadiness
                technologyReadiness
                implementProficiency
                pdfLink
                applicability
                publishedDate
                applicableBCAM
            }
        }
    }
`;

/**
 * __useGetPlantModernizationTechnologyAssessmentGuidesQuery__
 *
 * To run a query within a React component, call `useGetPlantModernizationTechnologyAssessmentGuidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlantModernizationTechnologyAssessmentGuidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlantModernizationTechnologyAssessmentGuidesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlantModernizationTechnologyAssessmentGuidesQuery(baseOptions?: Apollo.QueryHookOptions<GetPlantModernizationTechnologyAssessmentGuidesQuery, GetPlantModernizationTechnologyAssessmentGuidesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetPlantModernizationTechnologyAssessmentGuidesQuery, GetPlantModernizationTechnologyAssessmentGuidesQueryVariables>(GetPlantModernizationTechnologyAssessmentGuidesDocument, options);
}

export function useGetPlantModernizationTechnologyAssessmentGuidesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlantModernizationTechnologyAssessmentGuidesQuery, GetPlantModernizationTechnologyAssessmentGuidesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetPlantModernizationTechnologyAssessmentGuidesQuery, GetPlantModernizationTechnologyAssessmentGuidesQueryVariables>(GetPlantModernizationTechnologyAssessmentGuidesDocument, options);
}

export type GetPlantModernizationTechnologyAssessmentGuidesQueryHookResult = ReturnType<typeof useGetPlantModernizationTechnologyAssessmentGuidesQuery>;
export type GetPlantModernizationTechnologyAssessmentGuidesLazyQueryHookResult = ReturnType<typeof useGetPlantModernizationTechnologyAssessmentGuidesLazyQuery>;
export type GetPlantModernizationTechnologyAssessmentGuidesQueryResult = Apollo.QueryResult<GetPlantModernizationTechnologyAssessmentGuidesQuery, GetPlantModernizationTechnologyAssessmentGuidesQueryVariables>;
export const GetPlantModernizationMtaSweepScoreLegendDocument = gql`
    query GetPlantModernizationMtaSweepScoreLegend {
        nuclearPlantModernizationSweepScoreLegend(
            id: "7kpMQEqhGnWYHbuYi5I45B"
            locale: "en-US"
        ) {
            categoriesCollection {
                items {
                    categoryName
                    categoryDescription
                    categoryLevel0
                    categoryLevel1
                    categoryLevel2
                    categoryLevel3
                }
            }
        }
    }
`;

/**
 * __useGetPlantModernizationMtaSweepScoreLegendQuery__
 *
 * To run a query within a React component, call `useGetPlantModernizationMtaSweepScoreLegendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlantModernizationMtaSweepScoreLegendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlantModernizationMtaSweepScoreLegendQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlantModernizationMtaSweepScoreLegendQuery(baseOptions?: Apollo.QueryHookOptions<GetPlantModernizationMtaSweepScoreLegendQuery, GetPlantModernizationMtaSweepScoreLegendQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetPlantModernizationMtaSweepScoreLegendQuery, GetPlantModernizationMtaSweepScoreLegendQueryVariables>(GetPlantModernizationMtaSweepScoreLegendDocument, options);
}

export function useGetPlantModernizationMtaSweepScoreLegendLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlantModernizationMtaSweepScoreLegendQuery, GetPlantModernizationMtaSweepScoreLegendQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetPlantModernizationMtaSweepScoreLegendQuery, GetPlantModernizationMtaSweepScoreLegendQueryVariables>(GetPlantModernizationMtaSweepScoreLegendDocument, options);
}

export type GetPlantModernizationMtaSweepScoreLegendQueryHookResult = ReturnType<typeof useGetPlantModernizationMtaSweepScoreLegendQuery>;
export type GetPlantModernizationMtaSweepScoreLegendLazyQueryHookResult = ReturnType<typeof useGetPlantModernizationMtaSweepScoreLegendLazyQuery>;
export type GetPlantModernizationMtaSweepScoreLegendQueryResult = Apollo.QueryResult<GetPlantModernizationMtaSweepScoreLegendQuery, GetPlantModernizationMtaSweepScoreLegendQueryVariables>;
export const GetBcamByIdDocument = gql`
    query GetBcamById($id: Int) {
        epri_BCam(id: $id) {
            id
            costOfCapital
            createdBy
            createdDate
            description
            fteCost
            fteHours
            materialSoftware
            modelRun
            modifiedDate
            oneTimeEngineeringContract
            oneTimeHardware
            oneTimeInstallationContract
            oneTimeLabor
            oneTimeSoftware
            ongoingHours
            onlineContractServiceSaving
            onlineLaborSaving
            onlineMaterialInventorySaving
            outageContractServiceSaving
            outageLaborSaving
            outageMaterialInventorySaving
            outages
            projectName
            qualitativeBenefits
            reactors
            refuelCycle
        }
    }
`;

/**
 * __useGetBcamByIdQuery__
 *
 * To run a query within a React component, call `useGetBcamByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBcamByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBcamByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBcamByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetBcamByIdQuery, GetBcamByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBcamByIdQuery, GetBcamByIdQueryVariables>(GetBcamByIdDocument, options);
      }
export function useGetBcamByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBcamByIdQuery, GetBcamByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBcamByIdQuery, GetBcamByIdQueryVariables>(GetBcamByIdDocument, options);
        }
export type GetBcamByIdQueryHookResult = ReturnType<typeof useGetBcamByIdQuery>;
export type GetBcamByIdLazyQueryHookResult = ReturnType<typeof useGetBcamByIdLazyQuery>;
export type GetBcamByIdQueryResult = Apollo.QueryResult<GetBcamByIdQuery, GetBcamByIdQueryVariables>;
export const GetAllBcamsDocument = gql`
    query GetAllBcams {
  epri_BCam {
    id
    costOfCapital
    createdBy
    createdDate
    description
    fteCost
    fteHours
    materialSoftware
    modelRun
    modifiedDate
    oneTimeEngineeringContract
    oneTimeHardware
    oneTimeInstallationContract
    oneTimeLabor
    oneTimeSoftware
    ongoingHours
    onlineContractServiceSaving
    onlineLaborSaving
    onlineMaterialInventorySaving
    outageContractServiceSaving
    outageLaborSaving
    outageMaterialInventorySaving
    outages
    projectName
    qualitativeBenefits
    reactors
    refuelCycle
  }
}
    `;

/**
 * __useGetAllBcamsQuery__
 *
 * To run a query within a React component, call `useGetAllBcamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBcamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBcamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllBcamsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllBcamsQuery, GetAllBcamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllBcamsQuery, GetAllBcamsQueryVariables>(GetAllBcamsDocument, options);
      }
export function useGetAllBcamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllBcamsQuery, GetAllBcamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllBcamsQuery, GetAllBcamsQueryVariables>(GetAllBcamsDocument, options);
        }
export type GetAllBcamsQueryHookResult = ReturnType<typeof useGetAllBcamsQuery>;
export type GetAllBcamsLazyQueryHookResult = ReturnType<typeof useGetAllBcamsLazyQuery>;
export type GetAllBcamsQueryResult = Apollo.QueryResult<GetAllBcamsQuery, GetAllBcamsQueryVariables>;
export const SaveBcamDocument = gql`
    mutation SaveBcam($input: BcamInput!) {
  epri_postBcamRequest(input: $input)
}
    `;
export type SaveBcamMutationFn = Apollo.MutationFunction<SaveBcamMutation, SaveBcamMutationVariables>;

/**
 * __useSaveBcamMutation__
 *
 * To run a mutation, you first call `useSaveBcamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveBcamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBcamMutation, { data, loading, error }] = useSaveBcamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveBcamMutation(baseOptions?: Apollo.MutationHookOptions<SaveBcamMutation, SaveBcamMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<SaveBcamMutation, SaveBcamMutationVariables>(SaveBcamDocument, options);
}

export type SaveBcamMutationHookResult = ReturnType<typeof useSaveBcamMutation>;
export type SaveBcamMutationResult = Apollo.MutationResult<SaveBcamMutation>;
export type SaveBcamMutationOptions = Apollo.BaseMutationOptions<SaveBcamMutation, SaveBcamMutationVariables>;
export const DeleteBcamDocument = gql`
    mutation deleteBcam($id: Int!) {
        epri_postBcamRequest(input: {id: $id, action: Delete})
    }
`;
export type DeleteBcamMutationFn = Apollo.MutationFunction<DeleteBcamMutation, DeleteBcamMutationVariables>;

/**
 * __useDeleteBcamMutation__
 *
 * To run a mutation, you first call `useDeleteBcamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBcamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBcamMutation, { data, loading, error }] = useDeleteBcamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBcamMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBcamMutation, DeleteBcamMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<DeleteBcamMutation, DeleteBcamMutationVariables>(DeleteBcamDocument, options);
}

export type DeleteBcamMutationHookResult = ReturnType<typeof useDeleteBcamMutation>;
export type DeleteBcamMutationResult = Apollo.MutationResult<DeleteBcamMutation>;
export type DeleteBcamMutationOptions = Apollo.BaseMutationOptions<DeleteBcamMutation, DeleteBcamMutationVariables>;
export const GetBcamsDocument = gql`
    query GetBcams {
        epri_BCam {
            id
            modelRun
            modifiedDate
            projectName
        }
    }
`;

/**
 * __useGetBcamsQuery__
 *
 * To run a query within a React component, call `useGetBcamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBcamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBcamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBcamsQuery(baseOptions?: Apollo.QueryHookOptions<GetBcamsQuery, GetBcamsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetBcamsQuery, GetBcamsQueryVariables>(GetBcamsDocument, options);
}

export function useGetBcamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBcamsQuery, GetBcamsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetBcamsQuery, GetBcamsQueryVariables>(GetBcamsDocument, options);
}

export type GetBcamsQueryHookResult = ReturnType<typeof useGetBcamsQuery>;
export type GetBcamsLazyQueryHookResult = ReturnType<typeof useGetBcamsLazyQuery>;
export type GetBcamsQueryResult = Apollo.QueryResult<GetBcamsQuery, GetBcamsQueryVariables>;