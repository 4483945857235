import React from "react";
import {AppBar, Button, CircularProgress, Container} from "@mui/material";
import {AccountCircle, LogoutOutlined} from "@mui/icons-material";
import {EpriLogoShortThree, useAuth, useBreakpoints} from "epri-react-core-components";
import MenuItem from "@mui/material/MenuItem";
import './header.scss'
import TagManager from 'react-gtm-module';
import { useGetAllBcamsQuery} from "../generated/graphql";




const Header = () => {

    const {mobile} = useBreakpoints()
    const {isLoading, login, isAuthenticated, user, logout} = useAuth();
    const {data} = useGetAllBcamsQuery();
    const epri_BCam =(data?.epri_BCam ?? [])

    const sortedData = (epri_BCam?.sort((a, b) => {
        if (a?.modifiedDate && b?.modifiedDate) {
          return parseInt(b.modifiedDate ) - parseInt(a.modifiedDate);
        }
        return 0;
      }));
           
    if (isAuthenticated && (user?.userId !== undefined)) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'activeUser',
            id: `${user?.id}`,
            number_of_projects: epri_BCam.length,
            last_modified_project:sortedData[0]?.projectName
          },
        });

    }

    return <AppBar position='relative'
                   className={`flex items-center min-h-68 ${isAuthenticated ? 'bg-blue' : 'bg-black'}`}>
        <Container className='max-w-header items-center' style={{
            padding: "0 16px",
            margin: "0 16px",
            height: "68px",
            maxWidth: '1600px'
        }}>
            <div className='flex justify-between items-center' style={{height: '68px'}}>
                <a className='logo' href='https://www.epri.com'>
                    <EpriLogoShortThree/>
                </a>
                {isLoading ? (
                    <CircularProgress className="authLoading" style={{color: "white"}}/>
                ) : (<div className="loginLogoutUI">
                        {isAuthenticated ? (
                            <div>
                                <div className="username">
                                    <div className='flex'>
                                        <MenuItem key={`user-icon`}
                                                  className="item">
                                            <AccountCircle className="icon largeIcon"/>
                                            <span>{!mobile && `${user?.firstName ?? ""} ${user?.lastName ?? ""}`}</span>
                                        </MenuItem>

                                        <MenuItem
                                            key={`account-option-item-logout`}
                                            className="item"
                                            onClick={() => logout()}
                                            // onKeyDown={handleEnterOrSpaceKey(logout)}
                                        >
                                            <LogoutOutlined/>
                                        </MenuItem>
                                    </div>
                                </div>
                            </div>
                        ) : mobile
                            ? <AccountCircle color="inherit" onClick={login} className="rowItem"/>
                            : <Button color="inherit" onClick={login}>Login/Register</Button>
                        }
                    </div>
                )}</div>
        </Container>
    </AppBar>
}

export default Header