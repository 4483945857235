import {AUDIENCE, CLIENT_ID, isProxyEnabled, DOMAIN} from "./constants";
import {getAuthorizationHeader} from "epri-react-core-components";
import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";

const GRAPHQL_BASEURL = isProxyEnabled ? "" : process.env.REACT_APP_GRAPHQL_BASEURL || "https://svc-dev.epri.com";

const httpLink = createHttpLink({
    uri: `${GRAPHQL_BASEURL}/graphql`
});

const authLink = setContext(async (_, {headers}) => {
    const authHeader = await getAuthorizationHeader(DOMAIN, CLIENT_ID, AUDIENCE)
    if (authHeader) {
        return {
            headers: {
                ...headers,
                authorization: authHeader,
            }
        }
    }
});

const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

export default apolloClient;
