import React, { ReactNode } from "react";
import { SomethingWentWrong } from "epri-react-core-components";

type Props = {
  fallBackUI?: ReactNode;
};
type State = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<Props, State> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  get hasFallbackUI() {
    return this.props.fallBackUI !== undefined;
  }

  render() {
    if (this.state.hasError) {
      const { fallBackUI } = this.props;
      return this.hasFallbackUI ? fallBackUI : <SomethingWentWrong />;
    }

    return this.props.children;
  }
}

// ? [REVISIT] will we run into an issue w/ react-hook Provider & higherOrderComponents (non-hook consumer)
export default ErrorBoundary;
