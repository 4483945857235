import {Container, Markdown} from "epri-react-core-components";
import React from "react";
//import {useGetFooterInfoQuery} from "../generated/graphql";
import {Facebook, LinkedIn, Twitter, YouTube} from "@mui/icons-material";
import {notNil, footer} from "../constants";
import {ReactComponent as EpriLogoShortGrey} from './epri-logo-short-gray.svg'
import './footer.scss'

const Footer = () => {
    //const {langCode} = useAuth();

    //const {data, loading, error} = useGetFooterInfoQuery({variables: {locale: langCode}});

    const {address, telephone, established, footerLinks, googleMapsAddressLink} = footer ?? {};

    const _socialMediaLinks =
        footer?.socialMediaLinks?.linksCollection?.items.filter(notNil).map(({name: linkName, url}) => {
            let icon;
            let ariaLabel;
            linkName = linkName ?? "";

            if (/twitter/i.test(linkName)) {
                icon = <Twitter/>;
                ariaLabel = "twitter-link";
            }
            if (/linkedin/i.test(linkName)) {
                icon = <LinkedIn/>;
                ariaLabel = "linkedin-link";
            }
            if (/facebook/i.test(linkName)) {
                icon = <Facebook/>;
                ariaLabel = "facebook-link";
            }
            if (/youtube/i.test(linkName)) {
                icon = <YouTube/>;
                ariaLabel = "youtube-link";
            }

            return {name: linkName, url, icon, ariaLabel};
        }) ?? [];
    // if (loading || error) {
    //     return <div/>
    // }
    return <Container>
        <footer style={{borderTop: '1px solid rgba(0,0,0,.15)'}}>
            <div style={{margin: '20px 0 50px 20px'}}>
                <div className="flex flex-wrap mb-5 text-sm footer-links">
                    {footerLinks?.linksCollection?.items.filter(notNil).map((link, idx) =>
                        link.url ? (
                            <a key={link.url ?? idx} href={`https://www.epri.com${link.url}`}>
                                {link.name}
                            </a>
                        ) : null
                    )}
                </div>
                <div className="flex justify-center flex-warp items-center">
                    <a href={`https://www.epri.com`} className="mr-8">
                        <EpriLogoShortGrey/>
                    </a>
                    <div className="flex justify-between items-center flex-grow text-xs tracking-wide leading-4">
                        <div>
                            <p>
                                {googleMapsAddressLink ? <a href={googleMapsAddressLink}>{address}</a> : null}
                                {" | "}
                                <a href={`tel:${telephone}`}>{telephone}</a>
                            </p>
                            {established ? (
                                <p>
                                    <Markdown>{established}</Markdown>
                                </p>
                            ) : null}
                        </div>
                        <div className="flex justify-evenly w-40">
                            {_socialMediaLinks.map((link, idx) =>
                                link.url ? (
                                    <a
                                        key={link.url ?? idx}
                                        href={link.url}
                                        aria-label={link.ariaLabel}
                                        className="icon-background"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {link.icon}
                                    </a>
                                ) : null
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </Container>
}

export default Footer