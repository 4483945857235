import React from "react";

const HandbookHome = React.lazy(() => import('./HandbookHome'))
const BCAM = React.lazy(() => import('./BCAM'))
const MTA = React.lazy(() => import('./MTA'))
const EditOnlineBcam = React.lazy(() => import("./OnlineBcam/Edit"))
const OnlineBcamList = React.lazy(() => import("./OnlineBcam/List"))
const UsersGuide = React.lazy(() => import('./OnlineBcam/UsersGuide/UsersGuide'))

const routes = {
    publicRoutes: [
        {
            title: "Toolbox Home",
            exact: true,
            route: '/',
            component: HandbookHome
        },
        {
            title: "Business Case Analysis Models",
            exact: true,
            route: '/bcam',
            component: BCAM
        },
        {
            title: "Modernization Technology Assessment",
            exact: true,
            route: '/mta',
            component: MTA
        },
    ],
    authorizedRoutes: [
        {
            title: "Online BCAM",
            exact: true,
            route: '/online-bcam',
            navItem: true,
            component: OnlineBcamList
        },
        {
            title: "Edit Online BCAM",
            exact: true,
            route: '/online-bcam/edit/:id',
            component: EditOnlineBcam
        },
        {
            title: "Online BCAM User's Guide",
            exact: true,
            navItem: true,
            route: '/online-bcam/users-guide',
            component: UsersGuide
        }
    ]
}

export default routes;