import React from "react";
import {ArrowBack} from "@mui/icons-material";
import {NavLink, useHistory} from "react-router-dom";
import routes from "../../routes";
import {useAuth} from 'epri-react-core-components'
import '../header.scss'
import './nav.scss'

const Nav = () => {

    const history = useHistory()
    const {isAuthenticated} = useAuth()

    const styledNavLink = (title: string, exact: boolean, route: string) => <NavLink exact={exact} to={route}
                                                                                     className='mr-4 text-white no-underline'
                                                                                     activeClassName='active'
                                                                                     key={route}>{title}</NavLink>

    return <div className="nav">
        <div className='row mainRow'>
            <ArrowBack sx={{marginRight: '20px'}} onClick={() => history.goBack()}/>
            <div>
                <span>Nuclear Plant Modernization Toolbox</span>
            </div>
        </div>
        <div className='row pl-16 mb-2 leading-8 w-full'>
            {routes.publicRoutes?.map(it => styledNavLink(it.title, it.exact, it.route))}
            {isAuthenticated && routes.authorizedRoutes.filter(it => it.navItem)
                .map(it => styledNavLink(it.title, it.exact, it.route))}
        </div>
    </div>
}

export default Nav