import * as React from "react";
import * as ReactDOM from "react-dom";

import App from "./App";
import CssBaseline from "@mui/material/CssBaseline";
import apolloClient from "./apolloClient";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {SnackbarProvider} from "notistack";
import {ApolloProvider} from "@apollo/client";
import {BrowserRouter as Router} from "react-router-dom";

const theme = createTheme();

ReactDOM.render(<ThemeProvider theme={theme}>
    <CssBaseline/>
    <SnackbarProvider>
        <ApolloProvider client={apolloClient}>
            <Router>
                <App/>
            </Router>
        </ApolloProvider>
    </SnackbarProvider>
</ThemeProvider>, document.getElementById("root"));