import React from "react";
import {AuthorizedRoute, UrlAccessRoute} from "epri-react-core-components";

const LoggedInRoute = ({component}: { component: React.LazyExoticComponent<any> }) => {
    return <AuthorizedRoute>
        <UrlAccessRoute url="/pmtk">
        {React.createElement(component)}
        </UrlAccessRoute>
    </AuthorizedRoute>
}

export default LoggedInRoute